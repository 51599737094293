import type { ReactNode } from 'react';

import { Loader } from 'modules/ui';

import { useIsAdmin } from '../../hooks/useIsAdmin';

export interface AdminFirewallProps {
    loader?: ReactNode;
    firewall?: ReactNode;
    children: ReactNode;
}

export const AdminFirewall = ({ loader = <Loader />, firewall = null, children }: AdminFirewallProps) => {
    const isAdmin = useIsAdmin();

    if (isAdmin === undefined) {
        return <>{loader}</>;
    }

    if (isAdmin === false) {
        return <>{firewall}</>;
    }

    return <>{children}</>;
};
