import { useDispatch } from 'react-redux';

import { useAppSelector } from 'hooks/useAppSelector';

import { TagManager } from 'services/utilities';

import { setSecretsNameFilter } from '../services/actions';
import { selectFilteredSecretsCount, selectSecretsNameFilter, selectViewFilteredSecrets } from '../services/selectors';

export const useSecretsNameFilter = () => {
    const filter = useAppSelector(selectSecretsNameFilter);
    const secrets = useAppSelector(selectViewFilteredSecrets);
    const count = useAppSelector(selectFilteredSecretsCount);

    const dispatch = useDispatch();

    return {
        filter,
        secrets,
        count,
        updateFilter: (value: string) => {
            TagManager.push('used_search');
            dispatch(setSecretsNameFilter(value));
        },
    };
};
