import { useFela } from 'react-fela';
import { FormattedMessage } from 'react-intl';

import * as felaRules from './ErrorMessage.rules';

export const ErrorMessage = () => {
    const { css } = useFela();

    return (
        <div className={css(felaRules.message)}>
            <FormattedMessage id="error.general" />
        </div>
    );
};
