import type { TRuleWithTheme } from 'styles/theme';

export const container: TRuleWithTheme = ({ theme }) => ({
    '&.ant-tabs .ant-tabs-nav': {
        width: 'fit-content',

        marginBottom: '0.75rem',
    },
    '&.ant-tabs .ant-tabs-ink-bar': {
        borderRadius: 2,
        backgroundColor: theme.colors.brightMagenta,
    },
    '&.ant-tabs .ant-tabs-tab': {
        paddingTop: 0,
        paddingRight: '0.875rem',
        paddingBottom: '0.375rem',
        paddingLeft: '0.875rem',
    },
    '&.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn': {
        color: theme.colors.brightMagenta,
    },

    '&.ant-tabs .ant-tabs-tab .ant-tabs-tab-btn': {
        fontSize: '1rem',
        fontWeight: 600,
        lineHeight: '1.5em',
        letterSpacing: '0.0125em',

        color: theme.colors.foregroundsTertiary,
    },

    '&.ant-tabs .ant-tabs-tab .ant-tabs-tab-btn:active': {
        color: theme.colors.foregroundsTertiary,
    },

    '&.ant-tabs.ant-tabs-top .ant-tabs-nav::before': {
        borderRadius: 2,
        borderBottomWidth: 2,
        borderBottomStyle: 'solid',
        borderBottomColor: theme.colors.foregroundsSeparator,
    },
});
