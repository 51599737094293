import type { TRuleWithTheme } from 'styles/theme';

export const label: TRuleWithTheme = () => ({
    display: 'block',
    marginBottom: '0.375rem',
});

export const container: TRuleWithTheme = () => ({
    display: 'flex',
    alignItems: 'center',
});
