import type { RouteObject } from 'react-router-dom-v5-compat';

import config from 'config';

export const fullWidthLayoutRoutes = [
    config.routes.newSecret,
    config.routes.updateSecret,
    config.routes.secret,
    config.routes.secrets,
    config.routes.updateSecret,
    config.routes.securityAuditUpdateSecret,
    config.routes.securityAuditLevelUpdateSecret,
    config.routes.securityAuditAgeUpdateSecret,
    config.routes.securityAuditLeaksUpdateSecret,
].map(route => ({ path: route })) satisfies RouteObject[];
