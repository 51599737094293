import type { TRuleWithTheme } from 'styles/theme';

export const container: TRuleWithTheme<{ hasMeta: boolean }> = ({ hasMeta }) => ({
    width: '100%',
    maxWidth: '32.5rem',

    extend: {
        condition: hasMeta,
        style: {
            paddingBottom: '1rem',
        },
    },
});

export const tag: TRuleWithTheme = () => ({
    '&.ant-tag': {
        marginTop: '0.5rem',
        marginRight: '0.5rem',
    },
});

export const results: TRuleWithTheme = () => ({
    marginTop: '0.5rem',
});

export const autocomplete: TRuleWithTheme = () => ({
    '&.ant-select': {
        width: '100%',
    },
});

export const groupOption: TRuleWithTheme = () => ({
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
});
