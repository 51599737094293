import { createAction } from '@reduxjs/toolkit';

import type { Secret } from '../../types';
import types from '../actionTypes';

export const shareSecret = createAction(types.SHARE_SECRET, (id: Secret['id'], activate: boolean = true) => ({
    meta: { id },
    payload: { activate },
}));

export const shareSecretSuccess = createAction(
    types.SHARE_SECRET_SUCCESS,
    (id: Secret['id'], share: Secret['share'] = undefined) => ({
        meta: { id, lastSuccessAt: new Date().toISOString() },
        payload: share,
    }),
);

export const shareSecretFailure = createAction(types.SHARE_SECRET_FAILURE, (id: Secret['id'], error: any) => ({
    meta: { id },
    payload: undefined,
    error,
}));

export const shareSecretReset = createAction(types.SHARE_SECRET_RESET, (id: Secret['id']) => ({
    meta: { id },
    payload: undefined,
}));
