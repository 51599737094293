import { createAction } from '@reduxjs/toolkit';

import types from '../actionTypes';

export const deleteSecrets = createAction(types.DELETE_SECRETS);

export const deleteSecretsSuccess = createAction(types.DELETE_SECRETS_SUCCESS);

export const deleteSecretsFailure = createAction(types.DELETE_SECRETS_FAILURE, (error: unknown) => ({
    error,
    payload: undefined,
}));
