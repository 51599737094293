import { createAction } from '@reduxjs/toolkit';

import types from './actionTypes';

export const fetchChangelog = createAction(types.FETCH_CHANGELOG);

export const fetchChangelogSuccess = createAction<string>(types.FETCH_CHANGELOG_SUCCESS);

export const fetchChangelogFailure = createAction(types.FETCH_CHANGELOG_FAILURE, (error: any) => ({
    payload: undefined,
    error,
}));
