import type { TRuleWithTheme } from 'styles/theme';

export const container: TRuleWithTheme = ({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',

    paddingTop: '0.75rem',
    paddingRight: '0.5rem',
    paddingBottom: '0.5rem',
    paddingLeft: '0.5rem',

    marginTop: '2rem',

    backgroundColor: theme.colors.backgroundsTertiary,

    borderRadius: '0.5rem',

    filter: 'drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.16))',
});

export const textWrapper: TRuleWithTheme = ({ theme }) => ({
    display: 'flex',
    alignItems: 'flex-start',

    marginBottom: '0.75rem',

    fontSize: '0.875rem',
    fontWeight: 700,
    lineHeight: 1.14,

    color: theme.colors.black,
});

export const icon: TRuleWithTheme = () => ({
    flexShrink: 0,

    position: 'relative',
    top: '-0.25rem',

    marginRight: '0.25rem',
});
