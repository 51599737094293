import tinyColor from 'tinycolor2';

import type { TRuleWithTheme } from 'styles/theme';

export const card: TRuleWithTheme = ({ theme: { colors, zIndexes } }) => ({
    position: 'fixed',
    right: '1.5rem',
    bottom: '6.25rem',
    zIndex: zIndexes.cta,

    padding: '1rem 1rem 1.5rem',
    maxWidth: 196,

    backgroundColor: colors.white,
    boxShadow: `0 2px 6px 0 ${tinyColor(colors.black).setAlpha(0.16).toString()}`,

    borderRadius: '0.75rem',

    min768: {
        right: '2rem',
        bottom: '9.5rem',
    },
});

export const title: TRuleWithTheme = ({ theme: { colors } }) => ({
    color: colors.black,
    letterSpacing: '0.4px',
    lineHeight: 1.33,
    fontSize: '1.5rem',
    fontWeight: 700,
    marginBottom: '0.5rem',
});

export const text: TRuleWithTheme = ({ theme: { colors } }) => ({
    color: colors.brownGrey2,
    letterSpacing: '0.61px',
    fontSize: '0.88rem',
    marginBottom: '1rem',
});

export const buttonClose: TRuleWithTheme = () => ({
    position: 'absolute',
    border: 'unset',
    right: '0.5rem',
    top: '0.5rem',
    padding: 0,
    height: '1.5rem',
    width: '1.5rem',
    cursor: 'pointer',
});
