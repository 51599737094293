import type { TRuleWithTheme } from 'styles/theme';

export const container: TRuleWithTheme = () => ({
    overflowY: 'auto',
});

export const spinnerContainer: TRuleWithTheme = () => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '1rem',
    height: '4rem',
});
