import type { ReactNode } from 'react';

import { useFela } from 'react-fela';

import AutoSizer from 'react-virtualized-auto-sizer';

import { useAppSelector } from 'hooks/useAppSelector';

import { Loadable } from 'modules/ui';

import { selectLoadSecretsApi } from '../../services/selectors';

import { container } from './SecretsListContainer.rules';

export interface SecretsListContainerProps {
    children: AutoSizer['props']['children'];
    isEmpty: boolean;
    empty: ReactNode;
}

export const SecretsListContainer = ({ children, isEmpty, empty }: SecretsListContainerProps) => {
    const { inProgress: showLoader } = useAppSelector(selectLoadSecretsApi);

    const { css } = useFela();

    return (
        <Loadable showLoader={showLoader}>
            <div className={css(container)}>{isEmpty ? empty : <AutoSizer>{children}</AutoSizer>}</div>
        </Loadable>
    );
};
