import type { SVGProps } from 'react';

export const Spinner = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
        <path
            d="M18.984 11.992a7.992 7.992 0 1 1-15.984 0A7.992 7.992 0 0 1 10.992 4m8.256 7.098L16.8 12.709m2.448-1.611 1.611 2.448"
            fill="none"
            stroke="#000"
            strokeLinecap="square"
            strokeWidth="2"
        />
    </svg>
);
