import type { TRuleWithTheme } from 'styles/theme';

export const container: TRuleWithTheme = () => ({
    display: 'flex',
    alignItems: 'center',
});

export const totp: TRuleWithTheme = ({ theme }) => ({
    overflow: 'hidden',

    marginRight: '1.25rem',

    fontSize: '1.125rem',
    lineHeight: '1.5rem',
    letterSpacing: '0.125rem',
});

export const title: TRuleWithTheme = () => ({
    display: 'flex',
    alignItems: 'center',

    gap: '0.5rem',

    marginBottom: '0.75rem',
});

export const text: TRuleWithTheme = () => ({
    marginBottom: '1.75rem',
});
