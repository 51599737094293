import type { ReactNode } from 'react';
import { useFela } from 'react-fela';

import * as felaRules from './SettingsItem.rules';

export interface SettingsItemProps {
    children: ReactNode;
}

export const SettingsItem = ({ children }: SettingsItemProps) => {
    const { css } = useFela();

    return <div className={css(felaRules.container)}>{children}</div>;
};
