import { useFela } from 'react-fela';
import { useIntl } from 'react-intl';

import { Icon } from 'modules/ui';

import * as felaRules from './GeneratePasswordCloseButton.rules';

export interface GeneratePasswordCloseButtonProps {
    onClick: () => void;
}

export const GeneratePasswordCloseButton = ({ onClick }: GeneratePasswordCloseButtonProps) => {
    const { css } = useFela();

    const intl = useIntl();

    return (
        <button
            type="button"
            onClick={onClick}
            className={css(felaRules.button)}
            aria-label={intl.formatMessage({
                id: 'secret.generate.close',
            })}
        >
            <Icon type="x" />
        </button>
    );
};
