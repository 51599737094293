import type { TRuleWithTheme } from 'styles/theme';

export const form: TRuleWithTheme = () => ({
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
});

export const description: TRuleWithTheme = () => ({
    marginTop: '0.125rem',
});
