import type { TRuleWithTheme } from 'styles/theme';

export const container: TRuleWithTheme<{ removable: boolean }> = ({ removable }) => ({
    width: '100%',

    paddingTop: removable ? '0.75rem' : 0,

    '> .Text': {
        position: 'relative',
    },
});

export const list: TRuleWithTheme = () => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '0.75rem',
});
