import { useFela } from 'react-fela';
import { FormattedMessage } from 'react-intl';

import enSrc from 'assets/images/flags/en.png';
import csSrc from 'assets/images/flags/cs.png';
import deSrc from 'assets/images/flags/de.png';
import plSrc from 'assets/images/flags/pl.png';
import esSrc from 'assets/images/flags/es.png';
import ruSrc from 'assets/images/flags/ru.png';

import { Dropdown } from 'modules/ui';

import { Language, supportedLanguages } from '../../contants';

import { useLocale } from '../../hooks/useLocale';
import { useSetLocale } from '../../hooks/useSetLocale';

import * as felaRules from './LanguageSwitcher.rules';

const imgMap: Record<Language, string> = {
    [Language.EN]: enSrc,
    [Language.CS]: csSrc,
    [Language.DE]: deSrc,
    [Language.PL]: plSrc,
    [Language.ES]: esSrc,
    [Language.RU]: ruSrc,
};

export const LanguageSwitcher = () => {
    const { css } = useFela();
    const { locale } = useLocale();

    const setLocale = useSetLocale();

    return (
        <Dropdown
            items={supportedLanguages.map(lang => ({
                key: lang,
                label: (
                    <span className={css(felaRules.value)}>
                        <img src={imgMap[lang]} alt={lang} className={css(felaRules.flag)} />
                        <FormattedMessage id={`language.${lang}`} />
                    </span>
                ),
                active: locale === lang,
                onClick: () => {
                    setLocale(lang);
                },
            }))}
        >
            <span className={css(felaRules.value)}>
                <img src={imgMap[locale]} alt={locale} className={css(felaRules.flag)} />
                <FormattedMessage id={`language.${locale}`} />
            </span>
        </Dropdown>
    );
};
