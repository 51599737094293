import { put, all, select } from 'redux-saga/effects';

import { api } from 'config/antonio';
import { config } from 'config/config';
import { logger } from 'config/logger';

import { takeLatestRequest } from 'services/utilities/takeRequest';

import { actions as messagesActions } from 'modules/messages';

import type { WhitelistUser } from '../../types';

import {
    fetchWhitelist,
    fetchWhitelistSuccess,
    fetchWhitelistFailure,
    fetchWhitelistCancel,
    fetchWhitelistNext,
    fetchWhitelistRequest,
} from '../actions';
import { normalizeWhitelist } from '../normalizers';
import { selectWhitelistApi } from '../selectors';

function* handler(
    action: ReturnType<typeof fetchWhitelist> | ReturnType<typeof fetchWhitelistNext>,
    signal: AbortSignal,
) {
    const apiState: ReturnType<typeof selectWhitelistApi> = yield select(selectWhitelistApi);

    const isNext = action.type === fetchWhitelistNext.toString();

    if (isNext && !apiState.nextPageToken) {
        return;
    }

    const params: Record<string, any> = {};

    if (action.meta.filter) {
        params.query = action.meta.filter;
    }

    if (isNext) {
        params.nextPageToken = apiState.nextPageToken;
    }

    yield put(fetchWhitelistRequest(isNext));

    try {
        const { data } = yield* api.get<{
            data: WhitelistUser[];
            metadata: {
                nextPageToken?: string;
            };
        }>(config.api.contactsQuery, {
            params,
            signal,
        });

        const { entities, result } = normalizeWhitelist(data.data);

        yield put(
            fetchWhitelistSuccess(entities.whitelistUser, result, {
                filter: action.meta.filter,
                nextPageToken: data.metadata.nextPageToken,
                isNext,
            }),
        );
    } catch (e) {
        yield put(
            messagesActions.displayErrorMessage({
                message: { id: 'error.whitelist.fetch' },
            }),
        );
        logger.error(e);

        yield put(fetchWhitelistFailure(e.message, isNext));
    }
}

export function* fetchWhitelistSaga() {
    yield all([
        yield takeLatestRequest(
            {
                actionCreator: fetchWhitelist,
                cancelActionFunction: () => fetchWhitelistCancel(),
                idSelector: () => 'whitelistUsers',
            },
            handler,
        ),
        yield takeLatestRequest(
            {
                actionCreator: fetchWhitelistNext,
                cancelActionFunction: () => fetchWhitelistCancel(true),
                idSelector: () => 'whitelistUsers',
            },
            handler,
        ),
    ]);
}
