import loglevel from 'loglevel';
import { captureException } from '@sentry/react';

import { IS_PRODUCTION } from 'constants/index';

if (IS_PRODUCTION) {
    loglevel.disableAll();
} else {
    loglevel.enableAll();
}

const { warn, info, trace, debug } = loglevel;

const error = (err: any) => {
    loglevel.error(err);
    captureException(err);
};

export const logger = {
    warn,
    info,
    trace,
    debug,
    error,
};
