import { useForm, useFormState } from 'react-final-form';

import type { SecretFormValues } from 'modules/secrets/types';

export const useUpdateSecretForm = () => {
    const { submit } = useForm<SecretFormValues>();
    const { dirty, submitting, submitError } = useFormState<SecretFormValues>();

    return {
        dirty,
        submitting,
        submitError,
        submit,
    };
};
