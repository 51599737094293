import type { ReactNode } from 'react';
import { useFela } from 'react-fela';

import { Heading } from 'modules/ui';

import * as felaRules from './SecurityAuditGroupItem.rules';

export interface SecurityAuditGroupItemProps {
    name: ReactNode;
    count: number;
    isFirstGroup?: boolean;
}

export const SecurityAuditGroupItem = ({ name, count, isFirstGroup = false }: SecurityAuditGroupItemProps) => {
    const { css } = useFela({
        isFirstGroup,
    });

    return (
        <div className={css(felaRules.container)}>
            <Heading elementType="h2" color="brownGrey" customStyle={felaRules.heading}>
                {name}
                <span className={css(felaRules.count)}> ({count})</span>
            </Heading>
        </div>
    );
};
