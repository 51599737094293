import type { TRuleWithTheme } from 'styles/theme';

export const message: TRuleWithTheme = ({ theme }) => ({
    marginTop: 20,

    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    columnGap: 8,
    alignItems: 'center',

    color: theme.colors.password[0],
});

export const text: TRuleWithTheme = ({ theme }) => ({
    fontSize: '1.25rem',
    lineHeight: 1.6,
});
