import type { AccessPermission, EntityWithPermissions, User } from 'types';

import type { Group } from 'modules/groups';
import type { WhitelistUser } from 'modules/whitelist';

import type { TOTPError } from './components/TOTPFromQRCodeButton/errors/TOTPError';
import type { ListItemType, SecurityAge, SecurityLeaks, SecurityLevel } from './constants';

export enum SearchOptionType {
    /**
     * Literal search term (e.g. "foo bar"). Saved to search history when neither record, tag or group is selected. Search is done locally.
     */
    FILTER = 'filter',
    /**
     * Secret tag searchable by name. Search is done locally.
     */
    TAG = 'tag',
    /**
     * Google Workspace group searchable by name. Search results are fetched from API.
     */
    GROUP = 'group',
    /**
     * Secret record searchable by name or username. Search is done locally.
     */
    RECORD = 'record',
}

export interface SearchHistory {
    value: string;
    label: string;
    type: SearchOptionType;
}

export enum SearchSecretAttributeType {
    TAG = 'tag',
    GROUP = 'group',
}

export interface ViewFilter {
    id: string;
    email: string;
    name: string;
    type: 'user' | 'group';
}

export interface Base64File {
    name: string;
    data: string;
}

export interface Share {
    id: string;
    until: string;
}

export interface TOTPInfo {
    algorithm: string;
    codeLength: number;
    period: number;
}

export interface TOTP {
    code: string;
    validityStart: number;
    validityEnd: number;
}

export interface Secret {
    id: string;
    userPermissions: AccessPermission[];
    createdAt?: string;
    updatedAt?: string;
    name: string;
    username?: string;
    password?: string;
    passwordHashed?: string;
    passwordUpdatedAt?: string;
    hasPassword?: boolean;
    note?: string;
    web?: string;
    tags?: string[];
    securityLevel?: SecurityLevel;
    groups?: Group[];
    whitelistUsers?: WhitelistUser[];
    createdBy?: User;
    file?: Base64File;
    ignoreSecurityReport?: boolean;
    leaksIncidence?: number;
    share?: Share;
    hasTOTP: boolean;
    TOTP?: TOTPInfo;
    favicon?: string;
}

export interface ListSecretBase {
    key: string;
    type: ListItemType.SECRET;
}

export interface ListGroupBase {
    key: string;
    type: ListItemType.GROUP;
}

export interface ListSecret extends ListSecretBase, Secret {
    latinisedName: string;
    group: string;
}

export interface ListGroup extends ListGroupBase {
    name: string;
}

export interface SecurityAuditLevelSecret extends ListSecretBase, Secret {}

export interface SecurityAuditLevelGroup extends ListGroupBase {
    securityLevel: SecurityLevel;
    count: number;
}

export interface SecurityAuditAgeSecret extends ListSecretBase, Secret {
    securityAge: SecurityAge;
}

export interface SecurityAuditAgeGroup extends ListGroupBase {
    securityAge: SecurityAge;
    count: number;
}

export interface SecurityAuditDuplicitiesSecret extends ListSecretBase, Secret {
    duplicities?: number;
}

export interface SecurityAuditDuplicitiesGroup extends ListGroupBase {
    count: number;
}

export interface SecurityAuditLeaksSecret extends ListSecretBase, Secret {
    securityLeaks: SecurityLeaks;
}

export interface SecurityAuditLeaksGroup extends ListGroupBase {
    securityLeaks: SecurityLeaks;
    count: number;
}

export type SecretById = {
    [key: Secret['id']]: Secret;
};

export enum EntityKey {
    SECRETS = 'secrets',
}

export type CopyableSecretKeys = keyof Pick<Secret, 'username' | 'password' | 'TOTP'>;

export type SecretFormValues = Pick<
    Secret,
    'name' | 'username' | 'password' | 'note' | 'web' | 'tags' | 'file' | 'hasTOTP'
> & {
    groups?: EntityWithPermissions[];
    whitelistUsers?: EntityWithPermissions[];
    TOTP: string | null | TOTPError;
};

export type SecurityAuditLevelItem = SecurityAuditLevelSecret | SecurityAuditLevelGroup;

export type SecurityAuditAgeItem = SecurityAuditAgeSecret | SecurityAuditAgeGroup;

export type SecurityAuditLeaksItem = SecurityAuditLeaksSecret | SecurityAuditLeaksGroup;

export type SecurityAuditReportItem =
    | SecurityAuditDuplicitiesGroup
    | SecurityAuditDuplicitiesSecret
    | SecurityAuditAgeItem
    | SecurityAuditLeaksItem
    | SecurityAuditLevelItem;

export interface ListItemSize {
    desktop: number;
    mobile: number;
}
