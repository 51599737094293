const modulePrefix = 'settings';

export default {
    UPDATE_SETTINGS: `${modulePrefix}/UPDATE_SETTINGS`,
    UPDATE_SETTINGS_SUCCESS: `${modulePrefix}/UPDATE_SETTINGS_SUCCESS`,
    UPDATE_SETTINGS_FAILURE: `${modulePrefix}/UPDATE_SETTINGS_FAILURE`,
    UPDATE_SETTINGS_RESET: `${modulePrefix}/UPDATE_SETTINGS_RESET`,

    LOAD_SETTINGS: `${modulePrefix}/LOAD_SETTINGS`,
    LOAD_SETTINGS_SUCCESS: `${modulePrefix}/LOAD_SETTINGS_SUCCESS`,
    LOAD_SETTINGS_FAILURE: `${modulePrefix}/LOAD_SETTINGS_FAILURE`,
    LOAD_SETTINGS_RESET: `${modulePrefix}/LOAD_SETTINGS_RESET`,

    LOAD_ORDER: `${modulePrefix}/LOAD_ORDER`,
    LOAD_ORDER_SUCCESS: `${modulePrefix}/LOAD_ORDER_SUCCESS`,
    LOAD_ORDER_FAILURE: `${modulePrefix}/LOAD_ORDER_FAILURE`,
    LOAD_ORDER_RESET: `${modulePrefix}/LOAD_ORDER_RESET`,

    SET_SETTINGS: `${modulePrefix}/SET_SETTINGS`,
};
