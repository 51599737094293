import type { ReactNode } from 'react';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Popover } from 'antd';
import { useFela } from 'react-fela';

import { Button, Icon } from 'modules/ui';

import type { Group } from '../../types';
import { GroupDetail } from '../GroupDetail';

import * as felaRules from './GroupDetailButton.rules';

// If we use name straight, ant-button adds a span we don't want
const Name = ({ children }: { children: ReactNode }) => <>{children}</>;

export interface GroupDetailButtonProps {
    id: Group['id'];
    name?: Group['name'];
}

export const GroupDetailButton = ({ id, name }) => {
    const [visible, setVisible] = useState(false);

    const { css } = useFela({ popoverOpen: visible });

    return (
        <Popover
            content={<GroupDetail id={id} />}
            trigger="click"
            open={visible}
            onOpenChange={v => {
                setVisible(v);
            }}
            overlayClassName={css(felaRules.popover)}
            align={{ points: ['tl', 'bl'], offset: [0, 4] }}
            arrow={false}
        >
            <Button
                icon={<Icon type="caret" className={css(felaRules.caret)} />}
                type="text"
                color="foregroundsPrimary"
                size="large"
                customStyle={felaRules.button}
            >
                {name ? <Name>{name}</Name> : <FormattedMessage id="group.detail" />}
            </Button>
        </Popover>
    );
};
