import type { TRuleWithTheme } from 'styles/theme';

export const container: TRuleWithTheme = ({ theme }) => ({
    paddingTop: '1rem',
    paddingBottom: '1rem',

    borderTop: `1px solid ${theme.colors.lightGrey4}`,

    marginBottom: 0,

    min768: {
        marginBottom: 0,
    },

    ':first-of-type': {
        borderTop: 'none',
        paddingTop: 0,
    },

    ':last-of-type': {
        paddingBottom: 0,
    },
});
