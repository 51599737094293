import { setLocale } from '@ackee/jerome';
import { useDispatch } from 'react-redux';

import type { Language } from '../contants';

export const useSetLocale = () => {
    const dispatch = useDispatch();

    return (locale: `${Language}`) => {
        dispatch(setLocale(locale));
    };
};
