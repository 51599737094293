import { useFela } from 'react-fela';

import { SecretsSearch } from '../../SecretsSearch';
import { AddSecretButton } from '../../AddSecretButton';

import * as felaRules from './SecretsHeader.rules';

export const SecretsHeader = () => {
    const { css } = useFela();

    return (
        <div className={css(felaRules.container)}>
            <SecretsSearch />
            <AddSecretButton />
        </div>
    );
};
