const env = {
    PRODUCTION: 'production',
    DEVELOPMENT: 'development',
} as const;

export const IS_DEVELOPMENT = process.env.NODE_ENV === env.DEVELOPMENT;
export const IS_PRODUCTION = process.env.NODE_ENV === env.PRODUCTION;

export const IS_DEMO = process.env.REACT_APP_DEMO === 'true';
export const IS_GA_ENABLED = process.env.REACT_APP_IS_GA_ENABLED === 'true';

export const IS_SAAS = process.env.REACT_APP_SAAS === 'true';

// The value is used in Cypress to trigger certain tests only for SAAS build
window.IS_SAAS_CYPRESS = IS_SAAS;
window.IS_DEMO_CYPRESS = IS_DEMO;
