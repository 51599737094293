import downloadFile from 'js-file-download';
import { put, takeLeading } from 'redux-saga/effects';

import { config } from 'config/config';
import { logger } from 'config/logger';
import { api } from 'config/antonio';

import { actions as messagesActions } from 'modules/messages';

import { SUPPORTED_FILE_MIME, EXPORT_FILE_NAME } from '../../constants';

import { exportRequestSuccess, exportRequestFailure, exportRequest } from '../actions';

function* exportHandler() {
    try {
        const { data } = yield* api.post<Blob>(config.api.export, null, {
            headers: {
                accept: SUPPORTED_FILE_MIME,
            },
            responseDataType: 'blob',
        });

        downloadFile(data, EXPORT_FILE_NAME, SUPPORTED_FILE_MIME);

        yield put(exportRequestSuccess());
    } catch (e) {
        yield put(
            messagesActions.displayErrorMessage({
                message: { id: 'error.import-export.export' },
            }),
        );
        logger.error(e);

        yield put(exportRequestFailure());
    }
}

export default function* exportSaga() {
    yield takeLeading(exportRequest.toString(), exportHandler);
}
