import { FormattedMessage } from 'react-intl';

import { Icon, Alert } from 'modules/ui';

import * as felaRules from './DemoNotice.rules';

export const DemoNotice = () => (
    <Alert
        showIcon
        type="warning"
        icon={<Icon type="warning" width={20} height={20} />}
        message={<FormattedMessage id="demo.notice" />}
        customStyle={felaRules.container}
    />
);
