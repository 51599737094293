import { useFela } from 'react-fela';

import { ButtonHTMLType } from '../Button';
import type { IconProps } from '../Icon';
import { Icon } from '../Icon';

import { defaultStyle, ghostTypeStyle, primaryTypeStyle } from './IconButton.rules';

export enum IconButtonType {
    DEFAULT = 'default',
    PRIMARY = 'primary',
    GHOST = 'ghost',
}

const typeStyleMap = {
    [IconButtonType.DEFAULT]: [defaultStyle],
    [IconButtonType.PRIMARY]: [defaultStyle, primaryTypeStyle],
    [IconButtonType.GHOST]: [ghostTypeStyle],
};

export interface IconButtonProps extends Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'type'> {
    icon: IconProps['type'] | IconProps;
    type?: `${IconButtonType}`;
    htmlType?: `${ButtonHTMLType}`;
    customStyle?: typeof defaultStyle;
    children?: React.ReactNode;
}

export const IconButton = ({
    type = IconButtonType.DEFAULT,
    icon,
    htmlType = ButtonHTMLType.BUTTON,
    customStyle,
    children = null,
    ...props
}: IconButtonProps) => {
    const { css } = useFela();

    return (
        <button
            {...props}
            className={`IconButton IconButton-${type} ${css(...typeStyleMap[type], customStyle)}`}
            type={htmlType}
        >
            {typeof icon === 'string' ? <Icon type={icon} /> : <Icon {...icon} />}
            {children}
        </button>
    );
};
