import type { ReactNode } from 'react';

import { Authenticated } from '@ackee/petrus';

import { Loader } from 'modules/ui';

import { Auth } from '../Auth';

const AuthLoader = () => <Loader randomize />;

export const Authorized = ({ children }: { children: ReactNode }) => (
    <Authenticated LoaderComponent={AuthLoader} FallbackComponent={Auth}>
        {children}
    </Authenticated>
);
