import type { SVGProps } from 'react';

export const Guide = (props: SVGProps<SVGSVGElement>) => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M7.0001 9.99998H10.0001V6.99998L6.5001 3.49998C7.61973 2.96525 8.8776 2.79078 10.1005 3.0006C11.3234 3.21041 12.4512 3.79419 13.3285 4.67155C14.2059 5.5489 14.7897 6.67668 14.9995 7.89958C15.2093 9.12248 15.0348 10.3803 14.5001 11.5L20.5001 17.5C20.8979 17.8978 21.1214 18.4374 21.1214 19C21.1214 19.5626 20.8979 20.1022 20.5001 20.5C20.1023 20.8978 19.5627 21.1213 19.0001 21.1213C18.4375 21.1213 17.8979 20.8978 17.5001 20.5L11.5001 14.5C10.3805 15.0347 9.1226 15.2092 7.8997 14.9994C6.6768 14.7895 5.54903 14.2058 4.67167 13.3284C3.79432 12.451 3.21054 11.3233 3.00072 10.1004C2.7909 8.87748 2.96537 7.61961 3.5001 6.49998L7.0001 9.99998Z"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
