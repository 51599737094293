import type { SVGProps } from 'react';

export const Chat = (props: SVGProps<SVGSVGElement>) => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M19 14L16.5455 11.5455H10.8182C10.6012 11.5455 10.3931 11.4593 10.2396 11.3058C10.0862 11.1524 10 10.9443 10 10.7273V5.81818C10 5.60119 10.0862 5.39308 10.2396 5.23964C10.3931 5.0862 10.6012 5 10.8182 5H18.1818C18.3988 5 18.6069 5.0862 18.7604 5.23964C18.9138 5.39308 19 5.60119 19 5.81818V14Z"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M14 15.5V15.7273C14 15.9443 13.9138 16.1524 13.7604 16.3058C13.6069 16.4593 13.3988 16.5455 13.1818 16.5455H7.45455L5 19V10.8182C5 10.6012 5.0862 10.3931 5.23964 10.2396C5.39308 10.0862 5.60119 10 5.81818 10H6"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
