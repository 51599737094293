import { useFela } from 'react-fela';
import { useIntl } from 'react-intl';
import type { ReactNode } from 'react';

import { isBottomReached } from 'modules/ui/utilities';

import { SpinLoader } from '../SpinLoader';

import * as felaRules from './InfiniteScroll.rules';

export interface InfiniteScrollProps {
    handleLoadMore?: () => void;
    isLoadingMore?: boolean;
    children: ReactNode;
    customStyle?: typeof felaRules.container;
}

export const InfiniteScroll = ({ handleLoadMore, isLoadingMore, children, customStyle }: InfiniteScrollProps) => {
    const { css } = useFela();
    const { formatMessage } = useIntl();

    return (
        <div
            className={css(felaRules.container, customStyle)}
            onScroll={e => {
                const bottomReached = isBottomReached(e.target as HTMLDivElement);
                if (bottomReached && !isLoadingMore) {
                    handleLoadMore?.();
                }
            }}
        >
            {children}
            {isLoadingMore ? (
                <div className={css(felaRules.spinnerContainer)}>
                    <SpinLoader
                        width={28}
                        height={28}
                        alt={formatMessage({
                            id: 'select.loading',
                        })}
                    />
                </div>
            ) : null}
        </div>
    );
};
