import type { SVGProps } from 'react';

export const TOTP = (props: SVGProps<SVGSVGElement>) => (
    <svg width="26" height="24" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M6.33333 17.6744H1L5.62667 11.4611C5.9108 11.1487 6.1167 10.7734 6.22742 10.3659C6.33814 9.95846 6.35052 9.53052 6.26354 9.11733C6.17656 8.70415 5.9927 8.31752 5.7271 7.98927C5.4615 7.66102 5.12174 7.40054 4.7358 7.22926C4.34986 7.05797 3.92876 6.98079 3.50715 7.00405C3.08555 7.02731 2.6755 7.15036 2.31074 7.36307C1.94598 7.57577 1.63695 7.87205 1.40908 8.22753C1.1812 8.583 1.041 8.98751 1 9.40776"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M10.333 17.6744V7.00769H15.6663"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M10.333 12.3411H14.333"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M19.667 17.6744V9.67436C19.667 8.96711 19.9479 8.28884 20.448 7.78874C20.9481 7.28864 21.6264 7.00769 22.3337 7.00769C23.0409 7.00769 23.7192 7.28864 24.2193 7.78874C24.7194 8.28884 25.0003 8.96711 25.0003 9.67436V17.6744"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M19.667 13.6744H25.0003"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
