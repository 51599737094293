import { createAction } from '@reduxjs/toolkit';

export const updateTag = createAction('tags/updateTag', (tag: [string, string]) => ({
    meta: {
        tag: tag[0],
    },
    payload: {
        tag: tag[1],
    },
}));

export const updateTagSuccess = createAction('tags/updateTagSuccess', (tag: string) => ({
    meta: {
        tag,
    },
    payload: undefined,
}));

export const updateTagFailure = createAction('tags/updateTagFailure', (tag: string, error: any) => ({
    meta: {
        tag,
    },
    payload: undefined,
    error,
}));

export const updateTagReset = createAction('tags/updateTagReset', (tag: string) => ({
    meta: {
        tag,
    },
    payload: undefined,
}));
