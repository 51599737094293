import { eventChannel } from 'redux-saga';
import { take, put } from 'redux-saga/effects';

import { api } from 'config/antonio';

import { apiWakedUp } from '../slice';

function createRequestChannel() {
    return eventChannel(emit => {
        const interceptor = api.interceptors.request.use(request => {
            emit(request.url);
            return request;
        });

        return () => {
            api.interceptors.request.eject(interceptor);
        };
    });
}

export function* requestInterceptor() {
    const channel = createRequestChannel();

    try {
        while (true) {
            yield take(channel);

            yield put(apiWakedUp());
        }
    } finally {
        channel.close();
    }
}
