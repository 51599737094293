import { useDispatch } from 'react-redux';

import { useAppSelector } from 'hooks/useAppSelector';

import { selectUpdateTagApi } from '../services/selectors';
import { updateTag } from '../services/actions';

export const useUpdateTag = (tag: string) => {
    const api = useAppSelector(state => selectUpdateTagApi(state, tag));

    const dispatch = useDispatch();
    return {
        api,
        update: (value: string) => {
            dispatch(updateTag([tag, value]));
        },
    };
};
