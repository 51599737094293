export const Fields = {
    DEFAULT_PERMISSION: 'defaultPermission',
    SHARING_PERMISSION: 'sharingPermission',
};

export enum SharingPermission {
    EVERYONE = 'everyone',
    ADMIN_ONLY = 'adminOnly',
    DISALLOWED = 'disallowed',
}
