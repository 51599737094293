import { useFela } from 'react-fela';

import { Loadable, Logo } from 'modules/ui';

import { useFetchShareSecret } from '../../hooks/useFetchShareSecret';

import { ShareSecretDetail } from '../ShareSecretDetail';

import * as felaRules from './ShareSecretDetailPage.rules';

export const ShareSecretDetailPage = () => {
    const { css } = useFela();

    const { id, showLoader } = useFetchShareSecret();

    return (
        <div className={css(felaRules.container)}>
            <Logo customStyle={felaRules.logo} />
            <div className={css(felaRules.wrapper)}>
                <Loadable showLoader={showLoader} minHeight="15rem">
                    <ShareSecretDetail id={id} />
                </Loadable>
            </div>
        </div>
    );
};
