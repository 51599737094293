import { useFela } from 'react-fela';

import { Message } from '../Message';
import { MessageContent } from '../MessageContent';

import { useMessages } from '../../hooks/useMessages';

import { list } from './MessageList.rules';

export const MessageList = () => {
    const { css } = useFela();

    const messages = useMessages();

    return (
        <div className={css(list)}>
            {messages.map(({ id, level, message, button, visible }) => (
                <Message key={id} visible={visible}>
                    {state => <MessageContent id={id} level={level} state={state} message={message} button={button} />}
                </Message>
            ))}
        </div>
    );
};
