import { useRef } from 'react';
import type { ReactNode } from 'react';
import { CSSTransition } from 'react-transition-group';

import Swipe from 'react-easy-swipe';

import { useFela } from 'react-fela';

import { Logo } from 'modules/ui';
import { ExtensionCTA, shouldDisplayExtensionPromo } from 'modules/extension';

import { MenuButton } from '../MenuButton';

import { menu, menuButton, menuHeader, menuOverlay, logoWrapper, TRANSITION_DURATION } from './MenuMobile.rules';

export interface MenuMobileProps {
    open: boolean;
    openMenu: () => void;
    closeMenu: () => void;
    onAnimationFinised: (open: boolean) => void;
    children: ReactNode;
}

export const MenuMobile = ({ open, openMenu, closeMenu, onAnimationFinised, children }: MenuMobileProps) => {
    const { css } = useFela();

    const nodeRef = useRef(null);

    return (
        <Swipe onSwipeLeft={closeMenu}>
            <CSSTransition
                mountOnEnter
                unmountOnExit
                in={open}
                nodeRef={nodeRef}
                timeout={TRANSITION_DURATION}
                onExited={() => {
                    onAnimationFinised(false);
                }}
                onEntered={() => {
                    onAnimationFinised(true);
                }}
            >
                <div ref={nodeRef} className={css(menu)}>
                    <div>
                        <MenuButton type="close" customStyle={menuButton} handleClick={closeMenu} />
                        {children}
                    </div>
                    {shouldDisplayExtensionPromo ? <ExtensionCTA /> : null}
                </div>
            </CSSTransition>
            {open && <div tabIndex={0} role="button" className={css(menuOverlay)} onClick={closeMenu} />}
            <div className={css(menuHeader)}>
                <MenuButton type="open" handleClick={openMenu} />
                <div className={css(logoWrapper)}>
                    <Logo />
                </div>
            </div>
        </Swipe>
    );
};
