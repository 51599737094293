import type { TRuleWithTheme } from 'styles/theme';

export const container: TRuleWithTheme = ({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    width: '100%',

    paddingTop: '0.25rem',
    paddingRight: '0.75rem',
    paddingBottom: '0.25rem',
    paddingLeft: '0.75rem',

    borderRadius: '0.5rem',
    border: 'none',

    backgroundColor: theme.colors.backgroundsTertiary,

    textAlign: 'left',
});

export const userInfo: TRuleWithTheme = () => ({
    overflow: 'auto',
});

export const user: TRuleWithTheme = () => ({
    display: 'flex',
    alignItems: 'center',

    width: '100%',
    height: '1.5rem',

    overflow: 'hidden',

    fontWeight: 500,
});

export const userIcon: TRuleWithTheme = () => ({
    flexShrink: 0,

    position: 'relative',

    height: '1rem',

    marginRight: '0.375rem',
});

export const adminIcon: TRuleWithTheme = ({ theme }) => ({
    position: 'absolute',
    bottom: 0,
    right: 0,

    borderRadius: '50%',

    backgroundColor: theme.colors.white,
});

export const name: TRuleWithTheme = () => ({
    whiteSpace: 'nowrap',

    overflow: 'hidden',
    textOverflow: 'ellipsis',
});

export const close: TRuleWithTheme = () => ({
    flexShrink: 0,

    marginLeft: '0.5rem',
});

export const guideItem: TRuleWithTheme = ({ theme }) => ({
    paddingTop: '0.5rem',
    paddingBottom: '0.5rem',
    paddingRight: '1.5rem',
    paddingLeft: '1.5rem',

    marginTop: '-0.5rem',
    marginBottom: '-0.5rem',
    marginRight: '-1.5rem',
    marginLeft: '-1.5rem',
});
