import { useFela } from 'react-fela';

import loaderSrc from 'assets/images/import_loader.png';

import * as felaRules from './SpinLoader.rules';

export interface SpinLoaderProps {
    width?: number;
    height?: number;
    alt?: string;
    customStyle?: typeof felaRules.loader;
}

export const SpinLoader = ({ customStyle, ...props }: SpinLoaderProps) => {
    const { css } = useFela();

    return <img className={css(felaRules.loader, customStyle)} alt="" src={loaderSrc} {...props} />;
};
