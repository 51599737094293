import { combineReducers } from 'redux';
import { basicApiReducer } from '@ackee/redux-utils';

import types from '../actionTypes';

import {
    exportRequest,
    exportRequestFailure,
    exportRequestSuccess,
    importRequest,
    importRequestFailure,
    importRequestSuccess,
} from '../actions';

const exportReducer = basicApiReducer({
    actionTypes: {
        REQUEST: exportRequest.toString(),
        SUCCESS: exportRequestSuccess.toString(),
        FAILURE: exportRequestFailure.toString(),
    },
});

const importReducer = basicApiReducer({
    actionTypes: {
        REQUEST: importRequest.toString(),
        SUCCESS: importRequestSuccess.toString(),
        FAILURE: importRequestFailure.toString(),
    },
});

const importValidation = basicApiReducer({
    actionTypes: {
        REQUEST: types.IMPORT_VALIDATION_REQUEST,
        SUCCESS: types.IMPORT_VALIDATION_REQUEST_SUCCESS,
        FAILURE: types.IMPORT_VALIDATION_REQUEST_FAILURE,
        CANCEL: types.IMPORT_VALIDATION_REQUEST_CANCEL,
    },
});

export default combineReducers({
    export: exportReducer,
    import: importReducer,
    importValidation,
});
