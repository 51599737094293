import { put, takeEvery } from 'redux-saga/effects';

import { api } from 'config/antonio';
import { config } from 'config/config';
import { logger } from 'config/logger';

import { actions as messagesActions } from 'modules/messages';

import type { SecurityAuditData, SecurityAuditLevelReport } from '../../types';

import { fetchAudit, fetchAuditFailure, fetchAuditSuccess } from '../actions';

type EndpointData = {
    reports: (SecurityAuditLevelReport & {
        percentage: string;
    })[];
    total: number;
};

const normalizeAudit = (data: EndpointData): SecurityAuditData => ({
    ...data,
    reports: data.reports.map(report => ({
        ...report,
        percentage: Number.parseFloat(report.percentage),
    })),
});

function* handler() {
    try {
        const { data } = yield* api.post<EndpointData>(config.api.securityAudit, null);

        const audit = normalizeAudit(data);

        yield put(fetchAuditSuccess(audit));
    } catch (e) {
        logger.error(e);
        yield put(fetchAuditFailure(e));

        yield put(
            messagesActions.displayErrorMessage({
                message: { id: 'error.securityAudit.fetch' },
            }),
        );
    }
}

export function* fetchAuditSaga() {
    yield takeEvery(fetchAudit.toString(), handler);
}
