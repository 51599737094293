import { takeEvery, put } from 'redux-saga/effects';

import { api } from 'config/antonio';
import { config } from 'config/config';
import { logger } from 'config/logger';

import { actions as messagesActions } from 'modules/messages';
import { actions as modalsActions, StaticModal } from 'modules/modals';

import { deleteSecrets, deleteSecretsFailure, deleteSecretsSuccess } from '../actions';

function* handler() {
    try {
        yield* api.delete(config.api.secrets);

        yield put(deleteSecretsSuccess());

        yield put(modalsActions.setModalVisibility(StaticModal.DELETE_SECRETS, false));

        yield put(
            messagesActions.displaySuccessMessage({
                message: { id: 'success.secrets.delete' },
            }),
        );
    } catch (e) {
        yield put(
            messagesActions.displayErrorMessage({
                message: { id: 'error.secrets.delete' },
            }),
        );
        logger.error(e);

        yield put(deleteSecretsFailure(e));
    }
}

export default function* () {
    yield takeEvery(deleteSecrets.toString(), handler);
}
