import { useFela } from 'react-fela';
import { FormattedMessage } from 'react-intl';
import { isEmpty } from 'lodash';

import { CopyButton, PasswordVisualisation, Tag, Text } from 'modules/ui';
import { hasReadPermission } from 'utilities/permission';
import { Groups } from 'modules/groups';
import { Whitelist } from 'modules/whitelist';

import { SecretSection } from '../../SecretSection';
import { SecretFieldSection } from '../../SecretFieldSection';
import { SecretUrl } from '../../SecretUrl';
import { useSecretDetailContext } from '../../SecretDetailPage';
import { TOTP } from '../TOTP';
import { SecretFile } from '../../SecretFile';

import { EmptyContentGuard } from './EmptyContentGuard';

import * as felaRules from './SecretDetailSections.rules';

export const SecretDetailSections = () => {
    const { css } = useFela();

    const { id, web, username, password, hasTOTP, tags, file, note, groups, whitelistUsers, userPermissions } =
        useSecretDetailContext();

    const hasRead = hasReadPermission(userPermissions);

    return (
        <div className={css(felaRules.container)}>
            <SecretSection title="secret.sections.password.title">
                <EmptyContentGuard>
                    {web ? (
                        <SecretFieldSection heading={<FormattedMessage id="secret.web" />}>
                            <CopyButton key={id} text={web}>
                                <SecretUrl web={web} />
                            </CopyButton>
                        </SecretFieldSection>
                    ) : null}
                    {username ? (
                        <SecretFieldSection heading={<FormattedMessage id="secret.username" />}>
                            <CopyButton key={id} text={username} />
                        </SecretFieldSection>
                    ) : null}
                    {password ? (
                        <SecretFieldSection heading={<FormattedMessage id="secret.password" />}>
                            <CopyButton disabled={!hasRead} type="password" key={id} text={password}>
                                <PasswordVisualisation password={password} />
                            </CopyButton>
                        </SecretFieldSection>
                    ) : null}
                    {hasTOTP ? (
                        <SecretFieldSection heading={<FormattedMessage id="secret.totp" />}>
                            <TOTP id={id} />
                        </SecretFieldSection>
                    ) : null}
                </EmptyContentGuard>
            </SecretSection>

            <SecretSection title="secret.sections.details.title">
                <EmptyContentGuard>
                    {!isEmpty(tags) && (
                        <SecretFieldSection heading={<FormattedMessage id="secret.tags" />}>
                            <div className={css(felaRules.tags)}>
                                {tags.map(tag => (
                                    <Tag key={tag}>{tag}</Tag>
                                ))}
                            </div>
                        </SecretFieldSection>
                    )}
                    {file && hasRead ? (
                        <SecretFieldSection heading={<FormattedMessage id="secret.file" />}>
                            <SecretFile file={file} />
                        </SecretFieldSection>
                    ) : null}
                    {note && hasRead ? (
                        <SecretFieldSection heading={<FormattedMessage id="secret.note" />}>
                            <Text preserveNewLines>{note}</Text>
                        </SecretFieldSection>
                    ) : null}
                </EmptyContentGuard>
            </SecretSection>

            <SecretSection title="secret.sections.permissions.title" customStyle={felaRules.rightColumn}>
                <EmptyContentGuard message="secret.sections.permissions.empty">
                    {!isEmpty(groups) ? (
                        <SecretFieldSection heading={<FormattedMessage id="secret.groups" />}>
                            <Groups groups={groups} />
                        </SecretFieldSection>
                    ) : null}
                    {!isEmpty(whitelistUsers) ? (
                        <SecretFieldSection heading={<FormattedMessage id="secret.whitelistUsers" />}>
                            <Whitelist key={id} whitelist={whitelistUsers} />
                        </SecretFieldSection>
                    ) : null}
                </EmptyContentGuard>
            </SecretSection>
        </div>
    );
};
