import { createAction } from '@reduxjs/toolkit';

import type { MessagePayload, MessageOptions } from 'modules/messages/types';

import { VISIBILITY_DURATION } from '../../config';
import { Level } from '../../constants';

import { actionTypes } from './types';

export const addMessageRequest = createAction(
    actionTypes.ADD_MESSAGE_REQUEST,
    (id: string, payload: MessagePayload, meta: MessageOptions = {}) => ({
        payload,
        meta: {
            duration: VISIBILITY_DURATION,
            ...meta,
            id,
        },
    }),
);

export const addMessageResolve = createAction(
    actionTypes.ADD_MESSAGE_RESOLVE,
    (payload: MessagePayload, meta: { id: string } & MessageOptions) => ({
        payload,
        meta,
    }),
);

export const hideMessage = createAction(actionTypes.HIDE_MESSAGE, (id: string) => ({
    payload: undefined,
    meta: {
        id,
    },
}));

export const removeMessage = createAction(actionTypes.REMOVE_MESSAGE, (id: string) => ({
    payload: undefined,
    meta: {
        id,
    },
}));

export const clearMessages = createAction(actionTypes.CLEAR_MESSAGES);

export const displayMessageFactory = (type: string, level: Level) =>
    createAction(
        type,
        ({
            message,
            button,
            options,
        }: {
            message: MessagePayload['message'];
            button?: MessagePayload['button'];
            options?: MessageOptions;
        }) => ({
            payload: {
                message,
                button,
                level,
            },
            meta: options,
        }),
    );

export const displaySuccessMessage = displayMessageFactory(actionTypes.DISPLAY_SUCCESS_MESSAGE, Level.SUCCESS);

export const displayErrorMessage = displayMessageFactory(actionTypes.DISPLAY_ERROR_MESSAGE, Level.ERROR);

export const displayInfoMessage = displayMessageFactory(actionTypes.DISPLAY_INFO_MESSAGE, Level.INFO);

export const displayLoadingMessage = displayMessageFactory(actionTypes.DISPLAY_LOADING_MESSAGE, Level.LOADING);

export const displayWarningMessage = displayMessageFactory(actionTypes.DISPLAY_WARNING_MESSAGE, Level.WARNING);
