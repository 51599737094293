import { forwardRef } from 'react';
import { useFela } from 'react-fela';
import classNames from 'classnames';

import { Input } from 'antd';
import type { InputProps, InputRef } from 'antd/es/input';

import { style } from './TextInput.rules';

export interface TextInputProps extends InputProps {
    customStyle?: typeof style;
}

export const TextInput = forwardRef<InputRef, TextInputProps>(({ customStyle, ...props }, ref) => {
    const { css } = useFela();

    return <Input ref={ref} {...props} className={classNames(css(style, customStyle), props.className)} />;
});
