import { useEffect, useState } from 'react';
import type { ReactNode } from 'react';
import { useFela } from 'react-fela';
import { FormattedMessage, useIntl } from 'react-intl';

import { Empty, Select } from 'antd';
import type { SelectProps as AntSelectProps } from 'antd/es/select';

import { Button, Icon, SpinLoader, isBottomReached } from 'modules/ui';

import * as felaRules from './SelectInput.rules';

const { Option, OptGroup } = Select;

interface IOption {
    label: ReactNode;
    value: string;
    title?: string;
    key?: string;
}

interface IOptionGroup {
    key: string;
    label: ReactNode;
    options: IOption[];
}

const isOptionGroup = (item: IOptionGroup | IOption): item is IOptionGroup => item.hasOwnProperty('options');

function renderOptions({ options, className }: { options: (IOption | IOptionGroup)[]; className: string }) {
    return options.map(item => {
        if (isOptionGroup(item)) {
            return (
                <OptGroup key={item.key} label={item.label}>
                    {renderOptions({
                        options: item.options,
                        className,
                    })}
                </OptGroup>
            );
        }
        return (
            <Option key={item.key ?? item.value} value={item.value} title={item.title} className={className}>
                {item.label}
            </Option>
        );
    });
}

export interface SelectInputProps extends Omit<AntSelectProps, 'options' | 'notFoundContent'> {
    options: (IOption | IOptionGroup)[];
    empty?: ReactNode;
    customStyle?: typeof felaRules.select;
    customOptionStyle?: typeof felaRules.option;
    hasMore?: boolean;
    loadMore?: () => void;
    loadingMore?: boolean;
    popupShouldIgnoreParentContainer?: boolean;
}

export const SelectInput = ({
    id,
    open = false,
    onDropdownVisibleChange,
    options,
    empty,
    customStyle,
    customOptionStyle,
    loading,
    loadingMore,
    hasMore,
    loadMore,
    popupShouldIgnoreParentContainer = false,
    ...rest
}: SelectInputProps) => {
    const { css } = useFela();

    const intl = useIntl();

    const [innerOpen, setOpen] = useState(open);

    useEffect(() => {
        setOpen(open);
    }, [open]);

    return (
        <Select
            id={id}
            className={css(felaRules.select, customStyle)}
            getPopupContainer={triggerNode =>
                popupShouldIgnoreParentContainer ? undefined : triggerNode.parentElement
            }
            suffixIcon={
                <Icon color="black" type="dropdown" customStyle={innerOpen ? felaRules.arrowOpen : undefined} />
            }
            {...rest}
            open={innerOpen}
            onDropdownVisibleChange={o => {
                setOpen(o);
                onDropdownVisibleChange?.(o);
            }}
            notFoundContent={<Empty image={null} description={empty} />}
            onPopupScroll={event => {
                const bottomReached = isBottomReached(event.target as HTMLDivElement);

                if (!loadingMore && bottomReached) {
                    loadMore?.();
                }
            }}
        >
            {renderOptions({
                options,
                className: css(felaRules.option, customOptionStyle),
            })}
            {loadingMore ? (
                <Option disabled className={css(felaRules.infinite)}>
                    <SpinLoader
                        width={24}
                        height={24}
                        alt={intl.formatMessage({
                            id: 'select.loading',
                        })}
                    />
                </Option>
            ) : null}
            {hasMore && !loadingMore ? (
                <Option disabled className={css(felaRules.infinite)}>
                    <Button type="link" onClick={loadMore}>
                        <FormattedMessage id="select.loadMore" />
                    </Button>
                </Option>
            ) : null}
        </Select>
    );
};
