import type { SVGProps } from 'react';

export const Warning = (props: SVGProps<SVGSVGElement>) => (
    <svg fill="currentColor" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 20c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8-3.589 8-8 8Zm0-18C6.478 2 2 6.477 2 12s4.478 10 10 10 10-4.477 10-10S17.522 2 12 2Z"
        />
        <path fillRule="evenodd" clipRule="evenodd" d="M11 14h2V6h-2v8ZM11 18h2v-2h-2v2Z" />
    </svg>
);
