import type { SVGProps } from 'react';

export const Password = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor" {...props}>
        <g fillRule="evenodd">
            <path d="M7 19h10v-6H7v6zm1-9.989V9c0-2.206 1.794-4 4-4s4 1.794 4 4v2H8V9.011zM18 11V8.989A6.008 6.008 0 0 0 12 3a6.008 6.008 0 0 0-6 5.989V11a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-8a1 1 0 0 0-1-1z" />
            <path d="M12 18a1 1 0 0 0 1-1v-2a1 1 0 1 0-2 0v2a1 1 0 0 0 1 1" />
        </g>
    </svg>
);
