import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom-v5-compat';

import { useBreakpoint } from 'hooks/useBreakpoint';
import { MenuMobile } from 'modules/layout';
import { useIsAdmin } from 'modules/auth';
import { AdminSecretsView, UserSecretsView } from 'modules/secrets';
import { UserGuideSecretsView } from 'modules/user-guide';

import { SidebarContent } from '../SidebarContent';

const mobileAlignOffset: [number, number] = [0, 20];
export const desktopAlignOffset: [number, number] = [40, 0];

export const SidebarMobile = () => {
    const [menuOpen, setMenuOpen] = useState(false);
    // showGuide is almost in sync with menuOpen but it switches with animation
    const [showGuide, setShowGuide] = useState(menuOpen);

    const isAdmin = useIsAdmin();

    const isMin768 = useBreakpoint('sm');
    const { pathname } = useLocation();

    // Auto-close the sidebar when the route changes
    useEffect(() => {
        setMenuOpen(false);
    }, [pathname]);

    return (
        <MenuMobile
            open={menuOpen}
            openMenu={() => setMenuOpen(true)}
            closeMenu={() => setMenuOpen(false)}
            onAnimationFinised={open => {
                setShowGuide(open);
            }}
        >
            {isAdmin ? (
                <UserGuideSecretsView
                    show={showGuide}
                    placement={isMin768 ? 'rightTop' : 'bottomLeft'}
                    align={{
                        offset: isMin768 ? desktopAlignOffset : mobileAlignOffset,
                    }}
                >
                    <AdminSecretsView />
                </UserGuideSecretsView>
            ) : (
                <UserSecretsView />
            )}
            <SidebarContent />
        </MenuMobile>
    );
};
