import { useDispatch } from 'react-redux';

import { useAppSelector } from 'hooks/useAppSelector';

import { changeGroupVisibilityRequest } from '../services/actions';
import { selectChangeGroupVisibilityApi } from '../services/selectors';

export const useChangeGroupVisibility = (id: string) => {
    const { inProgress } = useAppSelector(state => selectChangeGroupVisibilityApi(state, id));

    const dispatch = useDispatch();

    const changeGroupVisibility = (visible: boolean) => {
        dispatch(changeGroupVisibilityRequest(id, visible));
    };

    return {
        changeGroupVisibility,
        inProgress,
    };
};
