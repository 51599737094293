import type { ReactNode } from 'react';
import { useFela } from 'react-fela';
import classNames from 'classnames';
import { Tag as AntTag } from 'antd';
import type { TagProps, CheckableTagProps } from 'antd/es/tag';

import type { theme } from 'styles';

import { container } from './Tag.rules';

interface ChildrenProp {
    customStyle?: typeof container;
    color?: keyof typeof theme.colors | 'currentColor';
    children: ReactNode;
}

export const CheckableTag = ({ customStyle, color, ...props }: CheckableTagProps & ChildrenProp) => {
    const { css } = useFela({
        color,
    });

    return <AntTag.CheckableTag {...props} className={classNames(css(container, customStyle), props.className)} />;
};

export const Tag = ({ customStyle, closable, color, icon, ...props }: TagProps & ChildrenProp) => {
    const { css } = useFela({
        closable,
        color,
        hasIcon: Boolean(icon),
    });

    return (
        <AntTag
            {...props}
            closable={closable}
            icon={icon}
            className={classNames(css(container, customStyle), props.className)}
        />
    );
};
