import { translateSelector } from '@ackee/jerome';

import { useAppSelector } from 'hooks/useAppSelector';

import type { Language } from '../contants';

export const useLocale = () =>
    useAppSelector(translateSelector) as {
        locale: `${Language}`;
    };
