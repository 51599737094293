import type { TRuleWithTheme } from 'styles/theme';

import type { LoaderProps } from './Loader';

const keyframe = {
    '0%': {
        animationTimingFunction: 'ease-in',
    },
    '50%': {
        transform: 'translate(0, 24px)',
        animationTimingFunction: 'ease-out',
    },
    '55%': {
        transform: 'translate(0, 30px)',
        animationTimingFunction: 'ease-in',
    },
    '65%': {
        transform: 'translate(0, 19px)',
        animationTimingFunction: 'ease-out',
    },
    '95%': {
        animationTimingFunction: 'ease-in',
    },
    '100%': {
        animationTimingFunction: 'ease-in',
    },
};

export const container: TRuleWithTheme<Pick<LoaderProps, 'minHeight'>> = ({ theme, minHeight }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    width: '100%',
    height: '100%',
    minHeight,

    fontSize: '1rem',
    fontWeight: '500',
    lineHeight: '1.5rem',

    color: theme.colors.black,
    backgroundColor: theme.colors.white,

    textTransform: 'lowercase',
});

export const wrapper: TRuleWithTheme = () => ({ position: 'relative', top: '-1rem' });

export const image: TRuleWithTheme<{
    animate: boolean;
}> = ({ theme, animate }) => ({
    position: 'relative',
    top: animate ? -20 : 16,

    display: 'block',

    marginTop: 0,
    marginRight: 'auto',
    marginBottom: animate ? '0.75rem' : 0,
    marginLeft: 'auto',

    animationName: animate ? keyframe : undefined,
    animationDuration: '1s',
    animationIterationCount: 'infinite',

    color: theme.colors.blue,
});
