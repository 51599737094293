import type { ReactNode } from 'react';
import { useFela } from 'react-fela';
import { generatePath, useLocation } from 'react-router-dom-v5-compat';
import { FormattedMessage } from 'react-intl';

import { useAppSelector } from 'hooks/useAppSelector';

import { Heading, Link, Icon, Button, Text } from 'modules/ui';
import type { IconProps } from 'modules/ui';
import { useModal } from 'modules/modals';

import { IGNORE_SECURITY_AUDIT_REPORT_MODAL } from '../../constants';
import type { Secret } from '../../types';

import { selectIgnoreReportSecretApi } from '../../services/selectors';

import * as felaRules from './SecurityAuditSecretItem.rules';

export interface SecurityAuditSecretItemProps extends Pick<Secret, 'id' | 'name'> {
    additional?: ReactNode;
    icon?: IconProps['type'];
    isLastInGroup?: boolean;
    updateSecretRoute: string;
    ignoreable?: boolean;
}

export const SecurityAuditSecretItem = ({
    id,
    name,
    additional,
    icon,
    updateSecretRoute,
    isLastInGroup = false,
    ignoreable = false,
}: SecurityAuditSecretItemProps) => {
    const { css } = useFela({ isLastInGroup, icon });
    const { pathname, search } = useLocation();

    const { updateModal } = useModal<{
        id: Secret['id'];
    }>(IGNORE_SECURITY_AUDIT_REPORT_MODAL);

    const ignoreApi = useAppSelector(state => selectIgnoreReportSecretApi(state, id));

    return (
        <div className={css(felaRules.container)} data-testid={`security-audit-secret-${id}`}>
            <div className={css(felaRules.contentWrapper)}>
                <Heading
                    color="black"
                    elementType="h3"
                    customStyle={icon ? felaRules.headingWithIcon : felaRules.heading}
                >
                    {icon ? <Icon type={icon} customStyle={felaRules.icon} /> : null}
                    {name}
                </Heading>
                {additional ? (
                    <Text size="small" color="brownGrey2" customStyle={felaRules.additional}>
                        {additional}
                    </Text>
                ) : null}
            </div>
            <div className={css(felaRules.actionsWrapper)}>
                <Link
                    color="blue"
                    type="router"
                    to={generatePath(updateSecretRoute, {
                        id,
                    })}
                    state={{ from: pathname + search }}
                    customStyle={felaRules.detailLink}
                >
                    <FormattedMessage id="securityAudit.list.update" />
                </Link>
                {ignoreable ? (
                    <Button
                        color="red"
                        type="link"
                        onClick={() => {
                            updateModal(true, {
                                id,
                            });
                        }}
                        disabled={ignoreApi.inProgress}
                        customStyle={felaRules.dismissButton}
                    >
                        <FormattedMessage id="securityAudit.list.ignoreReport" />
                    </Button>
                ) : null}
            </div>
        </div>
    );
};
