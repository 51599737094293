import { FormattedMessage } from 'react-intl';

import { ConfirmationModal } from 'modules/modals';

import { useImportValidationModal } from '../../hooks/useImportValidationModal';

export const ImportValidationModal = () => {
    const { visible, handleAccept, handleClose } = useImportValidationModal();

    return (
        <ConfirmationModal
            open={visible}
            okButton={{
                onClick: handleAccept,
                children: <FormattedMessage id="import.modal.validation.ok" />,
            }}
            cancelButton={{
                onClick: handleClose,
                children: <FormattedMessage id="import.modal.validation.cancel" />,
            }}
        >
            <FormattedMessage id="import.modal.validation.content" />
        </ConfirmationModal>
    );
};
