import { FormattedMessage } from 'react-intl';

import { AccessRole } from 'types';

import { Dropdown } from 'modules/ui';

export interface RoleDropdownProps {
    value: AccessRole;
    onChange: (value: AccessRole) => void;
}

const permissions = [AccessRole.VIEWER, AccessRole.EDITOR, AccessRole.VIEWER_AUTOFILL_ONLY];

export const RoleDropdown = ({ value, onChange }: RoleDropdownProps) => (
    <Dropdown
        items={permissions.map(permission => ({
            key: permission,
            label: <FormattedMessage id={`secret.permission.${permission}`} />,
            active: value === permission,
            onClick: () => {
                onChange(permission);
            },
        }))}
    >
        <FormattedMessage id={`secret.permission.${value}`} />
    </Dropdown>
);
