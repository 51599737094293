import type { TRuleWithTheme } from 'styles/theme';

export const container: TRuleWithTheme = ({ theme }) => ({
    width: '100%',

    padding: '1.25rem',

    backgroundColor: theme.colors.lightGrey2,

    borderRadius: '1rem',
});

export const heading: TRuleWithTheme = () => ({
    marginBottom: '0.75rem',
});

export const text: TRuleWithTheme = () => ({
    marginBottom: '2rem',

    fontSize: '0.875rem',
    lineHeight: '1.1875rem',
});
