import { push } from 'redux-first-history';
import { takeEvery, put } from 'redux-saga/effects';

import { api } from 'config/antonio';
import { config } from 'config/config';
import { logger } from 'config/logger';

import { actions as messagesActions } from 'modules/messages';
import { actions as modalsActions } from 'modules/modals';

import { REMOVE_SECRET_MODAL_KEY } from '../../constants';

import { removeSecretSuccess, removeSecretFailure, removeSecret } from '../actions';

function* handler({ meta }: ReturnType<typeof removeSecret>) {
    try {
        yield* api.delete(config.api.secret, {
            uriParams: {
                id: meta.id,
            },
        });

        yield put(removeSecretSuccess(meta.id));
        yield put(push(config.routes.home));

        yield put(
            messagesActions.displaySuccessMessage({
                message: { id: 'success.secrets.remove' },
            }),
        );
    } catch (e) {
        yield put(
            messagesActions.displayErrorMessage({
                message: { id: 'error.secrets.remove' },
            }),
        );
        logger.error(e);

        yield put(removeSecretFailure(meta.id, e));
    } finally {
        yield put(modalsActions.setModalVisibility(REMOVE_SECRET_MODAL_KEY, false));
    }
}

export default function* () {
    yield takeEvery(removeSecret.toString(), handler);
}
