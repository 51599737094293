import type { TRuleWithTheme } from 'styles/theme';

const size = 3;

export const iconButtonContainer: TRuleWithTheme = () => ({
    minWidth: `${size}rem`,
    minHeight: `${size}rem`,

    lineHeight: `${size}rem`,
});
