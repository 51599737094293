import { useFela } from 'react-fela';
import { FormattedMessage } from 'react-intl';

import { Alert, Button, Icon } from 'modules/ui';
import { hasReadPermission } from 'utilities/permission';

import { useSecretDetailContext, useSharingModal } from '../../SecretDetailPage';
import { useIsPublicSharingActiveWithPermissions } from '../hooks';

import * as felaRules from './SecretDetailAlerts.rules';

export const SecretDetailAlerts = () => {
    const { css } = useFela(felaRules);
    const { toggle } = useSharingModal();

    const { userPermissions } = useSecretDetailContext();
    const isPublicSharingActive = useIsPublicSharingActiveWithPermissions();

    const hasReadAccess = hasReadPermission(userPermissions);

    if (!hasReadAccess) {
        return (
            <Alert
                size="fullWidth"
                message={<FormattedMessage id="secret.autofillOnly.info" />}
                type="info"
                icon={<Icon type="info" />}
                showIcon
            />
        );
    }

    if (isPublicSharingActive) {
        return (
            <>
                <Alert
                    size="fullWidth"
                    textAlign="center"
                    message={
                        <div className={css(felaRules.sharingAlertContent)}>
                            <FormattedMessage id="secret.share.alert.title" />
                            <Button
                                type="text"
                                color="alertsSuccessText"
                                customStyle={felaRules.sharingAlertButton}
                                icon={<Icon width="1rem" height="1rem" type="settings" />}
                                onClick={toggle}
                            >
                                <FormattedMessage id="secret.share.alert.settingsButton" />
                            </Button>
                        </div>
                    }
                    type="success"
                />
            </>
        );
    }

    return null;
};
