import { createAction } from '@reduxjs/toolkit';

import type { Secret } from '../../types';
import types from '../actionTypes';

export const readSecret = createAction(types.READ_SECRET, (id: Secret['id']) => ({
    meta: { id },
    payload: undefined,
}));

export const readSecretSuccess = createAction(types.READ_SECRET_SUCCESS, (id: Secret['id']) => ({
    meta: { id, lastSuccessAt: new Date().toISOString() },
    payload: undefined,
}));

export const readSecretFailure = createAction(types.READ_SECRET_FAILURE, (id: Secret['id'], error: any) => ({
    meta: { id },
    payload: undefined,
    error,
}));
