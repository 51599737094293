import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { useAppSelector } from 'hooks/useAppSelector';

import type { Secret } from '../types';
import { isPublicSharingActive } from '../utilities';

import { shareSecret } from '../services/actions';
import { selectShareSecretApi } from '../services/selectors';

export interface ShareSecretButtonProps {
    id: Secret['id'];
    share?: Secret['share'];
}

export const useShareSecret = (id: Secret['id'], share: Secret['share']) => {
    const isSharingActive = isPublicSharingActive(share);

    const [shared, setShared] = useState(isSharingActive);

    const { inProgress } = useAppSelector(state => selectShareSecretApi(state, id));

    useEffect(() => {
        if (!inProgress) {
            setShared(isSharingActive);
        }
    }, [isSharingActive, inProgress]);

    const dispatch = useDispatch();

    return {
        inProgress,
        shared,
        handleShare: (share: boolean) => {
            setShared(share);
            dispatch(shareSecret(id, share));
        },
    };
};
