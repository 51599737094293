import type { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { useFela } from 'react-fela';

import { IS_SAAS } from 'constants/index';

import config from 'config/index';

import { Icon, Link, Alert } from 'modules/ui';

import { MAX_SAAS_SECRETS_COUNT } from '../../constants';
import { useSaasCountReached } from '../../hooks/useSaasCountReached';

import * as felaRules from './SecretsCountAlert.rules';

export interface SecretsCountAlertProps {}

export const SecretsCountAlert = () => {
    const { css } = useFela();
    const { count, isError, isWarning } = useSaasCountReached();

    if (IS_SAAS) {
        const type = isError ? 'error' : 'warning';

        if (isWarning || isError) {
            return (
                <Alert
                    showIcon
                    icon={<Icon type="warning" width={20} height={20} />}
                    size="fullWidth"
                    message={
                        <FormattedMessage
                            id="secret.count-alert"
                            values={{
                                count,
                                maxCount: MAX_SAAS_SECRETS_COUNT,
                                a: (parts: ReactNode) => (
                                    <Link size="small" type="native" inheritColor href={config.links.payment}>
                                        {parts}
                                    </Link>
                                ),
                            }}
                        />
                    }
                    type={type}
                    data-testid={`secrets-count-saas-alert-${type}`}
                />
            );
        }
    }

    return <div className={css(felaRules.divider)} />;
};
