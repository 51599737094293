import type { TRuleWithTheme } from 'styles/theme';

export const guideItem: TRuleWithTheme = () => ({
    paddingTop: '0.5rem',
    paddingRight: '1.5rem',
    paddingBottom: '0.5rem',
    paddingLeft: '1.5rem',

    marginTop: '-0.5rem',
    marginRight: '-1.5rem',
    marginBottom: '-0.5rem',
    marginLeft: '-1.5rem',
});

export const tooltip: TRuleWithTheme = ({ theme }) => ({
    '&.ant-tooltip': {
        maxWidth: 209,
    },
    '&.ant-tooltip .ant-tooltip-arrow::before': {
        backgroundColor: theme.colors.white,
    },
    '&.ant-tooltip .ant-tooltip-inner': {
        padding: '1.25rem',

        boxShadow: 'none',

        backgroundColor: theme.colors.white,
    },

    min768: {
        '&.ant-tooltip': {
            maxWidth: 474,
        },
        '&.ant-tooltip .ant-tooltip-inner': {
            padding: '1.5rem',
        },
    },
});

export const title: TRuleWithTheme = () => ({
    marginBottom: '0.5rem',

    min768: {
        marginBottom: '0.75rem',
    },
});

export const text: TRuleWithTheme = () => ({
    marginBottom: '1.25rem',

    min768: {
        marginBottom: '3.25rem',
    },
});

export const button: TRuleWithTheme = () => ({
    '&.ant-btn': {
        display: 'flex',

        marginLeft: 'auto',
    },
});
