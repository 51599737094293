import { createAction } from '@reduxjs/toolkit';

export const removeTag = createAction('tags/removeTag', (tag: string) => ({
    meta: {
        tag: tag,
    },
    payload: undefined,
}));

export const removeTagSuccess = createAction('tags/removeTagSuccess', (tag: string) => ({
    meta: {
        tag,
    },
    payload: undefined,
}));

export const removeTagFailure = createAction('tags/removeTagFailure', (tag: string, error: any) => ({
    meta: {
        tag,
    },
    payload: undefined,
    error,
}));

export const removeTagReset = createAction('tags/removeTagReset', (tag: string) => ({
    meta: {
        tag,
    },
    payload: undefined,
}));
