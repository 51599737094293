import type { SVGProps } from 'react';

export const StatusNoPass = (props: SVGProps<SVGSVGElement>) => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="#9B9B9B" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.78039 12.7196H11.1052V8.32478H3.78039V12.7196ZM4.51287 5.40292V5.39487C4.51287 3.77902 5.82693 2.46496 7.44278 2.46496C9.05863 2.46496 10.3727 3.77902 10.3727 5.39487V5.40292V6.85982H4.51287V5.40292ZM11.8376 6.85982V5.40292V5.39487V5.38681C11.8325 2.96744 9.86361 1 7.44277 1C5.02193 1 3.05303 2.96744 3.04791 5.38681V5.39487V5.40292V6.85982C2.64285 6.85982 2.31543 7.18797 2.31543 7.5923V13.4521C2.31543 13.8565 2.64285 14.1846 3.04791 14.1846H11.8376C12.2427 14.1846 12.5701 13.8565 12.5701 13.4521V7.5923C12.5701 7.18797 12.2427 6.85982 11.8376 6.85982Z"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.4428 11.9872C7.84787 11.9872 8.17528 11.659 8.17528 11.2547V9.78973C8.17528 9.3854 7.84787 9.05725 7.4428 9.05725C7.03774 9.05725 6.71033 9.3854 6.71033 9.78973V11.2547C6.71033 11.659 7.03774 11.9872 7.4428 11.9872Z"
        />
        <rect x="2" y="3.1853" width="1.46496" height="15.5056" transform="rotate(-45 2 3.1853)" />
    </svg>
);
