import { useFela } from 'react-fela';

import type { Group } from '../../types';

import { GroupMembers } from '../GroupMembers';
import { NestedGroupOverlay } from '../NestedGroupOverlay';
import { useNestedGroup } from '../../hooks';

import { container } from './GroupDetail.rules';

export interface GroupDetailProps {
    id: Group['id'];
}

export const GroupDetail = ({ id }) => {
    const { css } = useFela();

    const { openedNestedGroup, openNestedGroup, closeNestedGroup } = useNestedGroup();

    return (
        <>
            <section className={css(container)}>
                <GroupMembers id={id} openNestedGroup={openNestedGroup} />
            </section>
            <NestedGroupOverlay group={openedNestedGroup} handleClose={closeNestedGroup} />
        </>
    );
};
