import { createElement } from 'react';
import type { ReactNode } from 'react';
import { useFela } from 'react-fela';

import type { Theme } from 'styles/theme';

import { container, LabelSize, LabelType } from './Label.rules';

export interface LabelProps {
    children: ReactNode;
    elementType?: keyof JSX.IntrinsicElements;
    type?: `${LabelType}`;
    size?: `${LabelSize}`;
    color?: keyof Theme['colors'];
    htmlFor?: string;
    customStyle?: typeof container;
}

export const Label = ({
    children,
    customStyle,
    elementType = 'span',
    type = LabelType.DEFAULT,
    size = LabelSize.REGULAR,
    color,
    htmlFor,
}: LabelProps) => {
    const { css } = useFela({ type, size, color });

    return createElement(elementType, { className: `Label ${css(container, customStyle)}`, htmlFor }, children);
};
