import { useSelector } from 'react-redux';

import { selectGeneralSettings } from '../services/selectors';

export const useSubscriptionAlerts = () => {
    const { subscription } = useSelector(selectGeneralSettings);

    const canceledSubscriptionAlertVisible = subscription === 'canceled';

    const notLinkedAlertVisible = subscription === 'not-linked';

    return { canceledSubscriptionAlertVisible, notLinkedAlertVisible };
};
