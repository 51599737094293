import type { ReactNode } from 'react';
import { useFela } from 'react-fela';

import * as felaRules from './UserGuide.rules';

export interface UserGuideProps {
    open: boolean;
    children: ReactNode;
    extend?: {
        item?: typeof felaRules.item;
    };
}

export const UserGuide = ({ open, children, extend }: UserGuideProps) => {
    const { css } = useFela();

    if (open) {
        return (
            <div className={css(felaRules.wrapper)}>
                <div className={css(felaRules.item, extend?.item)}>
                    <div className={css(felaRules.itemOverlay)} />
                    {children}
                </div>
                <div className={css(felaRules.overlay)} />
            </div>
        );
    }

    return <>{children}</>;
};
