import { useFela } from 'react-fela';
import type { PropsWithChildren } from 'react';

import * as felaRules from './SecretHeader.rules';
import { BackButton } from '../BackButton';

export interface SecretHeaderProps extends PropsWithChildren {
    showBackButton?: boolean;
}

export const SecretHeader = ({ children, showBackButton = true }: SecretHeaderProps) => {
    const { css } = useFela({ showBackButton });

    return (
        <div className={css(felaRules.container)}>
            {showBackButton && <BackButton />}
            <div>{children}</div>
        </div>
    );
};
