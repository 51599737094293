import { FormattedMessage } from 'react-intl';

import config from 'config';

import { IconType } from 'modules/ui';

import type { SecurityAuditLeaksItem } from '../../types';

import { ListItemType, SecurityLeaks, UP_TO_LEAKS } from '../../constants';

import { IgnoreIndicator } from '../IgnoreIndicator';

import { SecurityAuditGroupItem } from '../SecurityAuditGroupItem';
import { SecurityAuditSecretItem } from '../SecurityAuditSecretItem';

import { SecurityAuditItemRenderer } from '../SecurityAuditItemRenderer';
import type { SecurityAuditItemRendererProps } from '../SecurityAuditItemRenderer';

export interface SecurityAuditLeaksItemRendererProps
    extends Omit<SecurityAuditItemRendererProps<SecurityAuditLeaksItem>, 'children'> {}

export const SecurityAuditLeaksItemRenderer = (props: SecurityAuditLeaksItemRendererProps) => (
    <SecurityAuditItemRenderer<SecurityAuditLeaksItem> {...props}>
        {({ item, isFirstGroup, isLastInGroup }) => (
            <>
                {item.type === ListItemType.GROUP ? (
                    <SecurityAuditGroupItem
                        name={
                            <FormattedMessage
                                id={`password.leaks.${item.securityLeaks}`}
                                values={{
                                    upToLeaks: UP_TO_LEAKS,
                                }}
                            />
                        }
                        count={item.count}
                        isFirstGroup={isFirstGroup}
                    />
                ) : (
                    <SecurityAuditSecretItem
                        id={item.id}
                        name={item.name}
                        icon={
                            item.securityLeaks === SecurityLeaks.MANY_LEAKS && !item.ignoreSecurityReport
                                ? IconType.STATUS_WARNING
                                : null
                        }
                        isLastInGroup={isLastInGroup}
                        updateSecretRoute={config.routes.securityAuditLeaksUpdateSecret}
                        additional={item.ignoreSecurityReport ? <IgnoreIndicator /> : null}
                    />
                )}
            </>
        )}
    </SecurityAuditItemRenderer>
);
