import { Secret } from './Secret';
import { Text } from './Text';
import type { TextProps } from './Text';

export enum CopyButtonType {
    TEXT = 'text',
    PASSWORD = 'password',
    TOTP = 'totp',
}

export interface CopyButtonProps extends Pick<TextProps, 'text' | 'disabled' | 'children'> {
    type?: `${CopyButtonType}`;
}

export const CopyButton = ({ type = CopyButtonType.TEXT, ...props }: CopyButtonProps) => {
    if (type === CopyButtonType.PASSWORD || type === CopyButtonType.TOTP) {
        return <Secret type={type} {...props} />;
    }

    return <Text {...props} />;
};
