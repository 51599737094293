import { eventChannel } from 'redux-saga';
import type { EventChannel } from 'redux-saga';
import { takeEvery, put, select } from 'redux-saga/effects';

import { actions as messagesActions } from 'modules/messages';

import { Status } from '../../constants';

import { getConnectionStatus } from '../../utils/getConnectionStatus';

import { networkStatusSelector } from '../selectors';
import { updateNetworkStatus } from '../actions';

function* createConnectionChannel() {
    let prevStatus: Status = yield select(networkStatusSelector);

    return eventChannel<Status>(emit => {
        function handleConnectionStatusChange() {
            const status = getConnectionStatus();
            if (status !== prevStatus) {
                emit(status);
                prevStatus = status;
            }
        }

        window.addEventListener('online', handleConnectionStatusChange);
        window.addEventListener('offline', handleConnectionStatusChange);

        return () => {
            window.removeEventListener('online', handleConnectionStatusChange);
            window.removeEventListener('offline', handleConnectionStatusChange);
        };
    });
}

const options = {
    duration: 3.5,
};

function* handleConnectionChange(status: Status) {
    if (status === Status.OFFLINE) {
        yield put(messagesActions.displayInfoMessage({ message: { id: 'network.offline' }, options }));
    } else {
        yield put(messagesActions.displayInfoMessage({ message: { id: 'network.online' }, options }));
    }
    yield put(updateNetworkStatus(status));
}

export default function* networkConnection() {
    const connectionChannel: EventChannel<Status> = yield createConnectionChannel();

    yield takeEvery(connectionChannel, handleConnectionChange);
}
