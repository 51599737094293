import type { TRuleWithTheme } from 'styles/theme';

export const customContainer: TRuleWithTheme = () => ({
    '& .ant-select-selection-item svg': {
        // Hide icon in the select input as it copies the option to the input (:man-shrugging:)
        display: 'none',
    },
});

export const customOption: TRuleWithTheme = ({ theme }) => ({
    '&.ant-select-item-option.ant-select-item-option-active svg': {
        // Color the viewIcon with hover color
        color: 'inherit',
    },

    '&.ant-select-item-option.ant-select-item-option-selected svg': {
        // Keep the viewIcon color when option is selected
        color: theme.colors.iconsSecondary,
    },

    '&.ant-select-item-option.ant-select-item-option-selected.ant-select-item-option-active svg': {
        // Keep the viewIcon color when option is selected
        color: theme.colors.iconsSecondary,
    },
});

export const viewOption: TRuleWithTheme = () => ({
    display: 'flex',
    alignItems: 'center',

    overflow: 'auto',
});

export const viewIcon: TRuleWithTheme = ({ theme }) => ({
    flexShrink: 0,

    marginRight: '0.75rem',

    color: theme.colors.iconsSecondary,
});

export const viewChildren: TRuleWithTheme = () => ({
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
});
