export enum Language {
    EN = 'en',
    CS = 'cs',
    DE = 'de',
    PL = 'pl',
    ES = 'es',
    RU = 'ru',
}

export const supportedLanguages: Language[] = Object.values(Language);
