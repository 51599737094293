const modulePrefix = 'whitelist';

export default {
    FETCH_WHITELIST: `${modulePrefix}/FETCH_WHITELIST`,
    FETCH_WHITELIST_NEXT: `${modulePrefix}/FETCH_WHITELIST_NEXT`,
    FETCH_WHITELIST_REQUEST: `${modulePrefix}/FETCH_WHITELIST_REQUEST`,
    FETCH_WHITELIST_SUCCESS: `${modulePrefix}/FETCH_WHITELIST_SUCCESS`,
    FETCH_WHITELIST_FAILURE: `${modulePrefix}/FETCH_WHITELIST_FAILURE`,
    FETCH_WHITELIST_CANCEL: `${modulePrefix}/FETCH_WHITELIST_CANCEL`,

    SET_WHITELIST: `${modulePrefix}/SET_WHITELIST`,
};
