import { useFela } from 'react-fela';
import { FormattedMessage } from 'react-intl';

import config from 'config';

import { useBackendVersion } from '../../hooks/useBackendVersion';

import { container } from './Version.rules';

export interface VersionProps {
    customStyle?: typeof container;
}

export const Version = ({ customStyle }: VersionProps) => {
    const { css } = useFela();

    const backendVersion = useBackendVersion();

    return (
        <span className={css(container, customStyle)}>
            <FormattedMessage
                id={backendVersion ? 'version.with-backend' : 'version'}
                values={{ version: config.version, backendVersion }}
            />
        </span>
    );
};
