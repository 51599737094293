import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { useAppSelector } from 'hooks/useAppSelector';

import { fetchGroupMembers } from '../services/actions';
import { selectGroupMembersApi, selectGroupMembers } from '../services/selectors';

export const useFetchGroupMembers = (id: string) => {
    const { inProgress, success, error } = useAppSelector(state => selectGroupMembersApi(state, { id }));
    const groupMembers = useAppSelector(state => selectGroupMembers(state, { id }))
        .sort((a, b) => a.email?.localeCompare(b.email))
        .sort((a, b) => a.type?.localeCompare(b.type));

    const dispatch = useDispatch();

    const wasResetted = !inProgress && !success && !error;

    useEffect(() => {
        if (wasResetted) {
            dispatch(fetchGroupMembers(id));
        }
    }, [id, wasResetted, dispatch]);

    return {
        showLoader: inProgress,
        groupMembers,
    };
};
