import type { TRuleWithTheme } from 'styles/theme';

export const container: TRuleWithTheme = () => ({
    height: '100%',

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    paddingBottom: '0.75rem',
});

export const text: TRuleWithTheme = () => ({
    maxWidth: '19.875rem',
    textAlign: 'center',
});
