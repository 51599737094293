import { useFela } from 'react-fela';

import { Text } from 'modules/ui';

import type { Group } from '../../types';

import { GroupItem } from '../GroupItem';
import type { GroupItemProps } from '../GroupItem';

import * as felaRules from '../Groups/Groups.rules';
import { useGroupsWithLocalizedAllUsersGroup } from '../../hooks';

export interface FormFieldGroupsProps extends Pick<GroupItemProps, 'onRemove' | 'onUpdate'> {
    value: Group[];
}

export const FormFieldGroups = ({ value, onRemove, onUpdate }: FormFieldGroupsProps) => {
    const groups = useGroupsWithLocalizedAllUsersGroup(value);

    const { css } = useFela({
        removable: true,
    });

    if (!groups.length) {
        return null;
    }

    return (
        <div className={css(felaRules.container)}>
            <Text size="small" elementType="ul" customStyle={felaRules.list}>
                {groups.map(group => (
                    <GroupItem key={group.id} {...group} editable onRemove={onRemove} onUpdate={onUpdate} />
                ))}
            </Text>
        </div>
    );
};
