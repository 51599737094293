import { useMediaQuery } from 'react-responsive';
import { useFela } from 'react-fela';

import type { Theme } from 'styles/theme';

export const useBreakpoint = (breakpoint: keyof Theme['breakpoints']): boolean => {
    const { theme } = useFela<Theme>();
    return useMediaQuery({
        query: `(min-width: ${theme.breakpoints[breakpoint]}px)`,
    });
};
