import { Link, type LinkProps } from 'react-router-dom';

import { SettingsItem } from 'modules/forms';

import { Icon, Text } from 'modules/ui';

import * as felaRules from './SettingsLink.rules';

export interface SettingsLinkProps {
    to: LinkProps['to'];
    title: string;
}

export const SettingsLink = ({ to, title }: SettingsLinkProps) => (
    <SettingsItem>
        <Link to={to}>
            <Text elementType="span" size="small" customStyle={felaRules.link}>
                {title}
                <Icon type="dropdown" width={20} height={20} customStyle={felaRules.anchor} />
            </Text>
        </Link>
    </SettingsItem>
);
