import { useIntl } from 'react-intl';
import { useFela } from 'react-fela';

import { IconButton, Text } from 'modules/ui';

import * as felaRules from './InnerFile.rules';

export interface InnerFileProps {
    name: string;
    onRemove: () => void;
}

export const InnerFile = ({ name, onRemove }: InnerFileProps) => {
    const intl = useIntl();

    const { css } = useFela();

    return (
        <div className={css(felaRules.attachment)} data-testid="dropzone-file">
            <Text customStyle={felaRules.name}>{name}</Text>
            <IconButton
                type="ghost"
                icon="x"
                aria-label={intl.formatMessage({
                    id: 'remove.file',
                })}
                onClick={() => {
                    onRemove();
                }}
            />
        </div>
    );
};
