import { put, takeLatest } from 'redux-saga/effects';

import { AccessRole } from 'types';

import { config } from 'config/config';
import { logger } from 'config/logger';
import { api } from 'config/antonio';

import { actions as messagesActions } from 'modules/messages';

import { SharingPermission } from '../../constants';
import type { Settings } from '../../types';

import { loadSettingsSuccess, loadSettingsFailure, loadSettings } from '../actions';

const prepareSettings = (settings: Settings): Settings => ({
    sharingPermission: SharingPermission.DISALLOWED,
    defaultPermission: AccessRole.VIEWER,
    ...settings,
});

function* fetchSettings() {
    try {
        const { data } = yield* api.get<Settings>(config.api.settings);

        yield put(loadSettingsSuccess(prepareSettings(data)));
    } catch (e) {
        yield put(loadSettingsFailure(e));

        yield put(
            messagesActions.displayErrorMessage({
                message: { id: 'error.settings.load' },
                options: {
                    duration: 5,
                },
            }),
        );
        logger.error(e);
    }
}

export function* loadSettingsSaga() {
    yield takeLatest(loadSettings.toString(), fetchSettings);
}
