import type { TRuleWithTheme } from 'styles/theme';

export const value: TRuleWithTheme = () => ({
    display: 'inline-flex',
    alignItems: 'center',

    verticalAlign: 'bottom',

    textTransform: 'uppercase',
});

export const flag: TRuleWithTheme = () => ({
    width: 15,
    height: 10,

    marginRight: '0.375rem',
});
