import { isEmpty } from 'lodash';

import { useSecurityAuditLeaksSecretsList } from '../../hooks/useSecurityAuditLeaksSecretsList';

import { SecretsListContainer } from '../SecretsListContainer';
import { SecurityAuditLeaksSecretsList } from '../SecurityAuditLeaksSecretsList';
import { SecurityAuditListEmpty } from '../SecurityAuditListEmpty';

export const SecurityAuditLeaksSecrets = () => {
    const data = useSecurityAuditLeaksSecretsList();

    return (
        <SecretsListContainer
            isEmpty={isEmpty(data)}
            empty={<SecurityAuditListEmpty messageId="securityAudit.list.empty" />}
        >
            {({ height, width }) => <SecurityAuditLeaksSecretsList data={data} height={height} width={width} />}
        </SecretsListContainer>
    );
};
