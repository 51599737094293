import { SearchOptionType } from '../../../types';
import type { Option } from '../types';

/** AntD autocomplete has a bug that always passes the last option to onSelect when multiple options have the same value.
 * This can happen when you search for a tag with full name. In that case, there are two options with the same value. AntD will always
 * pass the last one (= tag option) even if you select the first one (= filter option). Adding prefix to the tag value fixes this issue.
 * It needs to be removed when selecting the option.
 * @see https://github.com/ant-design/ant-design/issues/38951
 */
export const TAG_OPTION_VALUE_PREFIX = '__TAG__';

export const createTagOption = (tag: string): Option => ({
    value: TAG_OPTION_VALUE_PREFIX + tag,
    label: tag,
    title: tag,
    type: SearchOptionType.TAG,
});

export const createGroupOption = (id: string, name: string): Option => ({
    value: id,
    label: name,
    title: name,
    type: SearchOptionType.GROUP,
});

export const createFilterOption = (filter: string): Option => ({
    value: filter,
    label: filter,
    title: filter,
    type: SearchOptionType.FILTER,
});

export const createRecordOption = (id: string, name: string): Option => ({
    value: id,
    label: name,
    title: name,
    type: SearchOptionType.RECORD,
});
