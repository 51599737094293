import { FormattedMessage } from 'react-intl';

import { StaticModal, useModal } from 'modules/modals';
import { Button } from 'modules/ui';
import { SettingsItem } from 'modules/forms';

import { DeleteSecretsModal } from '../DeleteSecretsModal';

export const DeleteSecrets = () => {
    const { modal, updateModal } = useModal(StaticModal.DELETE_SECRETS, { autoClose: true });

    return (
        <SettingsItem>
            <Button
                type="link"
                color="red"
                size="small"
                onClick={() => {
                    updateModal(true);
                }}
            >
                <FormattedMessage id="settings.secrets.delete" />
            </Button>
            <DeleteSecretsModal
                open={modal?.visibility}
                onClose={() => {
                    updateModal(false);
                }}
            />
        </SettingsItem>
    );
};
