import { createSelector } from '@reduxjs/toolkit';

import type { AppState } from 'types';

import { selectEntities } from 'services/selectors';

const selectWhitelistEntities = createSelector(selectEntities, entities => entities.whitelist);

export const selectWhitelist = createSelector(selectWhitelistEntities, ({ byIds, ids }) => ids.map(id => byIds[id]));

export const selectWhitelistApi = (state: AppState) => state.api.whitelist.fetch;
