import { useDispatch } from 'react-redux';

import { useModal, StaticModal } from 'modules/modals';

import { importValidationRequestCancel, importValidationRequestSuccess } from '../services/actions';

export const useImportValidationModal = () => {
    const { modal, updateModal } = useModal(StaticModal.IMPORT_VALIDATION);
    const dispatch = useDispatch();

    const handleClose = () => {
        dispatch(importValidationRequestCancel());
        updateModal(false);
    };

    const handleAccept = () => {
        dispatch(importValidationRequestSuccess());
        updateModal(false);
    };

    return {
        visible: modal?.visibility,
        handleClose,
        handleAccept,
    };
};
