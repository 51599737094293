import { isApiAntonioError, ErrorCode } from 'services/utilities/isApiError';

import type { ImportValidationErrorData } from '../types';

export const isNonIgnorableImportError = (e: unknown) => {
    if (isApiAntonioError(e) && e.data.errorCode === ErrorCode.IMPORT_VALIDATION_INVALID_FILE) {
        return true;
    }

    if (
        isApiAntonioError<ImportValidationErrorData>(e) &&
        e.data.errorCode === ErrorCode.IMPORT_VALIDATION_INVALID_SECRETS
    ) {
        const { invalidSecretError, parseSecretError } = e.data.errorData;

        const isNonIgnorableError = invalidSecretError !== 0 || parseSecretError !== 0;

        return isNonIgnorableError;
    }

    return false;
};
