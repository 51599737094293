import { combineRules } from 'fela';

import type { TRuleWithTheme } from 'styles/theme';

export const container: TRuleWithTheme = () => ({
    display: 'grid',
    gridAutoFlow: 'column',
    gridAutoColumns: 'minmax(0, max-content)',
    gap: '1rem',

    justifyContent: 'space-between',

    ':last-child': {
        marginBottom: '0',
    },
});

const itemText: TRuleWithTheme = () => ({
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
});

export const desktopUserItem: TRuleWithTheme = combineRules(itemText, () => ({
    display: 'none',

    min768: {
        display: 'block',
    },
}));

export const mobileUserItem: TRuleWithTheme = combineRules(itemText, () => ({
    min768: {
        display: 'none',
    },
}));

export const editOptionsContainer: TRuleWithTheme = () => ({
    display: 'flex',
    gap: '1.25rem',
    alignItems: 'center',
});

export const trashButton: TRuleWithTheme = ({ theme }) => ({
    color: theme.colors.red,
});

export const roleText: TRuleWithTheme = () => ({
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
});
