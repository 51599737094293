import { useEffect, useState } from 'react';
import { Transition } from 'react-transition-group';
import type { TransitionProps } from 'react-transition-group/Transition';

import type { Message as TMessage } from '../../types';

import { TRANSITION_DURATION } from '../../config';

export interface MessageProps {
    visible: TMessage['visible'];
    children: TransitionProps['children'];
}

export function Message({ visible, children }: MessageProps) {
    const [mounted, setMountedFlag] = useState(false);

    useEffect(() => {
        setMountedFlag(true);
    }, []);

    return (
        <Transition in={mounted && visible} timeout={TRANSITION_DURATION}>
            {children}
        </Transition>
    );
}
