import { useIntl } from 'react-intl';

import { createGroupLabel, useGroups } from 'modules/groups';

import type { ViewFilter } from '../../types';

import { SecretsViewSelect, Option } from '../SecretsViewSelect';

export interface SecretsViewGroupSelectProps {
    viewFilter: ViewFilter;
    onChange: (viewFilter: ViewFilter) => void;
}

export const SecretsViewGroupSelect = ({ viewFilter, onChange }: SecretsViewGroupSelectProps) => {
    const { dataWithLocalizedAllUsersGroup, api, handleSearch, handleLoadMore } = useGroups();

    const intl = useIntl();

    return (
        <SecretsViewSelect
            placeholder={intl.formatMessage({
                id: 'secrets.view.modal.group.placeholder',
            })}
            onChange={value => {
                const option = dataWithLocalizedAllUsersGroup.find(({ id }) => id === value);

                onChange({
                    id: option.id,
                    email: option.email,
                    name: option.name,
                    type: 'group',
                });

                handleSearch(undefined);
            }}
            options={dataWithLocalizedAllUsersGroup.map(group => ({
                key: group.id,
                value: group.id,
                title: group.email,
                label: <Option icon="group">{createGroupLabel(group)}</Option>,
            }))}
            onSearch={handleSearch}
            hasMoreOptions={Boolean(api.nextPageToken)}
            loadingMoreOptions={api.inProgressNext}
            onMoreOptions={handleLoadMore}
            defaultValue={viewFilter?.type === 'group' ? viewFilter?.id : undefined}
        />
    );
};
