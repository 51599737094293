import type { TRuleWithTheme } from 'styles/theme';

export { container, text } from '../Text/Text.rules';

const iconSize = 1.5;

export const icon: TRuleWithTheme = () => ({
    cursor: 'pointer',

    width: `${iconSize}rem`,
    height: `${iconSize}rem`,
});

export const secretContainer: TRuleWithTheme = () => ({
    display: 'flex',
    alignItems: 'center',
    gap: '0.375rem',
});

export const disabledWarningText: TRuleWithTheme = () => ({
    maxWidth: '8.5rem',
});

export const cryptedText: TRuleWithTheme = () => ({
    letterSpacing: '0.125rem',
    fontSize: '1.125rem',
});
