import type { SVGProps } from 'react';

export const Settings = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
        <g
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            clipPath="url(#clip0_2040_1495)"
        >
            <path d="M14.994 12.015a2.999 2.999 0 10-5.998 0 2.999 2.999 0 005.998 0zM4.677 15.014a1.699 1.699 0 00-.61-.72 1.678 1.678 0 00-.899-.28h-.17M10.076 21.011v-.09a1.609 1.609 0 00-.31-.92 1.889 1.889 0 00-.77-.66M15.074 19.332a1.67 1.67 0 00-.73.61c-.173.268-.267.58-.27.9v.17M20.991 13.934h-.09a1.69 1.69 0 00-.92.31 1.86 1.86 0 00-.64.8M19.313 8.936c.128.298.34.551.61.73.265.179.579.273.899.27h.17M13.994 3.019v.09a1.7 1.7 0 00.27.91c.176.289.429.524.73.68M8.996 4.698a1.59 1.59 0 00.73-.6 1.7 1.7 0 00.27-.91v-.17M2.998 10.016h.09c.32.003.634-.091.9-.27a2 2 0 00.71-.75"></path>
            <path d="M3 14.014H1v-3.998h2M20.992 13.934h2.01V9.936h-2.01M9.996 3.019v-1.99h3.998v1.99M10.076 21.011v1.99h3.998v-1.99M8.996 19.342a1.619 1.619 0 00-1-.08 1.6 1.6 0 00-.83.44l-.12.1M16.994 19.802l-.07-.06a1.598 1.598 0 00-.87-.43 1.67 1.67 0 00-1 .06M19.342 15.044a1.68 1.68 0 00-.08.94c.062.318.218.61.45.839l.12.12M19.712 7.047l-.06.06a1.68 1.68 0 00-.44.87 1.65 1.65 0 00.1 1M14.994 4.698c.298.121.626.149.94.08a1.6 1.6 0 00.83-.44l.12-.12M7.047 4.268l.06.07c.241.244.552.408.89.47a2 2 0 001-.08M4.699 8.996c.119-.298.15-.624.09-.94a1.68 1.68 0 00-.45-.84l-.12-.12M4.219 16.993l.07-.06a1.68 1.68 0 00.44-.83 2.119 2.119 0 00-.05-1.089M4.218 16.993l-1.41 1.42M7.046 19.822l-1.41 1.42M5.637 21.241L2.81 18.412M16.893 4.218l1.409-1.42M19.71 7.037l1.42-1.41M21.132 5.627L18.302 2.8M4.218 7.097l-1.4-1.41M7.048 4.268l-1.4-1.4M2.818 5.688l2.83-2.82M17.002 19.762l1.4 1.41M19.832 16.933l1.4 1.41M18.402 21.171l2.83-2.828"></path>
        </g>
        <defs>
            <clipPath id="clip0_2040_1495">
                <path fill="#fff" d="M0 0H23.97V24H0z" transform="rotate(-90 12 12)"></path>
            </clipPath>
        </defs>
    </svg>
);
