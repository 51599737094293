import { useFela } from 'react-fela';
import { Divider } from 'antd';

import { container } from './MenuDivider.rules';

export const MenuDivider = () => {
    const { css } = useFela();

    return <Divider className={css(container)} />;
};
