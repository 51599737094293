export const MODULE_PREFIX = 'import-export';

export const EXPORT_FILE_NAME = 'export.csv';
export const SUPPORTED_FILE_MIME = 'text/csv';

export const IMPORT_FORM = 'import-form';

export const Fields = {
    SECRETS: 'secrets',
    SECRETS_FORMAT: 'format',
    DELIMITER: 'delimiter',
    REPLACE_SECRETS: 'replaceSecrets',
} as const;
