import { useDispatch } from 'react-redux';

import { useAppSelector } from 'hooks/useAppSelector';

import type { SearchHistory } from '../types';

import { selectSearchHistory } from '../services/selectors';
import { setSearchHistory } from '../services/actions';

export const useSearchHistory = () => {
    const dispatch = useDispatch();
    const searchHistory = useAppSelector(selectSearchHistory);

    const updateHistory = (option: SearchHistory) => {
        dispatch(setSearchHistory(option));
    };

    return {
        searchHistory,
        updateHistory,
    };
};
