import { createAction } from '@reduxjs/toolkit';

import type { SubmitActions } from 'modules/forms';

import type { SecretFormValues } from '../../types';

import actionTypes from '../actionTypes';

export const createSecret = createAction(
    actionTypes.CREATE_SECRET,
    (values: SecretFormValues, meta: SubmitActions<SecretFormValues>) => ({
        payload: values,
        meta,
    }),
);

export const createSecretSuccess = createAction(actionTypes.CREATE_SECRET_SUCCESS);

export const createSecretFailure = createAction(actionTypes.CREATE_SECRET_FAILURE, (error: any) => ({
    payload: undefined,
    error,
}));
