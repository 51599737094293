import { apiSelector } from '@ackee/redux-utils';
import { useParams } from 'react-router-dom-v5-compat';
import { useDispatch } from 'react-redux';
import { useFormState } from 'react-final-form';

import { useAppSelector } from 'hooks/useAppSelector';

import { useModal } from 'modules/modals';

import { REMOVE_SECRET_MODAL_KEY } from '../constants';
import type { SecretFormValues } from '../types';

import { removeSecret } from '../services/actions';

const subscription = {
    values: true,
};

export const useRemoveSecret = () => {
    const { id } = useParams<{
        id: string;
    }>();

    const { values } = useFormState<SecretFormValues>({
        subscription,
    });

    const { modal, updateModal } = useModal(REMOVE_SECRET_MODAL_KEY);

    const { inProgress: deleting } = useAppSelector(state => apiSelector(state, 'secrets', 'remove', id));

    const dispatch = useDispatch();

    const handleRemove = () => {
        dispatch(removeSecret(id));
    };

    const handleClose = () => {
        updateModal(false);
    };

    return {
        open: modal?.visibility,
        name: values.name,
        deleting,
        handleRemove,
        handleClose,
    };
};
