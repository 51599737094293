import { createAction } from '@reduxjs/toolkit';

import type { SecurityAuditData } from '../types';
import types from './actionTypes';

export const fetchAudit = createAction(types.FETCH_AUDIT);

export const fetchAuditSuccess = createAction(types.FETCH_AUDIT_SUCCESS, (payload: SecurityAuditData) => ({
    payload,
}));

export const fetchAuditFailure = createAction(types.FETCH_AUDIT_FAILURE, (error: any) => ({
    payload: undefined,
    error,
}));
