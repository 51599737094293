import { MenuDesktop } from 'modules/layout';
import { useIsAdmin } from 'modules/auth';
import { AdminSecretsView, UserSecretsView } from 'modules/secrets';
import { UserGuideSecretsView } from 'modules/user-guide';

import { SidebarContent } from '../SidebarContent';
import { desktopAlignOffset } from '../SidebarMobile';

export const SidebarDesktop = () => {
    const isAdmin = useIsAdmin();

    return (
        <MenuDesktop>
            {isAdmin ? (
                <UserGuideSecretsView
                    placement="rightTop"
                    align={{
                        offset: desktopAlignOffset,
                    }}
                >
                    <AdminSecretsView />
                </UserGuideSecretsView>
            ) : (
                <UserSecretsView />
            )}
            <SidebarContent />
        </MenuDesktop>
    );
};
