import { useFela } from 'react-fela';

import { Switch as AntSwitch } from 'antd';
import type { SwitchProps as AntSwitchProps } from 'antd/es/switch';

import * as felaRules from './Switch.rules';

export interface SwitchProps extends Omit<AntSwitchProps, 'className'> {
    customStyle?: typeof felaRules.container;
}

export const Switch = ({ customStyle, ...props }: SwitchProps) => {
    const { css } = useFela();

    return <AntSwitch {...props} className={css(felaRules.container, customStyle)} />;
};
