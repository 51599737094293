import type { TRuleWithTheme } from 'styles/theme';

export const container: TRuleWithTheme = () => ({
    display: 'grid',
    gridAutoFlow: 'column',
    gap: '1rem',
    alignItems: 'center',
    justifyContent: 'space-between',

    ':last-child': {
        marginBottom: '0',
    },
});

export const editOptionsContainer: TRuleWithTheme = () => ({
    display: 'flex',
    gap: '1.25rem',
    alignItems: 'center',
});

export const trashButton: TRuleWithTheme = ({ theme }) => ({
    color: theme.colors.red,
});

export const roleText: TRuleWithTheme = () => ({
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
});
