import type { PropsWithChildren } from 'react';
import useToggle from 'react-use-toggle';

import { SharingModalContext } from './context';

export const SharingModalProvider = ({ children }: PropsWithChildren) => {
    const [open, toggle] = useToggle();

    return <SharingModalContext.Provider value={{ open, toggle }}>{children}</SharingModalContext.Provider>;
};
