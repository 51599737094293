import { isEmpty } from 'lodash';
import { REHYDRATE } from 'redux-persist';
import { put, select, take } from 'redux-saga/effects';
import type { AnyAction } from 'redux';

import { logger } from 'config/logger';

import { actions as messagesActions } from 'modules/messages';

import { SECRETS_CACHE } from '../../constants';

import {
    loadSecrets,
    loadSecretsSuccess,
    loadSecretsFailure,
    setSecrets,
    activateSyncSecrets,
    syncSecrets,
} from '../actions';

import { normalizeSecrets } from '../normalizers';

import fetchSecrets from './fetchSecrets';

import { selectSecretsEntities, selectMappedSecrets } from '../selectors';

export default function* () {
    try {
        yield put(loadSecrets());

        const entities: ReturnType<typeof selectSecretsEntities> = yield select(selectSecretsEntities);

        if (!entities.list._persist.rehydrated) {
            yield take((action: AnyAction) => action.type === REHYDRATE && action.key === SECRETS_CACHE);
        }

        const emptySecrets = isEmpty(yield select(selectMappedSecrets));

        if (emptySecrets) {
            const data = yield* fetchSecrets();

            const { entities, result } = normalizeSecrets(data);

            yield put(setSecrets(entities.secrets, result));
        }

        yield put(loadSecretsSuccess());

        yield put(activateSyncSecrets());

        if (!emptySecrets) {
            const verbose = true;
            yield put(syncSecrets(verbose));
        }
    } catch (e) {
        yield put(
            messagesActions.displayErrorMessage({
                message: { id: 'error.secrets.load' },
            }),
        );
        logger.error(e);

        yield put(loadSecretsFailure(e));
    }
}
