import { useForm } from 'react-final-form';

import { useAutosaveForm } from 'hooks/useAutosaveForm';

import { DefaultPermissionField } from '../DefaultPermissionField';
import { SharingPermissionField } from '../SharingPermissionField';

export const SettingsForm = () => {
    const { submit } = useForm();
    const { delayedSubmit } = useAutosaveForm(submit);

    return (
        <>
            <DefaultPermissionField onChange={delayedSubmit} />
            <SharingPermissionField onChange={delayedSubmit} />
        </>
    );
};
