import { useMemo, type ReactNode } from 'react';
import { useFela } from 'react-fela';
import { matchPath, useLocation } from 'react-router-dom-v5-compat';

import { Icon, IconType, Text, Link } from 'modules/ui';
import type { IconProps, ExternalLinkProps, InternalLinkProps } from 'modules/ui';

import * as felaRules from './MenuItem.rules';

export interface MenuItemProps {
    icon?: `${IconType}`;
    informationIcon?: Pick<IconProps, 'color' | 'type'>;
    link?: ExternalLinkProps | InternalLinkProps;
    children: ReactNode;
    additionalRoutesWithActiveClassName?: string[];
}

export const MenuItem = ({
    icon,
    informationIcon,
    link,
    children,
    additionalRoutesWithActiveClassName,
}: MenuItemProps) => {
    const { css } = useFela();
    const { pathname } = useLocation();

    const isCurrentRouteActive = useMemo(() => {
        if (!additionalRoutesWithActiveClassName) return false;

        return additionalRoutesWithActiveClassName.some(route => matchPath(pathname, route));
    }, [pathname, additionalRoutesWithActiveClassName]);

    const content = (
        <>
            {icon ? <Icon type={icon} customStyle={felaRules.icon} /> : null}
            <span className={css(felaRules.children)}>
                {children}
                {informationIcon ? (
                    <Icon {...informationIcon} width={12} height={12} customStyle={felaRules.linkIcon} />
                ) : null}
            </span>
        </>
    );

    if (link) {
        if (link.type === 'router') {
            return (
                <Link
                    {...link}
                    size="regular"
                    color="black"
                    customStyle={felaRules.link}
                    overrideActiveClassName={isCurrentRouteActive}
                >
                    {content}
                </Link>
            );
        }
        return (
            <Link {...link} size="regular" color="black" customStyle={felaRules.link}>
                {content}
            </Link>
        );
    }

    return (
        <Text size="medium" customStyle={felaRules.text} elementType="div">
            {content}
        </Text>
    );
};
