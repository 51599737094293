import { useFela } from 'react-fela';
import classNames from 'classnames';

import { Input } from 'antd';
import type { TextAreaProps } from 'antd/es/input';

import { style } from '../TextInput/TextInput.rules';

export interface TextareaInputProps extends TextAreaProps {
    customStyle?: typeof style;
}

export const TextareaInput = ({ customStyle, ...props }: TextareaInputProps) => {
    const { css } = useFela();

    return <Input.TextArea {...props} className={classNames(css(style, customStyle), props.className)} />;
};
