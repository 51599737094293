import { useCallback, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';

import { StaticModal, useModal } from 'modules/modals';

import { sendFeedbackType } from '../services/actions';

import type { FeedbackType } from '../constants';

type ModalState = {
    timestamp: number;
    waitTime: number;
};

const MIN_TIME_DIFF_FIRST = 7776000; // 3 months
const MIN_TIME_DIFF_OTHER = 15552000; // 6 months

export const useUserFeedback = () => {
    const { modal, updateModal } = useModal<ModalState>(StaticModal.USER_FEEDBACK);

    const dispatch = useDispatch();

    const updateFeedbackModal = useCallback(
        (visibility: boolean, firstTime: boolean = false) => {
            updateModal(
                visibility,
                !visibility
                    ? {
                          timestamp: new Date().getTime(),
                          waitTime: firstTime ? MIN_TIME_DIFF_FIRST : MIN_TIME_DIFF_OTHER,
                      }
                    : undefined,
            );
        },
        [updateModal],
    );

    const modalRef = useRef(modal);
    const updateModalRef = useRef(updateFeedbackModal);

    useEffect(() => {
        // If there is no state for the modal, keep the modal hidden but set the timestamp
        if (!modalRef.current) {
            updateModalRef.current(false, true);

            return;
        }

        // If the modal is hidden, check if we should show it
        if (!modalRef.current.visibility) {
            const now = new Date().getTime();

            const diff = (now - modalRef.current.data.timestamp) / 1000;

            const minTimeDiff = modalRef.current.data.waitTime ?? 0;

            if (diff >= minTimeDiff) {
                updateModalRef.current(true);
            }
        }
    }, []);

    const sendFeedback = useCallback(
        (feedbackAction: FeedbackType) => {
            dispatch(sendFeedbackType(feedbackAction));
        },
        [dispatch],
    );

    return {
        modal,
        updateModal: updateFeedbackModal,
        sendFeedback,
    };
};
