import type { TRuleWithTheme } from 'styles/theme';

export const container: TRuleWithTheme = () => ({
    display: 'flex',
    alignItems: 'center',

    gap: '0.62rem',
});
export const heading: TRuleWithTheme = () => ({
    textOverflow: 'ellipsis',
    overflow: 'hidden',

    marginTop: 0,
});

export const secretFavicon: TRuleWithTheme = ({ theme }) => ({
    height: '1.75rem',
    width: '1.75rem',
});
