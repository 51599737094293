import { schema, normalize } from 'normalizr';

import type { WhitelistUser, WhitelistUserById } from '../types';
import { EntityKey } from '../types';

const whitelistItemSchema = new schema.Entity<WhitelistUser>(EntityKey.WHITELIST_USERS);
const whitelistSchema = new schema.Array<WhitelistUser>(whitelistItemSchema);

export const normalizeWhitelistItem = (data: any) =>
    normalize<
        WhitelistUser,
        {
            [EntityKey.WHITELIST_USERS]: WhitelistUserById;
        },
        WhitelistUser['id']
    >(data, whitelistItemSchema);
export const normalizeWhitelist = (data: any) =>
    normalize<
        WhitelistUser,
        {
            [EntityKey.WHITELIST_USERS]: WhitelistUserById;
        },
        WhitelistUser['id'][]
    >(data, whitelistSchema);
