import type { TRuleWithTheme } from 'styles/theme';

import { SIZE_STYLE, HeadingSize } from 'modules/ui/components/typography/Heading/Heading.rules';

export const largeInput: TRuleWithTheme = () => ({
    '&.ant-input': {
        fontWeight: '700',

        height: 'unset',

        min768: {
            ...SIZE_STYLE[HeadingSize.BIG],
            lineHeight: 1,
            paddingBlock: 0,
        },
    },
});

export const largeFormItem: TRuleWithTheme = () => ({
    width: '100%',
});

export const fieldAndFaviconContainer: TRuleWithTheme = () => ({
    display: 'flex',
    alignItems: 'center',

    gap: '0.62rem',
});
export const secretFavicon: TRuleWithTheme = ({ theme }) => ({
    height: '1.75rem',
    width: '1.75rem',
});
