import type { TRuleWithTheme } from 'styles/theme';

export const container: TRuleWithTheme<{ showBackButton: boolean }> = ({ theme, showBackButton }) => ({
    display: 'flex',
    justifyContent: showBackButton ? 'space-between' : 'flex-end',
    alignItems: 'center',

    position: 'sticky',
    top: 0,
    zIndex: 10,

    width: '100%',

    paddingInline: '1rem',
    paddingBottom: '0.5rem',

    backgroundColor: theme.colors.white,

    min768: {
        paddingInline: '2rem',
    },

    min1024: {
        minHeight: '3.25rem',
        paddingBottom: 'unset',
    },
});
