import { AUTH_SESSION_START, AUTH_SESSION_END } from '@ackee/petrus';
import { take, all, fork, cancel } from 'redux-saga/effects';

import timingSecretsSync from './timingSecretsSync';
import loadSecrets from './loadSecrets';
import syncSecrets from './syncSecrets';

function* loadAndSyncSecrets() {
    yield all([loadSecrets(), timingSecretsSync(), syncSecrets()]);
}

export default function* () {
    while (true) {
        yield take(AUTH_SESSION_START);

        const task = yield fork(loadAndSyncSecrets);

        yield take(AUTH_SESSION_END);

        yield cancel(task);
    }
}
