import { put, takeEvery } from 'redux-saga/effects';

import config from 'config';
import { publicApi } from 'config/antonio';
import { logger } from 'config/logger';

import type { RootResponsePayload } from '../types';

import { fetchBackendVersion, setBackendVersion } from './slices';

export function* handler() {
    try {
        const { data } = yield* publicApi.get<RootResponsePayload>(config.api.root);

        yield put(setBackendVersion(data.serverVersion));
    } catch (error) {
        logger.error(error);
    }
}

export function* saga() {
    yield takeEvery(fetchBackendVersion.toString(), handler);
}
