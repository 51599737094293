import type { TRuleWithTheme } from 'styles/theme';

export const link: TRuleWithTheme = () => ({
    display: 'inline-block',

    marginTop: '0.2em',
});

export const container: TRuleWithTheme = () => ({
    marginTop: '1rem',
});
