import { put, takeLatest } from 'redux-saga/effects';

import { config } from 'config/config';
import { logger } from 'config/logger';
import { api } from 'config/antonio';

import { actions as messagesActions } from 'modules/messages';

import type { AdminSettings } from '../../types';

import { loadOrderSuccess, loadOrderFailure, loadOrder } from '../actions';

function* fetchOrder() {
    try {
        const { data } = yield* api.get<AdminSettings>(config.api.settingsAdmin);

        yield put(loadOrderSuccess(data.order ?? null));
    } catch (e) {
        yield put(loadOrderFailure(e));

        yield put(
            messagesActions.displayErrorMessage({
                message: { id: 'error.settings.order' },
                options: {
                    duration: 5,
                },
            }),
        );
        logger.error(e);
    }
}

export function* loadOrderSaga() {
    yield takeLatest(loadOrder.toString(), fetchOrder);
}
