import type { TRuleWithTheme } from 'styles/theme';

export const container: TRuleWithTheme = () => ({
    display: 'flex',
    flexWrap: 'wrap',

    gap: '0.5rem',

    paddingTop: '1.25rem',

    border: 'none',
    width: '100%',
    textAlign: 'left',
});

export const copyButton: TRuleWithTheme = () => ({
    marginLeft: 'auto',
    alignSelf: 'center',
});

export const icon: TRuleWithTheme = ({ theme }) => ({
    marginTop: '0.25rem',
});
