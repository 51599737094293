import type { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { useFela } from 'react-fela';

import type { MessageKey } from 'modules/localizations/translations/types';

import { Heading } from '../typography/Heading';
import { Text } from '../typography/Text';

import * as felaRules from './Pane.rules';

export interface PaneProps {
    titleId?: MessageKey;
    messageId?: MessageKey;
    children: ReactNode;
    'data-testid'?: string;
    extend?: {
        container?: typeof felaRules.container;
    };
}

export const Pane = ({ titleId, messageId, children, extend, 'data-testid': testId }: PaneProps) => {
    const { css } = useFela();

    return (
        <div className={css(felaRules.container, extend?.container)} data-testid={testId}>
            {titleId ? (
                <Heading elementType="h2" color="black" customStyle={felaRules.heading}>
                    <FormattedMessage id={titleId} />
                </Heading>
            ) : null}
            {messageId ? (
                <Text customStyle={felaRules.text}>
                    <FormattedMessage id={messageId} />
                </Text>
            ) : null}

            {children}
        </div>
    );
};
