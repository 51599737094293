import type { TRuleWithTheme } from 'styles/theme';

export const options: TRuleWithTheme = () => ({
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    columnGap: '1.5rem',
    rowGap: '0.125rem',

    marginTop: '-0.635rem',
});
