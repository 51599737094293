import { useState } from 'react';
import { useFela } from 'react-fela';
import { useIntl } from 'react-intl';

import shield from 'assets/images/icons/shield.svg';
import cloud from 'assets/images/icons/cloud.svg';
import lock from 'assets/images/icons/lock.svg';

import { container, wrapper, image } from './Loader.rules';

const loaders = {
    0: {
        image: lock,
        id: 'loader.basic',
        animate: true,
    },
    1: {
        image: shield,
        id: 'loader.shield',
        animate: false,
    },
    2: {
        image: cloud,
        id: 'loader.clouds',
        animate: false,
    },
    3: {
        image: lock,
        id: 'loader.google',
        animate: true,
    },
    4: {
        image: lock,
        id: 'loader.passwords',
        animate: true,
    },
    5: {
        image: lock,
        id: 'loader.encryption',
        animate: true,
    },
    6: {
        image: lock,
        id: 'loader.letters',
        animate: true,
    },
    7: {
        image: lock,
        id: 'loader.symbols',
        animate: true,
    },
    8: {
        image: lock,
        id: 'loader.buttons',
        animate: true,
    },
} as const;

const getRandomLoaderIndex = () => Math.floor(Math.random() * Object.keys(loaders).length) as keyof typeof loaders;

export interface LoaderProps {
    randomize?: boolean;
    minHeight?: number | string;
}

export const Loader = ({ randomize, minHeight }: LoaderProps) => {
    const [loader] = useState(() => loaders[randomize ? getRandomLoaderIndex() : 0]);

    const { css } = useFela({
        animate: loader.animate,
        minHeight,
    });

    const intl = useIntl();

    return (
        <div className={css(container)}>
            <div className={css(wrapper)}>
                <img
                    src={loader.image}
                    alt={intl.formatMessage({
                        id: 'loader.imageAlt',
                    })}
                    className={css(image)}
                />
                {intl.formatMessage({
                    id: loader.id,
                })}
            </div>
        </div>
    );
};
