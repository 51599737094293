import { createAction } from '@reduxjs/toolkit';

import type { SubmitActions } from 'modules/forms';

import type { SecretFormValues, Secret } from '../../types';
import types from '../actionTypes';

export const updateSecret = createAction(
    types.UPDATE_SECRET,
    (id: Secret['id'], values: SecretFormValues, actions?: Partial<SubmitActions<SecretFormValues>>) => ({
        meta: { id, ...actions },
        payload: values,
    }),
);

export const updateSecretSuccess = createAction(types.UPDATE_SECRET_SUCCESS, (id: Secret['id']) => ({
    meta: { id },
    payload: undefined,
}));

export const updateSecretFailure = createAction(types.UPDATE_SECRET_FAILURE, (id: Secret['id'], error: any) => ({
    meta: { id },
    payload: undefined,
    error,
}));

export const updateSecretReset = createAction(types.UPDATE_SECRET_RESET, (id: Secret['id']) => ({
    meta: { id },
    payload: undefined,
}));
