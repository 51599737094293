import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { useAppSelector } from 'hooks/useAppSelector';

import type { Secret } from '../types';

import { readSecret } from '../services/actions';
import { selectReadSecretApi, selectHasSecret } from '../services/selectors';

export const useFetchSecret = (id: Secret['id']) => {
    const { inProgress: showLoader } = useAppSelector(state => selectReadSecretApi(state, id));
    const hasSecret = useAppSelector(state => selectHasSecret(state, { id }));

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(readSecret(id));
    }, [id, dispatch]);

    return {
        id,
        showLoader,
        hasSecret,
    };
};
