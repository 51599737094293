import type { TRuleWithTheme } from 'styles/theme';

export const sharingAlertContent: TRuleWithTheme = () => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    gap: '2rem',
});

export const sharingAlertButton: TRuleWithTheme = () => ({
    '&.ant-btn-text': {
        fontSize: '0.75rem',
        fontWeight: 600,
    },
});
