import { useFela } from 'react-fela';
import { FormattedMessage } from 'react-intl';

import { useAppSelector } from 'hooks/useAppSelector';

import { SpinLoader, Text } from 'modules/ui';
import { Modal } from 'modules/modals';

import { selectImportApi } from '../../services/selectors';

import * as felaRules from './ImportProgressModal.rules';

export const ImportProgressModal = () => {
    const { inProgress } = useAppSelector(selectImportApi);

    const { css } = useFela();

    return (
        <Modal
            open={inProgress}
            width={474}
            extend={{
                container: felaRules.modalContainer,
            }}
        >
            <div className={css(felaRules.content)}>
                <SpinLoader customStyle={felaRules.loader} />
                <Text>
                    <FormattedMessage id="import.loader" />
                </Text>
            </div>
        </Modal>
    );
};
