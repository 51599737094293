import type { SVGProps } from 'react';

export const Group = (props: SVGProps<SVGSVGElement>) => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9 5C9.59334 5 10.1734 5.17595 10.6667 5.50559C11.1601 5.83524 11.5446 6.30377 11.7716 6.85195C11.9987 7.40013 12.0581 8.00333 11.9424 8.58527C11.8266 9.16722 11.5409 9.70177 11.1213 10.1213C10.7018 10.5409 10.1672 10.8266 9.58527 10.9424C9.00333 11.0581 8.40013 10.9987 7.85195 10.7716C7.30377 10.5446 6.83524 10.1601 6.50559 9.66671C6.17595 9.17337 6 8.59335 6 8C6 7.20435 6.31607 6.44129 6.87868 5.87868C7.44129 5.31607 8.20435 5 9 5ZM9 13C9.98891 13 10.9556 12.7068 11.7779 12.1573C12.6001 11.6079 13.241 10.827 13.6194 9.91342C13.9978 8.99979 14.0969 7.99446 13.9039 7.02455C13.711 6.05465 13.2348 5.16373 12.5355 4.46447C11.8363 3.76521 10.9454 3.289 9.97545 3.09608C9.00555 2.90315 8.00021 3.00217 7.08658 3.3806C6.17295 3.75904 5.39206 4.39991 4.84265 5.22215C4.29324 6.0444 4 7.0111 4 8C4 9.32609 4.52678 10.5979 5.46447 11.5355C6.40215 12.4732 7.67392 13 9 13Z"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.54 19C2.89073 18.3925 3.39503 17.888 4.00233 17.5369C4.60962 17.1859 5.29855 17.0007 6 17H12C12.7015 17.0007 13.3904 17.1859 13.9977 17.5369C14.605 17.888 15.1093 18.3925 15.46 19H2.54ZM12 15H6C4.4087 15 2.88258 15.6321 1.75736 16.7574C0.632141 17.8826 0 19.4087 0 21H18C18 19.4087 17.3679 17.8826 16.2426 16.7574C15.1174 15.6321 13.5913 15 12 15Z"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18 15H15.59C16.4386 15.5091 17.171 16.1904 17.74 17H18C18.7014 17.0007 19.3904 17.1859 19.9977 17.5369C20.605 17.888 21.1093 18.3925 21.46 19H18.71C18.8993 19.6499 18.9969 20.3231 19 21H24C24 19.4087 23.3679 17.8826 22.2426 16.7574C21.1174 15.6321 19.5913 15 18 15Z"
        />
        <path d="M15 5.00001C14.7446 5.00087 14.49 5.02767 14.24 5.08001C14.5741 5.67582 14.8009 6.3257 14.91 7.00001H15C15.5304 7.00001 16.0391 7.21073 16.4142 7.5858C16.7893 7.96087 17 8.46958 17 9.00001C17 9.53044 16.7893 10.0392 16.4142 10.4142C16.0391 10.7893 15.5304 11 15 11C14.7501 10.9994 14.5024 10.9519 14.27 10.86C13.9426 11.46 13.5128 11.9981 13 12.45C13.5311 12.7616 14.1269 12.9464 14.7412 12.9899C15.3554 13.0335 15.9713 12.9346 16.5411 12.7011C17.1108 12.4676 17.619 12.1057 18.0259 11.6436C18.4329 11.1815 18.7277 10.6317 18.8873 10.037C19.047 9.44235 19.0672 8.81884 18.9463 8.21506C18.8255 7.61128 18.5669 7.04355 18.1907 6.55609C17.8145 6.06863 17.3309 5.6746 16.7775 5.4047C16.224 5.13479 15.6157 4.9963 15 5.00001Z" />
    </svg>
);
