import { type PetrusTokens, tokensSelector } from '@ackee/petrus';
import { setTokens } from '@ackee/petrus/lib/services/actions';

import { store } from 'modules/core/modules/redux';

import { refreshTokensAsync } from './refreshTokensAsync';
import { isTokenExpired } from './isTokenExpired';

export const obtainValidAccessToken = async () => {
    const state = store.getState();
    const tokens = tokensSelector(state);

    if (!tokens) {
        return null;
    }

    if (isTokenExpired(tokens.accessToken as PetrusTokens['accessToken'])) {
        const refreshedTokens = await refreshTokensAsync(tokens as PetrusTokens);

        store.dispatch(setTokens(refreshedTokens));

        return refreshedTokens.accessToken;
    }

    return tokens.accessToken;
};
