import 'proxy-polyfill';

import ReactDOM from 'react-dom/client';

import { Core } from 'modules/core';
import { Application } from 'modules/application';

const container = document.getElementById('root');
const root = ReactDOM.createRoot(container);

root.render(
    <Core>
        <Application />
    </Core>,
);
