import { useFela } from 'react-fela';

import { config } from 'config/config';

import * as felaRules from './Logo.rules';

export interface LogoProps {
    customStyle?: typeof felaRules.container;
}

export const Logo = ({ customStyle }: LogoProps) => {
    const { css } = useFela();

    if (config.demo.enable) {
        return (
            <a
                className={`Logo ${css(felaRules.demoContainer, customStyle)}`}
                href="https://passwd.team"
                target="_blank"
                rel="noopener noreferrer"
            >
                <img
                    alt="Passwd logo"
                    className={css(felaRules.image)}
                    src={`${process.env.PUBLIC_URL}/images/logo.svg`}
                />
            </a>
        );
    }
    return (
        <div className={`Logo ${css(felaRules.container, customStyle)}`}>
            <img
                crossOrigin="anonymous"
                alt="Passwd logo"
                className={css(felaRules.image)}
                src={process.env.REACT_APP_LOGO_URL || `${process.env.PUBLIC_URL}/images/logo.svg`}
            />
        </div>
    );
};
