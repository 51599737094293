import { takeEvery } from 'redux-saga/effects';
import { LOCATION_CHANGE } from 'redux-first-history';

import config from 'config';

import type { LocationChangeAction } from 'types';

import doLogout from '../helpers/doLogout';

const handler = function* ({ payload: { location } }: LocationChangeAction) {
    const { pathname } = location;

    if (pathname === config.routes.logout) {
        yield* doLogout();
    }
};

export function* logoutSaga() {
    yield takeEvery(LOCATION_CHANGE, handler);
}
