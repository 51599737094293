const modulePrefix = 'secrets';

export default {
    LOAD_SECRETS: `${modulePrefix}/LOAD_SECRETS`,
    LOAD_SECRETS_SUCCESS: `${modulePrefix}/LOAD_SECRETS_SUCCESS`,
    LOAD_SECRETS_FAILURE: `${modulePrefix}/LOAD_SECRETS_FAILURE`,

    DELETE_SECRETS: `${modulePrefix}/DELETE_SECRETS`,
    DELETE_SECRETS_SUCCESS: `${modulePrefix}/DELETE_SECRETS_SUCCESS`,
    DELETE_SECRETS_FAILURE: `${modulePrefix}/DELETE_SECRETS_FAILURE`,

    ACTIVATE_SYNC_SECRETS: `${modulePrefix}/ACTIVATE_SYNC_SECRETS`,
    SYNC_SECRETS: `${modulePrefix}/SYNC_SECRETS`,

    READ_SECRET: `${modulePrefix}/READ_SECRET`,
    READ_SECRET_SUCCESS: `${modulePrefix}/READ_SECRET_SUCCESS`,
    READ_SECRET_FAILURE: `${modulePrefix}/READ_SECRET_FAILURE`,

    CREATE_SECRET: `${modulePrefix}/CREATE_SECRET`,
    CREATE_SECRET_SUCCESS: `${modulePrefix}/CREATE_SECRET_SUCCESS`,
    CREATE_SECRET_FAILURE: `${modulePrefix}/CREATE_SECRET_FAILURE`,

    UPDATE_SECRET: `${modulePrefix}/UPDATE_SECRET`,
    UPDATE_SECRET_SUCCESS: `${modulePrefix}/UPDATE_SECRET_SUCCESS`,
    UPDATE_SECRET_FAILURE: `${modulePrefix}/UPDATE_SECRET_FAILURE`,
    UPDATE_SECRET_RESET: `${modulePrefix}/UPDATE_SECRET_RESET`,

    REMOVE_SECRET: `${modulePrefix}/REMOVE_SECRET`,
    REMOVE_SECRET_SUCCESS: `${modulePrefix}/REMOVE_SECRET_SUCCESS`,
    REMOVE_SECRET_FAILURE: `${modulePrefix}/REMOVE_SECRET_FAILURE`,

    COPY_SECRET: `${modulePrefix}/COPY_SECRET`,
    COPY_SECRET_SUCCESS: `${modulePrefix}/COPY_SECRET_SUCCESS`,
    COPY_SECRET_FAILURE: `${modulePrefix}/COPY_SECRET_FAILURE`,
    COPY_SECRET_RESET: `${modulePrefix}/COPY_SECRET_RESET`,
    COPY_SECRET_INVALIDATE: `${modulePrefix}/COPY_SECRET_INVALIDATE`,

    IGNORE_REPORT_SECRET: `${modulePrefix}/IGNORE_REPORT_SECRET`,
    IGNORE_REPORT_SECRET_SUCCESS: `${modulePrefix}/IGNORE_REPORT_SECRET_SUCCESS`,
    IGNORE_REPORT_SECRET_FAILURE: `${modulePrefix}/IGNORE_REPORT_SECRET_FAILURE`,
    IGNORE_REPORT_SECRET_RESET: `${modulePrefix}/IGNORE_REPORT_SECRET_RESET`,

    SHARE_SECRET: `${modulePrefix}/SHARE_SECRET`,
    SHARE_SECRET_SUCCESS: `${modulePrefix}/SHARE_SECRET_SUCCESS`,
    SHARE_SECRET_FAILURE: `${modulePrefix}/SHARE_SECRET_FAILURE`,
    SHARE_SECRET_RESET: `${modulePrefix}/SHARE_SECRET_RESET`,

    READ_SHARE_SECRET: `${modulePrefix}/READ_SHARE_SECRET`,
    READ_SHARE_SECRET_SUCCESS: `${modulePrefix}/READ_SHARE_SECRET_SUCCESS`,
    READ_SHARE_SECRET_FAILURE: `${modulePrefix}/READ_SHARE_SECRET_FAILURE`,
    READ_SHARE_SECRET_RESET: `${modulePrefix}/READ_SHARE_SECRET_RESET`,

    GET_SECRET_TOTP: `${modulePrefix}/GET_SECRET_TOTP`,
    GET_SECRET_TOTP_SUCCESS: `${modulePrefix}/GET_SECRET_TOTP_SUCCESS`,
    GET_SECRET_TOTP_FAILURE: `${modulePrefix}/GET_SECRET_TOTP_FAILURE`,
    GET_SECRET_TOTP_RESET: `${modulePrefix}/GET_SECRET_TOTP_RESET`,

    REMOVE_SECRET_TOTP: `${modulePrefix}/REMOVE_SECRET_TOTP`,

    SET_SECRET: `${modulePrefix}/SET_SECRET`,
    SET_SECRETS: `${modulePrefix}/SET_SECRETS`,

    SET_SECRETS_TOTAL_COUNT: `${modulePrefix}/SET_SECRETS_TOTAL_COUNT`,

    SET_SECRETS_NAME_FILTER: `${modulePrefix}/SET_SECRETS_NAME_FILTER`,

    SET_SECRETS_LIST_OFFSET: `${modulePrefix}/SET_SECRETS_LIST_OFFSET`,

    SET_SECRETS_VIEW: `${modulePrefix}/SET_SECRETS_VIEW`,
    SET_SECRETS_VIEW_IDS: `${modulePrefix}/SET_SECRETS_VIEW_IDS`,

    SET_SEARCH_HISTORY: `${modulePrefix}/SET_SEARCH_HISTORY`,

    TOGGLE_ACTIVE_SECRET_ATTRIBUTE_FILTER: `${modulePrefix}/TOGGLE_ACTIVE_SECRET_ATTRIBUTE_FILTER`,
    CLEAR_ACTIVE_SECRET_ATTRIBUTE_FILTERS: `${modulePrefix}/CLEAR_ACTIVE_SECRET_ATTRIBUTE_FILTERS`,

    HANDLE_CLOSE_PAGE_FORM: `${modulePrefix}/HANDLE_CLOSE_PAGE_FORM`,

    GENERATE_RANDOM_PASSWORD: `${modulePrefix}/GENERATE_RANDOM_PASSWORD`,

    UPDATE_TAG: `${modulePrefix}/UPDATE_TAG`,
    REMOVE_TAG: `${modulePrefix}/REMOVE_TAG`,
};
