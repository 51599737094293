import { basicResetReducer } from '@ackee/redux-utils';
import { AUTH_SESSION_END } from '@ackee/petrus';
import { combineReducers } from 'redux';

import { entitiesReducer as secrets } from 'modules/secrets';
import { entitiesReducer as groups } from 'modules/groups';
import { entitiesReducer as whitelist } from 'modules/whitelist';
import { entitiesReducer as securityAudit } from 'modules/security-audit';
import { entitiesReducer as settings } from 'modules/settings';

const entitiesReducer = combineReducers({
    secrets,
    groups: basicResetReducer(groups, AUTH_SESSION_END),
    whitelist: basicResetReducer(whitelist, AUTH_SESSION_END),
    securityAudit: basicResetReducer(securityAudit, AUTH_SESSION_END),
    settings: basicResetReducer(settings, AUTH_SESSION_END),
});

export default entitiesReducer;
