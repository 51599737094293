import { useSelector } from 'react-redux';

import { useIsAdmin } from 'modules/auth';

import { selectGeneralSettings } from '../../../services/selectors';

export const useOverdueInvoiceAlert = () => {
    const { stripeStatus, adminEmail: stripeAdminEmail } = useSelector(selectGeneralSettings);
    const isLoggedUserAdmin = useIsAdmin();

    const isOverdue = stripeStatus === 'past_due';

    return {
        isOverdue,
        stripeAdminEmail,
        isLoggedUserAdmin,
    };
};
