import type { TRuleWithTheme } from 'styles/theme';

export const container: TRuleWithTheme = ({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    paddingTop: '1.25rem',
    paddingRight: '1.5rem',
    paddingBottom: '0.875rem',
    paddingLeft: '1.5rem',

    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: theme.colors.lightGrey4,
});

export const title: TRuleWithTheme = () => ({
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',

    marginBottom: '0.25rem',

    fontSize: '1rem',
    fontWeight: 400,
    lineHeight: '1.5em',

    letterSpacing: '0.03125em',
});

export const desc: TRuleWithTheme = ({ theme }) => ({
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',

    fontSize: '0.75rem',
    lineHeight: '1.5em',

    color: theme.colors.brownGrey2,
});

export const info: TRuleWithTheme = () => ({
    overflow: 'hidden',

    paddingRight: '2rem',
});

export const buttons: TRuleWithTheme = () => ({
    display: 'grid',
    gridAutoFlow: 'column',
    gap: '2rem',

    marginRight: '1.25rem',
});
