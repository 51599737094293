import tinyColor from 'tinycolor2';

import type { TRuleWithTheme } from 'styles/theme';

export enum OverlayType {
    BLUE = 'blue',
    WHITE_GRADIENT = 'whiteGradient',
}

export const container: TRuleWithTheme<{ type: `${OverlayType}` }> = ({ theme, type }) => {
    const TYPE_STYLE = {
        [OverlayType.BLUE]: {
            background: [theme.colors.blue, tinyColor(theme.colors.overlayBlue).setAlpha(0.7).toRgbString()],
        },
        [OverlayType.WHITE_GRADIENT]: {
            background: [
                'none',
                `linear-gradient(180deg, ${tinyColor(theme.colors.white).setAlpha(0).toRgbString()} 0, ${tinyColor(
                    theme.colors.white,
                )
                    .setAlpha(1)
                    .toRgbString()} 100%)`,
            ],
        },
    };
    return {
        position: 'absolute',
        width: '100%',
        height: '100%',
        zIndex: '1',
        ...TYPE_STYLE[type],
    };
};
