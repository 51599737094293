import { combineReducers } from 'redux';
import type { IHistoryContext } from 'redux-first-history';

import reducers from 'services/reducers';

export const createRootReducer = (routerReducer: IHistoryContext['routerReducer']) =>
    combineReducers({
        ...reducers,
        router: routerReducer,
    });
