import { createAction } from '@reduxjs/toolkit';

import types from '../actionTypes';

export const updateTag = createAction(types.UPDATE_TAG, (tag: [string, string]) => ({
    payload: {
        tag,
    },
}));

export const removeTag = createAction(types.REMOVE_TAG, (tag: string) => ({
    payload: { tag },
}));
