import type { SVGProps } from 'react';

export const Link = (props: SVGProps<SVGSVGElement>) => (
    <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
        <g fillRule="evenodd">
            <path d="M19 3h-7v2h5.586l-7.293 7.293 1.414 1.414L19 6.414V12h2V5c0-1.103-.897-2-2-2" />
            <path d="M17 19H5V7h3V5H5c-1.103 0-2 .897-2 2v12c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2v-3h-2v3z" />
        </g>
    </svg>
);
