import type { SubmitActions, SubmitErrors } from '../types';

export function handleFormSubmit<Values>(callback: (values: Values, actions: SubmitActions<Values>) => void) {
    return (values: Values) => {
        let actions: SubmitActions<Values>;
        const resolver = new Promise<SubmitErrors<Values> | null>(resolve => {
            actions = {
                success: () => {
                    resolve(null);
                },
                failure: (error = {}) => {
                    resolve(error);
                },
            };
        });

        callback(values, actions);

        return resolver;
    };
}
