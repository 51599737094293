import type { TRuleWithTheme } from 'styles/theme';

export const container: TRuleWithTheme = ({ theme }) => ({
    '& .ant-modal': {
        textAlign: 'right',
    },
});

export const icon: TRuleWithTheme = ({ theme }) => ({
    float: 'left',

    marginRight: '1rem',
    marginBottom: '1rem',

    fontSize: '1.5rem',
    color: theme.colors.orange,
});

export const text: TRuleWithTheme = () => ({
    marginBottom: '2.5rem',

    textAlign: 'left',
});
