import type { TRuleWithTheme } from 'styles/theme';

export const title: TRuleWithTheme = () => ({
    display: 'flex',
    alignItems: 'flex-start',

    gap: '0.5rem',

    marginBottom: '0.75rem',
});

export const text: TRuleWithTheme = () => ({
    marginBottom: '1rem',
});

export const formItem: TRuleWithTheme = () => ({
    '& .ant-form-item-label': {
        paddingLeft: 0,
        paddingRight: 0,

        marginBottom: '0.25rem',
    },
});
