import type { ReactNode } from 'react';
import { useFela } from 'react-fela';
import type { FieldMetaState } from 'react-final-form';

import { Form as AntForm } from 'antd';
import type { FormItemProps as AntFormItemProps } from 'antd/es/form';

import * as felaRules from './FormItem.rules';

const getValidateStatus = (
    touched: FieldMetaState<any>['touched'],
    error: FieldMetaState<any>['error'],
    submitError: FieldMetaState<any>['submitError'],
    valid: FieldMetaState<any>['valid'],
): AntFormItemProps['validateStatus'] => {
    if (touched) {
        if (error || submitError) return 'error';
        if (valid) return 'success';
    }
    return undefined;
};

function mapMeta<FieldValue>(meta: FieldMetaState<FieldValue>): AntFormItemProps {
    const props: AntFormItemProps = {};

    if (meta.touched && (meta.submitError || meta.error)) {
        props.help = meta.submitError || meta.error;
    }

    props.validateStatus = getValidateStatus(meta.touched, meta.error, meta.submitError, meta.valid);

    return props;
}

export interface FormItemProps<FieldValue> extends Omit<AntFormItemProps, 'className'> {
    meta?: FieldMetaState<FieldValue>;
    customStyle?: typeof felaRules.container;
    children?: ReactNode;
    fullWidth?: boolean;
}

export function FormItem<FieldValue>({ customStyle, meta, fullWidth = true, ...props }: FormItemProps<FieldValue>) {
    const { css } = useFela({ fullWidth });

    const mappedProps = meta ? mapMeta<FieldValue>(meta) : undefined;

    return <AntForm.Item {...props} {...mappedProps} className={css(felaRules.container, customStyle)} />;
}
