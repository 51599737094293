import { debounce } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { useAppSelector } from 'hooks/useAppSelector';

import { fetchWhitelist, fetchWhitelistNext } from '../services/actions';
import { selectWhitelist, selectWhitelistApi } from '../services/selectors';

export const useWhitelistUsers = () => {
    const [filter, setFilter] = useState('');

    const dispatch = useDispatch();

    const api = useAppSelector(selectWhitelistApi);

    const data = useAppSelector(selectWhitelist);

    const handleLoadMore = () => {
        dispatch(fetchWhitelistNext(filter));
    };

    const handleSearch = useMemo(() => debounce(setFilter, 250), []);

    useEffect(() => {
        dispatch(fetchWhitelist(filter));
    }, [dispatch, filter]);

    return { api, data, handleLoadMore, handleSearch };
};
