import { useFela } from 'react-fela';
import { FormattedMessage } from 'react-intl';
import type { PropsWithChildren } from 'react';

import type { MessageKey } from 'modules/localizations/translations/types';
import { Heading } from 'modules/ui';

import * as felaRules from './SecretSection.rules';

export interface SecretSectionProps extends PropsWithChildren {
    title: MessageKey;
    customStyle?: typeof felaRules.sectionContainer;
}

export const SecretSection = ({ title, children, customStyle }: SecretSectionProps) => {
    const { css } = useFela();

    return (
        <section className={css(felaRules.sectionContainer, customStyle)}>
            <Heading size="small" color="foregroundsPrimary">
                <FormattedMessage id={title} />
            </Heading>
            <div className={css(felaRules.contentContainer)}>{children}</div>
        </section>
    );
};
