import type { TRuleWithTheme } from 'styles/theme';

export const pageContainer: TRuleWithTheme = () => ({
    width: '100%',

    overflow: 'scroll',
});

export const contentContainer: TRuleWithTheme<{ isAdmin: boolean }> = ({ isAdmin }) => ({
    paddingBlock: '2rem',
    paddingInline: '1rem',

    // To prevent Tidio chat button from overlapping the content
    ...(isAdmin && {
        paddingBottom: '8rem',
    }),

    min1024: {
        paddingBlock: '3rem',
        paddingInline: '4.25rem',
    },
});
