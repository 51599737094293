import { takeEvery, put } from 'redux-saga/effects';

import {
    addMessageRequest,
    displayErrorMessage,
    displayInfoMessage,
    displayLoadingMessage,
    displaySuccessMessage,
    displayWarningMessage,
} from '../actions';

let id = 0;

function* createMessageHandler(
    action:
        | ReturnType<typeof displayInfoMessage>
        | ReturnType<typeof displayLoadingMessage>
        | ReturnType<typeof displaySuccessMessage>
        | ReturnType<typeof displayWarningMessage>
        | ReturnType<typeof displayErrorMessage>,
) {
    const nextId = String(id++);

    const { payload, meta } = action;

    yield put(addMessageRequest(nextId, payload, meta));
}

export function* createMessage() {
    yield takeEvery(
        [
            displayInfoMessage.toString(),
            displayLoadingMessage.toString(),
            displaySuccessMessage.toString(),
            displayWarningMessage.toString(),
            displayErrorMessage.toString(),
        ],
        createMessageHandler,
    );
}
