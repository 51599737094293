import { FormattedMessage } from 'react-intl';
import { generatePath, useLocation, useNavigate, useParams } from 'react-router-dom-v5-compat';

import { Button } from 'modules/ui';
import config from 'config';

export const CancelButton = () => {
    const navigate = useNavigate();
    const { state } = useLocation();
    const { id } = useParams();

    const handleClick = () => {
        if (state?.from) {
            navigate(state.from);
        } else {
            navigate(generatePath(config.routes.secret, { id }));
        }
    };

    return (
        <Button size="large" onClick={handleClick} type="text">
            <FormattedMessage id="cancel" />
        </Button>
    );
};
