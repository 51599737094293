import { Navigate, Route, Routes } from 'react-router-dom-v5-compat';

import { config } from 'config/config';

import { Layout, MainSection, UpgradeNotice } from 'modules/layout';
import {
    SecurityAuditSecretsPage,
    SecretsPage,
    SecretDetailPage,
    NewSecretPage,
    UpdateSecretPage,
} from 'modules/secrets';
import { ImportExportPage } from 'modules/import-export';
import { ChangelogPage } from 'modules/changelog';
import { SettingsPage, SubscriptionFirewall, Settings, TagsSettings, GroupsVisibilitySettings } from 'modules/settings';

import { Sidebar } from '../Sidebar';

export const ResponsiveApplication = () => (
    <Layout>
        <SubscriptionFirewall>
            <MainSection>
                <UpgradeNotice>
                    <Sidebar />
                    <Routes>
                        <Route path={config.routes.secrets} element={<SecretsPage />} />
                        {[
                            config.routes.updateSecret,
                            config.routes.securityAuditUpdateSecret,
                            config.routes.securityAuditLevelUpdateSecret,
                            config.routes.securityAuditAgeUpdateSecret,
                            config.routes.securityAuditLeaksUpdateSecret,
                        ].map(route => (
                            <Route key={route} path={route} element={<UpdateSecretPage />} />
                        ))}
                        <Route path={config.routes.newSecret} element={<NewSecretPage />} />
                        <Route path={config.routes.secret} element={<SecretDetailPage />} />

                        {[
                            config.routes.securityAudit,
                            config.routes.securityAuditLevel,
                            config.routes.securityAuditAge,
                            config.routes.securityAuditLeaks,
                        ].map(route => (
                            <Route key={route} path={route} element={<SecurityAuditSecretsPage />} />
                        ))}

                        <Route path={config.routes.importExport} element={<ImportExportPage />} />

                        <Route path={config.routes.changelog} element={<ChangelogPage />} />

                        <Route path={config.routes.settings} element={<SettingsPage />}>
                            <Route path={config.routes.settings} element={<Settings />} />
                            <Route path={config.routes.tagsSettings} element={<TagsSettings />} />
                            <Route
                                path={config.routes.groupsVisibilitySettings}
                                element={<GroupsVisibilitySettings />}
                            />
                        </Route>

                        <Route path={config.googleOAuth.redirectUrl} />

                        <Route path="*" element={<Navigate to={config.routes.secrets} replace />} />
                    </Routes>
                </UpgradeNotice>
            </MainSection>
        </SubscriptionFirewall>
    </Layout>
);
