import { combineReducers, createSlice } from '@reduxjs/toolkit';
import addDays from 'date-fns/addDays';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import config from 'config';

interface WhatIsNewState {
    version: string;
    validUntil: string;
}

const getState = () => ({
    version: config.version,
    validUntil: addDays(new Date(), 30).toISOString(),
});

const initialState: WhatIsNewState = getState();

const name = 'what-is-new';

const { reducer: whatIsNewReducer } = createSlice({
    name,
    initialState,
    reducers: {},
});

const isWhatIsNewState = (state: any): state is WhatIsNewState => typeof state === 'object' && 'version' in state;

export const whatIsNew = persistReducer(
    {
        key: name,
        storage,
        stateReconciler: inboundState => {
            if (isWhatIsNewState(inboundState)) {
                if (inboundState.version === config.version) {
                    return inboundState;
                }
            }

            return getState();
        },
    },
    whatIsNewReducer,
);

export const reducer = combineReducers({
    whatIsNew,
});
