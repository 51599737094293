import type { TRuleWithTheme } from 'styles/theme';

export const container: TRuleWithTheme = () => ({
    '& .ant-modal .ant-modal-content': {
        padding: 0,
    },
});

export const promo: TRuleWithTheme = () => ({
    width: '100%',
});

export const wrapper: TRuleWithTheme = () => ({
    paddingTop: '1.25rem',
    paddingRight: '1.5rem',
    paddingLeft: '1.5rem',
});

export const heading: TRuleWithTheme = () => ({
    marginBottom: '0.25rem',
});

export const buttons: TRuleWithTheme = () => ({
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '1rem',

    paddingTop: '1.5rem',
    paddingRight: '1.5rem',
    paddingLeft: '1.5rem',
    paddingBottom: '1.25rem',
});
