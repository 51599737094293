import { FormattedMessage } from 'react-intl';

import { Checkbox, CheckboxGroup } from 'modules/forms';

import { Options } from '../../../constants';

import * as felaRules from './GeneratePasswordOptions.rules';

export interface GeneratePasswordOptionsProps {
    value: Options[];
    onChange: (value: Options[]) => void;
}

export const GeneratePasswordOptions = ({ onChange, value }: GeneratePasswordOptionsProps) => (
    <CheckboxGroup value={value} onChange={onChange} customStyle={felaRules.options}>
        <Checkbox value={Options.UPPERCASE}>
            <FormattedMessage id={`secret.generate.checkboxes.${Options.UPPERCASE}`} />
        </Checkbox>
        <Checkbox value={Options.LOWERCASE}>
            <FormattedMessage id={`secret.generate.checkboxes.${Options.LOWERCASE}`} />
        </Checkbox>
        <Checkbox value={Options.NUMBERS}>
            <FormattedMessage id={`secret.generate.checkboxes.${Options.NUMBERS}`} />
        </Checkbox>
        <Checkbox value={Options.SYMBOLS}>
            <FormattedMessage id={`secret.generate.checkboxes.${Options.SYMBOLS}`} />
        </Checkbox>
    </CheckboxGroup>
);
