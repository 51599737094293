import type { ReactNode } from 'react';
import { useFela } from 'react-fela';

import { SelectInput } from 'modules/forms';
import type { SelectInputProps } from 'modules/forms';
import { Icon, IconProps } from 'modules/ui';

import * as felaRules from './SecretsViewSelect.rules';

export interface OptionProps {
    icon: IconProps['type'];
    children: ReactNode;
}

export const Option = ({ icon, children }: OptionProps) => {
    const { css } = useFela();

    return (
        <div className={css(felaRules.viewOption)}>
            <Icon type={icon} width={19} height={19} customStyle={felaRules.viewIcon} />
            <div className={css(felaRules.viewChildren)}>{children}</div>
        </div>
    );
};

export interface SecretsViewSelectProps
    extends Pick<SelectInputProps, 'loading' | 'placeholder' | 'options' | 'onChange' | 'onSearch' | 'defaultValue'> {
    loadingMoreOptions: boolean;
    onMoreOptions: () => void;
    hasMoreOptions: boolean;
}

export const SecretsViewSelect = ({
    onChange,
    onSearch,
    hasMoreOptions,
    loadingMoreOptions,
    onMoreOptions,
    loading,
    placeholder,
    options,
    defaultValue,
}: SecretsViewSelectProps) => (
    <SelectInput
        showSearch
        suffixIcon={<Icon type="search" color="iconsSecondary" />}
        placeholder={placeholder}
        onChange={onChange}
        options={options}
        optionFilterProp="title"
        onSearch={onSearch}
        loading={loading}
        loadingMore={loadingMoreOptions}
        hasMore={hasMoreOptions}
        loadMore={onMoreOptions}
        customStyle={felaRules.customContainer}
        customOptionStyle={felaRules.customOption}
        defaultValue={defaultValue}
    />
);
