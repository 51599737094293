import 'styles/fonts.css';
import 'antd/dist/reset.css';
import 'styles/globals.css';
import 'styles/resets.css';

import type { ReactNode } from 'react';

import { ConfigProvider } from 'antd';
import { StyleProvider as AntStyleProvider, legacyLogicalPropertiesTransformer } from '@ant-design/cssinjs';
import { useFela } from 'react-fela';

import type { Theme } from 'styles/theme';

interface StyleProviderProps {
    children: ReactNode;
}

export const StyleProvider = ({ children }: StyleProviderProps) => {
    const { theme } = useFela<Theme>();

    return (
        <AntStyleProvider transformers={[legacyLogicalPropertiesTransformer]}>
            <ConfigProvider
                theme={{
                    token: {
                        fontFamily: 'Soleil, sans-serif',

                        colorLinkActive: theme.colors.blue,
                        colorLinkHover: theme.colors.blue,

                        linkDecoration: 'none',
                        linkFocusDecoration: 'underline',
                        linkHoverDecoration: 'underline',

                        borderRadius: 4,
                    },
                }}
            >
                {children}
            </ConfigProvider>
        </AntStyleProvider>
    );
};
