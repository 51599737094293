import type { TRuleWithTheme } from 'styles/theme';

export const button: TRuleWithTheme = () => ({
    position: 'absolute',
    top: '0.75rem',
    right: '0.75rem',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    padding: 0,
    margin: 0,

    border: 'none',

    cursor: 'pointer',
});
