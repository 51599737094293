import { useFela } from 'react-fela';
import { FormattedMessage } from 'react-intl';

import { config } from 'config/config';

import { Button, Icon } from 'modules/ui';

import { container, textWrapper, icon } from './ExtensionCTA.rules';

export const ExtensionCTA = () => {
    const { css } = useFela();

    return (
        <div className={css(container)}>
            <div className={css(textWrapper)}>
                <Icon type="extension" color="brightMagenta" aria-label="Extension" customStyle={icon} />
                {config.demo.enable ? (
                    <FormattedMessage id="extension.cta.demo.text" />
                ) : (
                    <FormattedMessage id="extension.cta.text" />
                )}
            </div>
            <Button type="primary" size="small" href={config.extensionPromo.url} target="_blank">
                <FormattedMessage id="extension.cta.button" />
            </Button>
        </div>
    );
};
