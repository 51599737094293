import { useFela } from 'react-fela';
import { FormattedMessage, useIntl } from 'react-intl';

import { Switch } from 'modules/forms';
import { Link, Text } from 'modules/ui';
import { useIsAdmin } from 'modules/auth';
import config from 'config';

import { useShareSecret } from '../../../hooks/useShareSecret';
import type { Secret } from '../../../types';

import * as felaRules from './ShareSecretForm.rules';

export interface ShareSecretButtonProps {
    id: Secret['id'];
    share?: Secret['share'];
    canUserShare: boolean;
}

export const ShareSecretForm = ({ id, share, canUserShare }: ShareSecretButtonProps) => {
    const { css } = useFela();
    const intl = useIntl();

    const isAdmin = useIsAdmin();

    const { inProgress, shared, handleShare } = useShareSecret(id, share);

    return (
        <>
            <div className={css(felaRules.form)}>
                <Text elementType="h3">
                    <FormattedMessage id="secret.share.info" />
                </Text>

                {canUserShare ? (
                    <Switch
                        aria-label={intl.formatMessage({
                            id: shared ? 'secret.share.disable' : 'secret.share.enable',
                        })}
                        disabled={inProgress}
                        checked={shared}
                        onChange={handleShare}
                    />
                ) : (
                    <Text size="extraSmall" color="foregroundsTertiary">
                        {isAdmin ? (
                            <FormattedMessage
                                id="secret.share.unavailable.admin"
                                values={{
                                    link: chunks => (
                                        <Link to={config.routes.settings} type="router" inheritColor>
                                            {chunks}
                                        </Link>
                                    ),
                                }}
                            />
                        ) : (
                            <FormattedMessage id="secret.share.unavailable.user" />
                        )}
                    </Text>
                )}
            </div>
            <Text size="small" color="foregroundsTertiary" elementType="p" customStyle={felaRules.description}>
                <FormattedMessage id="secret.share.description" />
            </Text>
        </>
    );
};
