import type { TRuleWithTheme } from 'styles/theme';

export const container: TRuleWithTheme<{
    isFirstGroup: boolean;
}> = ({ theme, isFirstGroup }) => ({
    display: 'flex',
    alignItems: 'center',

    height: '100%',

    paddingTop: '1rem',
    paddingRight: '1.5rem',
    paddingBottom: '1rem',
    paddingLeft: '1.5rem',

    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: theme.colors.lightGrey,

    ...(!isFirstGroup
        ? {
              borderTopWidth: 1,
              borderTopStyle: 'solid',
              borderTopColor: theme.colors.lightGrey,
          }
        : undefined),
});

export const heading: TRuleWithTheme = ({ theme }) => ({
    fontSize: '1rem',
    fontWeight: 700,
    lineHeight: '1.5em',
});

export const count: TRuleWithTheme = ({ theme }) => ({
    fontWeight: 500,

    color: theme.colors.brownGrey3,
});
