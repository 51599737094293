import type { ReactNode } from 'react';
import { useFela } from 'react-fela';

import { ExclamationCircleFilled } from '@ant-design/icons';

import { Text } from 'modules/ui';

import { Modal, ModalProps } from '../Modal';

import * as felaRules from './ConfirmationModal.rules';

export interface ConfirmationModalProps extends Omit<ModalProps, 'alignButtonRight' | 'extend'> {
    icon?: ReactNode;
}

export function ConfirmationModal({ children = null, icon, ...props }: ConfirmationModalProps) {
    const { css } = useFela();
    return (
        <Modal
            extend={{
                container: felaRules.container,
            }}
            alignButtonsRight
            {...props}
        >
            <Text customStyle={felaRules.text}>
                {icon === null ? null : (
                    <span className={css(felaRules.icon)}>
                        {icon === undefined ? <ExclamationCircleFilled /> : icon}
                    </span>
                )}
                {children}
            </Text>
        </Modal>
    );
}
