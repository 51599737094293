import { createSelector } from '@reduxjs/toolkit';

import { authTokens, authUser } from 'services/selectors';

import { isDemoAccessToken } from './utils';

export const authUserIdSelector = createSelector(authUser, user => user.id);
export const authUserSelector = createSelector(authUser, user => user);

export const accessTokenSelector = createSelector([authTokens], tokens => tokens?.accessToken);
export const googleUserSelector = createSelector([accessTokenSelector], accessToken =>
    isDemoAccessToken(accessToken) ? accessToken?.googleUser : undefined,
);
