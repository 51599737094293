import { useContext } from 'react';

import { SharingModalContext } from '../context';

export const useSharingModal = () => {
    const context = useContext(SharingModalContext);

    if (!context) {
        throw new Error('useSharingModal must be used within a SharingModalProvider');
    }

    return context;
};
