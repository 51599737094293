import { generatorToPromise } from '@ackee/antonio-core';
import type { PetrusTokens } from '@ackee/petrus';

import { refreshTokens } from '../services/petrus/refreshTokens';

let refreshTokenRequest: Promise<PetrusTokens> | null = null;

export const refreshTokensAsync = async (tokens: PetrusTokens) => {
    if (!refreshTokenRequest) {
        refreshTokenRequest = generatorToPromise(refreshTokens(tokens));
        refreshTokenRequest.then(resetRefreshTokenRequest, resetRefreshTokenRequest);
    }

    return refreshTokenRequest;
};

const resetRefreshTokenRequest = () => {
    refreshTokenRequest = null;
};
