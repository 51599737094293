import { useAppSelector } from 'hooks/useAppSelector';

import { selectFilteredSecrets } from '../services/selectors';

export const useSecretsList = () => {
    const data = useAppSelector(selectFilteredSecrets);

    return {
        data,
    };
};
