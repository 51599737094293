import { useAppSelector } from 'hooks/useAppSelector';

import { selectHasSecrets } from '../../services/selectors';

import { SecretsListContainer } from '../SecretsListContainer';

import { SecretsList } from '../SecretsList';
import { SecretsListEmpty } from '../SecretsListEmpty';

export const Secrets = () => {
    const hasSecrets = useAppSelector(selectHasSecrets);

    return (
        <SecretsListContainer isEmpty={!hasSecrets} empty={<SecretsListEmpty />}>
            {({ height, width }) => <SecretsList height={height} width={width} />}
        </SecretsListContainer>
    );
};
