import { useState } from 'react';

import type { GroupMember } from '../types';

export const useNestedGroup = () => {
    const [openedNestedGroup, setOpenedNestedGroup] = useState<GroupMember | null>(null);

    const openNestedGroup = (groupMember: GroupMember) => {
        setOpenedNestedGroup(groupMember);
    };

    const closeNestedGroup = () => {
        setOpenedNestedGroup(null);
    };

    return {
        openedNestedGroup,
        openNestedGroup,
        closeNestedGroup,
    };
};
