import { FormattedMessage } from 'react-intl';

import { Pane, Text } from 'modules/ui';

import { ImportForm } from '../ImportForm';

import { ImportValidationModal } from '../ImportValidationModal';
import { ImportProgressModal } from '../ImportProgressModal';
import { ImportLimitReachedModal } from '../ImportLimitReachedModal';

import * as felaRules from './Import.rules';

export const Import = () => (
    <Pane titleId="import.title">
        <Text customStyle={felaRules.text}>
            <FormattedMessage id="import.message" />
        </Text>
        <ImportForm />
        <ImportValidationModal />
        <ImportProgressModal />
        <ImportLimitReachedModal />
    </Pane>
);
