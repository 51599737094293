import { useIntl } from 'react-intl';

import { TagsList } from 'modules/secrets/modules/tags';

import { SettingsDetailLayout } from '../SettingsDetailLayout';

export const TagsSettings = () => {
    const { formatMessage } = useIntl();

    return (
        <SettingsDetailLayout title={formatMessage({ id: 'settings.tags.title' })}>
            <TagsList />
        </SettingsDetailLayout>
    );
};
