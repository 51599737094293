import { useDispatch } from 'react-redux';

import { useAppSelector } from 'hooks/useAppSelector';

import { closeUserGuide, selectUserGuideOpen } from '../services/slice';

import type { Guide } from '../constants';

export const useUserGuide = (guide: Guide) => {
    const dispatch = useDispatch();

    const open = useAppSelector(state => selectUserGuideOpen(state, guide));

    const close = () => {
        dispatch(
            closeUserGuide({
                guide,
            }),
        );
    };

    return {
        open,
        close,
    };
};
