import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';

import type { AppState } from 'types';

import type { Guide } from '../constants';

const initialState: Partial<Record<Guide, boolean>> = {};

const name = 'user-guide';

const { reducer: userGuideReducer, actions } = createSlice({
    name,
    initialState,
    reducers: {
        closeUserGuide(
            state,
            {
                payload,
            }: PayloadAction<{
                guide: Guide;
            }>,
        ) {
            state[payload.guide] = false;
        },
    },
});

const { closeUserGuide } = actions;

export { closeUserGuide };

export const reducer = persistReducer(
    {
        key: name,
        storage,
    },
    userGuideReducer,
);

export const selectUserGuideOpen = (state: AppState, guide: Guide) => state.userGuide[guide] ?? true;
