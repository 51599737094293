import { FormattedMessage } from 'react-intl';

import { InfoLabel } from 'modules/forms';
import { Spacer } from 'modules/ui';

import { Fields } from '../../../constants';

import { TextField } from '../TextField';
import type { TextFieldProps } from '../TextField';

const name = Fields.WEB;

export const WebField = (props: Pick<TextFieldProps, 'onChange'>) => (
    <div>
        <TextField name={name} {...props} />
        <Spacer space={0.5} />
        <InfoLabel>
            <FormattedMessage id="secret.web.help" />
        </InfoLabel>
    </div>
);
