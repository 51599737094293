import { useFela } from 'react-fela';
import { FormattedMessage } from 'react-intl';
import { isEmpty } from 'lodash';

import { Empty, Loadable, Button, Icon, Text } from 'modules/ui';

import type { Group, GroupMember } from '../../types';
import { GroupMemberType } from '../../types';
import { useFetchGroupMembers } from '../../hooks';

import { container, groupButton, chevronRightIcon } from './GroupMembers.rules';

export interface GroupMembersProps {
    id: Group['id'];
    openNestedGroup: (groupMember: GroupMember) => void;
}

export const GroupMembers = ({ id, openNestedGroup }) => {
    const { showLoader, groupMembers } = useFetchGroupMembers(id);

    const { css } = useFela();

    return (
        <Loadable showLoader={showLoader}>
            <ul className={css(container)}>
                {isEmpty(groupMembers) && (
                    <Empty size="small" text={<FormattedMessage id="group.members.list.empty" />} />
                )}
                {groupMembers.map(member => (
                    <li key={member.email}>
                        {member.type === GroupMemberType.Group ? (
                            <Button type="text" onClick={() => openNestedGroup(member)} customStyle={groupButton}>
                                {member.email}
                                <Icon type="dropdown" customStyle={chevronRightIcon} />
                            </Button>
                        ) : (
                            <Text size="small" color="foregroundsTertiary">
                                {member.email}
                            </Text>
                        )}
                    </li>
                ))}
            </ul>
        </Loadable>
    );
};
