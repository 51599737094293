import type { TRuleWithTheme } from 'styles/theme';

export const container: TRuleWithTheme = () => ({
    display: 'flex',
    flexDirection: 'column',

    height: '100%',

    paddingInline: '1rem',
    paddingBlock: '1.5rem',

    min768: {
        paddingInline: '1.5rem',
        paddingBlock: '2.65rem',
    },
});

export const logo: TRuleWithTheme = () => ({
    flexShrink: 0,

    marginBottom: '2.625rem',
});

export const wrapper: TRuleWithTheme = ({ theme }) => ({
    overflow: 'auto',

    alignSelf: 'center',

    width: '100%',

    maxWidth: '37.875rem',

    paddingInline: '1rem',
    paddingBlock: '1rem',

    backgroundColor: theme.colors.white,

    borderRadius: '1.25rem',

    min768: {
        paddingInline: '2rem',
        paddingBlock: '2rem',
    },
});
