import { Navigate, Outlet } from 'react-router-dom-v5-compat';

import config from 'config';

import { AdminFirewall, NonDemoFirewall } from 'modules/auth';

export const SettingsPage = () => (
    <NonDemoFirewall>
        <AdminFirewall firewall={<Navigate to={config.routes.home} replace />}>
            <Outlet />
        </AdminFirewall>
    </NonDemoFirewall>
);
