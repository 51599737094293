import { useFela } from 'react-fela';

import * as felaRules from './SharingIndicatorIcon.rules';

export interface SharingIndicatorIconProps {
    visible: boolean;
}

export const SharingIndicatorIcon = ({ visible }: SharingIndicatorIconProps) => {
    const { css } = useFela({ visible });

    return (
        <div className={css(felaRules.indicatorContainer)} data-testid="sharing-indicator">
            <span className={css(felaRules.indicatorDot)} />
            <span className={css(felaRules.indicatorPulse)} />
        </div>
    );
};
