import type { EntityWithPermissions } from 'types';

export interface Group extends EntityWithPermissions {
    name: string;
    email: string;
    visible?: boolean;
}

export interface GroupWithUsedCount extends Group {
    usedCount: number;
}

export type GroupById = {
    [key: Group['id']]: Group;
};

export enum GroupMemberType {
    User = 'USER',
    Group = 'GROUP',
}

export interface GroupMember {
    id: string;
    email: string;
    type: GroupMemberType;
}

export type GroupMemberById = {
    [key: GroupMember['id']]: GroupMember;
};

export enum EntityKey {
    GROUPS = 'groups',
    GROUP_MEMBERS = 'groupMembers',
}
