import { useMemo } from 'react';

import { VisualizedCharacter } from './VisualizedCharacter';

export interface PasswordVisualisationProps {
    password: string;
}

export const PasswordVisualisation = ({ password }: PasswordVisualisationProps) => {
    const charArray = useMemo(() => [...password], [password]);
    return (
        <>
            {charArray.map((char, index) => (
                // It is safe to use index as key here
                // eslint-disable-next-line react/no-array-index-key
                <VisualizedCharacter key={index} char={char} />
            ))}
        </>
    );
};
