export const optionsWrapper = ({ theme }) => ({
    marginTop: '2rem',
});

export const buttonWrapper = ({ theme }) => ({
    display: 'flex',
    flexDirection: 'row-reverse',
    alignItems: 'center',

    paddingTop: '1.5rem',
    marginTop: '1.25rem',

    borderTop: `1px solid ${theme.colors.lightGrey4}`,
});
