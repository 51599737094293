import { translateSelector } from '@ackee/jerome';
import { createSelector } from '@reduxjs/toolkit';

import differenceInYears from 'date-fns/differenceInYears';
import parseISO from 'date-fns/parseISO';

import type { SecurityAuditAgeReport } from 'modules/security-audit';

import { ListItemType, SecurityAge } from '../../constants';

import type { SecurityAuditAgeSecret, SecurityAuditAgeGroup, Secret } from '../../types';

import { updatedAtLookup } from '../../utilities';
import { getSecretsWithGroups, compareByName } from './utilities';

import { selectMappedSecrets } from './list';

export const getSecretAge = (secret: Secret) => {
    const lastModifiedAt = updatedAtLookup(secret);
    const now = new Date();

    if (lastModifiedAt) {
        const yearsDiff = Math.abs(differenceInYears(parseISO(lastModifiedAt), now));

        if (yearsDiff === 0) {
            return SecurityAge.LESS_THAN_ONE_YEAR;
        }
        if (yearsDiff === 1) {
            return SecurityAge.ONE_YEAR;
        }
    }

    return SecurityAge.MORE_THAN_TWO_YEARS;
};

const selecSecurityAuditAgeListSecrets = createSelector(selectMappedSecrets, secrets =>
    secrets.map<SecurityAuditAgeSecret>(secret => ({
        ...secret,
        securityAge: getSecretAge(secret),
        type: ListItemType.SECRET,
        key: `age-secret-${secret.id}`,
    })),
);

export const selectSecurityAuditAgeSortedListSecrets = createSelector(
    [selecSecurityAuditAgeListSecrets, translateSelector],
    (secrets, { locale }) =>
        secrets.sort(function (secretA, secretB) {
            return secretB.securityAge - secretA.securityAge || compareByName(secretA, secretB, locale);
        }),
);

const createAuditAgeGroup = (securityAge: SecurityAge): SecurityAuditAgeGroup => ({
    type: ListItemType.GROUP,
    key: `age-group-${securityAge}`,
    securityAge,
    count: 0,
});

export const getSecurityAuditSecretsWithAgeGroups = (secrets: SecurityAuditAgeSecret[]) =>
    getSecretsWithGroups(
        secrets,
        (nextSecret, currentSecret) => nextSecret.securityAge !== currentSecret.securityAge,
        secret => createAuditAgeGroup(secret.securityAge),
        group => {
            group.count += 1;
        },
    );

export const selectSecurityAuditSecretsWithAgeGroups = createSelector(
    [selectSecurityAuditAgeSortedListSecrets],
    getSecurityAuditSecretsWithAgeGroups,
);

export const selectSecurityAuditAgeReports = createSelector(selecSecurityAuditAgeListSecrets, secrets => {
    const initialAgg: Record<SecurityAge, number> = {
        [SecurityAge.LESS_THAN_ONE_YEAR]: 0,
        [SecurityAge.ONE_YEAR]: 0,
        [SecurityAge.MORE_THAN_TWO_YEARS]: 0,
    };

    const secretGroups = secrets.reduce<Record<SecurityAge, number>>((report, secret) => {
        report[secret.securityAge] += 1;

        return report;
    }, initialAgg);

    const reports: SecurityAuditAgeReport[] = [
        SecurityAge.LESS_THAN_ONE_YEAR,
        SecurityAge.ONE_YEAR,
        SecurityAge.MORE_THAN_TWO_YEARS,
    ].map(age => ({
        percentage: (secretGroups[age] / secrets.length) * 100,
        total: secretGroups[age],
        age,
    }));

    return reports;
});
