import type { TRuleWithTheme } from 'styles/theme';

export const container: TRuleWithTheme = ({ theme }) => ({
    '&.ant-tooltip .ant-tooltip-arrow::before': {
        backgroundColor: theme.colors.white,
    },

    '&.ant-tooltip .ant-tooltip-inner': {
        color: theme.colors.brownGrey,
        backgroundColor: theme.colors.white,
    },
});
