import { combineReducers } from 'redux';
import { basicApiReducer } from '@ackee/redux-utils';

import { loadSettings, loadSettingsFailure, loadSettingsSuccess } from '../actions';

const load = basicApiReducer({
    actionTypes: {
        REQUEST: loadSettings.toString(),
        SUCCESS: loadSettingsSuccess.toString(),
        FAILURE: loadSettingsFailure.toString(),
    },
});

const loadOrder = basicApiReducer({
    actionTypes: {
        REQUEST: loadSettings.toString(),
        SUCCESS: loadSettingsSuccess.toString(),
        FAILURE: loadSettingsFailure.toString(),
    },
});

export const apiReducer = combineReducers({
    load,
    loadOrder,
});
