import { useFela } from 'react-fela';
import { Tooltip as AntTooltip } from 'antd';

import type { TooltipPropsWithTitle, TooltipPropsWithOverlay } from 'antd/es/tooltip';

import { container } from './Tooltip.rules';

interface CommonProps {
    customOverlayStyle?: typeof container;
}

type GetTooltipProps<
    TooltipProps extends {
        overlayClassName?: string;
    },
> = Omit<TooltipProps, 'overlayClassName'> & CommonProps;

export type TooltipProps = GetTooltipProps<TooltipPropsWithTitle> | GetTooltipProps<TooltipPropsWithOverlay>;

export const Tooltip = ({ customOverlayStyle, ...props }: TooltipProps) => {
    const { css } = useFela();

    return <AntTooltip {...props} overlayClassName={css(container, customOverlayStyle)} />;
};
