import { combineReducers } from 'redux';
import { basicResetReducer, basicApiReducer, containerReducer } from '@ackee/redux-utils';

import { createInfiniteApiReducer } from 'services/utilities/infiniteApiReducer';

import {
    fetchGroupMembers,
    fetchGroupMembersFailure,
    fetchGroupMembersSuccess,
    fetchGroupsRequest,
    fetchGroupsFailure,
    fetchGroupsSuccess,
    changeGroupVisibilityRequest,
    changeGroupVisibilityRequestSuccess,
    changeGroupVisibilityRequestFailure,
} from '../actions';

const fetch = createInfiniteApiReducer({
    request: fetchGroupsRequest,
    success: fetchGroupsSuccess,
    failure: fetchGroupsFailure,
});

const fetchDetailActionTypes = {
    REQUEST: fetchGroupMembers.toString(),
    SUCCESS: fetchGroupMembersSuccess.toString(),
    FAILURE: fetchGroupMembersFailure.toString(),
};

const fetchMembersContainer = containerReducer({
    actionTypes: Object.values(fetchDetailActionTypes),
    childReducer: basicApiReducer({
        actionTypes: fetchDetailActionTypes,
    }),
    selectors: {
        itemId: action => action.meta.groupId,
    },
});

const fetchMembers = basicResetReducer(fetchMembersContainer, fetchGroupsSuccess.toString());

const changeVisibilityActionTypes = {
    REQUEST: changeGroupVisibilityRequest.toString(),
    SUCCESS: changeGroupVisibilityRequestSuccess.toString(),
    FAILURE: changeGroupVisibilityRequestFailure.toString(),
};

const changeVisibility = containerReducer({
    actionTypes: Object.values(changeVisibilityActionTypes),
    childReducer: basicApiReducer({
        actionTypes: changeVisibilityActionTypes,
    }),
    selectors: {
        itemId: action => action.meta.groupId,
    },
});

export const apiReducer = combineReducers({
    fetch,
    fetchMembers,
    changeVisibility,
});
