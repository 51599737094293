import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import {
    CheckCircleFilled,
    CloseCircleFilled,
    InfoCircleFilled,
    WarningFilled,
    LoadingOutlined,
} from '@ant-design/icons';
import { useFela } from 'react-fela';
import type { TransitionStatus } from 'react-transition-group';

import type { Theme } from 'styles/theme';

import { Button } from 'modules/ui';

import type { Message } from '../../types';

import { Level } from '../../constants';
import { hideMessage } from '../../services/actions';

import * as felaRules from './MessageContent.rules';

interface MessageIconProps {
    theme: Theme;
}

const IconEnum = {
    [Level.SUCCESS]: ({ theme }: MessageIconProps) => (
        <CheckCircleFilled style={{ color: theme.colors.messageIcons.success }} />
    ),
    [Level.ERROR]: ({ theme }: MessageIconProps) => (
        <CloseCircleFilled style={{ color: theme.colors.messageIcons.error }} />
    ),
    [Level.INFO]: ({ theme }: MessageIconProps) => (
        <InfoCircleFilled style={{ color: theme.colors.messageIcons.info }} />
    ),
    [Level.WARNING]: ({ theme }: MessageIconProps) => (
        <WarningFilled style={{ color: theme.colors.messageIcons.warning }} />
    ),
    [Level.LOADING]: ({ theme }: MessageIconProps) => (
        <LoadingOutlined style={{ color: theme.colors.messageIcons.loading }} />
    ),
};

export interface MessageContentProps extends Omit<Message, 'visible'> {
    state: TransitionStatus;
}

export const MessageContent = ({ level, message, state, button, id }: MessageContentProps) => {
    const { css, theme } = useFela<Theme>({ state });
    const dispatch = useDispatch();
    const Icon = IconEnum[level];

    const handleOnClick = () => {
        if (typeof button.onClick === 'function') {
            button.onClick();
        } else {
            dispatch(button.onClick);
        }
        dispatch(hideMessage(id));
    };

    return (
        <div className={css(felaRules.content)}>
            <div className={css(felaRules.inner)}>
                <Icon theme={theme} />
                <div className={css(felaRules.text)}>
                    {typeof message === 'object' ? <FormattedMessage {...message} /> : message}
                </div>
                {button ? (
                    <Button customStyle={felaRules.button} type="primary" onClick={handleOnClick}>
                        {typeof button.message === 'object' ? <FormattedMessage {...button.message} /> : button.message}
                    </Button>
                ) : null}
            </div>
        </div>
    );
};
