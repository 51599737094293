import { createAction } from '@reduxjs/toolkit';

import type { SubmitActions } from 'modules/forms';

import type { ImportFormValues } from '../../types';

import types from '../actionTypes';

export const startImport = createAction(
    types.START_IMPORT,
    (values: ImportFormValues, meta: SubmitActions<ImportFormValues>) => ({
        payload: values,
        meta,
    }),
);

export const importRequest = createAction(types.IMPORT_REQUEST);
export const importRequestSuccess = createAction(types.IMPORT_REQUEST_SUCCESS);
export const importRequestFailure = createAction(types.IMPORT_REQUEST_FAILURE);
