import { useFela } from 'react-fela';
import { FormattedMessage } from 'react-intl';

import { Modal } from 'modules/modals';
import { Heading } from 'modules/ui';

import { useHasGlobalSharingPermissions } from 'modules/settings';
import { hasReadPermission } from 'utilities/permission';

import { ShareSecretForm } from './ShareSecretForm';
import { ShareSecretInfo } from './ShareSecretInfo';

import * as felaRules from './ShareSecretModal.rules';
import { SharePrivateLink } from './SharePrivateLink';
import { useSecretDetailContext } from '../SecretDetailPage';
import { isPublicSharingActive } from '../../utilities';

export interface ShareSecretButtonProps {
    open: boolean;
    onClose: () => void;
}

export const ShareSecretModal = ({ open, onClose }: ShareSecretButtonProps) => {
    const { css } = useFela();

    const { id, userPermissions, share } = useSecretDetailContext();

    const hasUserGlobalSharingPermissions = useHasGlobalSharingPermissions();
    const canUserRead = hasReadPermission(userPermissions);

    const canUserShare = canUserRead && hasUserGlobalSharingPermissions;

    return (
        <Modal
            open={open}
            onClose={onClose}
            closable
            width={520}
            extend={{
                buttons: felaRules.buttons,
            }}
        >
            <Heading elementType="h2" color="black">
                <FormattedMessage id="secret.modal.share.title" />
            </Heading>
            <div className={css(felaRules.sharingContainer)}>
                <ShareSecretForm id={id} share={share} canUserShare={canUserShare} />
                {canUserShare && isPublicSharingActive(share) && <ShareSecretInfo id={id} share={share} />}
            </div>
            <div className={css(felaRules.privateLinkContainer)}>
                <SharePrivateLink />
            </div>
        </Modal>
    );
};
