import { translateSelector } from '@ackee/jerome';
import { createSelector } from '@reduxjs/toolkit';

import { compareByName } from 'modules/secrets/services/selectors/utilities';

import { selectViewFilteredSecrets } from '../../../../services/selectors';

export const selectTagsList = createSelector([selectViewFilteredSecrets, translateSelector], (secrets, { locale }) => {
    const tagsCounts = secrets.reduce<Record<string, number>>((acc, secret) => {
        secret.tags?.forEach(tag => {
            acc[tag] = (acc[tag] ?? 0) + 1;
        });
        return acc;
    }, {});

    const tags = Object.entries(tagsCounts)
        .map(([name, count]) => ({ name, count }))
        .sort((tagA, tagB) => compareByName(tagA, tagB, locale))
        .sort((tagA, tagB) => tagB.count - tagA.count);

    return tags;
});

export const selectTagsOptions = createSelector([selectTagsList], tags =>
    tags.map(({ name }) => ({
        value: name,
        label: name,
    })),
);
