import { useAppSelector } from 'hooks/useAppSelector';

import { selectSettingsApi } from 'modules/settings';

import { selectLoadSecretsApi } from '../services/selectors';

export const useNewSecretPage = () => {
    const secretsApi = useAppSelector(selectLoadSecretsApi);
    const settingsApi = useAppSelector(selectSettingsApi);

    return {
        showLoader: settingsApi.inProgress || secretsApi.inProgress,
    };
};
