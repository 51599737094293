import { createSelector } from '@reduxjs/toolkit';
import { pick } from 'lodash';

import { hasWritePermission } from 'utilities/permission';

import { selectIdProp, authUser } from 'services/selectors';

import { selectSecretsEntities } from './entities';

import type { SecretFormValues, Secret } from '../../types';
import { TOTP_VALUE_PLACEHOLDER } from '../../constants';

const selectSecretsDetail = createSelector([selectSecretsEntities], ({ detail }) => detail);

export const selectSecret = createSelector([selectSecretsDetail, selectIdProp], (secrets, id): Secret => {
    const secret = secrets[id];

    return secret
        ? {
              ...secret,
              // Keep this from version where creator was added to whitelistUsers
              whitelistUsers: secret.whitelistUsers.filter(user => user.id !== secret.createdBy?.id),
          }
        : undefined;
});

export const selectHasSecret = createSelector([selectSecret], secret => Boolean(secret));
export const selectHasSecretPermissions = createSelector([selectSecret], secret =>
    hasWritePermission(secret?.userPermissions),
);

export const selectUpdateSecretFormInitialValues = createSelector([selectSecret], secret => {
    const values = pick(secret, [
        'name',
        'username',
        'password',
        'web',
        'note',
        'groups',
        'whitelistUsers',
        'tags',
        'file',
        'hasTOTP',
    ]);

    const initialValues: SecretFormValues = {
        ...values,
        TOTP: values.hasTOTP ? TOTP_VALUE_PLACEHOLDER : null,
    };

    return initialValues;
});

export const selectCreatedBy = createSelector([selectSecret, authUser], (secret, user) => {
    if (secret) {
        return secret.createdBy?.id;
    }

    return user.id;
});

export const secretNameSelector = createSelector([selectSecret], secret => secret?.name ?? '');
