import { FormattedMessage } from 'react-intl';

import config from 'config';
import { InfoLabel } from 'modules/forms';
import { Link } from 'modules/ui';
import { useIsAdmin } from 'modules/auth';

export const GroupsAndPermissionsInfoLabel = () => {
    const isAdmin = useIsAdmin();

    return (
        <InfoLabel>
            <FormattedMessage
                id={isAdmin ? 'secret.permissions.info.admin' : 'secret.permissions.info.user'}
                values={{
                    a1: chunks => (
                        <Link type="native" href={config.links.workspace} inheritColor>
                            {chunks}
                        </Link>
                    ),
                    a2: chunks => (
                        <Link type="native" href={config.links.roles} inheritColor>
                            {chunks}
                        </Link>
                    ),
                }}
            />
        </InfoLabel>
    );
};
