import { generatePath, useLocation, useMatch, useNavigate } from 'react-router-dom-v5-compat';

import config from 'config';

export const useHandleBackButtonClick = () => {
    const navigate = useNavigate();
    const { state } = useLocation();

    const updateSecretPageMatch = useMatch(config.routes.updateSecret);
    const isUpdateSecretPage = Boolean(updateSecretPageMatch);

    const handleBackButtonClick = () => {
        if (state?.from) {
            navigate(state.from);
            return;
        }

        if (isUpdateSecretPage) {
            navigate(generatePath(config.routes.secret, { id: updateSecretPageMatch.params.id }));
            return;
        }

        navigate(config.routes.secrets);
    };

    return handleBackButtonClick;
};
