import { combineRules } from 'fela';

import type { TRuleWithTheme } from 'styles/theme';

const INDICATOR_BASE_SIZE = 6;

export const indicatorContainer: TRuleWithTheme<{
    visible: boolean;
}> = ({ visible }) => ({
    display: visible ? 'grid' : 'none',
    alignItems: 'center',
    justifyItems: 'center',
});

export const indicatorDot: TRuleWithTheme = ({ theme }) => ({
    gridRow: 1,
    gridColumn: 1,

    width: INDICATOR_BASE_SIZE,
    height: INDICATOR_BASE_SIZE,

    borderRadius: '50%',

    backgroundColor: theme.colors.green,
});

export const indicatorPulse: TRuleWithTheme = combineRules(indicatorDot, ({ theme }) => ({
    backgroundColor: 'transparent',

    borderWidth: 0.5,
    borderColor: theme.colors.green,
    borderStyle: 'solid',

    animationName: {
        from: {
            transform: 'scale(1)',
            opacity: 1,
        },
        to: {
            transform: 'scale(3)',
            opacity: 0,
        },
    },

    animationDuration: '1250ms',
    animationIterationCount: 'infinite',
    animationTimingFunction: 'ease-in',
}));
