import type { TRuleWithTheme } from 'styles/theme';

export const style: TRuleWithTheme = ({ theme }) => ({
    '&.ant-checkbox-wrapper': {
        alignItems: 'center',

        color: theme.colors.black,
    },
    '&.ant-checkbox-wrapper .ant-checkbox .ant-checkbox-inner': {
        position: 'relative',
        top: 0,
        left: 0,
        display: 'block',
        width: 20,
        height: 20,
        backgroundColor: 'transparent',
        color: theme.colors.black,
        borderCollapse: 'separate',
        transition: 'all 0.3s',
        border: 'none',
    },
    '&.ant-checkbox-wrapper .ant-checkbox .ant-checkbox-inner::after': {
        left: 6,
    },
    '&.ant-checkbox-wrapper .ant-checkbox': {
        top: 0,

        transition: 'all 0.3s',

        borderRadius: '7px',
        border: '1px solid',
        borderColor: theme.colors.black,
        backgroundColor: 'transparent',

        transform: 'translate(0, 1px)',
    },
    '&.ant-checkbox-wrapper .ant-checkbox.ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after': {
        borderColor: theme.colors.white,
    },
    '& span.ant-checkbox-disabled': {
        border: '1px solid',
        borderColor: theme.colors.lightGrey4,
    },
    '& span.ant-checkbox::after': {
        border: 'none',
    },

    '&.ant-checkbox-wrapper .ant-checkbox.ant-checkbox-checked:not(.ant-checkbox-disabled)': {
        backgroundColor: theme.colors.brightMagenta,
        borderColor: theme.colors.brightMagenta,
    },

    '&.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-checked:not(.ant-checkbox-disabled) .ant-checkbox-inner':
        {
            backgroundColor: theme.colors.brightMagenta,
            borderColor: theme.colors.brightMagenta,
        },
});
