import { useIntl } from 'react-intl';

import { GroupsVisibilityList } from 'modules/groups';

import { SettingsDetailLayout } from '../SettingsDetailLayout';

interface GroupsVisibilitySettingsProps {}
export const GroupsVisibilitySettings = (props: GroupsVisibilitySettingsProps) => {
    const { formatMessage } = useIntl();

    return (
        <SettingsDetailLayout
            title={formatMessage({ id: 'settings.groupsVisibility.title' })}
            description={formatMessage({ id: 'settings.groupsVisibility.description' })}
        >
            <GroupsVisibilityList />
        </SettingsDetailLayout>
    );
};
