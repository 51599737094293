import { useEffect, useRef } from 'react';
import { VariableSizeList } from 'react-window';

import { useBreakpoint } from 'hooks/useBreakpoint';
import type { Secret } from 'modules/secrets';

import { SecretListId } from '../../constants';
import { useSharingIndicatorVisibility, useSecretsListOffset } from '../../hooks';

import { useSecretsList } from '../../hooks/useSecretsList';

import { ItemRenderer } from './ItemRenderer';

export const SECRET_LIST_ITEM_SIZES = {
    base: 94,
    propertyRowSize: 44,
    sharingIndicator: 38.875,
    tags: 36,
};

const DISPLAYED_PROPERTIES = ['username', 'hasPassword', 'hasTOTP'] satisfies ReadonlyArray<keyof Secret>;

export interface SecretsListProps {
    height: number;
    width: number;
}

export const SecretsList = ({ height, width }: SecretsListProps) => {
    const { data } = useSecretsList();

    const isMin768 = useBreakpoint('sm');
    const listRef = useRef<VariableSizeList<typeof data>>(null);

    const handleOffset = useSecretsListOffset(SecretListId.MAIN_LIST, listRef);

    const { getSharingIndicatorVisibility } = useSharingIndicatorVisibility();

    useEffect(() => {
        listRef.current.resetAfterIndex(0);
    }, [isMin768, data]);

    return (
        <VariableSizeList<typeof data>
            ref={listRef}
            height={height}
            width={width}
            itemData={data}
            itemCount={data.length}
            estimatedItemSize={SECRET_LIST_ITEM_SIZES.base}
            itemSize={index => {
                const displayedProperties = Object.entries(data[index])
                    // @ts-expect-error
                    .filter(([key]) => DISPLAYED_PROPERTIES.includes(key))
                    .filter(([_, value]) => Boolean(value)).length;

                const isSharingIndicatorVisible = getSharingIndicatorVisibility({
                    share: data[index].share,
                    userPermissions: data[index].userPermissions,
                });

                const hasTags = data[index].tags.length > 0;

                let itemSize = SECRET_LIST_ITEM_SIZES.base;
                if (isMin768) return itemSize + SECRET_LIST_ITEM_SIZES.propertyRowSize;

                itemSize += displayedProperties * SECRET_LIST_ITEM_SIZES.propertyRowSize;
                if (isSharingIndicatorVisible) itemSize += SECRET_LIST_ITEM_SIZES.sharingIndicator;
                if (hasTags) itemSize += SECRET_LIST_ITEM_SIZES.tags;

                return itemSize;
            }}
            itemKey={index => {
                const item = data[index];
                return item.key;
            }}
            onScroll={({ scrollOffset }) => handleOffset(scrollOffset)}
        >
            {ItemRenderer}
        </VariableSizeList>
    );
};
