import { useFela } from 'react-fela';
import { FormattedMessage } from 'react-intl';

import { Button } from 'modules/ui';

import { ShareSecretModal } from '../ShareSecretModal';
import { useSharingModal } from '../SecretDetailPage';
import { useIsPublicSharingActiveWithPermissions } from '../SecretDetail/hooks';

import * as felaRules from './ShareSecretButton.rules';
import { SharingIndicatorIcon } from '../SharingIndicatorIcon';

export const ShareSecretButton = () => {
    const { open, toggle } = useSharingModal();

    const showIndicator = useIsPublicSharingActiveWithPermissions();

    const { css } = useFela();

    return (
        <>
            <div className={css(felaRules.container)}>
                <div className={css(felaRules.indicatorContainer)}>
                    <SharingIndicatorIcon visible={showIndicator} />
                </div>
                <Button type="text" size="large" onClick={toggle}>
                    <FormattedMessage id="secret.share.button" />
                </Button>
            </div>
            <ShareSecretModal open={open} onClose={toggle} />
        </>
    );
};
