import { select, take } from 'redux-saga/effects';
import type { AnyAction } from 'redux';

import { Status } from '../../../constants';
import { isOnlineSelector } from '../../selectors';
import { updateNetworkStatus } from '../../actions';

export function* waitForOnline() {
    const isOnline = yield select(isOnlineSelector);

    if (!isOnline) {
        yield take((action: AnyAction) =>
            updateNetworkStatus.match(action) ? action.payload === Status.ONLINE : false,
        );
    }
}
