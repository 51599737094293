import { useFela } from 'react-fela';
import { FormattedMessage } from 'react-intl';

import { Text } from 'modules/ui';

import { SharingIndicatorIcon } from '../../../SharingIndicatorIcon';

import * as felaRules from './SharingIndicator.rules';

export const SharingIndicator = () => {
    const { css } = useFela(felaRules);

    return (
        <div className={css(felaRules.container)}>
            <SharingIndicatorIcon visible />
            <Text size="small" color="alertsSuccessText">
                <FormattedMessage id="secret.share.indicator.text" />
            </Text>
        </div>
    );
};
