import { useFela } from 'react-fela';

import { useIsAdmin } from 'modules/auth';

import { RemoveSecretModal } from '../../RemoveSecretModal';
import { SecretHeader } from '../../SecretHeader';
import { UpdateSecretForm } from '../../UpdateSecretForm';

import * as felaRules from './UpdateSecret.rules';
import { HeaderButtons } from './HeaderButtons';

export const UpdateSecret = () => {
    const isAdmin = useIsAdmin();

    const { css } = useFela({ isAdmin });

    return (
        <div className={css(felaRules.pageContainer)}>
            <SecretHeader showBackButton={false}>
                <HeaderButtons />
            </SecretHeader>
            <main className={css(felaRules.contentContainer)}>
                <UpdateSecretForm />
                <RemoveSecretModal />
            </main>
        </div>
    );
};
