import { Form } from 'react-final-form';
import type { FormProps } from 'react-final-form';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { handleFormSubmit } from 'modules/forms';

import { validateSecretFormFactory } from '../../utilities';

import type { SecretFormValues } from '../../types';

import { createSecret } from '../../services/actions';

export interface NewSecretFormProviderProps {
    children: FormProps<SecretFormValues>['children'];
}

const initialValues: SecretFormValues = {
    name: '',
    username: '',
    web: '',
    password: '',
    note: '',
    tags: [],
    groups: [],
    whitelistUsers: [],
    file: null,
    TOTP: null,
    hasTOTP: false,
};

export const NewSecretFormProvider = ({ children }: NewSecretFormProviderProps) => {
    const intl = useIntl();

    const dispatch = useDispatch();

    return (
        <Form<SecretFormValues>
            initialValue={initialValues}
            onSubmit={handleFormSubmit((values, actions) => {
                dispatch(createSecret(values, actions));
            })}
            validate={validateSecretFormFactory(intl)}
        >
            {children}
        </Form>
    );
};
