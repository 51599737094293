import { createAction } from '@reduxjs/toolkit';

import type { Secret } from '../../types';
import types from '../actionTypes';

export const readShareSecret = createAction(types.READ_SHARE_SECRET, (id: Secret['share']['id']) => ({
    meta: { id },
    payload: undefined,
}));

export const readShareSecretSuccess = createAction(
    types.READ_SHARE_SECRET_SUCCESS,
    (id: Secret['share']['id'], payload: Secret) => ({
        meta: { id, lastSuccessAt: new Date().toISOString() },
        payload,
    }),
);

export const readShareSecretFailure = createAction(
    types.READ_SHARE_SECRET_FAILURE,
    (id: Secret['share']['id'], error: any) => ({
        meta: { id },
        payload: undefined,
        error,
    }),
);

export const readShareSecretReset = createAction(types.READ_SHARE_SECRET_RESET, (id: Secret['share']['id']) => ({
    meta: { id },
    payload: undefined,
}));
