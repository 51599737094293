import type { TRuleWithTheme } from 'styles/theme';

export const autocomplete: TRuleWithTheme = ({ theme }) => ({
    '& .ant-select-item': {
        minHeight: 0,

        paddingTop: 0,
        paddingRight: 0,
        paddingBottom: '0.625rem',
        paddingLeft: 0,
    },
    '& .ant-select-item:first-child': {
        paddingTop: '1rem',
    },
    '& .ant-select-item:last-child': {
        paddingBottom: '1rem',
    },
    '& .ant-select-item-group': {
        paddingTop: '0.625rem',

        fontSize: '0.75rem',
        fontWeight: 500,
        lineHeight: 1.5,

        color: theme.colors.foregroundsTertiary,

        borderTopWidth: 1,
        borderTopStyle: 'solid',
        borderTopColor: theme.colors.foregroundsSeparator,
        borderRadius: 0,
    },
    '& .ant-select-item-group:first-child': {
        paddingTop: '1rem',
        borderTop: 'none',
    },
    '& .ant-select-item-option': {
        fontSize: '0.875rem',
        fontWeight: 500,
        lineHeight: 1.43,
        letterSpacing: '0.0143em',
    },
    '& .ant-select-item-option.ant-select-item-option-active': {
        background: 'transparent',
        color: theme.colors.blue,
    },

    '& .ant-select-dropdown': {
        paddingTop: 0,
        paddingBottom: 0,

        marginTop: '0.375rem',

        boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.16)',
    },

    '& .ant-select-dropdown .rc-virtual-list': {
        paddingLeft: '1rem',
        paddingRight: '1rem',
    },
});
