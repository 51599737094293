import { FormattedMessage } from 'react-intl';
import { Field } from 'react-final-form';
import { identity } from 'lodash';

import { Label, FormItem, TagsInput } from 'modules/forms';

import { Fields } from '../../../constants';

import { useTagsOptions } from '../../../modules/tags';

export interface TagsFieldProps {
    onChange?: () => void;
}

export const TagsField = ({ onChange }: TagsFieldProps) => {
    const name = Fields.TAGS;

    const label = (
        <Label>
            <FormattedMessage id={`secret.${name}.label`} />
        </Label>
    );

    const options = useTagsOptions();

    return (
        <Field<string[]> name={name} format={identity} parse={identity}>
            {({ input, meta }) => {
                const { value, onFocus, onBlur } = input;

                const handleChange = (value: string[]) => {
                    input.onChange(value);
                    onChange?.();
                };

                return (
                    <FormItem<string[]> label={label} meta={meta}>
                        <TagsInput
                            value={value}
                            onBlur={onBlur}
                            onChange={handleChange}
                            onFocus={onFocus}
                            onRemove={val => {
                                const newValue = value.filter(v => v !== val);

                                handleChange(newValue);
                            }}
                            options={options}
                        />
                    </FormItem>
                );
            }}
        </Field>
    );
};
