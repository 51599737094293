import type { SVGProps } from 'react';

export const Admin = (props: SVGProps<SVGSVGElement>) => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            fill="currentColor"
            d="M9.39131 3.13044C11.1172 3.13044 12.5217 4.53497 12.5217 6.26088C12.5217 7.98679 11.1172 9.39131 9.39131 9.39131C7.6654 9.39131 6.26088 7.98679 6.26088 6.26088C6.26088 4.53497 7.6654 3.13044 9.39131 3.13044ZM9.39131 11.4783C12.2734 11.4783 14.6087 9.14192 14.6087 6.26088C14.6087 3.37984 12.2734 1.04349 9.39131 1.04349C6.50922 1.04349 4.17392 3.37984 4.17392 6.26088C4.17392 9.14192 6.50922 11.4783 9.39131 11.4783Z"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            fill="currentColor"
            d="M2.6474 17.7391C3.36948 16.4922 4.7187 15.6522 6.26096 15.6522H12.5218C14.063 15.6522 15.4123 16.4922 16.1354 17.7391H2.6474ZM12.5218 13.5652H6.26096C2.80287 13.5652 9.15527e-05 16.369 9.15527e-05 19.8261H18.7827C18.7827 16.369 15.9789 13.5652 12.5218 13.5652Z"
        />
        <rect
            x="11.4783"
            y="22.9565"
            width="12.5217"
            height="12.5217"
            rx="6.26087"
            transform="rotate(-90 11.4783 22.9565)"
            fill="white"
        />
        <path
            d="M19.0408 16.7022C19.0408 16.3564 18.9034 16.0248 18.6589 15.7802C18.4144 15.5357 18.0828 15.3984 17.737 15.3984C17.3912 15.3984 17.0596 15.5357 16.815 15.7802C16.5705 16.0248 16.4332 16.3564 16.4332 16.7022C16.4332 17.048 16.5705 17.3796 16.815 17.6241C17.0596 17.8686 17.3912 18.006 17.737 18.006C18.0828 18.006 18.4144 17.8686 18.6589 17.6241C18.9034 17.3796 19.0408 17.048 19.0408 16.7022Z"
            strokeWidth="0.8"
            strokeLinecap="round"
            strokeLinejoin="round"
            stroke="currentColor"
        />
        <path
            d="M14.5557 18.006C14.4986 17.8787 14.4067 17.7703 14.2906 17.6931C14.1745 17.616 14.0388 17.5738 13.8994 17.5714H13.8256"
            strokeWidth="0.8"
            strokeLinecap="round"
            strokeLinejoin="round"
            stroke="currentColor"
        />
        <path
            d="M16.9025 20.6136V20.5745C16.8998 20.4305 16.8528 20.2909 16.7678 20.1746C16.6845 20.0503 16.5688 19.9511 16.4332 19.8878"
            strokeWidth="0.8"
            strokeLinecap="round"
            strokeLinejoin="round"
            stroke="currentColor"
        />
        <path
            d="M19.0755 19.8835C18.9463 19.9392 18.8361 20.0313 18.7583 20.1486C18.683 20.2652 18.6423 20.4009 18.6409 20.5397V20.6136"
            strokeWidth="0.8"
            strokeLinecap="round"
            strokeLinejoin="round"
            stroke="currentColor"
        />
        <path
            d="M21.6484 17.5366H21.6093C21.4657 17.5414 21.3267 17.5883 21.2094 17.6713C21.086 17.7589 20.9896 17.8794 20.9313 18.019"
            strokeWidth="0.8"
            strokeLinecap="round"
            strokeLinejoin="round"
            stroke="currentColor"
        />
        <path
            d="M20.9182 15.3636C20.974 15.4928 21.0661 15.603 21.1834 15.6809C21.2989 15.7585 21.4353 15.7994 21.5745 15.7982H21.6484"
            strokeWidth="0.8"
            strokeLinecap="round"
            strokeLinejoin="round"
            stroke="currentColor"
        />
        <path
            d="M18.6062 12.7907V12.8299C18.6069 12.9702 18.6476 13.1073 18.7235 13.2253C18.8 13.3512 18.9098 13.4535 19.0408 13.5209"
            strokeWidth="0.8"
            strokeLinecap="round"
            strokeLinejoin="round"
            stroke="currentColor"
        />
        <path
            d="M16.4332 13.5209C16.5629 13.4681 16.6735 13.3772 16.7504 13.2601C16.8263 13.1421 16.867 13.0049 16.8678 12.8646V12.7907"
            strokeWidth="0.8"
            strokeLinecap="round"
            strokeLinejoin="round"
            stroke="currentColor"
        />
        <path
            d="M13.8256 15.833H13.8647C14.0039 15.8341 14.1402 15.7932 14.2558 15.7156C14.3854 15.6358 14.4918 15.5234 14.5644 15.3897"
            strokeWidth="0.8"
            strokeLinecap="round"
            strokeLinejoin="round"
            stroke="currentColor"
        />
        <path
            d="M13.8256 17.5714H12.9564V15.8329H13.8256"
            strokeWidth="0.8"
            strokeLinecap="round"
            strokeLinejoin="round"
            stroke="currentColor"
        />
        <path
            d="M21.6484 17.5366H22.5219V15.7982H21.6484"
            strokeWidth="0.8"
            strokeLinecap="round"
            strokeLinejoin="round"
            stroke="currentColor"
        />
        <path
            d="M16.8678 12.7907V11.9259H18.6062V12.7907"
            strokeWidth="0.8"
            strokeLinecap="round"
            strokeLinejoin="round"
            stroke="currentColor"
        />
        <path
            d="M16.9025 20.6136V21.4784H18.6409V20.6136"
            strokeWidth="0.8"
            strokeLinecap="round"
            strokeLinejoin="round"
            stroke="currentColor"
        />
        <path
            d="M16.4332 19.8878C16.2956 19.8305 16.1435 19.8183 15.9986 19.853C15.8619 19.8792 15.7362 19.9458 15.6378 20.0442L15.5857 20.0877"
            strokeWidth="0.8"
            strokeLinecap="round"
            strokeLinejoin="round"
            stroke="currentColor"
        />
        <path
            d="M19.91 20.0877L19.8795 20.0616C19.7759 19.9617 19.6438 19.8964 19.5014 19.8748C19.3573 19.8388 19.2056 19.8479 19.0668 19.9008"
            strokeWidth="0.8"
            strokeLinecap="round"
            strokeLinejoin="round"
            stroke="currentColor"
        />
        <path
            d="M20.9313 18.019C20.8809 18.1491 20.8689 18.2908 20.8965 18.4275C20.9234 18.5662 20.9916 18.6934 21.0921 18.7926L21.1443 18.8448"
            strokeWidth="0.8"
            strokeLinecap="round"
            strokeLinejoin="round"
            stroke="currentColor"
        />
        <path
            d="M21.0921 14.5422L21.066 14.5683C20.9663 14.6726 20.8998 14.8042 20.8748 14.9464C20.8437 15.0922 20.8589 15.2442 20.9183 15.381"
            strokeWidth="0.8"
            strokeLinecap="round"
            strokeLinejoin="round"
            stroke="currentColor"
        />
        <path
            d="M19.0408 13.5209C19.1703 13.5735 19.3127 13.5857 19.4493 13.5556C19.586 13.5294 19.7116 13.4628 19.81 13.3644L19.8622 13.3123"
            strokeWidth="0.8"
            strokeLinecap="round"
            strokeLinejoin="round"
            stroke="currentColor"
        />
        <path
            d="M15.5857 13.334L15.6118 13.3644C15.7166 13.4707 15.8517 13.542 15.9986 13.5687C16.144 13.5942 16.2936 13.5822 16.4332 13.5339"
            strokeWidth="0.8"
            strokeLinecap="round"
            strokeLinejoin="round"
            stroke="currentColor"
        />
        <path
            d="M14.5644 15.3897C14.6162 15.26 14.6298 15.1183 14.6035 14.9812C14.5749 14.8431 14.507 14.7163 14.4079 14.6161L14.3558 14.5639"
            strokeWidth="0.8"
            strokeLinecap="round"
            strokeLinejoin="round"
            stroke="currentColor"
        />
        <path
            d="M14.3558 18.8665L14.3862 18.8404C14.4829 18.7408 14.5493 18.6157 14.5774 18.4797C14.6116 18.3226 14.6041 18.1593 14.5557 18.006"
            strokeWidth="0.8"
            strokeLinecap="round"
            strokeLinejoin="round"
            stroke="currentColor"
        />
        <path
            d="M14.3558 18.8665L13.743 19.4836"
            strokeWidth="0.8"
            strokeLinecap="round"
            strokeLinejoin="round"
            stroke="currentColor"
        />
        <path
            d="M15.5857 20.0964L14.9729 20.7135"
            strokeWidth="0.8"
            strokeLinecap="round"
            strokeLinejoin="round"
            stroke="currentColor"
        />
        <path
            d="M14.9729 20.7135L13.743 19.4836"
            strokeWidth="0.8"
            strokeLinecap="round"
            strokeLinejoin="round"
            stroke="currentColor"
        />
        <path
            d="M19.8665 13.3123L20.4793 12.6951"
            strokeWidth="0.8"
            strokeLinecap="round"
            strokeLinejoin="round"
            stroke="currentColor"
        />
        <path
            d="M21.0921 14.5378L21.7092 13.9251"
            strokeWidth="0.8"
            strokeLinecap="round"
            strokeLinejoin="round"
            stroke="currentColor"
        />
        <path
            d="M21.7092 13.925L20.4793 12.6951"
            strokeWidth="0.8"
            strokeLinecap="round"
            strokeLinejoin="round"
            stroke="currentColor"
        />
        <path
            d="M14.3558 14.5639L13.7473 13.9511"
            strokeWidth="0.8"
            strokeLinecap="round"
            strokeLinejoin="round"
            stroke="currentColor"
        />
        <path
            d="M15.5857 13.334L14.9772 12.7256"
            strokeWidth="0.8"
            strokeLinecap="round"
            strokeLinejoin="round"
            stroke="currentColor"
        />
        <path
            d="M13.7473 13.9511L14.9772 12.7256"
            strokeWidth="0.8"
            strokeLinecap="round"
            strokeLinejoin="round"
            stroke="currentColor"
        />
        <path
            d="M19.9143 20.0703L20.5228 20.6831"
            strokeWidth="0.8"
            strokeLinecap="round"
            strokeLinejoin="round"
            stroke="currentColor"
        />
        <path
            d="M21.1443 18.8404L21.7527 19.4532"
            strokeWidth="0.8"
            strokeLinecap="round"
            strokeLinejoin="round"
            stroke="currentColor"
        />
        <path
            d="M20.5228 20.6831L21.7527 19.4532"
            strokeWidth="0.8"
            strokeLinecap="round"
            strokeLinejoin="round"
            stroke="currentColor"
        />
    </svg>
);
