import { Text } from '../Text/Text';

export interface TextTagProps {
    children: string;
    elementType?: keyof JSX.IntrinsicElements;
}

export const TextTag = ({ children, elementType = 'span' }: TextTagProps) => (
    <Text size="small" elementType={elementType} color="foregroundsTertiary">
        {`#${children}`}
    </Text>
);
