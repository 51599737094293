import { useFela } from 'react-fela';
import { FormattedMessage } from 'react-intl';

import { GoogleOutlined } from '@ant-design/icons';

import { Button } from 'modules/ui';

import { useLogin } from '../../hooks/useLogin';

import { container } from './LoginButton.rules';

export const LoginButton = () => {
    const { css } = useFela();

    const { handleLogin } = useLogin();

    return (
        <div className={css(container)}>
            <Button type="primary" icon={<GoogleOutlined />} onClick={handleLogin}>
                <FormattedMessage id="auth.login.button.google" />
            </Button>
        </div>
    );
};
