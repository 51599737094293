import { createAction } from '@reduxjs/toolkit';

import types from './actionTypes';

export const setModalVisibility = createAction(
    types.SET_MODAL_VISIBILITY,
    (modal: string, visibility: boolean, data?: {}) => ({
        meta: {
            modal,
        },
        payload: {
            visibility,
            data,
        },
    }),
);
