import { Field } from 'react-final-form';
import { FormattedMessage } from 'react-intl';
import { identity } from 'lodash';

import { RadioButtonGroup, SettingsFormItem } from 'modules/forms';

import { Fields, SharingPermission } from '../../constants';
import type { SettingFormValues } from '../../types';

export interface SharingPermissionFieldProps {
    onChange: () => void;
}

export const SharingPermissionField = ({ onChange }: SharingPermissionFieldProps) => (
    <Field name={Fields.SHARING_PERMISSION} format={identity} parse={identity}>
        {({ input, meta }) => (
            <SettingsFormItem<SettingFormValues['sharingPermission']>
                meta={meta}
                label={<FormattedMessage id="settings.form.sharingPermission.label" />}
            >
                <RadioButtonGroup
                    name={input.name}
                    value={input.value}
                    onChange={e => {
                        input.onChange(e.target.value);
                        onChange();
                    }}
                    onBlur={input.onBlur}
                    onFocus={input.onFocus}
                    optionType="button"
                    options={[
                        SharingPermission.EVERYONE,
                        SharingPermission.ADMIN_ONLY,
                        SharingPermission.DISALLOWED,
                    ].map(value => ({
                        label: <FormattedMessage id={`settings.form.sharingPermission.${value}`} />,
                        value,
                    }))}
                />
            </SettingsFormItem>
        )}
    </Field>
);
