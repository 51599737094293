import { FormattedMessage } from 'react-intl';
import { Field } from 'react-final-form';
import { identity } from 'lodash';

import { Label, FormItem, TextInput } from 'modules/forms';
import type { FormItemProps, TextInputProps } from 'modules/forms';

import type { Fields } from '../../../constants';

export interface TextFieldProps extends Pick<FormItemProps<string>, 'help' | 'fullWidth' | 'required'> {
    name: Exclude<Fields, Fields.HAS_TOTP>;
    onChange?: () => void;
    extend?: {
        formItem?: FormItemProps<string>['customStyle'];
        input?: TextInputProps['customStyle'];
    };
    hideLabel?: boolean;
}

export const TextField = ({ name, help = null, onChange, extend, hideLabel, fullWidth, required }: TextFieldProps) => {
    const label = (
        <>
            <Label>
                <FormattedMessage id={`secret.${name}.label`} />
            </Label>
            {help}
        </>
    );

    return (
        <Field<string> name={name} format={identity} parse={identity}>
            {({ input, meta }) => {
                const { value, onFocus, onBlur } = input;

                return (
                    <FormItem<string>
                        htmlFor={name}
                        label={hideLabel ? null : label}
                        meta={meta}
                        fullWidth={fullWidth}
                        customStyle={extend?.formItem}
                        required={required}
                    >
                        <TextInput
                            id={name}
                            value={value}
                            onBlur={onBlur}
                            onFocus={onFocus}
                            onChange={e => {
                                input.onChange(e);
                                onChange?.();
                            }}
                            customStyle={extend?.input}
                        />
                    </FormItem>
                );
            }}
        </Field>
    );
};
