import type { SVGProps } from 'react';

export const Burger = (props: SVGProps<SVGSVGElement>) => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
        <g transform="translate(3 4)" fillRule="evenodd">
            <rect width="18" height="2" rx="1" />
            <rect y="7" width="18" height="2" rx="1" />
            <rect y="14" width="12" height="2" rx="1" />
        </g>
    </svg>
);
