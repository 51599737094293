import type { SearchParams } from '../../types';

export function parseRedirectUriParams(searchOrHash: string): SearchParams {
    if (searchOrHash[0] === '#') {
        searchOrHash = searchOrHash.slice(1);
    }

    const searchParams = new URLSearchParams(searchOrHash);

    return Object.fromEntries(searchParams.entries()) as unknown as SearchParams;
}
