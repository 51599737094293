import { combineReducers } from 'redux';
import { basicApiReducer, containerReducer } from '@ackee/redux-utils';

import {
    createSecret,
    createSecretFailure,
    createSecretSuccess,
    deleteSecrets,
    deleteSecretsFailure,
    deleteSecretsSuccess,
    getSecretTOTP,
    getSecretTOTPFailure,
    getSecretTOTPReset,
    getSecretTOTPSuccess,
    ignoreReportSecret,
    ignoreReportSecretFailure,
    ignoreReportSecretReset,
    ignoreReportSecretSuccess,
    loadSecrets,
    loadSecretsFailure,
    loadSecretsSuccess,
    readSecret,
    readSecretFailure,
    readSecretSuccess,
    readShareSecret,
    readShareSecretFailure,
    readShareSecretReset,
    readShareSecretSuccess,
    removeSecret,
    removeSecretFailure,
    removeSecretSuccess,
    shareSecret,
    shareSecretFailure,
    shareSecretReset,
    shareSecretSuccess,
    updateSecret,
    updateSecretFailure,
    updateSecretReset,
    updateSecretSuccess,
} from '../actions';

const load = basicApiReducer({
    actionTypes: {
        REQUEST: loadSecrets.toString(),
        SUCCESS: loadSecretsSuccess.toString(),
        FAILURE: loadSecretsFailure.toString(),
    },
});

const deleteReducer = basicApiReducer({
    actionTypes: {
        REQUEST: deleteSecrets.toString(),
        SUCCESS: deleteSecretsSuccess.toString(),
        FAILURE: deleteSecretsFailure.toString(),
    },
});

const create = basicApiReducer({
    actionTypes: {
        REQUEST: createSecret.toString(),
        SUCCESS: createSecretSuccess.toString(),
        FAILURE: createSecretFailure.toString(),
    },
});

const readActionTypes = {
    REQUEST: readSecret.toString(),
    SUCCESS: readSecretSuccess.toString(),
    FAILURE: readSecretFailure.toString(),
};

const read = containerReducer({
    childReducer: basicApiReducer({
        actionTypes: readActionTypes,
        initialState: {
            inProgress: true,
        },
    }),
    actionTypes: Object.values(readActionTypes),
    selectors: {
        itemId: action => action.meta.id,
    },
});

const updateActionTypes = {
    REQUEST: updateSecret.toString(),
    SUCCESS: updateSecretSuccess.toString(),
    FAILURE: updateSecretFailure.toString(),
    RESET: updateSecretReset.toString(),
};

const update = containerReducer({
    childReducer: basicApiReducer({
        actionTypes: updateActionTypes,
    }),
    actionTypes: Object.values(updateActionTypes),
    selectors: {
        itemId: action => action.meta.id,
    },
});

const removeActionTypes = {
    REQUEST: removeSecret.toString(),
    SUCCESS: removeSecretSuccess.toString(),
    FAILURE: removeSecretFailure.toString(),
};

const remove = containerReducer({
    childReducer: basicApiReducer({
        actionTypes: removeActionTypes,
    }),
    actionTypes: Object.values(removeActionTypes),
    selectors: {
        itemId: action => action.meta.id,
    },
});

const ignoreReportActionTypes = {
    REQUEST: ignoreReportSecret.toString(),
    SUCCESS: ignoreReportSecretSuccess.toString(),
    FAILURE: ignoreReportSecretFailure.toString(),
    RESET: ignoreReportSecretReset.toString(),
};

const ignoreReport = containerReducer({
    childReducer: basicApiReducer({
        actionTypes: ignoreReportActionTypes,
    }),
    actionTypes: Object.values(ignoreReportActionTypes),
    selectors: {
        itemId: action => action.meta.id,
    },
});

const shareActionTypes = {
    REQUEST: shareSecret.toString(),
    SUCCESS: shareSecretSuccess.toString(),
    FAILURE: shareSecretFailure.toString(),
    RESET: shareSecretReset.toString(),
};

const share = containerReducer({
    childReducer: basicApiReducer({
        actionTypes: shareActionTypes,
    }),
    actionTypes: Object.values(shareActionTypes),
    selectors: {
        itemId: action => action.meta.id,
    },
});

const readShareActionTypes = {
    REQUEST: readShareSecret.toString(),
    SUCCESS: readShareSecretSuccess.toString(),
    FAILURE: readShareSecretFailure.toString(),
    RESET: readShareSecretReset.toString(),
};

const readShare = containerReducer({
    childReducer: basicApiReducer({
        actionTypes: readShareActionTypes,
    }),
    actionTypes: Object.values(readShareActionTypes),
    selectors: {
        itemId: action => action.meta.id,
    },
});

const totpActionTypes = {
    REQUEST: getSecretTOTP.toString(),
    SUCCESS: getSecretTOTPSuccess.toString(),
    FAILURE: getSecretTOTPFailure.toString(),
    RESET: getSecretTOTPReset.toString(),
};

const totp = containerReducer({
    childReducer: basicApiReducer({
        actionTypes: totpActionTypes,
    }),
    actionTypes: Object.values(totpActionTypes),
    selectors: {
        itemId: action => action.meta.id,
    },
});

export const apiReducer = combineReducers({
    load,
    delete: deleteReducer,
    create,
    read,
    update,
    remove,
    ignoreReport,
    share,
    readShare,
    totp,
});
