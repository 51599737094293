import type { TRuleWithTheme } from 'styles/theme';

export const container: TRuleWithTheme = () => ({
    '&.ant-alert': {
        marginLeft: '-1rem',
        marginBottom: '1rem',
        marginRight: '-1rem',

        borderRadius: '0.5rem',
    },
});
