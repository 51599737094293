import { useDispatch } from 'react-redux';

import { useAppSelector } from 'hooks/useAppSelector';

import { selectActiveSecretsAttributesFilters } from '../services/selectors';
import { toggleActiveSecretAttributeFilter, clearActiveSecretAttributeFilters } from '../services/actions';
import type { SearchSecretAttributeType } from '../index';

export const useSecretsAttributesFilter = () => {
    const filter = useAppSelector(selectActiveSecretsAttributesFilters);

    const dispatch = useDispatch();

    const toggleFilter = (value: string, label: string, type: SearchSecretAttributeType, active: boolean) => {
        dispatch(toggleActiveSecretAttributeFilter(value, label, type, active));
    };

    const clearFilter = () => {
        dispatch(clearActiveSecretAttributeFilters());
    };

    return {
        filter,
        toggleFilter,
        clearFilter,
    };
};
