import { combineRules } from 'fela';

import type { TRuleWithTheme } from 'styles/theme';

export const container: TRuleWithTheme = () => ({
    overflow: 'auto',
});

export const image: TRuleWithTheme = () => ({
    display: 'block',
    width: 121,
    height: 'auto',
    maxWidth: '100%',
});

export const demoContainer = combineRules(container, (({ theme }) => ({
    position: 'relative',

    display: 'inline-block',

    paddingBottom: '0.75rem',

    overflow: 'unset',

    '::after': {
        content: '"Live demo"',

        position: 'absolute',
        bottom: 0,
        right: 0,

        display: 'flex',
        alignItems: 'center',

        height: '0.875rem',

        paddingLeft: '0.5rem',
        paddingBottom: 1,
        paddingRight: '0.5rem',

        fontSize: '0.5625rem',
        letterSpacing: '0.0375em',

        borderRadius: '0.4375rem',

        color: theme.colors.white,
        backgroundColor: theme.colors.brightMagenta,
    },
})) as TRuleWithTheme);
