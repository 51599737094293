import type { TRuleWithTheme } from 'styles/theme';

export const wrapper: TRuleWithTheme = () => ({
    position: 'relative',
    zIndex: 1,
});

export const item: TRuleWithTheme = ({ theme }) => ({
    position: 'relative',
    zIndex: 1,

    backgroundColor: theme.colors.white,

    borderRadius: '0.25rem',
});

export const itemOverlay: TRuleWithTheme = ({ theme }) => ({
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 2,

    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: theme.colors.brightMagenta,
    borderRadius: '0.25rem',
});

export const overlay: TRuleWithTheme = ({ theme }) => ({
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 0,

    background: theme.colors.black,
    opacity: '0.1',
});
