import { createAction } from '@reduxjs/toolkit';

import types from '../actionTypes';

export const loadSecrets = createAction(types.LOAD_SECRETS);

export const loadSecretsSuccess = createAction(types.LOAD_SECRETS_SUCCESS);

export const loadSecretsFailure = createAction(types.LOAD_SECRETS_FAILURE, (error: any) => ({
    error,
    payload: undefined,
}));
