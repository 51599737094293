import { Radio } from 'antd';

import { useFela } from 'react-fela';

import type { RadioGroupProps } from 'antd/es/radio';

import type { Theme } from 'styles/theme';

import * as felaRules from './RadioButton.rules';

interface RadioButtonGroupProps extends RadioGroupProps {
    backgroundColor?: keyof Theme['colors'];
}

export const RadioButtonGroup = ({ backgroundColor, ...props }: RadioButtonGroupProps) => {
    const { css } = useFela({ backgroundColor });
    return <Radio.Group {...props} className={css(felaRules.radioButton)} />;
};
