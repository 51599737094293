import { useFela } from 'react-fela';

import { FormattedMessage, useIntl } from 'react-intl';

import { RadioButtonGroup } from 'modules/forms';
import { SpinLoader, Text } from 'modules/ui';
import { StaticModal, useModal } from 'modules/modals';

import { useChangeGroupVisibility } from '../../hooks';

import * as felaRules from './GroupsVisibilityListItem.rules';
import type { GroupWithUsedCount } from '../../types';

const options = [
    { label: 'groups.visibility.visible', value: true },
    { label: 'groups.visibility.hidden', value: false },
] as const;

export interface GroupsVisibilityListItemProps {
    group: GroupWithUsedCount;
}

export const GroupsVisibilityListItem = ({ group }: GroupsVisibilityListItemProps) => {
    const { css } = useFela();
    const { formatMessage } = useIntl();
    const { updateModal } = useModal(StaticModal.HIDE_GROUP, { autoClose: true });

    const { changeGroupVisibility, inProgress } = useChangeGroupVisibility(group.id);

    return (
        <div className={css(felaRules.container)}>
            <div className={css(felaRules.textContainer)}>
                <Text elementType="h2">{group.name}</Text>
                <Text size="small" color="brownGrey2" customStyle={felaRules.usedCount}>
                    {group.usedCount > 0 ? (
                        <FormattedMessage id="groups.list.usedCount" values={{ count: group.usedCount }} />
                    ) : (
                        <FormattedMessage id="groups.list.notUsed" />
                    )}
                </Text>
            </div>
            <div className={css(felaRules.actionsContainer)}>
                {inProgress && <SpinLoader width={20} height={20} />}
                <RadioButtonGroup
                    name={group.id}
                    backgroundColor="bgGrey"
                    optionType="button"
                    disabled={inProgress}
                    options={options.map(({ label, value }) => ({
                        label: formatMessage({ id: label }),
                        value,
                    }))}
                    value={group.visible}
                    onChange={event => {
                        if (event.target.value === false) {
                            updateModal(true, { group });
                        } else {
                            changeGroupVisibility(true);
                        }
                    }}
                />
            </div>
        </div>
    );
};
