import type { PetrusTokens } from '@ackee/petrus';

import { publicApi } from 'config/antonio';
import { config } from 'config/config';
import { waitForOnline } from 'modules/network';

export type OauthRefreshTokenResponse = {
    accessToken: string;
    expiration: string;
};

export function* refreshTokens(tokens: PetrusTokens) {
    if (config.demo.enable) {
        throw new Error(
            "Petrus: refreshTokens method shouldn't be called in the demo application. Check the token expiration!",
        );
    }

    yield* waitForOnline();

    const { data } = yield* publicApi.post<OauthRefreshTokenResponse>(
        config.api.oauthRefreshToken,
        {
            refreshToken: tokens.refreshToken.token,
        },
        {
            headers: { 'x-islocalhost': config.isLocalhost ? 'true' : 'false' },
            baseURL: config.api.base,
        },
    );

    return {
        accessToken: {
            ...tokens.accessToken,
            token: data.accessToken,
            expiration: new Date(data.expiration).toISOString(),
        },
        refreshToken: tokens.refreshToken,
    } satisfies PetrusTokens;
}
