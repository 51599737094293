import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { loadSettings } from '../services/actions';
import { selectSettingsApi, selectGeneralSettings } from '../services/selectors';

export const useFetchSettings = () => {
    const api = useSelector(selectSettingsApi);
    const data = useSelector(selectGeneralSettings);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(loadSettings());
    }, [dispatch]);

    return {
        api,
        data,
    };
};
