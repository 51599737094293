import type { TRuleWithTheme } from 'styles/theme';

export const container: TRuleWithTheme = ({ theme }) => ({
    display: 'flex',
    width: '50%',
    flexDirection: 'column',
    '& > label': {
        marginTop: 10,
        color: theme.colors.black,
        fontSize: '0.8rem',
        min768: {
            fontSize: '0.9rem',
        },
    },
    '& .ant-checkbox-wrapper': {
        marginLeft: 0,
    },
    '& .ant-checkbox-wrapper-disabled > span ': {
        color: theme.colors.lightGrey4,
        transition: 'color 0.3s',
    },
});
