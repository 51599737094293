import Cookies from 'js-cookie';

// These cookies are set via website at passwd.team when a user comes from a campaign.
// We consume these cookies when the user uses demo.passwd.team or app.passwd.team to
// track a conversion of a user in the campaign. The same data are used when the user pays on the microsite.

// PS: The cookies are set in the microsite and in the WORDPRESS BLOG (https://gitlab.ack.ee/Web/passwd-blog-template)
// as the blog sits on passwd.team/blog

const originalUtmParams = 'passwd_utm_original';
const finalUtmParams = 'passwd_utm_final';

enum UTMKey {
    SOURCE = 'utm_source',
    MEDIUM = 'utm_medium',
    CAMPAIGN = 'utm_campaign',
}

type UTMParams = Record<UTMKey, string>;

const ensureUtmParams = (params: any) => {
    if (Object.keys(params).length === 3 && params[UTMKey.SOURCE] && params[UTMKey.MEDIUM] && params[UTMKey.CAMPAIGN]) {
        return params as UTMParams;
    }

    throw new Error("Utm params don't have all the required fields.");
};

export interface UTMParamsTuple {
    original: UTMParams;
    final: UTMParams;
}

export function getUtmParams(): UTMParamsTuple {
    const originalUtmCookie = Cookies.get(originalUtmParams);
    const finalUtmCookie = Cookies.get(finalUtmParams);

    // Both cookies should be set, if parsing one of them fails,
    // we don't want to use any of them
    try {
        return {
            original: ensureUtmParams(JSON.parse(originalUtmCookie)),
            final: ensureUtmParams(JSON.parse(finalUtmCookie)),
        };
    } catch (e) {}

    return undefined;
}
