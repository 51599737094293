import { put, takeEvery } from 'redux-saga/effects';

import { api } from 'config/antonio';
import { config } from 'config/config';
import { logger } from 'config/logger';

import { actions as modalActions, StaticModal } from 'modules/modals';
import { actions as messagesActions } from 'modules/messages';

import {
    changeGroupVisibilityRequest,
    changeGroupVisibilityRequestFailure,
    changeGroupVisibilityRequestSuccess,
} from '../actions';

function* changeGroupVisibilityHandler({ meta, payload }: ReturnType<typeof changeGroupVisibilityRequest>) {
    try {
        const apiPath = payload.visible ? config.api.showGroup : config.api.hideGroup;

        yield* api.post(apiPath, null, {
            uriParams: {
                id: meta.groupId,
            },
        });

        yield put(changeGroupVisibilityRequestSuccess(meta.groupId, payload.visible));

        yield put(modalActions.setModalVisibility(StaticModal.HIDE_GROUP, false));
    } catch (e) {
        logger.error(e);

        yield put(changeGroupVisibilityRequestFailure(meta.groupId, e));

        yield put(
            messagesActions.displayErrorMessage({
                message: { id: 'settings.groupsVisibility.error' },
            }),
        );

        yield put(modalActions.setModalVisibility(StaticModal.HIDE_GROUP, false));
    }
}

export function* changeGroupVisibilitySaga() {
    yield takeEvery(changeGroupVisibilityRequest.toString(), changeGroupVisibilityHandler);
}
