import { FormattedMessage } from 'react-intl';

import { Empty } from 'modules/ui';

import type { MessageKey } from 'modules/localizations/translations/types';

import * as felaRules from './SecurityAuditListEmpty.rules';

export interface SecurityAuditListEmptyProps {
    messageId: MessageKey;
}

export const SecurityAuditListEmpty = ({ messageId }: SecurityAuditListEmptyProps) => (
    <Empty text={<FormattedMessage id={messageId} />} customStyle={felaRules.container} />
);
