import { useFela } from 'react-fela';
import { useMemo } from 'react';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import { FormattedMessage } from 'react-intl';

import type { Theme } from 'styles/theme';

import { CopyButton, Text } from 'modules/ui';

import type { Secret } from '../../../types';
import { getTOTPDuration } from '../../../utilities/totp';

import { useTOTP } from '../../../hooks/useTOTP';

import * as felaRules from './TOTP.rules';

export interface TOTPProps {
    id: Secret['id'];
}

export const TOTP = ({ id }: TOTPProps) => {
    const { theme, css } = useFela<Theme>();
    const { activeTotp, removeTOTP } = useTOTP(id);

    const settings = useMemo(() => {
        if (activeTotp) {
            return {
                code: activeTotp.code,
                duration: getTOTPDuration(activeTotp),
            };
        }

        return {
            code: '',
            duration: 0,
        };
    }, [activeTotp]);

    return (
        <div className={css(felaRules.container)}>
            <CopyButton type="totp" key={id} text={settings.code} />
            <div className={css(felaRules.countdownContainer)}>
                <CountdownCircleTimer
                    key={settings.code}
                    isPlaying={Boolean(settings.duration)}
                    duration={30}
                    initialRemainingTime={settings.duration}
                    colors={
                        Boolean(settings.duration) ? theme.colors.accentTertiary : theme.colors.foregroundsSeparator
                    }
                    strokeLinecap="square"
                    strokeWidth={3}
                    size={24}
                    trailColor={theme.colors.foregroundsSeparator}
                    onComplete={() => {
                        removeTOTP(settings.code);
                    }}
                >
                    {({ remainingTime }) => <span className={css(felaRules.remainingTime)}>{remainingTime}</span>}
                </CountdownCircleTimer>
                <Text size="small" color="accentTertiary">
                    <FormattedMessage id="secret.totp.validity" />
                </Text>
            </div>
        </div>
    );
};
