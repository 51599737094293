import debounce from 'lodash/debounce';

const BROWSER_PREFIXES = ['moz', 'ms', 'o', 'webkit'] as const;

type Prefixes = typeof BROWSER_PREFIXES[number];

const getVisibilityEvent = (prefix: Prefixes) => `${prefix}visibilitychange`;

const getHiddenPropertyName = (prefix: Prefixes | undefined = undefined) => (prefix ? `${prefix}Hidden` : 'hidden');

const getBrowserPrefix = () => BROWSER_PREFIXES.find(prefix => getHiddenPropertyName(prefix) in document);

interface ListenerOptions {
    useDocumentFocus?: boolean;
    useWindowFocus?: boolean;
    initialTrigger?: boolean;
}

interface Listener {
    (visibility: boolean): void;
}

export const visibilityEventListener = (listener: Listener, options: ListenerOptions = {}) => {
    const { useDocumentFocus = true, useWindowFocus = true, initialTrigger = false } = options;

    const browserPrefix = getBrowserPrefix();
    const hiddenPropertyName = getHiddenPropertyName(browserPrefix);
    const visibilityEventName = getVisibilityEvent(browserPrefix);

    // All the event can be triggered at once and thus onVisible/onHidden would be called more times
    // Thus we debounce the function with really little debounce time to avoid this situation
    const handleVisibilityChange = debounce((event: boolean | Event = undefined) => {
        listener(typeof event === 'boolean' ? event : !document[hiddenPropertyName]);
    }, 5);

    const handleVisible = () => handleVisibilityChange(true);
    const handleHidden = () => handleVisibilityChange(false);

    if (initialTrigger) {
        handleVisibilityChange();
    }

    document.addEventListener(visibilityEventName, handleVisibilityChange, false);

    // extra event listeners for better behaviour
    if (useDocumentFocus) {
        document.addEventListener('focus', handleVisible, false);
        document.addEventListener('blur', handleHidden, false);
    }
    if (useWindowFocus) {
        window.addEventListener('focus', handleVisible, false);
        window.addEventListener('blur', handleHidden, false);
    }

    return {
        remove: () => {
            document.removeEventListener(visibilityEventName, handleVisibilityChange);

            if (useDocumentFocus) {
                document.removeEventListener('focus', handleVisible);
                document.removeEventListener('blur', handleHidden);
            }
            if (useWindowFocus) {
                window.removeEventListener('focus', handleVisible);
                window.removeEventListener('blur', handleHidden);
            }
        },
    };
};
