import type { ReactNode } from 'react';

import { useSubscriptionAlerts } from '../../hooks/useSubscriptionAlerts';

import { CanceledSubscriptionAlert } from './CanceledSubscriptionAlert';
import { NotLinkedAlert } from './NotLinkedAlert';

export interface SubscriptionFirewallProps {
    children: ReactNode;
}

export const SubscriptionFirewall = ({ children }: SubscriptionFirewallProps) => {
    const { canceledSubscriptionAlertVisible, notLinkedAlertVisible } = useSubscriptionAlerts();

    if (canceledSubscriptionAlertVisible) {
        return (
            <>
                <CanceledSubscriptionAlert />
                {children}
            </>
        );
    }

    if (notLinkedAlertVisible) {
        return (
            <>
                <NotLinkedAlert />
                {children}
            </>
        );
    }

    return <>{children}</>;
};
