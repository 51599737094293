import type { SVGProps } from 'react';

export const Hash = (props: SVGProps<SVGSVGElement>) => (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path d="M2.91602 5.25H11.0827H2.91602Z" fill="currentColor" />
        <path d="M2.91602 5.25H11.0827" stroke="currentColor" strokeLinecap="square" strokeLinejoin="round" />
        <path d="M2.91602 8.75H11.0827H2.91602Z" fill="currentColor" />
        <path d="M2.91602 8.75H11.0827" stroke="currentColor" strokeLinecap="square" strokeLinejoin="round" />
        <path d="M6.41732 2.3335L4.08398 11.6668L6.41732 2.3335Z" fill="currentColor" />
        <path d="M6.41732 2.3335L4.08398 11.6668" stroke="currentColor" strokeLinecap="square" strokeLinejoin="round" />
        <path d="M9.91732 2.3335L7.58398 11.6668" stroke="currentColor" strokeLinecap="square" strokeLinejoin="round" />
    </svg>
);
