import { Suspense, lazy } from 'react';
import { useFela } from 'react-fela';

import { Heading, Loader } from 'modules/ui';

import { ErrorMessage } from 'components/ErrorMessage';

import { useChangelog } from '../../hooks/useChangelog';

import * as felaRules from './Changelog.rules';

const ReactMarkdown = lazy(() => import('react-markdown'));

export const Changelog = () => {
    const changelog = useChangelog();

    const { css } = useFela();

    if (changelog.error) {
        return <ErrorMessage />;
    }

    return (
        <Suspense fallback={<Loader />}>
            <ReactMarkdown
                components={{
                    h1: props => <Heading elementType="h1">{props.children}</Heading>,
                    h2: props => <Heading elementType="h2">{props.children}</Heading>,
                    h3: props => <Heading elementType="h3">{props.children}</Heading>,
                    h4: props => <Heading elementType="h4">{props.children}</Heading>,
                    h5: props => <Heading elementType="h5">{props.children}</Heading>,
                    ul: props => <ul className={css(felaRules.list)}>{props.children}</ul>,
                    li: props => <li className={css(felaRules.listItem)}>{props.children}</li>,
                    p: props => <p className={css(felaRules.paragraph)}>{props.children}</p>,
                }}
                className={css(felaRules.container)}
                disallowedElements={['link', 'linkReference']}
            >
                {changelog.content}
            </ReactMarkdown>
        </Suspense>
    );
};
