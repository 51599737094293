import type { TRuleWithTheme } from 'styles/theme';

export const container: TRuleWithTheme = ({ theme }) => ({
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '1.5rem',

    padding: '0 1rem 1rem 1rem',

    min768: {
        paddingInline: '2.5rem',
    },

    min1024: {
        padding: '2.5rem',
    },
});
