import type { TRuleWithTheme } from 'styles/theme';

export const titleContainer: TRuleWithTheme = ({ theme }) => ({
    paddingTop: '1rem',
    paddingRight: '1.5rem',
    paddingBottom: '1.125rem',
    paddingLeft: '1.5rem',

    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: theme.colors.lightGrey4,

    min1440: {
        paddingTop: '3.75rem',
    },
});

export const title: TRuleWithTheme = () => ({
    display: 'flex',
    alignItems: 'center',
});

export const description: TRuleWithTheme = () => ({
    paddingTop: '0.75rem',
    paddingBottom: '0.25rem',
});

export const container: TRuleWithTheme = () => ({
    flex: 1,

    overflow: 'auto',

    display: 'flex',
    flexDirection: 'column',
});

export const link: TRuleWithTheme = () => ({
    display: 'flex',

    marginRight: '0.5rem',

    transform: 'rotate(90deg)',
});

export const wrapper: TRuleWithTheme = () => ({
    overflow: 'auto',
});
