import type { TRuleWithTheme } from 'styles/theme';

const rotate = {
    from: {
        transform: 'rotate(0deg)',
    },
    to: {
        transform: 'rotate(360deg)',
    },
};

export const loader: TRuleWithTheme = () => ({
    animationName: rotate,
    animationDuration: '1.5s',
    animationIterationCount: 'infinite',
    animationTimingFunction: 'linear',
});
