import { createReducer } from '@reduxjs/toolkit';
import { union } from 'lodash';

import { addMessageResolve, clearMessages, removeMessage } from '../actions';

const initialState: string[] = [];

export const idsReducer = createReducer(initialState, builder => {
    builder.addCase(addMessageResolve, (state, action) => union(state, [action.meta.id]));

    builder.addCase(removeMessage, (state, action) => state.filter(messageId => messageId !== action.meta.id));

    builder.addCase(clearMessages, () => initialState);
});
