import { basicApiReducer } from '@ackee/redux-utils';
import { combineReducers } from 'redux';

import { fetchAudit, fetchAuditFailure, fetchAuditSuccess } from '../actions';

const fetch = basicApiReducer({
    actionTypes: {
        REQUEST: fetchAudit.toString(),
        SUCCESS: fetchAuditSuccess.toString(),
        FAILURE: fetchAuditFailure.toString(),
    },
});

export const apiReducer = combineReducers({
    fetch,
});
