import { put } from 'redux-saga/effects';

import { api } from 'config/antonio';
import { config } from 'config/config';
import { logger } from 'config/logger';

import { takeLatestRequest } from 'services/utilities/takeRequest';

import type { Secret } from '../../types';

import { shareSecret, shareSecretFailure, shareSecretReset, shareSecretSuccess } from '../actions';

function* shareSecretHandler({ meta, payload }: ReturnType<typeof shareSecret>, signal: AbortSignal) {
    try {
        let data: Secret['share'] = undefined;

        if (payload.activate) {
            const response = yield* api.post<Secret['share']>(config.api.share, null, {
                uriParams: {
                    id: meta.id,
                },
                signal,
            });

            data = response.data;
        } else {
            yield* api.delete(config.api.share, {
                uriParams: {
                    id: meta.id,
                },
                signal,
            });
        }

        yield put(shareSecretSuccess(meta.id, data));
    } catch (e) {
        logger.error(e);

        yield put(shareSecretFailure(meta.id, e));
    }
}

export default function* shareSecretSaga() {
    yield takeLatestRequest(
        {
            actionCreator: shareSecret,
            cancelActionFunction: action => shareSecretReset(action.meta.id),
            idSelector: action => action.meta.id,
        },
        shareSecretHandler,
    );
}
