import { FormattedMessage } from 'react-intl';
import { useFela } from 'react-fela';

import { Text, CopyButton, Empty, PasswordVisualisation } from 'modules/ui';

import { useSharedSecretDetail } from '../../hooks/useSharedSecretDetail';

import type { Secret } from '../../types';

import { SecretFieldSection } from '../SecretFieldSection';
import { SecretNameWithIcon } from '../SecretNameWithIcon';
import { SecretFile } from '../SecretFile';
import { SecretUrl } from '../SecretUrl';

import * as felaRules from './ShareSecretDetail.rules';

export interface SecretDetailProps {
    id: Secret['share']['id'];
}

export const ShareSecretDetail = ({ id }: SecretDetailProps) => {
    const secret = useSharedSecretDetail(id);
    const { css } = useFela();

    if (!secret) {
        return <Empty data-testid="shared-secret-empty" text={<FormattedMessage id="secret.share.empty" />} />;
    }

    const { name, favicon, web, username, password, note, file } = secret;

    return (
        <>
            <SecretFieldSection>
                <SecretNameWithIcon name={name} favicon={favicon} />
            </SecretFieldSection>
            <div className={css(felaRules.propertiesContainer)}>
                {web ? (
                    <SecretFieldSection heading={<FormattedMessage id="secret.web" />}>
                        <CopyButton key={id} text={web}>
                            <SecretUrl web={web} />
                        </CopyButton>
                    </SecretFieldSection>
                ) : null}
                {username ? (
                    <SecretFieldSection heading={<FormattedMessage id="secret.username" />}>
                        <CopyButton text={username} />
                    </SecretFieldSection>
                ) : null}
                {password ? (
                    <SecretFieldSection heading={<FormattedMessage id="secret.password" />}>
                        <CopyButton type="password" text={password}>
                            <PasswordVisualisation password={password} />
                        </CopyButton>
                    </SecretFieldSection>
                ) : null}
                {file ? (
                    <SecretFieldSection heading={<FormattedMessage id="secret.file" />}>
                        <SecretFile file={file} />
                    </SecretFieldSection>
                ) : null}
                {note ? (
                    <SecretFieldSection heading={<FormattedMessage id="secret.note" />}>
                        <Text preserveNewLines>{note}</Text>
                    </SecretFieldSection>
                ) : null}
            </div>
        </>
    );
};
