import { useLayoutEffect, useState } from 'react';
import ReactDOM from 'react-dom';

import { MessageList } from '../MessageList';

const createElement = () => {
    const div = document.createElement('div');
    div.setAttribute('id', 'messages');
    return div;
};

const init = () => createElement();

export const Messages = () => {
    const [element] = useState(init);

    useLayoutEffect(() => {
        document.body.appendChild(element);

        return () => {
            document.body.removeChild(element);
        };
    }, [element]);

    if (element === null) {
        return null;
    }

    return ReactDOM.createPortal(<MessageList />, element);
};
