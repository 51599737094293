import { put } from 'redux-saga/effects';

import { config } from 'config/config';
import { logger } from 'config/logger';
import { publicApi } from 'config/antonio';

import { takeLatestRequest } from 'services/utilities/takeRequest';

import { readShareSecret, readShareSecretReset, readShareSecretSuccess, readShareSecretFailure } from '../actions';

import { normalizeSecret } from '../normalizers';

const prepareSecret = (secret: any, id: string) => ({
    ...secret,
    id,
});

interface ResponseData {
    secret: any;
    shareDetails: string;
}

function* readShareSecretHandler({ meta }: ReturnType<typeof readShareSecret>) {
    try {
        const { data } = yield* publicApi.get<ResponseData>(config.api.sharedSecret, {
            uriParams: {
                id: meta.id,
            },
        });

        const secret = prepareSecret(data.secret, meta.id);

        const { entities, result } = normalizeSecret(secret);

        yield put(readShareSecretSuccess(meta.id, entities.secrets[result]));
    } catch (e) {
        logger.error(e);

        yield put(readShareSecretFailure(meta.id, e));
    }
}

export default function* readShareSecretSaga() {
    yield takeLatestRequest(
        {
            actionCreator: readShareSecret,
            cancelActionFunction: action => readShareSecretReset(action.meta.id),
            idSelector: action => action.meta.id,
        },
        readShareSecretHandler,
    );
}
