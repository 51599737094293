import type { TRuleWithTheme } from 'styles/theme';

const size = 2.5;

export const defaultStyle: TRuleWithTheme = ({ theme }) => {
    const disabledStyle = {
        color: theme.colors.brownGrey3,
        borderColor: theme.colors.lightGrey,
        backgroundColor: theme.colors.lightGrey,

        cursor: 'default',
    };

    return {
        cursor: 'pointer',

        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',

        width: `${size}rem`,
        height: `${size}rem`,
        boxSizing: 'border-box',

        padding: 0,

        marginTop: 0,
        marginRight: 0,
        marginBottom: 0,
        marginLeft: 0,

        lineHeight: `${size}rem`,

        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: theme.colors.black,
        borderRadius: '50%',

        color: theme.colors.black,
        backgroundColor: theme.colors.white,

        ':hover': {
            color: theme.colors.blue,
            borderColor: theme.colors.blue,
        },
        ':focus': {
            color: theme.colors.blue,
            borderColor: theme.colors.blue,
        },

        '&[disabled]': disabledStyle,
        '&[disabled]:hover': disabledStyle,
        '&[disabled]:focus': disabledStyle,
    };
};

export const primaryTypeStyle: TRuleWithTheme = ({ theme }) => ({
    color: theme.colors.white,
    borderColor: theme.colors.blue,
    backgroundColor: theme.colors.blue,
    ':hover': {
        color: theme.colors.white,
        borderColor: theme.colors.hoverBlue,
        backgroundColor: theme.colors.hoverBlue,
    },
    ':focus': {
        color: theme.colors.white,
        borderColor: theme.colors.hoverBlue,
        backgroundColor: theme.colors.hoverBlue,
    },
});

export const ghostTypeStyle: TRuleWithTheme = ({ theme }) => {
    const disabledStyle = {
        color: theme.colors.iconsSecondary,

        cursor: 'default',
    };

    return {
        flexShrink: 0,
        flexGrow: 0,

        cursor: 'pointer',

        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

        padding: 0,
        margin: 0,

        background: 'none',
        border: 'none',
        boxShadow: 'none',

        '&[disabled]': disabledStyle,
        '&[disabled]:hover': disabledStyle,
        '&[disabled]:focus': disabledStyle,
    };
};
