import type { TRuleWithTheme } from 'styles/theme';

export const container: TRuleWithTheme = () => ({
    width: 'fit-content',

    paddingRight: '1rem',
    paddingLeft: '1rem',

    marginTop: '2.5rem',
    marginLeft: 'auto',
    marginBottom: '2.5rem',
    marginRight: 'auto',

    textAlign: 'center',
});

export const activeView: TRuleWithTheme = () => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',

    marginTop: '0.375rem',
});

export const icon: TRuleWithTheme = () => ({
    flexShrink: 0,

    marginRight: '0.375rem',
    marginLeft: '0.5rem',
});
