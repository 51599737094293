import { useFela } from 'react-fela';
import { FormattedMessage } from 'react-intl';

import { config } from 'config/config';
import { IS_DEMO, IS_SAAS } from 'constants/index';

import { Version } from 'modules/backend-version';
import { AckeeLogo, Heading, Icon, Link, Logo, Text } from 'modules/ui';
import { ErrorCode } from 'services/utilities/isApiError';

import { DemoLoginForm } from '../DemoLoginForm';
import { LoginButton } from '../LoginButton';
import * as felaRules from './Login.rules';
import { LoginErrorAlert } from './LoginErrorAlert';
import { LoginErrorMessage } from './LoginErrorMessage';
import { useErrorCodeParam } from './hooks/useErrorCodeParam';

const isClientInstance = !IS_DEMO && !IS_SAAS;

export const Login = () => {
    const { css } = useFela();
    const errorCode = useErrorCodeParam();

    return (
        <div className={css(felaRules.container)}>
            <header>
                <Logo customStyle={felaRules.logo} />
            </header>
            <main className={css(felaRules.wrapper)}>
                <Heading elementType="h1" color="black">
                    <FormattedMessage id={config.demo.enable ? 'auth.login.title.demo' : 'auth.login.title'} />
                </Heading>

                {errorCode !== undefined && (
                    <>
                        <LoginErrorMessage errorCode={errorCode} />

                        {errorCode !== ErrorCode.AUTH_CLIENT_IS_NOT_CONFIGURED && (
                            <>
                                <Text>
                                    <FormattedMessage
                                        id={config.demo.enable ? 'auth.login.desc.demo' : 'auth.login.desc'}
                                    />
                                </Text>
                                {config.demo.enable ? <DemoLoginForm /> : <LoginButton />}
                            </>
                        )}

                        <LoginErrorAlert errorCode={errorCode} />
                    </>
                )}
            </main>
            <footer className={css(felaRules.infoWrapper)}>
                <Text size="small" color="brownGrey2" customStyle={felaRules.info}>
                    <span>
                        <FormattedMessage id="auth.login.copyright" />
                        <AckeeLogo />
                    </span>
                    <Version customStyle={felaRules.version} />
                    {isClientInstance ? (
                        <Link
                            href={config.links.emergency}
                            target="_blank"
                            type="native"
                            color="brownGrey2"
                            size="regular"
                        >
                            <span className={css(felaRules.emergency)}>
                                <Icon width={20} height={20} type="warning-triangle" />
                                <Icon
                                    width={10}
                                    height={10}
                                    type="settings"
                                    customStyle={felaRules.emergencySettingsIcon}
                                />
                            </span>
                            <FormattedMessage id="auth.login.emergency" />
                        </Link>
                    ) : null}
                </Text>
            </footer>
        </div>
    );
};
