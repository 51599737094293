import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';

export const DemoHead = () => {
    const intl = useIntl();

    return (
        <Helmet>
            <title>
                {intl.formatMessage({
                    id: 'demo.head.title',
                })}
            </title>
            <meta
                name="description"
                content={intl.formatMessage({
                    id: 'demo.head.desc',
                })}
            />
        </Helmet>
    );
};
