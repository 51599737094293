import { FormattedMessage } from 'react-intl';
import { useFela } from 'react-fela';

import { Icon } from 'modules/ui';

import * as felaRules from './IgnoreIndicator.rules';

export const IgnoreIndicator = () => {
    const { css } = useFela();

    return (
        <span className={css(felaRules.container)}>
            <Icon type="no-report" width={14} height={14} customStyle={felaRules.icon} />
            <FormattedMessage id="securityAudit.ignored" />
        </span>
    );
};
