import type { TRuleWithTheme } from 'styles/theme';

export const container: TRuleWithTheme = () => ({
    position: 'relative',
});

export const indicatorContainer: TRuleWithTheme = () => ({
    position: 'absolute',
    top: 0,
    right: 0,
});
