import { Status } from '../constants';

const connectionIsSupported = 'connection' in navigator;

export const getConnectionStatus = () => {
    // eslint-disable-next-line compat/compat
    const downlink = navigator.connection?.downlink;

    const isOffline = connectionIsSupported ? downlink === 0 || navigator.onLine === false : !navigator.onLine;

    return isOffline ? Status.OFFLINE : Status.ONLINE;
};
