import { combineRules } from 'fela';

import type { TRuleWithTheme } from 'styles/theme';

export const container: TRuleWithTheme = ({ theme }) => ({
    width: 'calc(100% - 3rem)',

    padding: '1.25rem',

    marginRight: '1.5rem',
    marginBottom: '2rem',
    marginLeft: '1.5rem',

    borderColor: theme.colors.lightGrey,
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: '1rem',
});

export const message: TRuleWithTheme = () => ({
    marginBottom: '1rem',
});

export const report: TRuleWithTheme = () => ({
    display: 'flex',

    gap: '0.75rem',

    marginTop: '0.25rem',

    overflow: 'auto',

    '&::-webkit-scrollbar': {
        display: 'none',
    },
});

export const item: TRuleWithTheme = () => ({
    flexShrink: 0,

    display: 'inline-block',

    paddingTop: '0.25rem',
    paddingRight: '0.75rem',
    paddingBottom: '0.25rem',
    paddingLeft: '0.75rem',

    fontSize: '1rem',
    fontWeight: 400,
    lineHeight: '1.5em',

    borderRadius: '0.25rem',
});

export const lowLevel = combineRules(item, (({ theme }) => ({
    backgroundColor: theme.colors.report.red,
    color: theme.colors.white,
})) as TRuleWithTheme);

export const highAge = combineRules(item, (({ theme }) => ({
    backgroundColor: theme.colors.report.yellow,
    color: theme.colors.black,
})) as TRuleWithTheme);

export const manyLeaks = highAge;

export const duplicities = combineRules(item, (({ theme }) => ({
    backgroundColor: theme.colors.report.grey,
    color: theme.colors.white,
})) as TRuleWithTheme);
