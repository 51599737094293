import { camelCase } from 'lodash';
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl';
import { useFela } from 'react-fela';

import config from 'config';

import { Icon, Link, Pane, Text } from 'modules/ui';
import { InfoLabel } from 'modules/forms';

import type { MessageKey } from 'modules/localizations/translations/types';

import type { Order } from '../../types';

import * as felaRules from './OrderInfo.rules';

export interface OrderInfoProps {
    order: Order;
}

export const OrderInfo = ({ order }: OrderInfoProps) => {
    const { css } = useFela();

    const intl = useIntl();

    return (
        <Pane
            data-testid="settings-order-info"
            extend={{
                container: felaRules.container,
            }}
        >
            <h2 className={css(felaRules.title)}>
                <FormattedMessage id={`settings.order.type.${order.interval}`} />
                <span
                    className={css(felaRules.billingEmail)}
                    aria-label={intl.formatMessage({
                        id: 'settings.order.billingEmail',
                    })}
                >
                    <Text size="small">{order.billingEmail}</Text>
                </span>
            </h2>
            <hr className={css(felaRules.divider)} />
            <p className={css(felaRules.info)}>
                <FormattedMessage
                    id={`settings.order.amount.${order.interval}`}
                    values={{
                        price: (
                            <FormattedNumber
                                value={Number.parseInt(order.amount, 10) / 100} // Price comes in US cents and we need to convert it to dollars
                                minimumFractionDigits={0}
                                maximumFractionDigits={2}
                                // eslint-disable-next-line react/style-prop-object
                                style="currency"
                                currency="USD"
                            />
                        ),
                    }}
                />
            </p>
            {order.tier ? (
                <p className={css(felaRules.info)}>
                    <FormattedMessage id={`settings.order.tier.${camelCase(order.tier)}` as MessageKey} />
                </p>
            ) : null}
            <Link size="regular" type="native" href={config.links.stripe}>
                <FormattedMessage id="settings.order.link" /> <Icon type="link" width={12} height={12} />
            </Link>
            <InfoLabel customStyle={felaRules.changeSubscriptionWarning}>
                <FormattedMessage id="settings.order.changeSubscriptionWarning" />
            </InfoLabel>
        </Pane>
    );
};
