import { createElement } from 'react';
import type { ReactNode } from 'react';

import { useFela } from 'react-fela';

import type { Theme } from 'styles/theme';

import { container, TextSize } from './Text.rules';

export interface TextProps {
    elementType?: string;
    color?: keyof Theme['colors'];
    size?: `${TextSize}`;
    customStyle?: typeof container;
    className?: string;
    preserveNewLines?: boolean;
    bold?: boolean;
    children: ReactNode;
}

export const Text = ({
    children,
    color = 'black',
    size = TextSize.REGULAR,
    className = '',
    elementType = 'p',
    preserveNewLines = false,
    bold = false,
    customStyle,
}: TextProps) => {
    const { css } = useFela({
        color,
        size,
        preserveNewLines,
        bold,
    });

    return createElement(
        elementType,
        {
            className: `Text ${css(container, customStyle)} ${className}`,
        },
        children,
    );
};
