import { combineReducers } from 'redux';
import { basicResetReducer } from '@ackee/redux-utils';
import { AUTH_SESSION_END } from '@ackee/petrus';

import { apiReducer as secrets } from 'modules/secrets';
import { apiReducer as securityAudit, constants as securityAuditConstants } from 'modules/security-audit';
import { apiReducer as importExport, constants as importExportConstants } from 'modules/import-export';
import { apiReducer as groups } from 'modules/groups';
import { apiReducer as whitelist } from 'modules/whitelist';
import { apiReducer as settings } from 'modules/settings';

import { apiReducer as tags } from 'modules/secrets/modules/tags';

const apiReducer = combineReducers({
    secrets,
    groups,
    settings,
    whitelist,
    tags,
    [securityAuditConstants.MODULE_PREFIX]: securityAudit,
    [importExportConstants.MODULE_PREFIX]: importExport,
});

const resetReducer = basicResetReducer(apiReducer, AUTH_SESSION_END);

export default resetReducer;
