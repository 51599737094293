import { useFela } from 'react-fela';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';

import { useAppSelector } from 'hooks/useAppSelector';

import { Heading } from 'modules/ui';
import { FormItem, Label, SelectInput } from 'modules/forms';

import { authUserIdSelector } from '../../services/selectors';
import { selectDemoUser } from '../../services/actions';

import { users } from '../../config';

import * as felaRules from './DemoUsersSwitcher.rules';

const options = users.map(user => ({
    key: user.id,
    value: user.id,
    label: user.name,
}));

export function DemoUsersSwitcher() {
    const dispatch = useDispatch();

    const value = useAppSelector(authUserIdSelector);

    const onChange = (id: string) => {
        dispatch(selectDemoUser(id));
    };

    const { css } = useFela();

    return (
        <div className={css(felaRules.card)}>
            <Heading elementType="h3" color="black" customStyle={felaRules.title}>
                <FormattedMessage id="auth.usersSwitcher.title" />
            </Heading>

            <FormItem
                label={
                    <Label customStyle={felaRules.label}>
                        <FormattedMessage id="auth.usersSwitcher.label" />
                    </Label>
                }
                customStyle={felaRules.formItem}
            >
                <SelectInput
                    id="demoUser"
                    value={value}
                    options={options}
                    onSelect={onChange}
                    optionFilterProp="children"
                    customStyle={felaRules.select}
                />
            </FormItem>
        </div>
    );
}
