import { takeEvery, put, select } from 'redux-saga/effects';

import { logger } from 'config/logger';
import { IS_DEMO } from 'constants/index';

import { actions as messagesActions } from 'modules/messages';

import type { ViewFilter } from '../../types';

import { setSecrets, setViewFilter, syncSecrets } from '../actions';
import { selectViewFilter } from '../selectors';
import { normalizeSecrets } from '../normalizers';

import fetchSecrets from './fetchSecrets';

function* handler({ meta: { verbose } }: ReturnType<typeof syncSecrets>) {
    try {
        if (verbose) {
            yield put(
                messagesActions.displayLoadingMessage({
                    message: { id: 'loading.secrets.fetch' },
                    options: {
                        key: 'loading',
                        duration: 0,
                    },
                }),
            );
        }

        const newData = yield* fetchSecrets();

        if (verbose) {
            yield put(
                messagesActions.displaySuccessMessage({
                    message: { id: 'success.secrets.fetch' },
                    options: {
                        key: 'loading',
                        duration: 3.5,
                    },
                }),
            );
        }

        const { entities, result } = normalizeSecrets(newData);

        yield put(
            setSecrets(entities.secrets, result, {
                replace: true,
            }),
        );

        // Synchronize view filter
        const viewFilter: ViewFilter = yield select(selectViewFilter);
        yield put(setViewFilter(viewFilter));
    } catch (e) {
        logger.error(e);
    }
}

export default function* () {
    if (IS_DEMO) {
        return;
    }

    yield takeEvery(syncSecrets.toString(), handler);
}
