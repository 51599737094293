import type { TRuleWithTheme } from 'styles/theme';

export const container: TRuleWithTheme<{ isFullWidthLayoutRoute?: boolean }> = ({ theme, isFullWidthLayoutRoute }) => ({
    display: 'flex',
    flexDirection: 'column',

    position: 'relative',

    width: '100%',

    backgroundColor: isFullWidthLayoutRoute ? 'transparent' : theme.colors.white,

    min1024: {
        flexDirection: 'row',

        overflow: 'auto',
        flexShrink: 0,

        width: isFullWidthLayoutRoute ? '100%' : `calc(50% + ${theme.widths.menu / 2}px)`,

        extend: {
            condition: !isFullWidthLayoutRoute,
            style: {
                borderRightWidth: 1,
                borderRightStyle: 'solid',
                borderRightColor: theme.colors.foregroundsSeparator,
            },
        },
    },
});
