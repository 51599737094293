import { useFela } from 'react-fela';
import { FormattedMessage, useIntl } from 'react-intl';

import { Icon, Tag } from 'modules/ui';
import { Label, Autocomplete, SearchInput } from 'modules/forms';

import type { MessageKey } from 'modules/localizations/translations/types';

import { SearchOptionType, SearchSecretAttributeType } from '../../types';

import { useSecretsSearch, isOption } from './hooks';
import type { SearchOption } from './types';

import * as felaRules from './SecretsSearch.rules';

export const SecretsSearch = () => {
    const intl = useIntl();

    const {
        value,
        fulltextSearch,
        filteredSecretsAttributes,
        results,
        options,
        handleSearch,
        handleSelect,
        handleClearFulltext,
        handleClearAttributeFilter,
    } = useSecretsSearch(intl.locale);

    const { css } = useFela({
        hasMeta: results !== null,
    });

    const mapOption = (option: SearchOption) => {
        if (isOption(option)) {
            switch (option.type) {
                case SearchOptionType.TAG: {
                    return {
                        ...option,
                        role: 'option',
                        label: <Tag color="currentColor">{option.label}</Tag>,
                    };
                }
                case SearchOptionType.FILTER: {
                    return {
                        ...option,
                        role: 'option',
                        label: `"${option.label}"`,
                    };
                }
                case SearchOptionType.GROUP: {
                    return {
                        ...option,
                        role: 'option',
                        label: (
                            <div className={css(felaRules.groupOption)}>
                                <Icon type="group" width={16} height={16} /> {option.label}
                            </div>
                        ),
                    };
                }
                default: {
                    return {
                        ...option,
                        role: 'option',
                        label: <b>{option.label}</b>,
                    };
                }
            }
        } else {
            return {
                ...option,
                label: intl.formatMessage({
                    id: option.label as MessageKey,
                }),
                options: option.options.map(mapOption),
            };
        }
    };

    return (
        <div className={css(felaRules.container)}>
            <Autocomplete<string, SearchOption>
                value={value}
                onSearch={handleSearch}
                onSelect={handleSelect}
                options={options.map(mapOption)}
                defaultActiveFirstOption
                listHeight={600}
                customStyle={felaRules.autocomplete}
            >
                <SearchInput
                    placeholder={intl.formatMessage({ id: 'secrets.search.placeholder' })}
                    aria-label={intl.formatMessage({ id: 'secrets.search.placeholder' })}
                    showClear={Boolean(fulltextSearch)}
                    onClear={handleClearFulltext}
                />
            </Autocomplete>

            {filteredSecretsAttributes.map(attribute => (
                <Tag
                    closable
                    closeIcon={<Icon type="delete" width={14} height={14} />}
                    key={attribute.value}
                    onClose={() => {
                        handleClearAttributeFilter(attribute.value, attribute.type);
                    }}
                    customStyle={felaRules.tag}
                    icon={
                        <Icon
                            type={attribute.type === SearchSecretAttributeType.TAG ? 'hash' : 'group'}
                            width={14}
                            height={14}
                        />
                    }
                >
                    {attribute.label}
                </Tag>
            ))}

            {results !== null ? (
                <Label color="foregroundsTertiary" customStyle={felaRules.results}>
                    <FormattedMessage
                        id="secrets.search.results"
                        values={{
                            results,
                        }}
                    />
                </Label>
            ) : null}
        </div>
    );
};
