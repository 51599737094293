import { useFela } from 'react-fela';
import { FormattedMessage } from 'react-intl';

import { Button } from 'modules/ui';
import { StaticModal, useModal } from 'modules/modals';

import * as felaRules from './TagsListItem.rules';

export interface TagsListItemProps {
    name: string;
    count: number;
}

export const TagsListItem = ({ name, count }: TagsListItemProps) => {
    const { css } = useFela();

    const updateModal = useModal(StaticModal.UPDATE_TAG);
    const removeModal = useModal(StaticModal.REMOVE_TAG);

    return (
        <div data-testid={`tags-list-item-${name}`} className={css(felaRules.container)}>
            <div className={css(felaRules.info)}>
                <h2 className={css(felaRules.title)}>{name}</h2>
                <p className={css(felaRules.desc)}>
                    <FormattedMessage
                        id="tags.list.desc"
                        values={{
                            count,
                        }}
                    />
                </p>
            </div>
            <div className={css(felaRules.buttons)}>
                <Button
                    type="link"
                    onClick={() => {
                        updateModal.updateModal(true, {
                            tag: name,
                            count,
                        });
                    }}
                >
                    <FormattedMessage id="tags.list.update" />
                </Button>
                <Button
                    type="link"
                    color="red"
                    onClick={() => {
                        removeModal.updateModal(true, {
                            tag: name,
                            count,
                        });
                    }}
                >
                    <FormattedMessage id="tags.list.remove" />
                </Button>
            </div>
        </div>
    );
};
