import sliderHandle from 'assets/images/icons/slider_handle.svg';

import type { TRuleWithTheme } from 'styles/theme';

const sliderHandleSize = '1.5rem';

export const container: TRuleWithTheme = ({ theme }) => ({
    '&.ant-slider': {
        marginTop: '0.625rem',
        marginRight: 0,
        marginBottom: '0.625rem',
        marginLeft: 0,
    },

    '&.ant-slider .ant-slider-track': {
        height: '0.1875rem',

        border: 'none',

        backgroundColor: theme.colors.brightMagenta,
    },

    '&.ant-slider .ant-slider-rail': {
        height: 3,

        border: 'none',

        backgroundColor: theme.colors.lightGrey3,
    },
    '&.ant-slider .ant-slider-step': {
        height: 3,
        border: 'none',
    },

    '&.ant-slider .ant-slider-handle': {
        top: '-0.4375rem',

        width: sliderHandleSize,
        height: sliderHandleSize,
    },

    '&.ant-slider .ant-slider-handle::after': {
        top: 0,
        left: 0,

        width: sliderHandleSize,
        height: sliderHandleSize,

        backgroundImage: `url(${sliderHandle})`,

        boxShadow: 'none',
    },

    '&.ant-slider:hover .ant-slider-handle::after': {
        boxShadow: 'none',
    },

    '&.ant-slider .ant-slider-handle:hover::after': {
        top: 0,
        left: 0,

        width: sliderHandleSize,
        height: sliderHandleSize,

        boxShadow: 'none',
    },
    '&.ant-slider .ant-slider-handle:focus::after': {
        top: 0,
        left: 0,

        width: sliderHandleSize,
        height: sliderHandleSize,

        boxShadow: '0 0 0 5px rgba(0, 0, 0, 0.12)',
    },
});
