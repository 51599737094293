import type { SVGProps } from 'react';

export const GroupVisible = (props: SVGProps<SVGSVGElement>) => (
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
        <g fill="#2FD894" fillRule="evenodd">
            <path d="M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2m0 2c4.411 0 8 3.589 8 8s-3.589 8-8 8-8-3.589-8-8 3.589-8 8-8" />
            <path d="M10.535 16.492c-.492 0-.982-.187-1.356-.56l-2.944-2.944 1.414-1.414 2.886 2.886 5.919-5.919 1.414 1.414-5.976 5.976a1.914 1.914 0 0 1-1.357.561" />
        </g>
    </svg>
);
