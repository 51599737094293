import { Field } from 'react-final-form';
import { FormattedMessage } from 'react-intl';
import { identity } from 'lodash';

import { AccessRole } from 'types';

import { RadioButtonGroup, SettingsFormItem } from 'modules/forms';

import { Fields } from '../../constants';
import type { SettingFormValues } from '../../types';

export interface DefaultPermissionFieldProps {
    onChange: () => void;
}

const options = [AccessRole.VIEWER, AccessRole.EDITOR, AccessRole.VIEWER_AUTOFILL_ONLY];

export const DefaultPermissionField = ({ onChange }: DefaultPermissionFieldProps) => (
    <Field name={Fields.DEFAULT_PERMISSION} format={identity} parse={identity}>
        {({ input, meta }) => (
            <SettingsFormItem<SettingFormValues['defaultPermission']>
                meta={meta}
                label={<FormattedMessage id="settings.form.defaultPermission.label" />}
            >
                <RadioButtonGroup
                    name={input.name}
                    value={input.value}
                    onChange={e => {
                        input.onChange(e.target.value);
                        onChange();
                    }}
                    onBlur={input.onBlur}
                    onFocus={input.onFocus}
                    optionType="button"
                    options={options.map(value => ({
                        label: <FormattedMessage id={`settings.form.defaultPermission.${value}`} />,
                        value,
                    }))}
                />
            </SettingsFormItem>
        )}
    </Field>
);
