import { useFela } from 'react-fela';

import ackeeLogo from 'assets/images/ackee_logo.svg';

import * as felaRules from './AckeeLogo.rules';

export const AckeeLogo = () => {
    const { css } = useFela();

    return (
        <a className={css(felaRules.container)} href="https://www.ackee.cz" target="_blank" rel="noopener noreferrer">
            <img alt="Ackee s.r.o." src={ackeeLogo} />
        </a>
    );
};
