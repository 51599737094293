import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { actions as messagesActions } from 'modules/messages';
import { logger } from 'config/logger';

export interface UseCopyToClipboardParams {
    onSuccess?: () => void;
    onError?: () => void;
}

export const useCopyToClipboard = (params?: UseCopyToClipboardParams) => {
    const dispatch = useDispatch();
    const intl = useIntl();

    const handleCopy = useCallback(
        async (text: string) => {
            try {
                // eslint-disable-next-line compat/compat
                await navigator.clipboard.writeText(text);

                if (params?.onSuccess) {
                    params.onSuccess();
                } else {
                    dispatch(
                        messagesActions.displaySuccessMessage({
                            message: intl.formatMessage({ id: 'copied' }),
                            options: {
                                duration: 2,
                            },
                        }),
                    );
                }
            } catch (e) {
                if (params?.onError) {
                    params.onError?.();
                } else {
                    dispatch(
                        messagesActions.displayErrorMessage({
                            message: intl.formatMessage({ id: 'error.secrets.copy' }),
                            options: {
                                duration: 2,
                            },
                        }),
                    );
                }

                logger.error(e);
            }
        },
        [dispatch, intl, params],
    );

    return { handleCopy };
};
