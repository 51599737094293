import { IS_SAAS } from 'constants/index';

import { useAppSelector } from 'hooks/useAppSelector';

import { MAX_SAAS_SECRETS_COUNT, WARNING_SAAS_SECRETS_COUNT } from '../constants';

import { selectSecretsCount } from '../services/selectors';

export const useSaasCountReached = () => {
    const count = useAppSelector(selectSecretsCount);

    return {
        count,
        isWarning: IS_SAAS && count >= WARNING_SAAS_SECRETS_COUNT,
        isError: IS_SAAS && count >= MAX_SAAS_SECRETS_COUNT,
    };
};
