import { FormSpy, useForm, useFormState } from 'react-final-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useFela } from 'react-fela';
import { Prompt } from 'react-router';

import { Alert, Button } from 'modules/ui';

import type { SecretFormValues } from '../../types';

import { FileField } from '../SecretForm/FileField';
import { GroupsField } from '../SecretForm/GroupsField';
import { NameFieldWithFavicon } from '../SecretForm/NameFieldWithFavicon';
import { NoteField } from '../SecretForm/NoteField';
import { PasswordField } from '../SecretForm/PasswordField';
import { TOTPField } from '../SecretForm/TOTPField';
import { TagsField } from '../SecretForm/TagsField';
import { UsernameField } from '../SecretForm/UsernameField';
import { WebField } from '../SecretForm/WebField';
import { WhitelistField } from '../SecretForm/WhitelistField';
import { SecretSection } from '../SecretSection';

import * as felaRules from './NewSecretForm.rules';
import { useHandleBackButtonClick } from '../BackButton/hooks';
import { GroupsAndPermissionsInfoLabel } from '../GroupsAndPermissionsInfoLabel';

const subscription = {
    submitting: true,
    submitError: true,
    valid: true,
    pristine: true,
};

export const NewSecretForm = () => {
    const { css } = useFela();
    const { formatMessage } = useIntl();

    const { submit } = useForm();
    const { submitting, submitError, pristine } = useFormState<SecretFormValues>({
        subscription,
    });
    const handleCancel = useHandleBackButtonClick();

    const isSaasLimitReachedError = submitError?.id === 'error.secrets.create.saas-limit';

    return (
        <form
            className="ant-form ant-form-vertical"
            onSubmit={e => {
                e.preventDefault();
                e.stopPropagation();

                submit();
            }}
        >
            <div className={css(felaRules.sectionsContainer)}>
                {submitError && (
                    <Alert
                        message={typeof submitError === 'object' ? <FormattedMessage {...submitError} /> : submitError}
                        type="error"
                        customStyle={felaRules.errorAlert}
                        data-testid={isSaasLimitReachedError ? 'saas-limit-reached-alert' : 'submit-error-alert'}
                    />
                )}

                <SecretSection title="secret.sections.mainInformation.title">
                    <NameFieldWithFavicon type="new" />
                    <WebField />
                    <UsernameField />
                    <PasswordField />
                    <TOTPField />
                </SecretSection>
                <SecretSection title="secret.sections.permissions.title">
                    <GroupsAndPermissionsInfoLabel />
                    <GroupsField />
                    <WhitelistField />
                </SecretSection>
                <SecretSection title="secret.sections.details.title">
                    <TagsField />
                    <FileField />
                    <NoteField />
                </SecretSection>
            </div>

            <div className={css(felaRules.buttonsContainer)}>
                <Button
                    type="primary"
                    htmlType="submit"
                    loading={submitting}
                    disabled={submitting || isSaasLimitReachedError}
                >
                    <FormattedMessage id="secret.add" />
                </Button>
                <Button type="text" size="large" onClick={handleCancel}>
                    <FormattedMessage id="cancel" />
                </Button>
            </div>

            <Prompt when={!pristine && !submitting} message={formatMessage({ id: 'secret.form.unsavedWarning' })} />

            <FormSpy
                subscription={{ submitFailed: true, errors: true }}
                onChange={({ submitFailed, errors }) => {
                    const errorKeys = Object.keys(errors);

                    if (!submitFailed || !errorKeys.length) return;

                    const el = document.getElementById(errorKeys[0]);

                    if (el) {
                        el.scrollIntoView({ behavior: 'smooth', block: 'center' });
                    }
                }}
            />
        </form>
    );
};
