import { createAction } from '@reduxjs/toolkit';

import types from '../actionTypes';
import type { Settings, Order } from '../../types';

export const loadSettings = createAction(types.LOAD_SETTINGS);

export const loadSettingsSuccess = createAction(types.LOAD_SETTINGS_SUCCESS, (payload: Settings) => ({
    payload,
    meta: {
        lastSuccessAt: new Date().toISOString(),
    },
}));

export const loadSettingsFailure = createAction(types.LOAD_SETTINGS_FAILURE, (error: any) => ({
    payload: undefined,
    error,
}));

export const loadOrder = createAction(types.LOAD_ORDER);

export const loadOrderSuccess = createAction(types.LOAD_ORDER_SUCCESS, (payload: Order) => ({
    payload,
    meta: {
        lastSuccessAt: new Date().toISOString(),
    },
}));

export const loadOrderFailure = createAction(types.LOAD_ORDER_FAILURE, (error: any) => ({
    payload: undefined,
    error,
}));
