import { InfiniteScroll, Loadable } from 'modules/ui';

import { GroupsVisibilityListItem } from '../GroupsVisibilityListItem';
import { HideGroupModal } from '../HideGroupModal';
import { useGroups } from '../../hooks';

import * as felaRules from './GroupsVisibilityList.rules';

export interface GroupsVisibilityListProps {}

export const GroupsVisibilityList = (props: GroupsVisibilityListProps) => {
    const { dataWithoutAllUsersGroup, api, handleLoadMore } = useGroups();

    return (
        <>
            <Loadable showLoader={api.inProgress}>
                <InfiniteScroll
                    handleLoadMore={handleLoadMore}
                    isLoadingMore={api.inProgressNext}
                    customStyle={felaRules.container}
                >
                    {dataWithoutAllUsersGroup.map(group => (
                        <GroupsVisibilityListItem group={group} key={group.id} />
                    ))}
                </InfiniteScroll>
            </Loadable>
            <HideGroupModal />
        </>
    );
};
