import { debounce } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { useAppSelector } from 'hooks/useAppSelector';

import { fetchGroups, fetchGroupsNext } from '../services/actions';
import { selectGroups, selectGroupsApi } from '../services/selectors';
import { useGroupsWithLocalizedAllUsersGroup } from './useGroupsWithLocalizedAllUsersGroup';
import { ALL_USERS_GROUP_ID } from '../constants';

export const useGroups = () => {
    const [filter, setFilter] = useState('');

    const dispatch = useDispatch();

    const api = useAppSelector(selectGroupsApi);

    const data = useAppSelector(selectGroups);

    const dataWithLocalizedAllUsersGroup = useGroupsWithLocalizedAllUsersGroup(data);
    const dataWithoutAllUsersGroup = data.filter(group => group.id !== ALL_USERS_GROUP_ID);

    const handleLoadMore = () => {
        dispatch(fetchGroupsNext(filter));
    };

    const handleSearch = useMemo(() => debounce(setFilter, 250), []);

    useEffect(() => {
        dispatch(fetchGroups(filter));
    }, [dispatch, filter]);

    return { api, data, dataWithLocalizedAllUsersGroup, dataWithoutAllUsersGroup, handleLoadMore, handleSearch };
};
