import { FormattedMessage, useIntl } from 'react-intl';
import { Prompt } from 'react-router';
import { useFela } from 'react-fela';

import { Alert } from 'modules/ui';

import { useUpdateSecretForm } from '../../hooks/useUpdateSecretForm';

import { FileField } from '../SecretForm/FileField';
import { GroupsField } from '../SecretForm/GroupsField';
import { NameFieldWithFavicon } from '../SecretForm/NameFieldWithFavicon';
import { NoteField } from '../SecretForm/NoteField';
import { PasswordField } from '../SecretForm/PasswordField';
import { TOTPField } from '../SecretForm/TOTPField';
import { TagsField } from '../SecretForm/TagsField';
import { UsernameField } from '../SecretForm/UsernameField';
import { WebField } from '../SecretForm/WebField';
import { WhitelistField } from '../SecretForm/WhitelistField';
import { SecretSection } from '../SecretSection';
import { GroupsAndPermissionsInfoLabel } from '../GroupsAndPermissionsInfoLabel';

import * as felaRules from './UpdateSecretForm.rules';

export const UpdateSecretForm = () => {
    const { submitError, dirty, submitting } = useUpdateSecretForm();
    const { formatMessage } = useIntl();
    const { css } = useFela();

    return (
        <div className={css(felaRules.container)}>
            {submitError && (
                <Alert
                    message={typeof submitError === 'object' ? <FormattedMessage {...submitError} /> : submitError}
                    type="error"
                />
            )}
            <div className={css(felaRules.sectionsContainer)}>
                <NameFieldWithFavicon type="update" />
                <SecretSection title="secret.sections.password.title">
                    <WebField />
                    <UsernameField fullWidth={false} />
                    <PasswordField fullWidth={false} />
                    <TOTPField />
                </SecretSection>
                <SecretSection title="secret.sections.details.title">
                    <TagsField />
                    <FileField />
                    <NoteField />
                </SecretSection>
                <SecretSection title="secret.sections.permissions.title" customStyle={felaRules.rightColumn}>
                    <GroupsAndPermissionsInfoLabel />
                    <GroupsField />
                    <WhitelistField />
                </SecretSection>
            </div>

            <Prompt when={dirty && !submitting} message={formatMessage({ id: 'secret.form.unsavedWarning' })} />
        </div>
    );
};
