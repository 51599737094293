import { useFela } from 'react-fela';
import { FormattedMessage } from 'react-intl';
import { useRef } from 'react';

import { hasReadPermission } from 'utilities/permission';
import { Heading, Text } from 'modules/ui';
import { useBreakpoint } from 'hooks/useBreakpoint';

import type { Secret } from '../../../../types';

import { CopySecretTOTP } from '../CopySecretTOTP';
import { CopySecretValue } from '../CopySecretValue';
import { SecretItemTags } from '../SecretItemTags';
import { SharingIndicator } from '../SharingIndicator';
import { SecretFieldSection } from '../../../SecretFieldSection';
import { useSharingIndicatorVisibility } from '../../../../hooks';

import * as felaRules from './SecretItemInfo.rules';

export interface SecretItemInfoProps extends Secret {}

export const SecretItemInfo = ({
    id,
    name,
    hasTOTP,
    share,
    userPermissions,
    hasPassword,
    username,
    favicon,
    tags,
}: SecretItemInfoProps) => {
    const { css } = useFela();
    const isDesktop = useBreakpoint('sm');

    const TOTPFieldRef = useRef<HTMLDivElement>(null);

    const { getSharingIndicatorVisibility } = useSharingIndicatorVisibility();
    const shouldShowSharingIndicator = getSharingIndicatorVisibility({ share, userPermissions });

    const hasRead = hasReadPermission(userPermissions);

    return (
        <div className={css(felaRules.container)}>
            <div className={css(felaRules.topRowContainer)}>
                <div className={css(felaRules.titleWithFavicon)}>
                    {favicon && (
                        <img
                            className={css(felaRules.secretFavicon)}
                            src={`data:image/png;base64,${favicon}`}
                            alt={`${name} favicon`}
                        />
                    )}
                    <Heading color="blue">{name}</Heading>
                </div>

                {isDesktop && <SecretItemTags tags={tags} />}
            </div>

            <div className={css(felaRules.contentContainer)}>
                <div className={css(felaRules.properties)}>
                    {Boolean(username) && (
                        <SecretFieldSection
                            heading={<FormattedMessage id="secret.username" />}
                            customLabelStyle={felaRules.propertyLabel}
                        >
                            <Text size="regular" customStyle={felaRules.propertyText}>
                                {username}
                            </Text>
                            <CopySecretValue id={id} property="username" />
                        </SecretFieldSection>
                    )}
                    {hasPassword && (
                        <SecretFieldSection
                            heading={<FormattedMessage id="secret.password" />}
                            customLabelStyle={felaRules.propertyLabel}
                        >
                            <Text
                                color={hasRead ? 'foregroundsPrimary' : 'foregroundsTertiary'}
                                customStyle={felaRules.cryptedCharacters}
                            >
                                <FormattedMessage id="crypted.password" />
                                <CopySecretValue id={id} property="password" disabled={!hasRead} />
                            </Text>
                        </SecretFieldSection>
                    )}
                    {hasTOTP && (
                        <SecretFieldSection
                            heading={<FormattedMessage id="secret.totp" />}
                            customLabelStyle={felaRules.propertyLabel}
                            ref={TOTPFieldRef}
                        >
                            <CopySecretTOTP id={id} disabled={!hasRead} ref={TOTPFieldRef} />
                        </SecretFieldSection>
                    )}
                    {!isDesktop && <SecretItemTags tags={tags} />}
                </div>
                {shouldShowSharingIndicator && <SharingIndicator />}
            </div>
        </div>
    );
};
