import config from 'config';

import type {
    AccessToken,
    AccessTokenResponse,
    DemoAccessToken,
    DemoAccessTokenResponse,
    DemoSearchParams,
    ParsedState,
    SearchParams,
} from '../../types';

export const isDemoAccessToken = (token: AccessToken | DemoAccessToken): token is DemoAccessToken => config.demo.enable;

export const isDemoSearchParams = (params: DemoSearchParams | SearchParams): params is DemoSearchParams =>
    config.demo.enable;

export const isDemoAccessTokenResponse = (
    params: DemoAccessTokenResponse | AccessTokenResponse,
): params is DemoAccessTokenResponse => config.demo.enable;

export const parseState = (state: SearchParams['state']): ParsedState => {
    try {
        return JSON.parse(decodeURIComponent(state));
    } catch (e) {
        return {};
    }
};
