import type { SVGProps } from 'react';

export const New = (props: SVGProps<SVGSVGElement>) => (
    <svg fill="currentColor" width="12" height="12" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.5 5.5H6.5V2.5C6.5 2.224 6.276 2 6 2C5.724 2 5.5 2.224 5.5 2.5V5.5H2.5C2.224 5.5 2 5.724 2 6C2 6.276 2.224 6.5 2.5 6.5H5.5V9.5C5.5 9.776 5.724 10 6 10C6.276 10 6.5 9.776 6.5 9.5V6.5H9.5C9.776 6.5 10 6.276 10 6C10 5.724 9.776 5.5 9.5 5.5Z"
        />
    </svg>
);
