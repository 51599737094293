import { useTagsList } from '../../hooks/useTagsList';

import { TagsListItem } from '../TagsListItem';
import { UpdateTagModal } from '../UpdateTagModal';
import { RemoveTagModal } from '../RemoveTagModal';

export const TagsList = () => {
    const tags = useTagsList();

    return (
        <>
            <UpdateTagModal />
            <RemoveTagModal />
            {tags.map(({ name, count }) => (
                <TagsListItem key={name} name={name} count={count} />
            ))}
        </>
    );
};
