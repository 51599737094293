import type { SVGProps } from 'react';

export const Edit = (props: SVGProps<SVGSVGElement>) => (
    <svg fill="currentColor" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M17 8.586 15.414 7 17 5.414 18.586 7 17 8.586zm-8.207 8.207-.355.355-2.643 1.057 1.058-2.643.354-.355 1.5-1.5L14 8.414 15.586 10l-5.293 5.293-1.5 1.5zm11.914-10.5-3-3a.999.999 0 0 0-1.414 0l-9 9-1.5 1.5-.5.5a.986.986 0 0 0-.22.336H5.07l-2 5a1 1 0 0 0 1.3 1.3l5-2v-.001a1.01 1.01 0 0 0 .336-.221l.5-.5 1.5-1.5 9-9a.999.999 0 0 0 0-1.414z"
            fillRule="evenodd"
        />
    </svg>
);
