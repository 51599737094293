import { useFela } from 'react-fela';

import { ShareSecretButton } from '../../ShareSecretButton';
import { UpdateSecretButton } from '../../UpdateSecretButton';

import { container } from './DetailButtons.rules';

export const DetailButtons = () => {
    const { css } = useFela();

    return (
        <div className={css(container)}>
            <ShareSecretButton />
            <UpdateSecretButton />
        </div>
    );
};
