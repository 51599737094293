import type { TRuleWithTheme } from 'styles/theme';

export const container: TRuleWithTheme = () => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '2rem',
});

export const textContainer: TRuleWithTheme = () => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '1.25rem',
});

export const imageContainer: TRuleWithTheme = () => ({
    textAlign: 'center',

    marginTop: '-1rem',
    marginBottom: '-2rem',
});
