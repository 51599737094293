import { createElement } from 'react';
import type { ReactNode } from 'react';
import { useFela } from 'react-fela';

import type { Theme } from 'styles/theme';

import { container, HeadingSize } from './Heading.rules';

export interface HeadingProps {
    elementType?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5';
    color?: keyof Theme['colors'];
    size?: `${HeadingSize}`;
    customStyle?: typeof container;
    children: ReactNode;
}

export const Heading = ({
    elementType = 'h1',
    color,
    size = HeadingSize.REGULAR,
    customStyle,
    children,
}: HeadingProps) => {
    const { css } = useFela({ color, size });

    return createElement(elementType, { className: `Heading ${css(container, customStyle)}` }, children);
};
