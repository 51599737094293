import type { TRuleWithTheme } from 'styles/theme';

export const propertiesContainer: TRuleWithTheme = () => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',

    paddingTop: '1.5rem',

    min768: {
        gap: '1.5rem',
    },
});
