import isFuture from 'date-fns/isFuture';

import { useAppSelector } from 'hooks/useAppSelector';

import { selectWhatIsNewValidity } from '../services/selectors';

export const useWhatIsNewVisible = () => {
    const validUntil = useAppSelector(selectWhatIsNewValidity);

    return isFuture(validUntil);
};
