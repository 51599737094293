import type { TRuleWithTheme } from 'styles/theme';

export const container: TRuleWithTheme = () => ({
    flex: 1,

    overflow: 'auto',

    display: 'flex',
    flexDirection: 'column',
});

export const noticeContainer: TRuleWithTheme = () => ({
    flexShrink: 0,
    flexGrow: 0,
    flexBasis: 'auto',
});

export const notice: TRuleWithTheme<{
    visible: boolean;
}> = ({ theme, visible }) => ({
    overflow: 'hidden',

    display: visible ? 'flex' : 'none',
    alignItems: 'center',
    justifyContent: 'space-between',

    width: '100%',

    paddingTop: '1rem',
    paddingLeft: '1.25rem',
    paddingBottom: '1rem',
    paddingRight: '0.75rem',

    willChange: 'height',

    background: theme.colors.blue,

    min768: {
        paddingTop: '0.75rem',
        paddingLeft: '2.5rem',
        paddingBottom: '0.75rem',
    },

    min1440: {
        paddingLeft: '5rem',
    },
});

export const content: TRuleWithTheme = () => ({
    flex: 1,

    overflow: 'auto',

    position: 'relative',

    display: 'flex',
    flexDirection: 'column',

    min1024: {
        flexDirection: 'row',
    },
});

export const text: TRuleWithTheme = () => ({
    display: 'flex',
    alignItems: 'center',
});

export const buyButton: TRuleWithTheme = () => ({
    marginLeft: '1.25rem',
});

export const closeButton: TRuleWithTheme = () => ({
    flexShrink: 0,

    marginLeft: '0.75rem',

    min768: {
        marginLeft: '2.5rem',
    },
});
