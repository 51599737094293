import { useFela } from 'react-fela';
import { Link } from 'react-router-dom';
import type { ReactNode } from 'react';

import { Heading, Icon, Text } from 'modules/ui';
import { config } from 'config/config';

import * as felaRules from './SettingsDetailLayout.rules';

export interface SettingsDetailLayoutProps {
    title: string;
    description?: string;
    children: ReactNode;
}

export const SettingsDetailLayout = ({ title, description, children }: SettingsDetailLayoutProps) => {
    const { css } = useFela();

    return (
        <div className={css(felaRules.container)}>
            <div className={css(felaRules.titleContainer)}>
                <Heading elementType="h1" color="black" customStyle={felaRules.title}>
                    <Link to={config.routes.settings} className={css(felaRules.link)}>
                        <Icon type="dropdown" />
                    </Link>
                    {title}
                </Heading>
                {description && (
                    <Text size="medium" color="brownGrey2" customStyle={felaRules.description}>
                        {description}
                    </Text>
                )}
            </div>
            {children}
        </div>
    );
};
