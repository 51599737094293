import type { TRuleWithTheme } from 'styles/theme';

export const container: TRuleWithTheme = () => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '0.25rem',

    paddingTop: '0.25rem',

    min1024: {
        paddingTop: '1rem',
    },
});
