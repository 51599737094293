import { reducer as application } from 'modules/application';
import { petrusReducer as petrus } from 'modules/auth';
import { reducer as backendVersion } from 'modules/backend-version';
import { reducer as changelog } from 'modules/changelog';
import { reducer as translate } from 'modules/localizations';
import { reducer as messages } from 'modules/messages';
import { reducer as modals } from 'modules/modals';
import { reducer as network } from 'modules/network';
import { reducer as secrets } from 'modules/secrets';
import { reducer as userGuide } from 'modules/user-guide';
import { reducer as wakeUp } from 'modules/wake-up';

import { IS_DEMO, IS_SAAS } from 'constants/index';

import api from './api';
import entities from './entities';

type Reducers = {
    translate: typeof translate;
    petrus: typeof petrus;
    secrets: typeof secrets;
    modals: typeof modals;
    messages: typeof messages;
    entities: typeof entities;
    api: typeof api;
    network: typeof network;
    changelog: typeof changelog;
    wakeUp?: typeof wakeUp;
    application: typeof application;
    backendVersion: typeof backendVersion;
    userGuide: typeof userGuide;
};

// NOTE:
// Do not use combineReducers() here,
// export reducers as plain object, not as a function.
const reducers: Reducers = {
    translate,
    petrus,
    secrets,
    modals,
    messages,
    entities,
    api,
    network,
    changelog,
    application,
    backendVersion,
    userGuide,
};

if (!IS_DEMO && !IS_SAAS) {
    reducers.wakeUp = wakeUp;
}

export default reducers;
