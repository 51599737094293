import { useDispatch } from 'react-redux';

import { login } from '../services/actions';

export function useLogin() {
    const dispatch = useDispatch();

    const handleLogin = () => {
        dispatch(login());
    };

    return { handleLogin };
}
