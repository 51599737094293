import type { PetrusConfig } from '@ackee/petrus';

import type { AccessTokenResponse, DemoAccessTokenResponse } from '../../types';

import { isDemoAccessTokenResponse } from '../utils';

export const enforceRefreshTokenScheme: PetrusConfig['oAuth']['enforceRefreshTokenScheme'] =
    function enforceRefreshTokenScheme(params: DemoAccessTokenResponse | AccessTokenResponse) {
        if (isDemoAccessTokenResponse(params)) {
            return null;
        }

        return {
            token: params.refresh_token,
        };
    };
