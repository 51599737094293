import { createReducer } from '@reduxjs/toolkit';

import type { Message } from '../../types';

import { addMessageResolve, clearMessages, hideMessage, removeMessage } from '../actions';

type ByIdState = {
    [key: string]: Message;
};

const initialState: ByIdState = {};

export const byIdReducer = createReducer(initialState, builder => {
    builder.addCase(addMessageResolve, (state, action) => {
        const {
            payload,
            meta: { id },
        } = action;

        state[id] = {
            id,
            visible: true,
            ...payload,
        };
    });

    builder.addCase(hideMessage, (state, action) => {
        const { id } = action.meta;

        if (state[id]?.visible) {
            state[id].visible = false;
        }
    });

    builder.addCase(removeMessage, (state, action) => {
        const { id } = action.meta;

        if (state[id]) {
            delete state[id];
        }
    });

    builder.addCase(clearMessages, () => initialState);
});
