import type { TRuleWithTheme } from 'styles/theme';

export const container: TRuleWithTheme = ({ theme }) => ({
    paddingTop: '3rem',
    paddingBottom: '3rem',

    // To easily remove a border in listContainer@SecurityAuditSecretsPage.rules.ts
    borderTopWidth: 1,
    borderTopStyle: 'solid',
    borderTopColor: theme.colors.white,
    marginTop: -1,
    marginBottom: 0,

    min768: {
        paddingTop: '9.5rem',
    },
});
