import type { TRuleWithTheme } from 'styles/theme';

export const container: TRuleWithTheme = ({ theme }) => ({
    backgroundColor: theme.colors.backgroundsSecondary,

    top: 0,

    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',

    padding: '0.25rem',

    min768: {
        minWidth: '20rem',
    },
});

export const generateButton: TRuleWithTheme = ({ theme }) => ({
    maxWidth: 'fit-content',
});
