import {
    createFetchAction,
    createFetchNextAction,
    createFetchRequestAction,
    createFetchSuccessAction,
    createFetchFailureAction,
    createFetchCancelAction,
} from 'services/utilities/infiniteApiReducer';

import type { WhitelistUser } from '../types';

import types from './actionTypes';

export const fetchWhitelist = createFetchAction(types.FETCH_WHITELIST);

export const fetchWhitelistNext = createFetchNextAction(types.FETCH_WHITELIST_NEXT);

export const fetchWhitelistRequest = createFetchRequestAction(types.FETCH_WHITELIST_REQUEST);

export const fetchWhitelistSuccess = createFetchSuccessAction<WhitelistUser>(types.FETCH_WHITELIST_SUCCESS);

export const fetchWhitelistFailure = createFetchFailureAction(types.FETCH_WHITELIST_FAILURE);

export const fetchWhitelistCancel = createFetchCancelAction(types.FETCH_WHITELIST_CANCEL);
