import type { ReactNode } from 'react';
import { useDispatch } from 'react-redux';
import { Form } from 'react-final-form';
import { useIntl } from 'react-intl';

import { useAppSelector } from 'hooks/useAppSelector';

import { Loader } from 'modules/ui';
import { handleFormSubmit } from 'modules/forms';

import type { SettingFormValues } from '../../types';

import { updateSettings } from '../../services/actions';
import { selectSettingsApi, selectSetttingsFormInitialValues } from '../../services/selectors';

export interface SettingsFormProviderProps {
    children: ReactNode;
}

export const SettingsFormProvider = ({ children }: SettingsFormProviderProps) => {
    const intl = useIntl();
    const dispatch = useDispatch();

    const api = useAppSelector(selectSettingsApi);
    const data = useAppSelector(selectSetttingsFormInitialValues);

    if (api.inProgress) {
        return <Loader />;
    }

    return (
        <Form<SettingFormValues>
            initialValues={data}
            initialValuesEqual={() => true}
            onSubmit={handleFormSubmit((values: SettingFormValues, actions) => {
                dispatch(updateSettings(values, actions));
            })}
        >
            {() => (
                <form
                    aria-label={intl.formatMessage({
                        id: 'settings.form.title',
                    })}
                >
                    {children}
                </form>
            )}
        </Form>
    );
};
