import { useFela } from 'react-fela';

import { Text } from 'modules/ui';

import type { Group } from '../../types';

import { GroupItem } from '../GroupItem';
import { useGroupsWithLocalizedAllUsersGroup } from '../../hooks';

import * as felaRules from './Groups.rules';

const defaultGroups = [];

export interface GroupsProps {
    groups: Group[];
}

export const Groups = ({ groups = defaultGroups }) => {
    const { css } = useFela();

    const groupsWithLocalizedAllUsersGroup = useGroupsWithLocalizedAllUsersGroup(groups);

    return (
        <div className={css(felaRules.container)}>
            <Text size="small" elementType="ul" customStyle={felaRules.list}>
                {groupsWithLocalizedAllUsersGroup.map(group => (
                    <GroupItem key={group.id} {...group} />
                ))}
            </Text>
        </div>
    );
};
