import type { TRuleWithTheme } from 'styles/theme';

export const container: TRuleWithTheme = () => ({
    max768: {
        display: 'block',
        ':not(:first-child)': {
            marginTop: '0.25rem',
        },
    },
});

export const icon: TRuleWithTheme = () => ({
    position: 'relative',
    top: '0.125rem',

    display: 'inline-block',

    marginRight: '0.25rem',
});
