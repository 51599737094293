import { Field } from 'react-final-form';
import { identity } from 'lodash';
import { FormattedMessage } from 'react-intl';

import { RadioButtonGroup, SettingsFormItem } from 'modules/forms';

import { toLowerCase } from 'utilities/string';

import type { Entries } from 'types/utils';

import { Fields } from '../../../constants';
import { Delimiter, ImportFormValues } from '../../../types';

interface DelimiterFieldProps {}

const options = Object.entries(Delimiter) as Entries<typeof Delimiter>;

export const DelimiterField = (props: DelimiterFieldProps) => (
    <Field name={Fields.DELIMITER} format={identity} parse={identity} initialValue={Delimiter.COMMA}>
        {({ input, meta }) => (
            <SettingsFormItem<ImportFormValues['delimiter']>
                meta={meta}
                label={<FormattedMessage id="import.form.delimiter.label" />}
            >
                <RadioButtonGroup
                    name={input.name}
                    value={input.value}
                    onChange={e => {
                        input.onChange(e.target.value);
                    }}
                    onBlur={input.onBlur}
                    onFocus={input.onFocus}
                    optionType="button"
                    options={options.map(([key, value]) => ({
                        label: <FormattedMessage id={`import.form.delimiter.${toLowerCase(key)}`} />,
                        value,
                    }))}
                />
            </SettingsFormItem>
        )}
    </Field>
);
