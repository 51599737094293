import { useFela } from 'react-fela';
import { FormattedDate, FormattedMessage } from 'react-intl';

import * as felaRules from './AgeIndicator.rules';

export interface AgeIndicatorProps {
    date: string;
}

export const AgeIndicator = ({ date }: AgeIndicatorProps) => {
    const { css } = useFela();

    return (
        <span className={css(felaRules.container)}>
            <FormattedMessage id="securityAudit.age.date" values={{ date: <FormattedDate value={date} /> }} />
        </span>
    );
};
