import { createAction } from '@reduxjs/toolkit';

import type { Secret } from '../../types';
import types from '../actionTypes';

export const ignoreReportSecret = createAction(types.IGNORE_REPORT_SECRET, (id: Secret['id']) => ({
    meta: { id },
    payload: undefined,
}));

export const ignoreReportSecretSuccess = createAction(types.IGNORE_REPORT_SECRET_SUCCESS, (id: Secret['id']) => ({
    meta: { id, lastSuccessAt: new Date().toISOString() },
    payload: undefined,
}));

export const ignoreReportSecretFailure = createAction(
    types.IGNORE_REPORT_SECRET_FAILURE,
    (id: Secret['id'], error: any) => ({
        meta: { id },
        payload: undefined,
        error,
    }),
);

export const ignoreReportSecretReset = createAction(types.IGNORE_REPORT_SECRET_RESET, (id: Secret['id']) => ({
    meta: { id },
    payload: undefined,
}));
