import { useFela } from 'react-fela';
import { FormattedMessage } from 'react-intl';

import { ErrorCode } from 'services/utilities/isApiError';
import { Icon } from 'modules/ui';

import * as felaRules from './LoginErrorMessage.rules';

export interface LoginErrorMessageProps {
    errorCode: string;
}

export const LoginErrorMessage = ({ errorCode }) => {
    const { css } = useFela(felaRules);

    switch (errorCode) {
        case ErrorCode.AUTH_CLIENT_IS_NOT_CONFIGURED:
            return (
                <section role="alert" className={css(felaRules.message)}>
                    <Icon type="warning" />
                    <p className={css(felaRules.text)}>
                        <FormattedMessage id="error.auth.clientNotConfigured" />
                    </p>
                </section>
            );

        default:
            return null;
    }
};
