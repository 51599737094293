import { useFela } from 'react-fela';
import { FormattedMessage } from 'react-intl';
import type { ReactNode } from 'react';

import alertSrc from 'assets/images/subscription_alert.svg';
import { Heading, Text } from 'modules/ui';

import type { FormattedMessageProps } from 'modules/localizations/translations/types';

import { Modal } from 'modules/modals';

import * as felaRules from './SubscriptionAlertModal.rules';

export interface SubscriptionAlertModalProps {
    title: FormattedMessageProps;
    text: FormattedMessageProps;
    endContent: ReactNode;
}

export const SubscriptionAlertModal = ({ title, text, endContent }: SubscriptionAlertModalProps) => {
    const { css } = useFela();

    return (
        <Modal open width={520}>
            <div className={css(felaRules.container)}>
                <div className={css(felaRules.imageContainer)}>
                    <img src={alertSrc} alt="" />
                </div>
                <div className={css(felaRules.textContainer)}>
                    <Heading elementType="h1" color="foregroundsPrimary">
                        <FormattedMessage {...title} />
                    </Heading>
                    <Text preserveNewLines color="foregroundsTertiary">
                        <FormattedMessage {...text} />
                    </Text>
                </div>
                <div>{endContent}</div>
            </div>
        </Modal>
    );
};
