import { useDispatch } from 'react-redux';

import { useAppSelector } from 'hooks/useAppSelector';

import { deleteSecrets } from '../services/actions';
import { selectDeleteSecretsApi } from '../services/selectors';

export const useDeleteSecrets = () => {
    const dispatch = useDispatch();

    const api = useAppSelector(selectDeleteSecretsApi);

    const handleDelete = () => {
        dispatch(deleteSecrets());
    };

    return {
        api,
        handleDelete,
    };
};
