import { Form } from 'react-final-form';
import { useFela } from 'react-fela';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { TagManager } from 'services/utilities';

import { Button } from 'modules/ui';
import { handleFormSubmit } from 'modules/forms';

import type { ImportFormValues } from '../../types';
import { Fields } from '../../constants';

import { startImport } from '../../services/actions';

import { SecretsField } from './SecretsField';
import { SecretsFormatField } from './SecretsFormatField';
import { DelimiterField } from './DelimiterField';
import { ReplaceSecretsField } from './ReplaceSecretsField';

import * as felaRules from './ImportForm.rules';

export const ImportForm = () => {
    const { css } = useFela();
    const intl = useIntl();

    const dispatch = useDispatch();

    return (
        <Form<ImportFormValues>
            onSubmit={async (values, form) => {
                const errors = await handleFormSubmit<ImportFormValues>((values, actions) => {
                    TagManager.push('used_import_export');
                    dispatch(startImport(values, actions));
                })(values);

                if (errors) {
                    Object.keys(errors).forEach(field => {
                        if (typeof errors[field] === 'object') {
                            errors[field] = intl.formatMessage(errors[field]);
                        } else if (typeof errors[field] === 'string') {
                            errors[field] = intl.formatMessage({ id: errors[field] });
                        }
                    });
                }

                form.restart();

                return errors;
            }}
            validate={values => {
                const errors: Partial<Record<keyof ImportFormValues, any>> = {};
                if (!values[Fields.SECRETS]) {
                    errors[Fields.SECRETS] = intl.formatMessage({ id: 'form.error.required' });
                }
                return errors;
            }}
        >
            {({ handleSubmit, submitting }) => (
                <form onSubmit={handleSubmit}>
                    <SecretsField />
                    <div className={css(felaRules.optionsWrapper)}>
                        <SecretsFormatField />
                        <DelimiterField />
                    </div>
                    <div className={css(felaRules.buttonWrapper)}>
                        <ReplaceSecretsField />
                        <Button type="primary" htmlType="submit" loading={submitting} disabled={submitting}>
                            <FormattedMessage id="import.form.button" />
                        </Button>
                    </div>
                </form>
            )}
        </Form>
    );
};
