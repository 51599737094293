import type { TRuleWithTheme } from 'styles/theme';

export const label: TRuleWithTheme = ({ theme }) => ({
    color: theme.colors.black,
});

export const formItem: TRuleWithTheme = () => ({
    '&.ant-form-item': {
        flexGrow: 1,

        marginLeft: '1.5rem',
        marginBottom: 0,

        min768: {
            marginBottom: 0,
        },
    },
});
