import { FormattedMessage } from 'react-intl';

import { config } from 'config/config';

import { Link } from 'modules/ui';

import { link } from './GdprLabel.rules';

export function GdprLabel() {
    return (
        <>
            <FormattedMessage id="auth.login.gdpr.label.text" />
            <Link type="native" href={config.demo.gdpr} customStyle={link}>
                <FormattedMessage id="auth.login.gdpr.label.link" />
            </Link>
        </>
    );
}
