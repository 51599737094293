import { entitiesSelector } from '@ackee/petrus';
import { createSelector } from '@reduxjs/toolkit';

import type { AppState } from 'types';

const selectPetrusEntities = (state: AppState) => entitiesSelector(state);

export const selectIdProp = (_: AppState, { id }: { id: string }) => id;

export const selectIdsProp = (_: AppState, { ids }: { ids: string[] }) => ids;

export const selectEntities = (state: AppState) => state.entities;

export const authUser = createSelector([selectPetrusEntities], entities => entities.user);
export const authTokens = createSelector([selectPetrusEntities], entities => entities.tokens);

export const isAuthUserAdmin = createSelector([authUser], authUser => Boolean(authUser?.isAdmin));

export const selectRouting = (state: AppState) => state.router;
