import { useContext } from 'react';

import { SecretDetailContext } from '../context';

export function useSecretDetailContext() {
    const context = useContext(SecretDetailContext);

    if (!context) {
        throw new Error('useSecretDetailContext must be used within a SecretDetailProvider');
    }

    return context;
}
