import type { TRuleWithTheme } from 'styles/theme';

export const container: TRuleWithTheme = () => ({
    display: 'block',

    min768: {
        display: 'inline-block',
        marginRight: '1rem',
    },
});
