import type { TRuleWithTheme } from 'styles/theme';

export const select: TRuleWithTheme = ({ theme }) => ({
    '&.ant-select.ant-select-single .ant-select-selector': {
        paddingLeft: '0.75rem',
        paddingRight: '0.5rem',

        borderRadius: '0.5rem',
        border: `1px solid ${theme.colors.black}`,
    },

    '&.ant-select.ant-select-single .ant-select-selector:hover': {
        border: `1px solid ${theme.colors.black}`,
    },
});

export const formItem: TRuleWithTheme = ({ theme }) => ({
    '&.ant-form-item': {
        marginBottom: 0,

        min768: {
            marginBottom: 0,
        },
    },
    '& .ant-form-item-label': {
        paddingLeft: 0,

        color: theme.colors.black,
    },
});

export const label: TRuleWithTheme = ({ theme }) => ({
    color: theme.colors.black,
});

export const card: TRuleWithTheme = ({ theme: { colors } }) => ({
    padding: '1rem',

    marginLeft: '-1rem',
    marginBottom: '1rem',
    marginRight: '-1rem',

    backgroundColor: colors.lime,
    borderRadius: '0.5rem',

    '& .ant-select': {
        width: '100%',
    },
});

export const title: TRuleWithTheme = ({ theme: { colors } }) => ({
    marginBottom: '1rem',
});
