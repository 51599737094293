import { put, takeEvery } from 'redux-saga/effects';

import { config } from 'config/config';
import { logger } from 'config/logger';
import { api } from 'config/antonio';

import { getSecretTOTPSuccess, getSecretTOTPFailure, getSecretTOTP } from '../actions';

import type { TOTP } from '../../types';

function* handler({ meta }: ReturnType<typeof getSecretTOTP>) {
    try {
        const { data } = yield* api.get<TOTP[]>(config.api.totp, {
            uriParams: {
                id: meta.id,
            },
        });

        yield put(getSecretTOTPSuccess(meta.id, data));
    } catch (e) {
        logger.error(e);

        yield put(getSecretTOTPFailure(meta.id, e));
    }
}

export default function* getSecretTOTPSaga() {
    yield takeEvery(getSecretTOTP.toString(), handler);
}
