import type { TRuleWithTheme } from 'styles/theme';

export const buttons: TRuleWithTheme = () => ({
    display: 'flex',
    flexWrap: 'wrap',

    gap: '1.38rem',
});

export const icon: TRuleWithTheme = () => ({
    marginLeft: 0,
    verticalAlign: 'bottom',
});

export const emphasizedText: TRuleWithTheme = ({ theme }) => ({
    color: theme.colors.brightMagenta,
});

export const note: TRuleWithTheme = ({ theme }) => ({
    color: theme.colors.foregroundsTertiary,
    fontStyle: 'italic',
    marginTop: '0.75rem',
});
