import { isEmpty } from 'lodash';
import type { IntlShape } from 'react-intl';

import type { SubmitErrors } from 'modules/forms';

import { TOTP_VALUE_PLACEHOLDER } from '../constants';
import type { SecretFormValues } from '../types';

import { TOTPError, isTOTPError } from '../components/TOTPFromQRCodeButton';
import { getFileFromBase64 } from './files';

export const TOTP_REGEX =
    /^([oO][tT][pP][aA][uU][tT][hH]:\/\/[tT][oO][tT][pP]\/.*[sS][eE][cC][rR][eE][tT]=[a-zA-Z1-7]{16,}.*|[a-zA-Z1-7]{16,})$/;

const isValidTOTP = (value: Exclude<SecretFormValues['TOTP'], TOTPError>) => {
    if (value === TOTP_VALUE_PLACEHOLDER) {
        return true;
    }

    const valueWithoutSpaces = value.replace(/\s/g, '');

    return TOTP_REGEX.test(valueWithoutSpaces);
};

export const MAX_FILE_SIZE = process.env.REACT_APP_SAAS === 'true' ? 30 * 1024 : 512 * 1024; // 30KiB for SAAS, 512KiB for clients

const isValidFileSize = async (value: SecretFormValues['file']) => {
    const file = await getFileFromBase64(value);

    return file.size <= MAX_FILE_SIZE;
};

export const validateSecretFormFactory = (intl: IntlShape) =>
    async function validateSecretForm(values: SecretFormValues) {
        const errors: SubmitErrors<SecretFormValues> = {};

        if (isEmpty(values.name)) {
            errors.name = intl.formatMessage({ id: 'form.error.required' });
        }

        if (values.TOTP) {
            // NOTE: I haven't found better way to set an error for specfic field directly in `TOTPField` component.
            if (isTOTPError(values.TOTP)) {
                errors.TOTP = intl.formatMessage({ id: `form.error.TOTP.qrcode.${values.TOTP.code}` });
            } else if (!isValidTOTP(values.TOTP)) {
                errors.TOTP = intl.formatMessage({ id: 'form.error.TOTP.general' });
            }
        }

        if (values.file && !(await isValidFileSize(values.file))) {
            errors.file = intl.formatMessage(
                { id: 'form.error.fileSize' },
                {
                    maxSize: process.env.REACT_APP_SAAS === 'true' ? '30KiB' : '512KiB',
                },
            );
        }

        return errors;
    };
