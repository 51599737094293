import type { TRuleWithTheme } from 'styles/theme';

export const container: TRuleWithTheme = () => ({
    display: 'flex',
    alignItems: 'center',

    gap: '0.75rem',

    min768: {
        gap: '1.25rem',
    },
});
