import { put, takeEvery } from 'redux-saga/effects';

import { config } from 'config/config';
import { logger } from 'config/logger';
import { api } from 'config/antonio';

import { isSaasLimitReachedError } from 'services/utilities/isApiError';

import { actions as modalsActions, StaticModal } from 'modules/modals';
import { actions as messagesActions } from 'modules/messages';

import { isNonIgnorableImportError } from '../../utilities/isNonIgnorableImportError';
import { Fields } from '../../constants';

import { importValidationRequest, importValidationRequestFailure, importValidationRequestSuccess } from '../actions';

function* importValidationHandler({ payload }: ReturnType<typeof importValidationRequest>) {
    try {
        const formData = new FormData();
        formData.append(Fields.SECRETS, payload.secrets);
        formData.append(Fields.SECRETS_FORMAT, payload.format);
        formData.append(Fields.DELIMITER, payload.delimiter);

        yield* api.post(config.api.importValidation, formData);

        yield put(importValidationRequestSuccess());
    } catch (e) {
        const statusCode = Number(e.response?.status) || 500;

        if (statusCode >= 500) {
            logger.error(e);

            yield put(
                messagesActions.displayErrorMessage({
                    message: { id: 'error.import-export.invalidImportFile' },
                }),
            );
            yield put(importValidationRequestFailure());

            return;
        }

        if (isSaasLimitReachedError(e)) {
            yield put(importValidationRequestFailure());

            yield put(modalsActions.setModalVisibility(StaticModal.IMPORT_LIMIT_REACHED, true, e.data.errorData));

            return;
        }

        if (isNonIgnorableImportError(e)) {
            yield put(importValidationRequestFailure(true));

            return;
        }

        // If we can ignore the validation error, open the validation modal and ask for explicit approval/disapproval from the user.
        yield put(modalsActions.setModalVisibility(StaticModal.IMPORT_VALIDATION, true));
    }
}

export default function* importValidationSaga() {
    yield takeEvery(importValidationRequest.toString(), importValidationHandler);
}
