import { combineReducers } from 'redux';

import { createInfiniteApiReducer } from 'services/utilities/infiniteApiReducer';

import { fetchWhitelistRequest, fetchWhitelistFailure, fetchWhitelistSuccess } from '../actions';

const fetch = createInfiniteApiReducer({
    request: fetchWhitelistRequest,
    success: fetchWhitelistSuccess,
    failure: fetchWhitelistFailure,
});

export const apiReducer = combineReducers({
    fetch,
});
