import type { TRuleWithTheme } from 'styles/theme';

export const container: TRuleWithTheme = () => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',

    width: '100%',
    height: '100%',
    minHeight: '100vh',

    paddingTop: '2rem',
    paddingLeft: '2rem',
    paddingBottom: '3rem',
    paddingRight: '2rem',

    min768: {
        paddingTop: '3rem',
        paddingLeft: '4rem',
        paddingRight: '4rem',
    },
});

export const logo: TRuleWithTheme = () => ({
    alignSelf: 'flex-start',

    marginBottom: '2rem',

    paddingBottom: '1.625rem',

    '::after': {
        height: '1.25rem',

        paddingLeft: '0.75rem',
        paddingBottom: 2,
        paddingRight: '0.75rem',

        fontSize: '0.875rem',

        borderRadius: '0.625rem',
    },
});

export const wrapper: TRuleWithTheme = () => ({
    marginBottom: '2.5rem',

    '> .Heading': {
        marginBottom: '0.625rem',

        fontSize: '2.625rem',
        fontWeight: 800,
        lineHeight: 'normal',
    },

    '> .Text': {
        marginBottom: '0.5rem',
    },

    min768: {
        paddingLeft: '10%',
        paddingRight: '10%',

        '> .Heading': {
            fontSize: '5rem',
        },

        '> .Text': {
            marginBottom: '0.5rem',

            fontSize: '1.25rem',
            lineHeight: '2rem',
        },
    },
});

export const infoWrapper: TRuleWithTheme = () => ({
    marginRight: '6rem',
});

const columnGap = '1rem';
const rowGap = '3rem';

export const info: TRuleWithTheme = () => ({
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    alignItems: 'flex-start',

    marginRight: `-${rowGap}`,
    marginBottom: `-${columnGap}`,

    min768: {
        flexDirection: 'row',
        alignItems: 'flex-end',
    },

    '& > *': {
        marginRight: rowGap,
        marginBottom: columnGap,
    },
});

export const version: TRuleWithTheme = () => ({
    color: 'inherit',
});

export const emergency: TRuleWithTheme = () => ({
    position: 'relative',

    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',

    marginRight: '0.5rem',

    verticalAlign: 'middle',
});

export const emergencySettingsIcon: TRuleWithTheme = ({ theme }) => ({
    position: 'absolute',
    bottom: 1,
    right: -2,

    padding: 1,

    backgroundColor: theme.colors.white,
    borderRadius: '50%',
});
