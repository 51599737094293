import type { TRuleWithTheme } from 'styles/theme';

export const container: TRuleWithTheme = () => ({
    display: 'block',

    fontSize: '0.875rem',
    lineHeight: '1.43em',
    letterSpacing: '0.0143em',

    color: 'inherit',
});
