import { createRenderer as create } from 'fela';

import { theme } from 'styles';

import { config } from '../config';

export const createRenderer = () => {
    const renderer = create(config);

    return { renderer, theme };
};
