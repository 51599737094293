import type { SVGProps } from 'react';

export const Logout = (props: SVGProps<SVGSVGElement>) => (
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
        <g fill="none" fillRule="evenodd">
            <g transform="translate(9 6)">
                <mask id="a" fill="#fff">
                    <path d="M0 .267h12v11.467H0z" />
                </mask>
                <path
                    d="M11.4 4.642 6.68.267 5.32 1.734 8.844 5H0v2h8.844L5.32 10.267l1.36 1.467 4.72-4.375c.381-.353.6-.848.6-1.36a1.86 1.86 0 0 0-.6-1.357"
                    fill="currentColor"
                    mask="url(#a)"
                />
            </g>
            <path d="M5 5h8V3H5c-1.103 0-2 .897-2 2v14c0 1.103.897 2 2 2h8v-2H5V5z" fill="currentColor" />
        </g>
    </svg>
);
