import { useFela } from 'react-fela';
import { FormattedMessage } from 'react-intl';

import { Heading, Tabs, Text } from 'modules/ui';

import type { ViewFilter } from '../../types';

import { SecretsViewUserSelect } from '../SecretsViewUserSelect';
import { SecretsViewGroupSelect } from '../SecretsViewGroupSelect';

import * as felaRules from './SecretsViewForm.rules';

export interface SecretsViewFormProps {
    viewFilter: ViewFilter;
    onChange: (viewFilter: ViewFilter) => void;
    onReset: () => void;
}

export const SecretsViewForm = ({ viewFilter, onChange, onReset }: SecretsViewFormProps) => {
    const { css } = useFela();

    return (
        <>
            <Heading color="black" customStyle={felaRules.title}>
                <FormattedMessage id="secrets.view.modal.title" />
            </Heading>
            <Text color="brownGrey" size="small" customStyle={felaRules.info}>
                <FormattedMessage id="secrets.view.modal.info" />
            </Text>
            <div className={css(felaRules.container)}>
                <Tabs
                    tabBarGutter={0}
                    destroyInactiveTabPane
                    onTabClick={() => {
                        onReset();
                    }}
                    items={[
                        {
                            key: 'user',
                            label: <FormattedMessage id="secrets.view.modal.tabs.user" />,
                            children: <SecretsViewUserSelect viewFilter={viewFilter} onChange={onChange} />,
                        },
                        {
                            key: 'group',
                            label: <FormattedMessage id="secrets.view.modal.tabs.group" />,
                            children: <SecretsViewGroupSelect viewFilter={viewFilter} onChange={onChange} />,
                        },
                    ]}
                />
            </div>
        </>
    );
};
