import { createReducer } from '@reduxjs/toolkit';

import type { WhitelistUser, WhitelistUserById } from '../../types';
import { fetchWhitelistSuccess } from '../actions';

export interface WhitelistState {
    byIds: WhitelistUserById;
    ids: WhitelistUser['id'][];
    filter?: string;
}

const initialState: WhitelistState = {
    byIds: {},
    ids: [],
};

export const entitiesReducer = createReducer(initialState, builder => {
    builder.addCase(fetchWhitelistSuccess, (state, action) => {
        const { byIds, ids } = action.payload;

        state.byIds = {
            ...state.byIds,
            ...byIds,
        };

        if (state.filter !== action.meta.filter) {
            state.filter = action.meta.filter;
        }

        if (action.meta.isNext) {
            state.ids = [...state.ids, ...ids];
        } else {
            state.ids = ids;
        }
    });
});
