import { useFela } from 'react-fela';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import { forwardRef, useEffect, useState } from 'react';

import { useTOTP } from 'modules/secrets/hooks';
import type { Theme } from 'styles/theme';
import type { Secret } from 'modules/secrets';

import * as felaRules from './TOTPCountdown.rules';

export interface TOTPCountdownProps {
    id: Secret['id'];
    copied: boolean;
}

export const TOTPCountdown = forwardRef<HTMLDivElement, TOTPCountdownProps>(({ id, copied }, TOTPFieldRef) => {
    const { theme, css } = useFela<Theme>(felaRules);
    const [hovered, setHovered] = useState(false);

    const { activeTotp, removeTOTP } = useTOTP(id, hovered);

    useEffect(() => {
        if (TOTPFieldRef && 'current' in TOTPFieldRef) {
            TOTPFieldRef.current.onmouseenter = () => setHovered(true);
            TOTPFieldRef.current.onmouseleave = () => setHovered(false);
        }
    }, [TOTPFieldRef]);

    const shouldShowCountdown = (hovered || copied) && activeTotp;

    if (!shouldShowCountdown) return null;

    const nowS = Date.now() / 1000;

    return (
        <div className={css(felaRules.countdownIcon)}>
            <CountdownCircleTimer
                key={activeTotp.code}
                isPlaying={activeTotp.validityEnd > nowS}
                duration={30}
                initialRemainingTime={activeTotp.validityEnd - nowS}
                strokeLinecap="square"
                strokeWidth={2}
                size={20}
                colors={theme.colors.accentTertiary}
                trailColor={theme.colors.foregroundsSeparator}
                onComplete={() => {
                    removeTOTP(activeTotp.code);
                }}
            >
                {({ remainingTime }) => <span className={css(felaRules.countdownTimerText)}>{remainingTime}</span>}
            </CountdownCircleTimer>
        </div>
    );
});
