import { createAction } from '@reduxjs/toolkit';

import {
    createFetchAction,
    createFetchNextAction,
    createFetchRequestAction,
    createFetchSuccessAction,
    createFetchFailureAction,
    createFetchCancelAction,
} from 'services/utilities/infiniteApiReducer';

import types from './actionTypes';

import type { Group, GroupMember, GroupMemberById } from '../types';

export const fetchGroups = createFetchAction(types.FETCH_GROUPS);

export const fetchGroupsNext = createFetchNextAction(types.FETCH_GROUPS_NEXT);

export const fetchGroupsRequest = createFetchRequestAction(types.FETCH_GROUPS_REQUEST);

export const fetchGroupsSuccess = createFetchSuccessAction<Group>(types.FETCH_GROUPS_SUCCESS);

export const fetchGroupsFailure = createFetchFailureAction(types.FETCH_GROUPS_FAILURE);

export const fetchGroupsCancel = createFetchCancelAction(types.FETCH_GROUPS_CANCEL);

export const fetchGroupMembers = createAction(types.FETCH_GROUP_MEMBERS, (groupId: Group['id']) => ({
    meta: {
        groupId,
    },
    payload: undefined,
}));

export const fetchGroupMembersSuccess = createAction(
    types.FETCH_GROUP_MEMBERS_SUCCESS,
    (groupId: Group['id'], byIds: GroupMemberById = {}, ids: GroupMember['id'][] = []) => ({
        payload: {
            byIds,
            ids,
        },
        meta: {
            groupId,
        },
    }),
);

export const fetchGroupMembersFailure = createAction(
    types.FETCH_GROUP_MEMBERS_FAILURE,
    (groupId: string, error: Error) => ({
        meta: { groupId },
        error,
        payload: undefined,
    }),
);

export const changeGroupVisibilityRequest = createAction(
    types.CHANGE_GROUP_VISIBILITY_REQUEST,
    (groupId: Group['id'], visible: boolean) => ({
        meta: { groupId },
        payload: { visible },
    }),
);

export const changeGroupVisibilityRequestSuccess = createAction(
    types.CHANGE_GROUP_VISIBILITY_REQUEST_SUCCESS,
    (groupId: Group['id'], visible: boolean) => ({
        meta: { groupId },
        payload: { visible },
    }),
);

export const changeGroupVisibilityRequestFailure = createAction(
    types.CHANGE_GROUP_VISIBILITY_REQUEST_FAILURE,
    (groupId: Group['id'], error: Error) => ({
        meta: { groupId },
        error,
        payload: undefined,
    }),
);
