import { useFela } from 'react-fela';

import { container, OverlayType } from './Overlay.rules';

export interface OverlayProps {
    type?: `${OverlayType}`;
}

export const Overlay = ({ type = OverlayType.BLUE }: OverlayProps) => {
    const { css } = useFela({ type });

    return <span className={css(container)} />;
};
