import { logoutRequest } from '@ackee/petrus';
import { put } from 'redux-saga/effects';
import { push } from 'redux-first-history';

import { config } from 'config/config';

export default function* doLogout() {
    yield put(logoutRequest());
    yield put(push(config.routes.home));
}
