import { put } from 'redux-saga/effects';

import { api } from 'config/antonio';
import { config } from 'config/config';
import { logger } from 'config/logger';

import { takeLatestRequest } from 'services/utilities/takeRequest';

import { actions as modalActions, StaticModal } from 'modules/modals';

import { removeTag as removeSecretTag } from '../../../../services/actions';

import { removeTag, removeTagSuccess, removeTagFailure, removeTagReset } from '../actions';

function* handler({ meta }: ReturnType<typeof removeTag>, signal: AbortSignal) {
    try {
        yield* api.delete<any>(config.api.tag, {
            uriParams: {
                tag: meta.tag,
            },
            signal,
        });

        yield put(removeSecretTag(meta.tag));
        yield put(removeTagSuccess(meta.tag));
        yield put(modalActions.setModalVisibility(StaticModal.REMOVE_TAG, false));
    } catch (e) {
        logger.error(e);

        yield put(removeTagFailure(meta.tag, e));
    }
}

export default function* () {
    yield takeLatestRequest(
        {
            actionCreator: removeTag,
            cancelActionFunction: action => removeTagReset(action.meta.tag),
            idSelector: action => action.meta.tag,
        },
        handler,
    );
}
