import { useRef } from 'react';
import { useIntl } from 'react-intl';

import { IconButton } from 'modules/ui';

import type { TOTPError } from './errors/TOTPError';
import { decodeQRCode } from './utils/decodeQRCode';

export interface TOTPFromQRCodeButtonProps {
    onChange: (qrCodeContent: string | TOTPError) => void;
}

/**
 * Parse 2FA secret from QR code image.
 */
export const TOTPFromQRCodeButton = ({ onChange }: TOTPFromQRCodeButtonProps) => {
    const { formatMessage } = useIntl();
    const inputRef = useRef<HTMLInputElement>(null);

    return (
        <IconButton
            type="ghost"
            icon={{ type: 'qrcode', color: 'blue' }}
            onClick={() => {
                inputRef.current.click();
            }}
            aria-label={formatMessage({ id: 'upload.TOTP' })}
            title={formatMessage({ id: 'upload.TOTP' })}
        >
            <input
                ref={inputRef}
                style={{ display: 'none' }}
                inputMode="none"
                name="qr-code"
                type="file"
                accept="image/*"
                multiple={false}
                capture="environment"
                onChange={async e => {
                    if (e.target.files.length) {
                        const file = e.target.files?.[0];
                        const message = await decodeQRCode(file);

                        onChange(message);
                    }
                }}
            />
        </IconButton>
    );
};
