import type { AnyAction } from 'redux';
import { take, fork, select } from 'redux-saga/effects';

import { Status } from '../../../constants';

import { updateNetworkStatus } from '../../actions';

import { isOfflineSelector } from '../../selectors';

export function* callWhenOnline(forkableGenerator: () => void) {
    do {
        const isOffline: boolean = yield select(isOfflineSelector);

        if (isOffline) {
            yield take((action: AnyAction) =>
                updateNetworkStatus.match(action) ? action.payload === Status.ONLINE : false,
            );
        }

        const task = yield fork(forkableGenerator);

        yield take((action: AnyAction) =>
            updateNetworkStatus.match(action) ? action.payload === Status.OFFLINE : false,
        );

        if (task.isRunning) {
            yield task.cancel();
        }
    } while (true);
}
