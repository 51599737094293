import { configure, TokensPersistence } from '@ackee/petrus';

import { config } from 'config/config';

import { fetchAccessToken } from './fetchAccessToken';
import { enforceAccessTokenScheme } from './enforceAccessTokenScheme';
import { enforceRefreshTokenScheme } from './enforceRefreshTokenScheme';
import { refreshTokens } from './refreshTokens';
import { getAuthUser } from './getAuthUser';

const { saga, reducer } = configure({
    selector: state => state.petrus,
    oAuth: {
        origin: window.location.origin,
        redirectPathname: config.googleOAuth.redirectUrl,
        fetchAccessToken,
        enforceAccessTokenScheme,
        enforceRefreshTokenScheme,
    },
    handlers: {
        refreshTokens,
        getAuthUser,
    },
    initialState: {
        tokensPersistence: config.demo.enable ? TokensPersistence.NONE : TokensPersistence.LOCAL,
    },
});

export { saga, reducer };
