import { combineReducers } from 'redux';
import { basicApiReducer, containerReducer } from '@ackee/redux-utils';

import {
    removeTag,
    removeTagFailure,
    removeTagReset,
    removeTagSuccess,
    updateTag,
    updateTagFailure,
    updateTagReset,
    updateTagSuccess,
} from '../actions';

const updateActionTypes = {
    REQUEST: updateTag.toString(),
    SUCCESS: updateTagSuccess.toString(),
    FAILURE: updateTagFailure.toString(),
    RESET: updateTagReset.toString(),
};

const update = containerReducer({
    childReducer: basicApiReducer({
        actionTypes: updateActionTypes,
    }),
    actionTypes: Object.values(updateActionTypes),
    selectors: {
        itemId: action => action.meta.tag,
    },
});

const removeActionTypes = {
    REQUEST: removeTag.toString(),
    SUCCESS: removeTagSuccess.toString(),
    FAILURE: removeTagFailure.toString(),
    RESET: removeTagReset.toString(),
};

const remove = containerReducer({
    childReducer: basicApiReducer({
        actionTypes: removeActionTypes,
    }),
    actionTypes: Object.values(removeActionTypes),
    selectors: {
        itemId: action => action.meta.tag,
    },
});

export const apiReducer = combineReducers({
    update,
    remove,
});
