import { Field } from 'react-final-form';
import { identity } from 'lodash';
import { FormattedMessage } from 'react-intl';

import { RadioButtonGroup, SettingsFormItem } from 'modules/forms';

import { Fields } from '../../../constants';
import { ImportFormValues, SecretsFormat } from '../../../types';

interface SecretsFormatFieldProps {}

const options = Object.values(SecretsFormat);

export const SecretsFormatField = (props: SecretsFormatFieldProps) => (
    <Field name={Fields.SECRETS_FORMAT} format={identity} parse={identity} initialValue={SecretsFormat.PASSWD}>
        {({ input, meta }) => (
            <SettingsFormItem<ImportFormValues['format']>
                meta={meta}
                label={<FormattedMessage id="import.form.secretsFormat.label" />}
            >
                <RadioButtonGroup
                    name={input.name}
                    value={input.value}
                    onChange={e => {
                        input.onChange(e.target.value);
                    }}
                    onBlur={input.onBlur}
                    onFocus={input.onFocus}
                    optionType="button"
                    options={options.map(value => ({
                        label: <FormattedMessage id={`import.form.secretsFormat.${value}`} />,
                        value,
                    }))}
                />
            </SettingsFormItem>
        )}
    </Field>
);
