import { takeLatest } from 'redux-saga/effects';

import { selectDemoUser } from '../actions';

import setDemoUser from './setDemoUser';

function* switchDemoUser({ payload: userId }: ReturnType<typeof selectDemoUser>) {
    yield setDemoUser(userId);
}

export function* switchDemoUserSaga() {
    yield takeLatest(selectDemoUser.toString(), switchDemoUser);
}
