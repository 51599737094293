import { createReducer } from '@reduxjs/toolkit';

import { getConnectionStatus } from '../../utils/getConnectionStatus';

import type { Status } from '../../constants';

import { updateNetworkStatus } from '../actions';

const initialState: {
    status: Status;
} = {
    status: getConnectionStatus(),
};

export const reducer = createReducer(initialState, builder => {
    builder.addCase(updateNetworkStatus, (state, action) => {
        state.status = action.payload;
    });
});
