import { takeEvery } from 'redux-saga/effects';

import config from 'config/index';
import { api } from 'config/antonio';

import { sendFeedbackType } from './actions';

function* worker(action: ReturnType<typeof sendFeedbackType>) {
    yield* api.post(config.api.feedback, {
        feedback: action.payload,
    });
}

export function* saga() {
    yield takeEvery(sendFeedbackType.toString(), worker);
}
