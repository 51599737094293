import { FormItem, FormItemProps } from '../FormItem';
import { Label } from '../Label';
import { LabelSize } from '../Label/Label.rules';

import { SettingsItem } from '../SettingsItem';

import * as felaRules from './SettingsFormItem.rules';

export interface SettingsFormItemProps<FieldValue> extends FormItemProps<FieldValue> {}

export function SettingsFormItem<FieldValue>({ label, meta, children }: SettingsFormItemProps<FieldValue>) {
    return (
        <SettingsItem>
            <FormItem<FieldValue>
                meta={meta}
                label={
                    <Label size={LabelSize.LARGE} customStyle={felaRules.label}>
                        {label}
                    </Label>
                }
                customStyle={felaRules.container}
            >
                {children}
            </FormItem>
        </SettingsItem>
    );
}
