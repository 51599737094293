import { createSelector } from '@reduxjs/toolkit';

import { selectIdProp } from 'services/selectors';

import { selectSecretsEntities } from './entities';

const selectTOTPS = createSelector([selectSecretsEntities], entities => entities.totp);

export const selectSecretTOTPS = createSelector([selectTOTPS, selectIdProp], (totps, id) => totps[id] ?? []);
export const selectActiveTOTP = createSelector(selectSecretTOTPS, totps =>
    totps.find(({ validityEnd, validityStart }) => {
        const now = Date.now();

        return now >= validityStart * 1000 && now <= validityEnd * 1000;
    }),
);
