import type { TransitionStatus } from 'react-transition-group/Transition';

import type { TRuleWithTheme } from 'styles/theme';

import { TRANSITION_DURATION } from '../../config';

const getMessageStateStyle = (state: TransitionStatus) => {
    switch (state) {
        case 'entering':
        case 'entered':
            return {
                opacity: 1,
                transform: 'translateY(0%)',
            };

        case 'exiting':
        case 'exited':
            return {
                opacity: 0,
                transform: 'translateY(-50%)',
            };

        default:
            return {};
    }
};

export const content: TRuleWithTheme<{
    state: TransitionStatus;
}> = ({ theme, state }) => ({
    display: 'flex',
    transition: `transform ${TRANSITION_DURATION}ms, opacity ${TRANSITION_DURATION}ms`,
    opacity: 0,
    willChange: 'transform, opacity',
    marginBottom: '0.5rem',
    ...getMessageStateStyle(state),
});

export const inner: TRuleWithTheme = ({ theme }) => ({
    borderRadius: '4px',
    display: 'inline-flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.colors.white,
    padding: '11px 16px',
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.15)',
});

export const text: TRuleWithTheme = () => ({
    paddingLeft: '0.5rem',
    color: 'rgba(0, 0, 0, 0.65)',
    fontSize: '0.875rem',
    letterSpacing: '0.1px',
});

export const button: TRuleWithTheme = () => ({
    '&.ant-btn': {
        height: '1.5rem',
        padding: '0 0.5rem',
        marginLeft: '2rem',
        fontSize: '0.875rem',
    },
});
