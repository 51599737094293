import { createReducer } from '@reduxjs/toolkit';

import { fetchChangelogFailure, fetchChangelogSuccess } from './actions';

const initialState: {
    content: string;
    error?: any;
} = {
    content: null,
};

export const reducer = createReducer(initialState, builder => {
    builder.addCase(fetchChangelogSuccess, (state, action) => {
        state.content = action.payload;
        state.error = undefined;
    });
    builder.addCase(fetchChangelogFailure, (state, action) => {
        state.content = null;
        state.error = action.error;
    });
});
