import { useParams } from 'react-router-dom-v5-compat';
import { useFela } from 'react-fela';

import { useSecretDetail } from 'modules/secrets/hooks';

import { Fields } from '../../../constants';
import { TextField } from '../TextField';

import * as felaRules from './NameFieldWithFavicon.rules';

export enum NameFieldType {
    NEW_SECRET = 'new',
    UPDATE_SECRET = 'update',
}

export interface NameFieldProps {
    type: `${NameFieldType}`;
}

export const NameFieldWithFavicon = ({ type }: NameFieldProps) => {
    const { css } = useFela();
    const { id } = useParams<{
        id: string;
    }>();

    const detail = useSecretDetail(id);

    const isInUpdateForm = type === 'update';

    return (
        <div className={css(felaRules.fieldAndFaviconContainer)}>
            {isInUpdateForm && detail?.favicon && (
                <img
                    src={`data:image/png;base64,${detail.favicon}`}
                    alt={`${detail.name} favicon`}
                    className={css(felaRules.secretFavicon)}
                />
            )}
            <TextField
                name={Fields.NAME}
                hideLabel={isInUpdateForm}
                required={!isInUpdateForm}
                extend={{
                    formItem: felaRules.largeFormItem,
                    input: isInUpdateForm && felaRules.largeInput,
                }}
            />
        </div>
    );
};
