import { config } from 'config/config';

import register from './register';
import unregister from './unregister';

export function* saga() {
    if (config.serviceWorker) {
        yield register();
    } else {
        yield unregister();
    }
}
