import qs from 'query-string';
import { entitiesSelector } from '@ackee/petrus';
import { takeEvery, put, select } from 'redux-saga/effects';
import { LOCATION_CHANGE, replace } from 'redux-first-history';
import type { Location } from 'history';

import type { LocationChangeAction } from 'types';

const getPath = (location: Location) => `${location.pathname}?${location.search}${location.hash}`;

function* locationHandler(action: LocationChangeAction) {
    const {
        payload: { location },
    } = action;
    const search = qs.parse(location.search);

    const entities = yield select(entitiesSelector);

    if (!search.demoUser && entities.user) {
        search.demoUser = 'true';

        const path = getPath({
            ...location,
            search: qs.stringify(search),
        });
        yield put(replace(path));
    }
    if (search.demoUser && !entities.user) {
        delete search.demoUser;

        const path = getPath({
            ...location,
            search: qs.stringify(search),
        });
        yield put(replace(path));
    }
}

export function* trackLoggedDemoUserSaga() {
    yield takeEvery(LOCATION_CHANGE, locationHandler);
}
