import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { useAppSelector } from 'hooks/useAppSelector';

import { selectBackendVersion } from '../services/selectors';
import { fetchBackendVersion } from '../services/slices';

export const useBackendVersion = () => {
    const dispatch = useDispatch();

    const version = useAppSelector(selectBackendVersion);

    useEffect(() => {
        if (!version) {
            dispatch(fetchBackendVersion());
        }
    }, [version, dispatch]);

    return version;
};
