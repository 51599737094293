import type { SVGProps } from 'react';

export const StatusBolt = (props: SVGProps<SVGSVGElement>) => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="#0000FF" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.13783 1.31665L2.14345 9.55892C1.97753 9.78706 1.95366 10.089 2.08167 10.3404C2.20967 10.5918 2.46791 10.7501 2.75 10.7501H6.5V15.2501C6.5 15.5749 6.70914 15.8628 7.01807 15.9633C7.327 16.0637 7.66549 15.9539 7.85655 15.6912L13.8566 7.44118C14.0225 7.21304 14.0463 6.9111 13.9183 6.65972C13.7903 6.40834 13.5321 6.25005 13.25 6.25005H9.5V1.75959C9.50022 1.74159 9.49978 1.72359 9.49871 1.70565C9.49251 1.59955 9.46427 1.49938 9.41848 1.40965C9.37284 1.31984 9.30844 1.23808 9.22628 1.17066C9.15475 1.11179 9.07212 1.06591 8.982 1.03662C8.8919 1.0073 8.79808 0.995809 8.7056 1.00135C8.5995 1.00754 8.49933 1.03579 8.4096 1.08157C8.31979 1.12721 8.23803 1.19161 8.17062 1.27377C8.15919 1.28765 8.14825 1.30196 8.13783 1.31665ZM8 4.05644L4.22283 9.25005H7.25C7.66422 9.25005 8 9.58584 8 10.0001V12.9437L11.7772 7.75005H8.75C8.33579 7.75005 8 7.41427 8 7.00005V4.05644Z"
        />
    </svg>
);
