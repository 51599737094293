import type { TRuleWithTheme } from 'styles/theme';

export const attachment: TRuleWithTheme = ({ theme }) => ({
    display: 'flex',

    alignItems: 'center',
    justifyContent: 'space-between',

    paddingTop: '2rem',
    paddingRight: '1.5rem',
    paddingBottom: '2rem',
    paddingLeft: '1.5rem',

    borderWidth: '0.0625rem',
    borderStyle: 'solid',
    borderColor: '#EDEBE8',
    backgroundColor: '#EDEBE8',

    borderRadius: '0.25rem',
});

export const name: TRuleWithTheme = () => ({
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'auto',

    marginRight: '2rem',
});

export const trashButton: TRuleWithTheme = ({ theme }) => ({
    cursor: 'pointer',

    padding: 0,
    margin: 0,

    backgroundColor: 'transparent',
    border: 'none',
    boxShadow: 'none',
});
