import { createSelector } from '@reduxjs/toolkit';

import type { AppState } from 'types';

const selectModals = (state: AppState) => state.modals;

type ModalMeta = {
    modal: string;
};

export const selectModal = createSelector(
    [selectModals, (_: AppState, meta: ModalMeta) => meta.modal],
    (modals, modal) => modals[modal],
);

const defaultModalData = {};

export const selectModalVisibility = createSelector(selectModal, modal => modal?.visibility ?? false);

export const selectModalData = createSelector(selectModal, modal => modal?.data ?? defaultModalData);
