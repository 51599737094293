const modulePrefix = 'groups';

export default {
    FETCH_GROUPS: `${modulePrefix}/FETCH_GROUPS`,
    FETCH_GROUPS_NEXT: `${modulePrefix}/FETCH_GROUPS_NEXT`,
    FETCH_GROUPS_REQUEST: `${modulePrefix}/FETCH_GROUPS_REQUEST`,
    FETCH_GROUPS_SUCCESS: `${modulePrefix}/FETCH_GROUPS_SUCCESS`,
    FETCH_GROUPS_FAILURE: `${modulePrefix}/FETCH_GROUPS_FAILURE`,
    FETCH_GROUPS_CANCEL: `${modulePrefix}/FETCH_GROUPS_CANCEL`,

    SET_GROUPS: `${modulePrefix}/SET_GROUPS`,

    FETCH_GROUP_MEMBERS: `${modulePrefix}/FETCH_GROUP_MEMBERS`,
    FETCH_GROUP_MEMBERS_SUCCESS: `${modulePrefix}/FETCH_GROUP_MEMBERS_SUCCESS`,
    FETCH_GROUP_MEMBERS_FAILURE: `${modulePrefix}/FETCH_GROUP_MEMBERS_FAILURE`,

    SET_GROUP_MEMBERS: `${modulePrefix}/SET_GROUP_MEMBERS`,

    CHANGE_GROUP_VISIBILITY_REQUEST: `${modulePrefix}/CHANGE_GROUP_VISIBILITY_REQUEST`,
    CHANGE_GROUP_VISIBILITY_REQUEST_SUCCESS: `${modulePrefix}/CHANGE_GROUP_VISIBILITY_REQUEST_SUCCESS`,
    CHANGE_GROUP_VISIBILITY_REQUEST_FAILURE: `${modulePrefix}/CHANGE_GROUP_VISIBILITY_REQUEST_FAILURE`,
};
