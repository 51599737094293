import { FormattedMessage } from 'react-intl';

import { Label, Slider } from 'modules/forms';
import type { SliderProps } from 'modules/forms';
import { Spacer } from 'modules/ui';

export interface GeneratePasswordLengthProps {
    value: number;
    onChange: SliderProps['onChange'];
}

export const GeneratePasswordLength = ({ onChange, value }: GeneratePasswordLengthProps) => (
    <div>
        <Label type="dark">
            <FormattedMessage id="secret.generate.length" values={{ length: value }} />
        </Label>
        <Spacer space={1.5} />
        <Slider min={4} max={50} value={value} onChange={onChange} />
    </div>
);
