import { isEmpty } from 'lodash';

import { useSecurityAuditReportSecretsList } from '../../hooks/useSecurityAuditReportSecretsList';

import { SecretsListContainer } from '../SecretsListContainer';
import { SecurityAuditReportSecretsList } from '../SecurityAuditReportSecretsList';
import { SecurityAuditListEmpty } from '../SecurityAuditListEmpty';

export const SecurityAuditReportSecrets = () => {
    const data = useSecurityAuditReportSecretsList();

    return (
        <SecretsListContainer
            isEmpty={isEmpty(data)}
            empty={<SecurityAuditListEmpty messageId="securityAudit.report.empty" />}
        >
            {({ height, width }) => <SecurityAuditReportSecretsList data={data} height={height} width={width} />}
        </SecretsListContainer>
    );
};
