import { call, put, takeEvery } from 'redux-saga/effects';

import { config } from 'config/config';
import { logger } from 'config/logger';
import { api } from 'config/antonio';

import types from '../actionTypes';

import { updateSettings, updateSettingsSuccess, updateSettingsFailure } from '../actions';

function* handler({ payload, meta }: ReturnType<typeof updateSettings>) {
    try {
        const { data } = yield call([api, api.put], config.api.settings, payload);

        yield put(updateSettingsSuccess(data));

        meta.success();
    } catch (e) {
        yield put(updateSettingsFailure(e));

        logger.error(e);

        meta.failure();
    }
}

export function* updateSettingsSaga() {
    yield takeEvery(types.UPDATE_SETTINGS, handler);
}
