import { AUTH_SESSION_END, AUTH_SESSION_START } from '@ackee/petrus';
import type { AnyAction } from 'redux';
import { eventChannel } from 'redux-saga';
import { cancel, fork, take, takeEvery, select, actionChannel } from 'redux-saga/effects';

import type { Task } from 'redux-saga';

import config from 'config/index';
import { api } from 'config/antonio';

import { visibilityEventListener } from 'services/utilities/visibilityListener';

import { selectWakeUpFlag } from '../slice';

const MIN_TIME_DIFF = 10 * 60; // 10 min

const createVisibilityChannel = () =>
    eventChannel<boolean>(emit => {
        const listener = visibilityEventListener(visible => {
            emit(visible);
        });

        return listener.remove;
    });

export function* worker() {
    const channel = createVisibilityChannel();

    try {
        while (true) {
            const visible: boolean = yield take(channel);

            if (visible) {
                const { timestamp } = yield select(selectWakeUpFlag);

                const now = new Date().getTime();

                const timeDiff = Math.max(0, Math.ceil((now - timestamp) / 1000));

                if (timeDiff < MIN_TIME_DIFF) {
                    continue;
                }

                yield* api.get(config.api.root);
            }
        }
    } finally {
        channel.close();
    }
}

export function* wakeup() {
    const channel = yield actionChannel([AUTH_SESSION_START, AUTH_SESSION_END]);

    try {
        let task: Task;

        yield takeEvery(channel, function* (action: AnyAction) {
            if (action.type === AUTH_SESSION_START) {
                task = yield fork(worker);
            } else if (action.type === AUTH_SESSION_END) {
                yield cancel(task);
            }
        });
    } catch (e) {
        yield cancel(channel);
    }
}
