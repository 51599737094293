import { useFela } from 'react-fela';

import { spacer } from './Spacer.rules';

export interface SpacerProps {
    space: number;
}

export const Spacer = ({ space }: SpacerProps) => {
    const { css } = useFela({
        space,
    });

    return <div className={css(spacer)} />;
};
