import { all, takeEvery } from 'redux-saga/effects';
import { LOCATION_CHANGE } from 'redux-first-history';

import config from 'config';

import type { LocationChangeAction } from 'types';

import { TagManager } from 'services/utilities';

function* initializeTagManager() {
    TagManager.initialize();

    yield takeEvery(LOCATION_CHANGE, (action: LocationChangeAction) => {
        if (action.payload) {
            const { location } = action.payload;

            TagManager.push('pageview', {
                url: location.pathname,
            });

            if (location.pathname === config.routes.securityAudit) {
                TagManager.push('view_security_report');
            }
        }
    });
}

export function* app() {
    yield all([initializeTagManager()]);
}
