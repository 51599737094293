import { generatePath, useHistory } from 'react-router';

import config from 'config';

import { SearchOptionType, SearchSecretAttributeType } from '../../../types';
import { useSearchHistory } from '../../../hooks/useSearchHistory';
import type { Option, SearchOption } from '../types';
import { useSecretsAttributesFilter } from '../../../hooks/useSecretsAttributesFilter';
import { useSecretsNameFilter } from '../../../hooks/useSecretsNameFilter';
import { TAG_OPTION_VALUE_PREFIX } from '../utilities';

export const isOption = (item: SearchOption): item is Option => !item.hasOwnProperty('options');

export const useHandleSearchSelect = (setSearchTerm: (value: string) => void) => {
    const history = useHistory();
    const { updateHistory } = useSearchHistory();

    const secretsNameFilter = useSecretsNameFilter();
    const secretsAttributesFilter = useSecretsAttributesFilter();

    const handleSelect = (value: string, option: Option) => {
        if (!isOption(option)) return;

        switch (option.type) {
            case SearchOptionType.TAG: {
                const tag = option.value.replace(TAG_OPTION_VALUE_PREFIX, '');
                secretsAttributesFilter.toggleFilter(tag, tag, SearchSecretAttributeType.TAG, true);
                setSearchTerm(secretsNameFilter.filter);

                updateHistory({
                    value: tag,
                    label: tag,
                    type: SearchOptionType.TAG,
                });

                break;
            }
            case SearchOptionType.GROUP: {
                secretsAttributesFilter.toggleFilter(option.value, option.title, SearchSecretAttributeType.GROUP, true);
                setSearchTerm(secretsNameFilter.filter);

                updateHistory({
                    value: option.value,
                    label: option.title,
                    type: SearchOptionType.GROUP,
                });

                break;
            }
            case SearchOptionType.RECORD: {
                history.push(
                    generatePath(config.routes.secret, {
                        id: value,
                    }),
                );
                setSearchTerm(secretsNameFilter.filter);

                updateHistory({
                    value: option.value,
                    label: option.title,
                    type: SearchOptionType.RECORD,
                });

                break;
            }
            default: {
                secretsNameFilter.updateFilter(value);

                updateHistory({
                    value: option.value,
                    label: option.value,
                    type: SearchOptionType.FILTER,
                });
            }
        }
    };

    return handleSelect;
};
