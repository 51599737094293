import { useFela } from 'react-fela';
import { FormattedMessage } from 'react-intl';

import { Button } from '../../Button';
import type { ButtonProps } from '../../Button';
import { Text as UIText } from '../../typography/Text';
import { useCopyToClipboard } from '../../../hooks/useCopyToClipboard';

import * as felaRules from './Text.rules';

export interface TextProps extends Omit<ButtonProps, 'type'> {
    text: string;
}

export const Text = ({ text, children, disabled = false }: TextProps) => {
    const { css } = useFela();

    const { handleCopy } = useCopyToClipboard();

    return (
        <div className={css(felaRules.container)}>
            <UIText customStyle={felaRules.text}>{children ?? text}</UIText>

            <Button
                data-testid="copy-button-text"
                type="default"
                size="small"
                disabled={disabled}
                onClick={() => handleCopy(text)}
            >
                <FormattedMessage id="copy" />
            </Button>
        </div>
    );
};
