import type { TRuleWithTheme } from 'styles/theme';

export const backButton: TRuleWithTheme = ({ theme }) => ({
    width: 'fit-content',

    '&.ant-btn-text': {
        gap: '0.625rem',
    },
});

export const backButtonIcon: TRuleWithTheme = ({ theme }) => ({
    transform: 'rotate(270deg)',
});
