import { FormattedMessage } from 'react-intl';

import config from 'config';

import { IconType } from 'modules/ui';

import type { MessageKey } from 'modules/localizations/translations/types';

import type {
    SecurityAuditAgeGroup,
    SecurityAuditDuplicitiesGroup,
    SecurityAuditLeaksGroup,
    SecurityAuditLevelGroup,
    SecurityAuditReportItem,
} from '../../types';

import { ListItemType } from '../../constants';
import {
    isSecurityAuditAgeGroup,
    isSecurityAuditAgeSecret,
    isSecurityAuditDuplicitiesSecret,
    isSecurityAuditLeaksGroup,
    isSecurityAuditLeaksSecret,
    isSecurityAuditLevelGroup,
} from '../../utilities';

import { SecurityAuditGroupItem } from '../SecurityAuditGroupItem';
import { SecurityAuditSecretItem } from '../SecurityAuditSecretItem';

import { SecurityAuditItemRenderer } from '../SecurityAuditItemRenderer';
import type { SecurityAuditItemRendererProps } from '../SecurityAuditItemRenderer';
import { iconTypeBySecurityLevel } from '../SecurityAuditLevelItemRenderer';

export interface SecurityAuditAgeItemRendererProps
    extends Omit<SecurityAuditItemRendererProps<SecurityAuditReportItem>, 'children'> {}

export const getGroupMessageId = (
    item: SecurityAuditAgeGroup | SecurityAuditLevelGroup | SecurityAuditLeaksGroup | SecurityAuditDuplicitiesGroup,
) => {
    if (isSecurityAuditLevelGroup(item)) {
        return `securityAudit.report.level.${item.securityLevel}` as const;
    } else if (isSecurityAuditAgeGroup(item)) {
        return `securityAudit.report.age.${item.securityAge}` as const;
    } else if (isSecurityAuditLeaksGroup(item)) {
        return `securityAudit.report.leaks.${item.securityLeaks}` as const;
    }
    return 'securityAudit.report.duplicities';
};

export const SecurityAuditReportItemRenderer = (props: SecurityAuditAgeItemRendererProps) => (
    <SecurityAuditItemRenderer<SecurityAuditReportItem> {...props}>
        {({ item, isFirstGroup, isLastInGroup }) => {
            if (item.type === ListItemType.GROUP) {
                const messageId = getGroupMessageId(item);
                return (
                    <SecurityAuditGroupItem
                        name={<FormattedMessage id={messageId as MessageKey} />}
                        count={item.count}
                        isFirstGroup={isFirstGroup}
                    />
                );
            }

            if (isSecurityAuditAgeSecret(item)) {
                return (
                    <SecurityAuditSecretItem
                        id={item.id}
                        name={item.name}
                        updateSecretRoute={config.routes.securityAuditUpdateSecret}
                        isLastInGroup={isLastInGroup}
                        ignoreable
                    />
                );
            } else if (isSecurityAuditLeaksSecret(item)) {
                return (
                    <SecurityAuditSecretItem
                        id={item.id}
                        name={item.name}
                        icon={IconType.STATUS_WARNING}
                        isLastInGroup={isLastInGroup}
                        updateSecretRoute={config.routes.securityAuditUpdateSecret}
                        ignoreable
                    />
                );
            } else if (isSecurityAuditDuplicitiesSecret(item)) {
                return (
                    <SecurityAuditSecretItem
                        id={item.id}
                        name={item.name}
                        updateSecretRoute={config.routes.securityAuditUpdateSecret}
                        isLastInGroup={isLastInGroup}
                        additional={
                            <FormattedMessage
                                id="securityAudit.duplicities.count"
                                values={{ count: item.duplicities }}
                            />
                        }
                        ignoreable
                    />
                );
            }

            return (
                <SecurityAuditSecretItem
                    id={item.id}
                    name={item.name}
                    icon={iconTypeBySecurityLevel[item.securityLevel]}
                    isLastInGroup={isLastInGroup}
                    updateSecretRoute={config.routes.securityAuditUpdateSecret}
                    ignoreable
                />
            );
        }}
    </SecurityAuditItemRenderer>
);
