import type { TRuleWithTheme } from 'styles/theme';

export const container: TRuleWithTheme = ({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    padding: '1.25rem 1.5rem',

    borderBottom: `1px solid ${theme.colors.lightGrey4}`,
});

export const textContainer: TRuleWithTheme = ({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '0.25rem',
});

export const actionsContainer: TRuleWithTheme = ({ theme }) => ({
    display: 'flex',
    gap: '1rem',
    alignItems: 'center',
});

export const usedCount: TRuleWithTheme = ({ theme }) => ({
    fontSize: '0.75rem',
});
