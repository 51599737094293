import type { SVGProps } from 'react';

export const Dropdown = (props: SVGProps<SVGSVGElement>) => (
    <svg fill="currentColor" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18.707 8.293a.999.999 0 0 0-1.414 0L12 13.586 6.707 8.293a.999.999 0 1 0-1.414 1.414l6 6a.997.997 0 0 0 1.414 0l6-6a.999.999 0 0 0 0-1.414Z"
        />
    </svg>
);
