import { put } from 'redux-saga/effects';

import { logger } from 'config/logger';

import { actions as messagesActions } from 'modules/messages';

const handleError = (event: any) => {
    logger.error(event);

    window.location.reload();
};

export function* promptUpdate(registration: ServiceWorkerRegistration) {
    yield put(
        messagesActions.displayInfoMessage({
            message: { id: 'service-worker.update' },
            button: {
                message: {
                    id: 'service-worker.updateBtn',
                },
                onClick: () => {
                    try {
                        // when current, which is new, service worker became active
                        // we want to reload the page to show user new info
                        registration.waiting.addEventListener('error', handleError);
                        registration.waiting.postMessage({ type: 'SKIP_WAITING' });
                    } catch (event) {
                        handleError(event);
                    }
                },
            },
            options: {
                duration: 0,
            },
        }),
    );
}

export function* promptInstal() {
    yield put(
        messagesActions.displayInfoMessage({
            message: { id: 'service-worker.installed' },
            options: {
                duration: 5,
            },
        }),
    );
}
