import type { TRuleWithTheme } from 'styles/theme';

export const labelMarginLeft = 0.625;

export const container: TRuleWithTheme<{ fullWidth: boolean }> = ({ theme, fullWidth }) => ({
    '&.ant-form-item': {
        marginBottom: 0,

        maxWidth: fullWidth ? '100%' : '20rem',
    },

    '& .ant-form-item-label': {
        overflow: 'unset',
        whiteSpace: 'unset',
        textAlign: 'left',

        width: '100%',

        marginBottom: '0.375rem',
        padding: 0,
    },

    '& .ant-form-item-label label': {
        display: 'flex',

        height: 'auto',
    },

    '& .ant-form-item-label label::after': {
        display: 'none',
    },

    '& .ant-form-item-label label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before': {
        fontFamily: theme.fontFamily,
        color: theme.colors.inputsError,
    },

    '& .ant-form-item-control': {
        width: '100%',

        lineHeight: '1rem',
    },

    '& .ant-form-item-explain': {
        marginTop: '0.375rem',

        fontSize: '0.75rem',
        fontWeight: 400,
        lineHeight: '1rem',
    },
});
