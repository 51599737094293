import { useMemo, type PropsWithChildren } from 'react';
import { useFela } from 'react-fela';
import { matchRoutes, useLocation } from 'react-router-dom-v5-compat';

import { container } from './MainSection.rules';

import { fullWidthLayoutRoutes } from '../../../constants';

export interface MainSectionProps extends PropsWithChildren {}

export const MainSection = ({ children }: MainSectionProps) => {
    const location = useLocation();

    const isFullWidthLayoutRoute = useMemo(() => {
        const matchedFullWidthLayoutRoute = matchRoutes(fullWidthLayoutRoutes, location);
        return Boolean(matchedFullWidthLayoutRoute);
    }, [location]);

    const { css } = useFela({ isFullWidthLayoutRoute });

    return <div className={css(container)}>{children}</div>;
};
