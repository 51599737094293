import { AccessPermission, AccessRole } from 'types';

export const permissionByRole = {
    [AccessRole.EDITOR]: [AccessPermission.WRITE, AccessPermission.READ],
    [AccessRole.VIEWER]: [AccessPermission.READ],
    [AccessRole.VIEWER_AUTOFILL_ONLY]: [AccessPermission.AUTOFILL_ONLY],
};

export const permissionToRole = (accessPermission: AccessPermission[]) =>
    accessPermission.includes(AccessPermission.WRITE)
        ? AccessRole.EDITOR
        : accessPermission.includes(AccessPermission.AUTOFILL_ONLY)
        ? AccessRole.VIEWER_AUTOFILL_ONLY
        : AccessRole.VIEWER;

export const hasWritePermission = (permissions: AccessPermission[] = []) =>
    permissions?.includes(AccessPermission.WRITE);
export const hasReadPermission = (permissions: AccessPermission[] = []) =>
    permissions?.includes(AccessPermission.READ) || permissions?.includes(AccessPermission.WRITE);
