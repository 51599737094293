import { useFela } from 'react-fela';
import type { PropsWithChildren } from 'react';
import { FormattedMessage } from 'react-intl';

import type { MessageKey } from 'modules/localizations/translations/types';
import { areChildrenEmpty, Text } from 'modules/ui';

import * as felaRules from './EmptyContentGuard.rules';

export interface EmptyContentGuardProps extends PropsWithChildren {
    message?: MessageKey;
}

export const EmptyContentGuard = ({ children, message = 'secret.sections.empty' }: EmptyContentGuardProps) => {
    const { css } = useFela();

    if (areChildrenEmpty(children)) {
        return (
            <div className={css(felaRules.container)}>
                <Text size="small" color="foregroundsTertiary" customStyle={felaRules.text}>
                    <FormattedMessage id={message} />
                </Text>
            </div>
        );
    }

    return <>{children}</>;
};
