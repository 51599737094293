import type { SVGProps } from 'react';

export const NoReport = (props: SVGProps<SVGSVGElement>) => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M5 21V5M7 3H17C17.5304 3 18.0391 3.21071 18.4142 3.58579C18.7893 3.96086 19 4.46957 19 5V15M19 19.01V21L16 19L14 21L12 19L10 21L8 19L5 21"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path d="M11 7H15" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M9 11H11" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M13 15H15" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M15 11V11.0119" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M3 3L21 21" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);
