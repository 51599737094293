import { createAction } from '@reduxjs/toolkit';

import types from '../actionTypes';

export const activateSyncSecrets = createAction(types.ACTIVATE_SYNC_SECRETS);

export const syncSecrets = createAction(types.SYNC_SECRETS, (verbose: boolean = false) => ({
    meta: {
        verbose,
    },
    payload: undefined,
}));
