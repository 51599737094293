import type { SVGProps } from 'react';

export const Export = (props: SVGProps<SVGSVGElement>) => (
    <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
        <g fillRule="evenodd">
            <path d="M5 19v-8H3v8c0 1.103.897 2 2 2h14c1.103 0 2-.897 2-2v-8h-2v8H5Z" />
            <path d="M10.642 3.6 6.267 8.32l1.467 1.36L11 6.156V15h2V6.156l3.267 3.524 1.467-1.36-4.375-4.72a1.861 1.861 0 0 0-1.36-.6 1.86 1.86 0 0 0-1.357.6" />
        </g>
    </svg>
);
