import { useFela } from 'react-fela';

import { RemoveSecretButton } from './RemoveSecretButton';
import { CancelButton } from './CancelButton';

import * as felaRules from './HeaderButtons.rules';
import { SaveSecretButton } from './SaveSecretButton';

export const HeaderButtons = () => {
    const { css } = useFela(felaRules);

    return (
        <div className={css(felaRules.container)}>
            <RemoveSecretButton />
            <CancelButton />
            <SaveSecretButton />
        </div>
    );
};
