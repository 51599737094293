import { useDispatch } from 'react-redux';

import { useAppSelector } from 'hooks/useAppSelector';

import type { ViewFilter } from '../types';

import { selectViewFilter } from '../services/selectors';
import { setViewFilter } from '../services/actions';

export const useViewFilter = () => {
    const dispatch = useDispatch();
    const viewFilter = useAppSelector(selectViewFilter);

    const setView = (view: ViewFilter | null) => {
        dispatch(setViewFilter(view, true));
    };

    return {
        viewFilter,
        setView,
    };
};
