import { FormattedMessage } from 'react-intl';
import { Field } from 'react-final-form';
import { identity } from 'lodash';

import { FileInput, FormItem, Label } from 'modules/forms';

import type { Base64File } from '../../../types';
import { getBase64EncodedFile } from '../../../utilities';
import { Fields } from '../../../constants';

import { SecretFile } from '../../SecretFile';

import * as felaRules from './FileField.rules';

export interface FileFieldProps {
    onChange?: () => void;
}

export const FileField = ({ onChange }: FileFieldProps) => {
    const name = Fields.FILE;

    const label = (
        <Label>
            <FormattedMessage id={`secret.${name}.label`} />
        </Label>
    );

    return (
        <Field<Base64File> allowNull name={name} format={identity} parse={identity}>
            {({ input, meta }) => {
                const { value } = input;

                const handleChange = (val?: Base64File) => {
                    input.onFocus();
                    input.onChange(val);
                    input.onBlur();

                    onChange?.();
                };

                return (
                    <FormItem<Base64File> label={label} meta={meta} customStyle={felaRules.formItem}>
                        <FileInput
                            file={value}
                            onFile={file => {
                                if (file) {
                                    getBase64EncodedFile(file)
                                        .then(base64 => {
                                            handleChange(base64);
                                        })
                                        .catch(() => {
                                            handleChange(null);
                                        });
                                } else {
                                    handleChange(null);
                                }
                            }}
                            renderFile={({ onRemove }) => <SecretFile file={value} onRemove={onRemove} />}
                        />
                    </FormItem>
                );
            }}
        </Field>
    );
};
