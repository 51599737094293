import { AutoComplete } from 'antd';
import type { AutoCompleteProps as AntAutoCompleteProps } from 'antd/es/auto-complete';
import type { BaseOptionType, DefaultOptionType } from 'antd/es/select';
import { useFela } from 'react-fela';

import * as felaRules from './Autocomplete.rules';

export interface AutocompleteProps<
    Value = any,
    OptionType extends DefaultOptionType | BaseOptionType = DefaultOptionType,
> extends AntAutoCompleteProps<Value, OptionType> {
    customStyle?: typeof felaRules.autocomplete;
}

export function Autocomplete<Value = any, OptionType extends DefaultOptionType | BaseOptionType = DefaultOptionType>({
    customStyle,
    ...props
}: AutocompleteProps<Value, OptionType>) {
    const { css } = useFela();
    return (
        <AutoComplete<Value, OptionType>
            className={css(felaRules.autocomplete, customStyle)}
            getPopupContainer={triggerNode => triggerNode.parentElement}
            {...props}
        />
    );
}
