import { useFela } from 'react-fela';

import { Alert as AntAlert } from 'antd';
import type { AlertProps as AntAlertProps } from 'antd/es/alert';
import type { CSSProperties } from 'fela';

import type { AlertSize } from './Alert.rules';
import * as felaRules from './Alert.rules';

export interface AlertProps extends Omit<AntAlertProps, 'className'> {
    customStyle?: typeof felaRules.container;
    size?: AlertSize;
    textAlign?: CSSProperties['textAlign'];
}

export const Alert = ({ customStyle, size = 'small', textAlign = 'left', ...props }: AlertProps) => {
    const { css } = useFela({ size, textAlign });

    return <AntAlert {...props} className={css(felaRules.container, customStyle)} />;
};
