import type { TRuleWithTheme } from 'styles/theme';

export const countdownIcon: TRuleWithTheme = () => ({
    marginLeft: '0.625rem',
    marginTop: '-0.125rem',
});

export const countdownTimerText: TRuleWithTheme = ({ theme }) => ({
    position: 'relative',
    top: -1,

    fontSize: '0.7rem',
    fontWeight: 600,
    lineHeight: 'normal',
    letterSpacing: '0.01rem',

    color: theme.colors.accentTertiary,
});
