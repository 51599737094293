import type { ReactNode } from 'react';
import { useFela } from 'react-fela';

import type { Theme } from 'styles/theme';

import { container, EmptySize } from './Empty.rules';

export interface EmptyProps {
    text: ReactNode;
    size?: `${EmptySize}`;
    color?: keyof Theme['colors'];
    customStyle?: typeof container;
    'data-testid'?: string;
}

export const Empty = ({ text, size = EmptySize.REGULAR, color, customStyle, 'data-testid': testId }: EmptyProps) => {
    const { css } = useFela({ size, color });

    return (
        <div className={css(container, customStyle)} data-testid={testId}>
            {text}
        </div>
    );
};
