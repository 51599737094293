import type { TRuleWithTheme } from 'styles/theme';

export const title: TRuleWithTheme = () => ({
    marginBottom: '0.25rem',
});

export const info: TRuleWithTheme = () => ({
    marginBottom: '1.5rem',
});

export const container: TRuleWithTheme = () => ({
    marginBottom: '2rem',
});
