import type { TRuleWithTheme } from 'styles/theme';

export const container: TRuleWithTheme<{
    isActive: boolean;
}> = ({ theme }) => ({
    display: 'block',
    height: '100%',

    min768: {
        display: 'flex',

        paddingInline: '2.5rem',

        ':hover': {
            cursor: 'pointer',
            backgroundColor: theme.colors.paleMauve,
            '& .Heading': {
                color: theme.colors.brightMagenta,
            },
        },
    },
});
