import { useFela } from 'react-fela';

import { OverdueInvoiceAlert } from 'modules/settings';

import { SecretsCountAlert } from '../SecretsCountAlert';
import { Secrets } from '../Secrets';

import * as felaRules from './SecretsWithFilter.rules';
import { SecretsHeader } from './SecretsHeader';

export const SecretsWithFilter = () => {
    const { css } = useFela();

    return (
        <div className={css(felaRules.container)}>
            <SecretsHeader />
            <OverdueInvoiceAlert />
            <SecretsCountAlert />
            <Secrets />
        </div>
    );
};
