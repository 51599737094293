import { createAction, createReducer } from '@reduxjs/toolkit';
import type { ActionCreator } from '@reduxjs/toolkit';

interface ApiInfiniteListState {
    inProgress: boolean;
    inProgressNext: boolean;
    success: boolean;
    error: unknown;
    lastSuccessAt: string;
    nextPageToken: string;
}

interface TypedActionCreator<A>
    extends ActionCreator<
        A & {
            type: string;
        }
    > {
    type: string;
    toString: () => string;
}

interface ActionCreators {
    request: TypedActionCreator<{
        meta: {
            isNext: boolean;
        };
    }>;
    success: TypedActionCreator<{
        meta: {
            lastSuccessAt: string;
            nextPageToken: string;
            isNext: boolean;
        };
    }>;
    failure: TypedActionCreator<{
        meta: {
            isNext: boolean;
        };
        error: unknown;
    }>;
}

export const createInfiniteApiReducer = (
    actionCreators: ActionCreators,
    initialState: Partial<ApiInfiniteListState> = undefined,
) =>
    createReducer(
        {
            inProgress: false,
            inProgressNext: false,
            success: false,
            error: null,
            lastSuccessAt: null,
            nextPageToken: null,
            ...initialState,
        } as ApiInfiniteListState,
        builder => {
            builder.addCase(actionCreators.request, (state, action) => {
                if (action.meta.isNext) {
                    state.inProgressNext = true;
                } else {
                    state.inProgress = true;
                }

                state.success = false;
                state.error = null;
            });
            builder.addCase(actionCreators.success, (state, action) => {
                if (action.meta.isNext) {
                    state.inProgressNext = false;
                } else {
                    state.inProgress = false;
                }

                state.success = true;
                state.error = null;

                state.lastSuccessAt = action.meta.lastSuccessAt;
                state.nextPageToken = action.meta.nextPageToken;
            });
            builder.addCase(actionCreators.failure, (state, action) => {
                if (action.meta.isNext) {
                    state.inProgressNext = false;
                } else {
                    state.inProgress = false;
                }

                state.error = action.error;
            });
        },
    );

export const createFetchAction = (type: string) =>
    createAction(type, (filter?: string) => ({
        payload: undefined,
        meta: {
            filter,
        },
    }));

export const createFetchNextAction = (type: string) =>
    createAction(type, (filter?: string) => ({
        payload: undefined,
        meta: {
            filter,
        },
    }));

export const createFetchRequestAction = (type: string) =>
    createAction(type, (isNext: boolean = false) => ({
        payload: undefined,
        meta: {
            isNext,
        },
    }));

export function createFetchSuccessAction<
    E extends {
        id: string;
    },
>(type: string) {
    return createAction(
        type,
        (
            byIds: {
                [key: string]: E;
            } = {},
            ids: E['id'][] = [],
            {
                nextPageToken,
                filter,
                isNext = false,
            }: {
                nextPageToken?: string;
                filter?: string;
                isNext?: boolean;
            } = {},
        ) => ({
            payload: {
                byIds,
                ids,
            },
            meta: {
                lastSuccessAt: new Date().toISOString(),
                nextPageToken,
                filter,
                isNext,
            },
        }),
    );
}

export const createFetchFailureAction = (type: string) =>
    createAction(type, (error: unknown, isNext: boolean = false) => ({
        payload: undefined,
        error,
        meta: {
            isNext,
        },
    }));

export const createFetchCancelAction = (type: string) =>
    createAction(type, (isNext: boolean = false) => ({
        payload: undefined,
        meta: {
            isNext,
        },
    }));
