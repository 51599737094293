import { translateSelector } from '@ackee/jerome';
import { createSelector } from '@reduxjs/toolkit';

import { ListItemType, SecurityLevel } from '../../constants';
import type { SecurityAuditLevelSecret, SecurityAuditLevelGroup } from '../../types';

import { selectMappedSecrets } from './list';
import { getSecretsWithGroups, compareByName } from './utilities';

const selecSecurityAuditLevelListSecret = createSelector(selectMappedSecrets, secrets =>
    secrets.map<SecurityAuditLevelSecret>(secret => ({
        ...secret,
        type: ListItemType.SECRET,
        key: `level-secret-${secret.id}`,
    })),
);

export const selectSecurityAuditLevelSortedListSecrets = createSelector(
    [selecSecurityAuditLevelListSecret, translateSelector],
    (secrets, { locale }) =>
        secrets.sort(function (secretA, secretB) {
            const securityLevelA = secretA.securityLevel;
            const securityLevelB = secretB.securityLevel;

            if (securityLevelA === securityLevelB) {
                return compareByName(secretA, secretB, locale);
            }

            // We want undefined security level to be ordered at the end
            // (the value is "undefined" so it can't be ordered as numbers)
            if (securityLevelA < securityLevelB || securityLevelB === undefined) {
                return -1;
            }
            if (securityLevelA > securityLevelB || securityLevelA === undefined) {
                return 1;
            }

            return compareByName(secretA, secretB, locale);
        }),
);

const createAuditLevelGroup = (securityLevel: SecurityLevel): SecurityAuditLevelGroup => ({
    type: ListItemType.GROUP,
    key: `level-group-${securityLevel ?? 'noSecurityLevel'}`,
    securityLevel,
    count: 0,
});

export const getSecurityAuditSecretsWithLevelGroups = (secrets: SecurityAuditLevelSecret[]) =>
    getSecretsWithGroups(
        secrets,
        (prevSecret, currentSecret) => prevSecret.securityLevel !== currentSecret.securityLevel,
        secret => createAuditLevelGroup(secret.securityLevel),
        group => {
            group.count += 1;
        },
    );

export const selectSecurityAuditSecretsWithLevelGroups = createSelector(
    [selectSecurityAuditLevelSortedListSecrets],
    getSecurityAuditSecretsWithLevelGroups,
);
