export enum SecretsFormat {
    PASSWD = 'passwd',
    LASTPASS = 'lastpass',
    NORDPASS = 'nordpass',
}

export enum Delimiter {
    COMMA = ',',
    SEMICOLON = ';',
}

export interface ImportFormValues {
    secrets: File;
    format: SecretsFormat;
    delimiter: Delimiter;
    replaceSecrets: boolean;
}

export interface ImportValidationErrorData {
    invalidGroupError: number;
    parseSecretError: number;
    invalidSecretError: number;
    invalidUserError: number;
}
