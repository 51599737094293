import { store } from 'modules/core/modules/redux';

import { BACKEND_VERSION_HEADER } from './constants';
import { setBackendVersion } from './services/slices';

export function backendVersionInterceptor(response: Response) {
    const backendVersion = response.headers.get(BACKEND_VERSION_HEADER);

    if (backendVersion) {
        store.dispatch(setBackendVersion(backendVersion));
    }

    return response;
}
