import { put } from 'redux-saga/effects';

import { api } from 'config/antonio';
import { config } from 'config/config';
import { logger } from 'config/logger';

import { takeLatestRequest } from 'services/utilities/takeRequest';

import {
    ignoreReportSecret,
    ignoreReportSecretReset,
    ignoreReportSecretSuccess,
    ignoreReportSecretFailure,
} from '../actions';

function* handler({ meta }: ReturnType<typeof ignoreReportSecret>, signal: AbortSignal) {
    try {
        yield* api.post(
            config.api.ignoreReport,
            {
                ignoreSecurityReport: true,
            },
            {
                uriParams: {
                    id: meta.id,
                },
                signal,
            },
        );

        yield put(ignoreReportSecretSuccess(meta.id));
    } catch (e) {
        logger.error(e);

        yield put(ignoreReportSecretFailure(meta.id, e));
    }
}

export default function* () {
    yield takeLatestRequest(
        {
            actionCreator: ignoreReportSecret,
            cancelActionFunction: action => ignoreReportSecretReset(action.meta.id),
            idSelector: action => action.meta.id,
        },
        handler,
    );
}
