import type { TRuleWithTheme } from 'styles/theme';

export const ANIMATION_DURATION_MS = 300;

export enum AnimationDirection {
    IN = 'in',
    OUT = 'out',
}

export const container: TRuleWithTheme<{ animationDirection: AnimationDirection }> = ({
    theme,
    animationDirection,
}) => ({
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',

    top: 0,
    left: 0,
    height: '100%',
    width: '100%',

    backgroundColor: theme.colors.white,
    boxShadow: `0 6px 16px 0 ${theme.colors.brownGrey3}`,

    transform: animationDirection === AnimationDirection.IN ? 'translateX(0%)' : 'translateX(100%)',
    opacity: animationDirection === AnimationDirection.IN ? 1 : 0,

    transition: `all ${ANIMATION_DURATION_MS}ms ease-in-out`,
});

export const header: TRuleWithTheme = ({ theme }) => ({
    display: 'flex',
    gap: '0.62rem',
    alignItems: 'center',

    padding: '1rem 1rem 0.62rem 1rem',

    borderBottom: `1px solid ${theme.colors.foregroundsSeparator}`,

    fontSize: '0.875rem',
    fontWeight: 400,
    color: theme.colors.foregroundsPrimary,
});

export const closeButton: TRuleWithTheme = ({ theme }) => ({
    '&.ant-btn-link': {
        height: '1rem',
        width: '1rem',

        lineHeight: 'unset',
    },
});

export const chevronLeftIcon: TRuleWithTheme = ({ theme }) => ({
    rotate: '90deg',

    height: '1rem',
    width: '1rem',

    color: theme.colors.accentSecondary,
});

export const members: TRuleWithTheme = ({ theme }) => ({
    height: '100%',
    width: '100%',
    padding: '0.62rem 1rem 1rem 1rem',

    overflowY: 'scroll',
});
