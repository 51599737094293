import type { AccessPermission } from 'types';

import { useHasGlobalSharingPermissions } from 'modules/settings';
import { hasReadPermission } from 'utilities/permission';

import { isPublicSharingActive } from '../utilities';

import type { Share } from '../types';

interface UseIsSharingIndicatorVisibleProps {
    share: Share;
    userPermissions: AccessPermission[];
}

export const useSharingIndicatorVisibility = () => {
    const hasSharingPermissions = useHasGlobalSharingPermissions();

    const getSharingIndicatorVisibility = ({ share, userPermissions }: UseIsSharingIndicatorVisibleProps) => {
        const publicSharingActive = isPublicSharingActive(share);
        const hasRead = hasReadPermission(userPermissions);

        const shouldShowSharingIndicator = publicSharingActive && hasSharingPermissions && hasRead;

        return shouldShowSharingIndicator;
    };

    return { getSharingIndicatorVisibility };
};
