import type { User, EntityWithPermissions } from 'types';

export interface WhitelistUser extends User, EntityWithPermissions {}

export type WhitelistUserById = {
    [key: WhitelistUser['id']]: WhitelistUser;
};

export enum EntityKey {
    WHITELIST_USERS = 'whitelistUser',
}
