import type { TRuleWithTheme } from 'styles/theme';

export const modalContainer: TRuleWithTheme = () => ({
    '& .ant-modal-body': {
        padding: 0,
    },
});

export const content: TRuleWithTheme = () => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',

    paddingTop: '3.875rem',
    paddingRight: '2rem',
    paddingBottom: '3.875rem',
    paddingLeft: '2rem',
});

export const loader: TRuleWithTheme = () => ({
    marginBottom: '1rem',
});
