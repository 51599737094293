import { useEffect, useRef } from 'react';
import { useFela } from 'react-fela';
import { FormattedMessage } from 'react-intl';

import { config } from 'config/config';

import extPromo from 'assets/images/ext_promo.png';

import { Heading, Text } from 'modules/ui';
import { Modal, useModal, StaticModal } from 'modules/modals';

import { container, promo, wrapper, heading, buttons } from './ExtensionPrompt.rules';

export const ExtensionPrompt = () => {
    const { css } = useFela();

    const { modal, updateModal } = useModal(StaticModal.EXTENSION);

    const modalRef = useRef(modal);
    const updateModalRef = useRef(updateModal);

    useEffect(() => {
        if (modalRef.current === undefined) {
            updateModalRef.current(true);
        }
    }, []);

    const handleClick = () => {
        updateModal(false);
    };

    return (
        <Modal
            open={modal?.visibility}
            okButton={{
                href: config.extensionPromo.url,
                target: '_blank',
                onClick: handleClick,
                children: <FormattedMessage id="extension.modal.ok" />,
            }}
            cancelButton={{
                onClick: handleClick,
                children: <FormattedMessage id="extension.modal.cancel" />,
            }}
            width={400}
            extend={{
                container,
                buttons,
            }}
        >
            <img src={extPromo} alt="" className={css(promo)} />
            <div className={css(wrapper)}>
                <Heading elementType="h2" color="black" customStyle={heading}>
                    <FormattedMessage id="extension.modal.title" />
                </Heading>
                <Text color="brownGrey2">
                    <FormattedMessage id="extension.modal.text" />
                </Text>
            </div>
        </Modal>
    );
};
