import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { config } from 'config/config';

import { Button, Icon, IconButton } from 'modules/ui';

import { useBreakpoint } from 'hooks/useBreakpoint';

import { useSaasCountReached } from '../../hooks/useSaasCountReached';

import * as felaRules from './AddSecretButton.rules';

export const AddSecretButton = () => {
    const history = useHistory();
    const { formatMessage } = useIntl();

    const { isError } = useSaasCountReached();

    const isMin768 = useBreakpoint('sm');

    if (isMin768) {
        return (
            <Button
                type="primary"
                icon={<Icon type="add" />}
                disabled={isError}
                size="large"
                onClick={() => {
                    history.push(config.routes.newSecret);
                }}
            >
                <>
                    <FormattedMessage id="secret.add" />
                </>
            </Button>
        );
    }

    return (
        <IconButton
            type="primary"
            icon="add"
            disabled={isError}
            customStyle={felaRules.iconButtonContainer}
            aria-label={formatMessage({
                id: 'secret.list.add',
            })}
            onClick={() => {
                history.push(config.routes.newSecret);
            }}
        />
    );
};
