import { eventChannel } from 'redux-saga';
import { take } from 'redux-saga/effects';
import { isAntonioError } from '@ackee/antonio-core';

import { api } from 'config/antonio';
import { logger } from 'config/logger';

import doLogout from '../helpers/doLogout';

function createUnauthorizedResponseTrigger() {
    return eventChannel(emit => {
        const unauthorizedInterceptor = api.interceptors.response.use(
            response => response,
            error => {
                if (isAntonioError(error))
                    if ([401].includes(error?.response?.status)) {
                        logger.error(error);
                        emit(true);
                        return;
                    }

                return error;
            },
        );

        return () => {
            api.interceptors.response.eject(unauthorizedInterceptor);
        };
    });
}

export function* unauthorizedSaga() {
    try {
        const trigger = yield createUnauthorizedResponseTrigger();

        yield take(trigger);

        trigger.close();

        yield* doLogout();
    } catch (e) {
        logger.error(e);
    }
}
