import type { TRuleWithTheme } from 'styles/theme';

export const title: TRuleWithTheme = () => ({
    display: 'flex',
    alignItems: 'center',

    gap: '0.5rem',

    marginBottom: '0.25rem',
});

export const info: TRuleWithTheme = () => ({
    marginBottom: '0.5rem',
});
