import { useFela } from 'react-fela';
import { FormattedMessage, useIntl } from 'react-intl';

import { Button, Empty, Icon, Text } from 'modules/ui';
import { StaticModal, useModal } from 'modules/modals';
import { ALL_USERS_GROUP_ID } from 'modules/groups';

import { useViewFilter } from '../../hooks/useViewFilter';

import * as felaRules from './SecretsListContainer.rules';

export const SecretsListEmpty = () => {
    const { css } = useFela();

    const intl = useIntl();

    const { viewFilter } = useViewFilter();

    const { updateModal } = useModal(StaticModal.VIEW_FILTER);

    if (viewFilter) {
        const filterName =
            viewFilter.id === ALL_USERS_GROUP_ID
                ? intl.formatMessage({ id: 'secret.groups.allUsers.label' })
                : viewFilter.name;

        return (
            <div className={css(felaRules.container)} data-testid="secrets-list-empty">
                <Text>
                    <FormattedMessage id="secrets.view.empty.help" />
                </Text>
                <Text size="small" color="brownGrey" customStyle={felaRules.activeView}>
                    <FormattedMessage
                        id="secrets.view.empty.active"
                        values={{
                            view: (
                                <>
                                    <Icon type={viewFilter.type} width={16} height={16} customStyle={felaRules.icon} />
                                    <Button
                                        aria-label={intl.formatMessage(
                                            {
                                                id: 'secrets.view.empty.active',
                                            },
                                            {
                                                view: viewFilter.name,
                                            },
                                        )}
                                        type="link"
                                        onClick={() => {
                                            updateModal(true);
                                        }}
                                    >
                                        {filterName}
                                    </Button>
                                </>
                            ),
                        }}
                    />
                </Text>
            </div>
        );
    }

    return <Empty text={<FormattedMessage id="secret.list.empty" />} data-testid="secrets-list-empty" />;
};
