import type { TRuleWithTheme } from 'styles/theme';

export const button: TRuleWithTheme = ({ theme }) => ({
    '&.ant-btn.ant-btn-lg': {
        fontWeight: 400,
        padding: 0,
        fontSize: '0.875rem',
        letterSpacing: 'unset',

        min768: {
            fontSize: '1rem',
        },

        '&:hover': {
            color: theme.colors.accentPrimary,
        },
    },
});

export const caret: TRuleWithTheme<{ popoverOpen: boolean }> = ({ theme, popoverOpen }) => ({
    color: popoverOpen ? theme.colors.accentSecondary : theme.colors.iconsTertiary,

    transform: popoverOpen ? 'rotate(0deg)' : 'rotate(-90deg)',
    transitionProperty: 'transform color',
    transitionDuration: '0.3s',
});

export const popover: TRuleWithTheme = ({ theme }) => ({
    '& .ant-popover-inner': {
        overflow: 'hidden',
        position: 'relative',
    },
});
