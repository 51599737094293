import { FormattedMessage } from 'react-intl';

import config from 'config';

import type { SaasLimits } from 'types';

import { Modal, StaticModal, useModal } from 'modules/modals';
import { Heading, Icon, Text } from 'modules/ui';

import * as felaRules from './importLimitReachedModal.rules';

export const ImportLimitReachedModal = () => {
    const { modal, updateModal } = useModal<SaasLimits>(StaticModal.IMPORT_LIMIT_REACHED, {
        autoClose: true,
    });

    return (
        <Modal
            open={modal?.visibility}
            width={474}
            okButton={{
                type: 'primary',
                onClick: () => {
                    window.open(config.links.payment, '_blank');
                },
                children: <FormattedMessage id="import.modal.limit-reached.ok" />,
            }}
            cancelButton={{
                onClick: () => {
                    updateModal(false);
                },
                children: <FormattedMessage id="import.modal.limit-reached.cancel" />,
            }}
            extend={{ buttons: felaRules.buttons }}
            alignButtonsRight
        >
            <Heading elementType="h2" color="black" customStyle={felaRules.heading}>
                <Icon type="warning" color="red" customStyle={felaRules.icon} />
                <FormattedMessage id="import.modal.limit-reached.title" />
            </Heading>
            <Text size="small" color="brownGrey">
                <FormattedMessage
                    id="import.modal.limit-reached.desc"
                    values={{
                        beforeImport: modal?.data?.beforeImport,
                        importAmount: modal?.data?.importAmount,
                        maxSecrets: modal?.data?.maxSecrets,
                    }}
                />
            </Text>
        </Modal>
    );
};
