import { useFela } from 'react-fela';
import { Link, generatePath, useLocation } from 'react-router-dom-v5-compat';

import { config } from 'config/config';

import type { Secret } from '../../../types';

import { SecretItemInfo } from './SecretIItemInfo';

import { container } from './SecretItem.rules';

export interface SecretItemProps extends Secret {}

export const SecretItem = (props: SecretItemProps) => {
    const { css } = useFela();
    const { pathname, search } = useLocation();

    return (
        <Link
            to={generatePath(config.routes.secret, {
                id: props.id,
            })}
            className={css(container)}
            role="link"
            tabIndex={0}
            data-testid="secrets-list-item"
            state={{ from: pathname + search }}
        >
            <SecretItemInfo {...props} />
        </Link>
    );
};
