import { useFela } from 'react-fela';

import { Loadable } from 'modules/ui';
import { useIsAdmin } from 'modules/auth';

import { useNewSecretPage } from '../../hooks/useNewSecretPage';
import { NewSecretFormProvider } from '../NewSecretFormProvider';
import { NewSecretForm } from '../NewSecretForm';
import { MaxSaasSecretsFirewall } from '../MaxSaasSecretsFirewall';
import { BackButton } from '../BackButton';

import { NewSecretHeading } from './NewSecretHeading';

import * as felaRules from './NewSecretPage.rules';

export const NewSecretPage = () => {
    const { showLoader } = useNewSecretPage();

    const isAdmin = useIsAdmin();

    const { css } = useFela({ isAdmin });

    if (showLoader) {
        return <Loadable showLoader />;
    }

    return (
        <MaxSaasSecretsFirewall>
            <NewSecretFormProvider>
                {() => (
                    <main className={css(felaRules.contentContainer)}>
                        <BackButton />
                        <NewSecretHeading />
                        <NewSecretForm />
                    </main>
                )}
            </NewSecretFormProvider>
        </MaxSaasSecretsFirewall>
    );
};
