import { lazy, Suspense, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useFela } from 'react-fela';
import { Field } from 'react-final-form';
import { delay, identity } from 'lodash';
import useToggle from 'react-use-toggle';

import { Icon } from 'modules/ui';
import { Label, FormItem, TextInput, PasswordInput } from 'modules/forms';

import { Fields } from '../../../constants';

import { GeneratePasswordWidget } from '../../GeneratePasswordWidget';

import * as felaRules from './PasswordField.rules';
import { GeneratorButtonPopover } from './GeneratorButtonPopover';

const PasswordStrength = lazy(() =>
    import('../PasswordStrength').then(imports => ({
        default: imports.PasswordStrength,
    })),
);

export interface PasswordFieldProps {
    fullWidth?: boolean;
}

export const PasswordField = ({ fullWidth }: PasswordFieldProps) => {
    const { css } = useFela();

    const [hidden, toggle] = useToggle(true);
    const [generatorButtonPopoverOpen, setGeneratorPopoverButtonOpen] = useState(false);
    const [passwordGeneratorOpen, setPasswordGeneratorOpen] = useState(false);

    const name = Fields.PASSWORD;
    const label = (
        <Label>
            <FormattedMessage id={`secret.${name}`} />
        </Label>
    );

    return (
        <Field<string> name={name} format={identity} parse={identity}>
            {({ input, meta }) => {
                const { value, onFocus, onBlur, onChange } = input;

                const handleChange = (e: React.ChangeEvent<HTMLInputElement> | string) => {
                    const value = typeof e === 'string' ? e : e.target.value;

                    onChange(value);
                };

                const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
                    setGeneratorPopoverButtonOpen(true);
                    onFocus(e);
                };

                const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
                    // Delay closing the popover so the user can click on the button after the input loses focus
                    delay(setGeneratorPopoverButtonOpen, 250, false);
                    onBlur(e);
                };

                const handleGenerate = (password: string) => {
                    onFocus();
                    onChange(password);
                    onBlur();
                };

                return (
                    <>
                        <div className={css(felaRules.wrapper)}>
                            <div className={css(felaRules.container)}>
                                <FormItem<string>
                                    htmlFor={name}
                                    label={label}
                                    customStyle={felaRules.formItem}
                                    fullWidth={fullWidth}
                                    meta={meta}
                                >
                                    <GeneratePasswordWidget
                                        open={passwordGeneratorOpen}
                                        onClose={() => {
                                            setPasswordGeneratorOpen(false);
                                            setGeneratorPopoverButtonOpen(false);
                                        }}
                                        onSave={handleGenerate}
                                    >
                                        <GeneratorButtonPopover
                                            isOpen={generatorButtonPopoverOpen && !passwordGeneratorOpen}
                                            openPasswordGenerator={() => setPasswordGeneratorOpen(true)}
                                        >
                                            {hidden ? (
                                                <TextInput
                                                    id={name}
                                                    value={value}
                                                    onFocus={handleFocus}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    type="password"
                                                    suffix={<Icon onClick={toggle} type="eye" color="blue" />}
                                                    customStyle={felaRules.cryptedTextInput}
                                                    // Hack to prevent autocomplete in Chrome: https://stackoverflow.com/a/30976223
                                                    autoComplete="one-time-code"
                                                />
                                            ) : (
                                                <PasswordInput
                                                    id={name}
                                                    value={value}
                                                    onFocus={handleFocus}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    suffix={
                                                        <Icon onClick={toggle} type="eye-close" color="brightMagenta" />
                                                    }
                                                    customStyle={felaRules.textInput}
                                                />
                                            )}
                                        </GeneratorButtonPopover>
                                    </GeneratePasswordWidget>

                                    <Suspense fallback={null}>
                                        <PasswordStrength value={value} />
                                    </Suspense>
                                </FormItem>
                            </div>
                        </div>
                    </>
                );
            }}
        </Field>
    );
};
