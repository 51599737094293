import { put, select } from 'redux-saga/effects';
import { setUserWithTokens } from '@ackee/petrus';
import type { PetrusTokens } from '@ackee/petrus';
import type { Tokens } from '@ackee/petrus';

import { authTokens } from 'services/selectors';

import { enforceAccessTokenScheme } from '../petrus/enforceAccessTokenScheme';
import { getDemoUser } from '../helpers/getDemoUser';

import { isDemoAccessToken } from '../utils';

export default function* setDemoUser(userId: string) {
    const { accessToken }: PetrusTokens = yield select(authTokens);

    if (isDemoAccessToken(accessToken)) {
        const { user } = getDemoUser(userId);

        const tokens = {
            accessToken: enforceAccessTokenScheme({
                userId,
                googleUser: accessToken.googleUser,
            }),
            refreshToken: null,
            // TODO: the package should use PetrusTokens instead as they are populated with custom tokens
        } as Tokens;

        yield put(setUserWithTokens(user, tokens));
    }
}
