import type { TRuleWithTheme } from 'styles/theme';

export const container: TRuleWithTheme = () => ({
    marginBottom: '0.5rem',

    min1024: {
        marginBottom: '1rem',
    },
});

export const tag: TRuleWithTheme = ({ theme }) => ({
    '&.ant-tag': {
        paddingRight: '0.5rem',
        paddingLeft: '0.5rem',

        border: `1px solid ${theme.colors.lightGrey}`,
        backgroundColor: theme.colors.backgroundsSecondary,

        fontSize: '0.75rem',
        lineHeight: '1.25em',
    },
});

export const intro: TRuleWithTheme = ({ theme }) => ({
    display: 'inline-block',

    marginRight: '0.375rem',

    color: theme.colors.foregroundsTertiary,
});
