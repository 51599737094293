import { MODULE_PREFIX } from '../constants';

export default {
    EXPORT_REQUEST: `${MODULE_PREFIX}/EXPORT_REQUEST`,
    EXPORT_REQUEST_SUCCESS: `${MODULE_PREFIX}/EXPORT_REQUEST_SUCCESS`,
    EXPORT_REQUEST_FAILURE: `${MODULE_PREFIX}/EXPORT_REQUEST_FAILURE`,

    START_IMPORT: `${MODULE_PREFIX}/START_IMPORT`,
    IMPORT_REQUEST: `${MODULE_PREFIX}/IMPORT_REQUEST`,
    IMPORT_REQUEST_SUCCESS: `${MODULE_PREFIX}/IMPORT_REQUEST_SUCCESS`,
    IMPORT_REQUEST_FAILURE: `${MODULE_PREFIX}/IMPORT_REQUEST_FAILURE`,

    IMPORT_VALIDATION_REQUEST: `${MODULE_PREFIX}/IMPORT_VALIDATION_REQUEST`,
    IMPORT_VALIDATION_REQUEST_SUCCESS: `${MODULE_PREFIX}/IMPORT_VALIDATION_REQUEST_SUCCESS`,
    IMPORT_VALIDATION_REQUEST_FAILURE: `${MODULE_PREFIX}/IMPORT_VALIDATION_REQUEST_FAILURE`,
    IMPORT_VALIDATION_REQUEST_CANCEL: `${MODULE_PREFIX}/IMPORT_VALIDATION_REQUEST_CANCEL`,
};
