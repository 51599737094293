import { useFela } from 'react-fela';
import { Tabs as AntTabs } from 'antd';

import type { TabsProps as AntTabsProps } from 'antd';

import { container } from './Tabs.rules';

export interface TabsProps extends Omit<AntTabsProps, 'className'> {
    customStyle?: typeof container;
}

export const Tabs = ({ customStyle, ...props }: TabsProps) => {
    const { css } = useFela();

    return <AntTabs {...props} className={css(container, customStyle)} />;
};
