import type {
    SecurityAuditAgeGroup,
    SecurityAuditAgeSecret,
    SecurityAuditDuplicitiesSecret,
    SecurityAuditLeaksGroup,
    SecurityAuditLeaksSecret,
    SecurityAuditLevelGroup,
    SecurityAuditReportItem,
} from '../types';

export const isSecurityAuditAgeSecret = (item: SecurityAuditReportItem): item is SecurityAuditAgeSecret =>
    'securityAge' in item;

export const isSecurityAuditDuplicitiesSecret = (
    item: SecurityAuditReportItem,
): item is SecurityAuditDuplicitiesSecret => 'duplicities' in item;

export const isSecurityAuditLeaksSecret = (item: SecurityAuditReportItem): item is SecurityAuditLeaksSecret =>
    'securityLeaks' in item;

export const isSecurityAuditAgeGroup = (item: SecurityAuditReportItem): item is SecurityAuditAgeGroup =>
    'securityAge' in item;

export const isSecurityAuditLevelGroup = (item: SecurityAuditReportItem): item is SecurityAuditLevelGroup =>
    'securityLevel' in item;

export const isSecurityAuditLeaksGroup = (item: SecurityAuditReportItem): item is SecurityAuditLeaksGroup =>
    'securityLeaks' in item;
