import type { SVGProps } from 'react';

export const Info = (props: SVGProps<SVGSVGElement>) => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11 18H13V10H11V18ZM11 8H13V6H11V8ZM12 22C17.522 22 22 17.522 22 12C22 6.478 17.522 2 12 2C6.478 2 2 6.478 2 12C2 17.522 6.478 22 12 22Z"
        />
    </svg>
);
