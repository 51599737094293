import { Form } from 'react-final-form';
import { generatePath, useNavigate, useParams, useLocation } from 'react-router-dom-v5-compat';
import type { FormProps } from 'react-final-form';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { useAppSelector } from 'hooks/useAppSelector';

import { handleFormSubmit } from 'modules/forms';
import config from 'config';

import type { SecretFormValues } from '../../types';
import { validateSecretFormFactory } from '../../utilities';
import { Fields, TOTP_VALUE_PLACEHOLDER } from '../../constants';

import { updateSecret } from '../../services/actions';
import { selectUpdateSecretFormInitialValues } from '../../services/selectors';

export interface UpdateSecretFormProviderProps {
    children: FormProps<SecretFormValues>['children'];
}

const initialValuesEqual = () => true;

export const UpdateSecretFormProvider = ({ children }: UpdateSecretFormProviderProps) => {
    const { id } = useParams<{
        id: string;
    }>();
    const { state } = useLocation();
    const navigate = useNavigate();

    const intl = useIntl();

    const dispatch = useDispatch();

    const initialValues = useAppSelector(state =>
        selectUpdateSecretFormInitialValues(state, {
            id,
        }),
    );

    return (
        <Form<SecretFormValues>
            initialValues={initialValues}
            // Turn off reinitializations, the form is the source of truth
            initialValuesEqual={initialValuesEqual}
            onSubmit={async (values, form) => {
                // Do not keep secret key, replace it with placeholder and send value to backend
                form.change(Fields.TOTP, values.TOTP ? TOTP_VALUE_PLACEHOLDER : null);

                const errors = await handleFormSubmit<SecretFormValues>((...args) => {
                    dispatch(updateSecret(id, ...args));
                })(values);

                if (errors) return errors;

                if (state?.from) {
                    navigate(state.from);
                } else {
                    navigate(generatePath(config.routes.secret, { id }));
                }
            }}
            validate={validateSecretFormFactory(intl)}
        >
            {children}
        </Form>
    );
};
