import { call, takeEvery, put } from 'redux-saga/effects';

import { api } from 'config/antonio';
import { config } from 'config/config';
import { logger } from 'config/logger';

import { actions as messagesActions } from 'modules/messages';

import { fetchGroupMembers, fetchGroupMembersSuccess, fetchGroupMembersFailure } from '../actions';
import { normalizeGroupMembers } from '../normalizers';

function* handler({ meta: { groupId } }: ReturnType<typeof fetchGroupMembers>) {
    try {
        const { data } = yield call([api, api.get], config.api.groupMembers, {
            uriParams: {
                id: groupId,
            },
        });

        const { entities, result } = normalizeGroupMembers(data);

        yield put(fetchGroupMembersSuccess(groupId, entities.groupMembers, result));
    } catch (e) {
        yield put(
            messagesActions.displayErrorMessage({
                message: { id: 'error.groups.fetchMembers' },
            }),
        );
        logger.error(e);

        yield put(fetchGroupMembersFailure(groupId, e.message));
    }
}

export function* fetchGroupMembersSaga() {
    yield takeEvery(fetchGroupMembers.toString(), handler);
}
