import type { TRuleWithTheme } from 'styles/theme';

export const container: TRuleWithTheme = () => ({
    display: 'grid',

    gap: '1rem',

    min1024: {
        gridAutoFlow: 'column',
        gridTemplateRows: 'repeat(2, auto)',
        gridTemplateColumns: 'repeat(2, 1fr)',

        gap: '2.5rem',
    },
});

export const rightColumn: TRuleWithTheme = () => ({
    min1024: {
        gridColumn: '2',
        gridRow: '1 / span 2',
    },
});

export const permissionsAlert: TRuleWithTheme = () => ({
    '&.ant-alert': {
        marginBottom: 0,
    },
});

export const tags: TRuleWithTheme = () => ({
    display: 'flex',
    gap: '0.5rem',

    flexWrap: 'wrap',
});
