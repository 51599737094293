export type TOTPErrorCode =
    /**
     * The QR code is invalid.
     */
    | 'NO_CONTENT_PARSED'

    /**
     * Shit happens.
     */
    | 'UNKNOWN';

export class TOTPError<
    Code extends TOTPErrorCode = TOTPErrorCode,
    Description extends string = string,
    OriginalError extends Error = Error,
> extends Error {
    public readonly code: Code;
    public readonly id: 'TOTPError';
    public readonly description: Description | undefined;
    public readonly originalError: OriginalError | null;

    constructor(code: Code, description?: Description, originalError: OriginalError | null = null) {
        super(description ? `${code}: ${description}` : code);

        this.id = 'TOTPError';
        this.code = code;
        this.description = description;
        this.name = 'TOTPError';
        this.originalError = originalError;
    }

    private stringify() {
        return JSON.stringify(this, null, 2);
    }

    toString() {
        return this.stringify();
    }
}
export function isTOTPError(error: unknown): error is TOTPError {
    return error instanceof TOTPError && error.id === 'TOTPError';
}

export function isTOTPErrorWithCodes<Codes extends TOTPErrorCode[], Description extends string = string>(
    error: unknown,
    codes: Codes,
): error is TOTPError<Codes[number], Description> {
    return isTOTPError(error) && codes.includes(error.code);
}
