import { useFela } from 'react-fela';
import { useDispatch } from 'react-redux';

import { FormattedMessage, useIntl } from 'react-intl';

import { Button, Icon, Text } from 'modules/ui';
import { actions as messagesActions } from 'modules/messages';

import { useCopyToClipboard } from 'modules/ui';

import * as felaRules from './SharePrivateLink.rules';

const options = {
    duration: 1,
};

export const SharePrivateLink = () => {
    const { css } = useFela();
    const dispatch = useDispatch();

    const intl = useIntl();

    const { handleCopy } = useCopyToClipboard({
        onSuccess: () => {
            dispatch(
                messagesActions.displaySuccessMessage({
                    message: intl.formatMessage({ id: 'success.secrets.share' }),
                    options,
                }),
            );
        },
        onError: () => {
            dispatch(
                messagesActions.displayErrorMessage({
                    message: intl.formatMessage({ id: 'error.secrets.share' }),
                    options,
                }),
            );
        },
    });

    return (
        <div className={css(felaRules.container)}>
            <Icon type="url" customStyle={felaRules.icon} />
            <div>
                <Text size="regular">
                    <FormattedMessage id="secret.share.privateLink.title" />
                </Text>
                <Text size="small" color="foregroundsTertiary">
                    <FormattedMessage id="secret.share.privateLink.description" />
                </Text>
            </div>
            <Button onClick={() => handleCopy(window.location.toString())} customStyle={felaRules.copyButton}>
                <FormattedMessage id="secret.share.copy" />
            </Button>
        </div>
    );
};
