import { all } from 'redux-saga/effects';

import { IS_SAAS, IS_DEMO } from 'constants/index';

import { saga as localizationsSaga } from 'modules/localizations';
import { saga as auth } from 'modules/auth';
import { saga as secrets } from 'modules/secrets';
import { saga as groups } from 'modules/groups';
import { saga as whitelist } from 'modules/whitelist';
import { saga as serviceWorker } from 'modules/service-worker';
import { saga as network } from 'modules/network';
import { saga as importExport } from 'modules/import-export';
import { saga as messages } from 'modules/messages';
import { saga as changelog } from 'modules/changelog';
import { saga as securityAudit } from 'modules/security-audit';
import { saga as userFeedback } from 'modules/user-feedback';
import { saga as wakeUp } from 'modules/wake-up';
import { saga as settings } from 'modules/settings';
import { saga as backendVersion } from 'modules/backend-version';

import { saga as tags } from 'modules/secrets/modules/tags';

import { app } from './app';

export default function* rootSaga() {
    yield all(
        [
            localizationsSaga(),
            app(),
            auth(),
            messages(),
            secrets(),
            groups(),
            whitelist(),
            serviceWorker(),
            network(),
            importExport(),
            changelog(),
            settings(),
            securityAudit(),
            backendVersion(),
            tags(),
            !IS_DEMO && !IS_SAAS ? wakeUp() : undefined,
            !IS_DEMO ? userFeedback() : undefined,
        ].filter(Boolean),
    );
}
