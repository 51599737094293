import { FormattedMessage } from 'react-intl';

import { Button, Icon } from 'modules/ui';

import { useHandleBackButtonClick } from './hooks';

import * as felaRules from './BackButton.rules';

export const BackButton = () => {
    const handleBackButtonClick = useHandleBackButtonClick();

    return (
        <Button
            type="text"
            size="large"
            icon={<Icon type="import-small" customStyle={felaRules.backButtonIcon} />}
            onClick={handleBackButtonClick}
            customStyle={felaRules.backButton}
        >
            <FormattedMessage id="back" />
        </Button>
    );
};
