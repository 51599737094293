import { FormattedMessage } from 'react-intl';

import { Alert, Icon, Link } from 'modules/ui';
import config from 'config';

import { useOverdueInvoiceAlert } from './hooks';

export const OverdueInvoiceAlert = () => {
    const { isOverdue, isLoggedUserAdmin, stripeAdminEmail } = useOverdueInvoiceAlert();

    if (isOverdue) {
        return (
            <Alert
                showIcon
                icon={<Icon type="warning" width={20} height={20} />}
                size="fullWidth"
                message={
                    isLoggedUserAdmin ? (
                        <FormattedMessage
                            id="settings.overdueInvoice.alert.admin"
                            values={{
                                link: chunks => (
                                    <Link type="native" href={config.links.stripe} inheritColor>
                                        {chunks}
                                    </Link>
                                ),
                                b: chunks => <strong>{chunks}</strong>,
                            }}
                        />
                    ) : (
                        <FormattedMessage
                            id="settings.overdueInvoice.alert.user"
                            values={{
                                stripeAdminEmail: (
                                    <Link type="native" href={`mailto:${stripeAdminEmail}`} inheritColor>
                                        {stripeAdminEmail}
                                    </Link>
                                ),
                                b: chunks => <strong>{chunks}</strong>,
                            }}
                        />
                    )
                }
                type="error"
                data-testid="overdue-invoice-alert"
            />
        );
    }

    return null;
};
