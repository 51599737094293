const modulePrefix = 'auth/';

export const actionTypes = {
    OAUTH_LOGIN: `${modulePrefix}OAUTH_LOGIN`,
    LOGIN_DEMO_USER: `${modulePrefix}LOGIN_DEMO_USER`,
    SELECT_DEMO_USER: `${modulePrefix}SELECT_DEMO_USER`,

    FETCH_USER_INFO: `${modulePrefix}FETCH_USER_INFO`,
    SET_USER_INFO: `${modulePrefix}FETCH_USER_INFO_SET`,
};
