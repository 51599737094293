import { useCallback } from 'react';

import type { MessageKey } from 'modules/localizations/translations/types';

import { isValueMatchingSearchTerm } from '../../../utilities';

import type { OptGroup, Option } from '../types';

const MAX_GROUP_ITEMS = 5;

type ObjectKeysWithStringValues<T> = {
    [K in keyof T]: T[K] extends string ? K : never;
}[keyof T];

interface CreateOptionsGroupParams<T> {
    inputEntities: T[];
    customFilter?: (arg: T) => boolean;
    createOptionMapFn: (arg: T) => Option;
    groupLabel: MessageKey;
    searchBy: ObjectKeysWithStringValues<T>[];
}

export const useCreateOptionsGroup = (searchTerm: string, locale: string) => {
    const createOptionsGroup = useCallback(
        <T>({
            inputEntities,
            customFilter,
            groupLabel,
            createOptionMapFn,
            searchBy,
        }: CreateOptionsGroupParams<T>): OptGroup | null => {
            const filteredOptions = inputEntities
                .filter(customFilter ?? (() => true))
                .map(entity => {
                    const searchByPropertyMatches = searchBy.map(property =>
                        isValueMatchingSearchTerm(entity[property] as string, searchTerm, locale),
                    );

                    // Prioritize options based on the order of searchBy properties
                    return { ...entity, searchPriority: searchByPropertyMatches.indexOf(true) };
                })
                .filter(entity => entity.searchPriority >= 0)
                .sort((a, b) => a.searchPriority - b.searchPriority)
                .slice(0, MAX_GROUP_ITEMS)
                .map<Option>(createOptionMapFn);

            if (filteredOptions.length === 0) return null;

            return {
                label: groupLabel,
                options: filteredOptions,
            };
        },
        [searchTerm, locale],
    );

    return { createOptionsGroup };
};
