import { all } from 'redux-saga/effects';

import { IS_DEMO, IS_SAAS } from 'constants/index';

import { wakeup } from './wakeup';
import { requestInterceptor } from './requestInterceptor';

export function* saga() {
    if (!IS_DEMO && !IS_SAAS) {
        yield all([wakeup(), requestInterceptor()]);
    }
}
