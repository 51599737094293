import { put } from 'redux-saga/effects';

import { config } from 'config/config';
import { logger } from 'config/logger';

import { takeLatestRequest } from 'services/utilities/takeRequest';

import { actions as messagesActions } from 'modules/messages';

import { fetchChangelog, fetchChangelogFailure, fetchChangelogSuccess } from '../actions';

function* handler() {
    try {
        const response = yield fetch(config.publicChangelog);

        const contentType = response.headers.get('Content-Type');

        if (!contentType.includes('text/markdown')) {
            throw Error('Invalid content type');
        }

        const content = yield response.text();

        yield put(fetchChangelogSuccess(content));
    } catch (e) {
        logger.error(e);

        yield put(fetchChangelogFailure(e));

        yield put(
            messagesActions.displayErrorMessage({
                message: { id: 'error.changelog.fetch' },
            }),
        );
    }
}

export default function* () {
    yield takeLatestRequest(
        {
            actionCreator: fetchChangelog,
            idSelector: action => action.type,
        },
        handler,
    );
}
