import type { TRuleWithTheme } from 'styles/theme';

export const container: TRuleWithTheme = ({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    padding: '1.25rem',
    width: '100%',

    backgroundColor: theme.colors.inputsBackgroundPlaceholder,

    border: `1px solid ${theme.colors.foregroundsSeparator}`,
    borderRadius: '0.25rem',
});

export const text: TRuleWithTheme = () => ({
    marginRight: '0.75rem',

    overflow: 'auto',

    whiteSpace: 'nowrap',

    textOverflow: 'ellipsis',
});

export const downloadButton: TRuleWithTheme = ({ theme }) => ({
    color: theme.colors.accentPrimary,

    ':hover': {
        color: theme.colors.foregroundsPrimary,
    },
});
