import type { TRuleWithTheme } from 'styles/theme';

export const container: TRuleWithTheme = () => ({});

export const sectionsContainer: TRuleWithTheme = () => ({
    display: 'grid',
    gap: '1rem',

    min1024: {
        gridAutoFlow: 'column',
        gridTemplateRows: 'repeat(2, auto)',
        gridTemplateColumns: 'repeat(2, 1fr)',

        gap: '2.5rem',
    },
});

export const rightColumn: TRuleWithTheme = () => ({
    min1024: {
        gridColumn: '2',
        gridRow: '2 / span 2',
    },
});
