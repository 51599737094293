import type { SVGProps } from 'react';

export const WarningTriangle = (props: SVGProps<SVGSVGElement>) => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M12 9V11M12 15V15.01"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M5.00019 19H19.0002C19.3265 18.9977 19.6473 18.9156 19.9346 18.7609C20.2219 18.6061 20.4669 18.3835 20.6484 18.1123C20.8299 17.841 20.9422 17.5296 20.9756 17.205C21.0091 16.8804 20.9626 16.5525 20.8402 16.25L13.7402 4.00002C13.5672 3.68741 13.3137 3.42685 13.0059 3.2454C12.6982 3.06396 12.3474 2.96826 11.9902 2.96826C11.6329 2.96826 11.2822 3.06396 10.9744 3.2454C10.6667 3.42685 10.4131 3.68741 10.2402 4.00002L3.14019 16.25C3.02013 16.5456 2.97252 16.8656 3.00134 17.1833C3.03016 17.501 3.13456 17.8073 3.30584 18.0764C3.47712 18.3456 3.7103 18.5698 3.98592 18.7305C4.26153 18.8912 4.57158 18.9836 4.89019 19"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
