import { createAction } from '@reduxjs/toolkit';

import type { Secret } from '../../types';
import types from '../actionTypes';

export const removeSecret = createAction(types.REMOVE_SECRET, (id: Secret['id']) => ({
    meta: {
        id,
    },
    payload: undefined,
}));

export const removeSecretSuccess = createAction(types.REMOVE_SECRET_SUCCESS, (id: Secret['id']) => ({
    meta: {
        id,
    },
    payload: undefined,
}));

export const removeSecretFailure = createAction(types.REMOVE_SECRET_FAILURE, (id: Secret['id'], error: any) => ({
    meta: {
        id,
    },
    payload: undefined,
    error,
}));
