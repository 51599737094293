import { schema, normalize } from 'normalizr';
import { merge, omit } from 'lodash';

import { SECRET_PLACEHOLDER, SENSITIVE_DATA_KEYS } from '../constants';

import type { EntityKey, Secret, SecretById } from '../types';

export const omitSensitiveKeys = (secret: Secret) => omit(secret, SENSITIVE_DATA_KEYS);

const secretSchema = new schema.Entity<Secret>(
    'secrets',
    {},
    {
        processStrategy: entity => merge({}, SECRET_PLACEHOLDER, entity),
    },
);
const secretListSchema = new schema.Array<Secret>(secretSchema);

export const normalizeSecret = (data: any) =>
    normalize<
        Secret,
        {
            [EntityKey.SECRETS]: SecretById;
        },
        Secret['id']
    >(data, secretSchema);

export const normalizeSecrets = (data: any[]) =>
    normalize<
        Secret,
        {
            [EntityKey.SECRETS]: SecretById;
        },
        Secret['id'][]
    >(data, secretListSchema);
