import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

const { reducer, actions } = createSlice({
    name: 'backend-version',
    initialState: null as string,
    reducers: {
        setBackendVersion(_, action: PayloadAction<string>) {
            return action.payload;
        },
        fetchBackendVersion(state) {
            return state;
        },
    },
});

const { setBackendVersion, fetchBackendVersion } = actions;

export { reducer, setBackendVersion, fetchBackendVersion };
