import type { TRuleWithTheme } from 'styles/theme';

const keyframe = {
    '0%': {
        transform: 'translate(-50%, -100%)',
    },
    '100%': {
        transform: 'translate(-50%, 0%)',
    },
};

export const list: TRuleWithTheme = ({ theme }) => ({
    position: 'fixed',
    top: '1rem',
    left: '50%',
    zIndex: 9999,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    transition: 'transform',
    willChange: 'transform',
    transform: 'translateX(-50%)',

    animationName: keyframe,
    animationDelay: 0,
    animationDuration: '1s',
    animationIterationCount: 1,
    animationTimingFunction: 'ease-out',
});
