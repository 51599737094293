import { useFela } from 'react-fela';

import * as felaRules from './VisualizedCharacter.rules';

export interface VisualizedCharProps {
    char: string;
}

export const VisualizedCharacter = ({ char }: VisualizedCharProps) => {
    const { css } = useFela({
        char,
    });

    return <span className={css(felaRules.char)}>{char}</span>;
};
