import type { ReactNode } from 'react';

import { Loader } from '../Loader';
import type { LoaderProps } from '../Loader';

export interface LoadableProps extends Pick<LoaderProps, 'minHeight'> {
    showLoader?: boolean;
    children?: ReactNode;
}

export const Loadable = ({ showLoader = false, minHeight, children = null }: LoadableProps) => {
    if (showLoader) {
        return <Loader minHeight={minHeight} />;
    }

    return <>{children}</>;
};
