import { FormattedMessage, useIntl } from 'react-intl';

import { Label } from 'modules/forms';
import { createGroupLabel, FormFieldGroups, useGroups } from 'modules/groups';
import type { Group } from 'modules/groups';

import { Fields } from '../../../constants';

import { SelectField } from '../SelectField';
import type { SelectFieldProps } from '../SelectField';

const name = Fields.GROUPS;

export const GroupsField = ({ onChange }: Pick<SelectFieldProps<Group>, 'onChange'>) => {
    const intl = useIntl();

    const placeholder = intl.formatMessage({ id: 'secret.groups.placeholder' });

    const { api, dataWithLocalizedAllUsersGroup, handleLoadMore, handleSearch } = useGroups();

    return (
        <SelectField<Group>
            id={name}
            name={name}
            label={
                <Label>
                    <FormattedMessage id="secret.groups.label" />
                </Label>
            }
            placeholder={placeholder}
            options={dataWithLocalizedAllUsersGroup
                .filter(group => group.visible !== false)
                .map(group => ({
                    value: group.id,
                    entity: group,
                    key: group.id,
                    label: createGroupLabel(group),
                }))}
            onChange={onChange}
            onSearch={handleSearch}
            loadingMore={api.inProgressNext}
            onLoadMore={handleLoadMore}
            hasMore={Boolean(api.nextPageToken)}
            popupShouldIgnoreParentContainer
        >
            {(value, actions) => (
                <FormFieldGroups value={value} onUpdate={actions.onUpdate} onRemove={actions.onRemove} />
            )}
        </SelectField>
    );
};
