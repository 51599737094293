import { useFela } from 'react-fela';

import { Changelog } from '../Changelog';

import * as felaRules from './ChangelogPage.rules';

export const ChangelogPage = () => {
    const { css } = useFela();
    return (
        <div className={css(felaRules.container)}>
            <Changelog />
        </div>
    );
};
