import type { TRuleWithTheme } from 'styles/theme';

const switchBorder = 1;
const switchHandle = 16;
const switchHeight = 20;

export const container: TRuleWithTheme = ({ theme }) => ({
    '&.ant-switch': {
        minWidth: 38,
        height: switchHeight,

        backgroundColor: theme.colors.white,
        borderColor: theme.colors.lightGrey,
        borderWidth: switchBorder,
        borderStyle: 'solid',
    },
    '&.ant-switch:hover': {
        backgroundColor: 'unset',
    },
    '&.ant-switch:focus': {
        boxShadow: 'none',
    },
    '&.ant-switch.ant-switch-checked:hover': {
        backgroundColor: 'unset',
    },
    '&.ant-switch.ant-switch-checked:focus': {
        boxShadow: 'none',
    },
    '&.ant-switch .ant-switch-handle': {
        top: 1,
        left: 1,

        width: switchHandle,
        height: switchHandle,
    },
    '&.ant-switch .ant-switch-handle::before': {
        borderRadius: switchHandle / 2,

        backgroundColor: theme.colors.blue,
    },
    '&.ant-switch.ant-switch-checked .ant-switch-handle': {
        top: -switchBorder,
        left: `calc(100% - ${switchHeight}px + ${switchBorder}px)`,

        width: switchHeight,
        height: switchHeight,
    },
    '&.ant-switch.ant-switch-checked .ant-switch-handle::before': {
        borderRadius: switchHeight / 2,

        backgroundColor: theme.colors.brightMagenta,
    },
});
