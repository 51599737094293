import { useFela } from 'react-fela';

import { Checkbox as AntdCheckbox } from 'antd';
import type { CheckboxProps as AntdCheckboxProps } from 'antd/es/checkbox';

import { style } from './Checkbox.rules';

export interface CheckboxProps extends AntdCheckboxProps {
    customStyle?: typeof style;
}

export function Checkbox({ customStyle, ...props }: CheckboxProps) {
    const { css } = useFela();

    return <AntdCheckbox {...props} className={css(style, customStyle)} />;
}
