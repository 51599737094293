import type { TRuleWithTheme } from 'styles/theme';

export const link: TRuleWithTheme = () => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
});

export const anchor: TRuleWithTheme = () => ({
    transform: 'rotate(-90deg)',
});
