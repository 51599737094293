import type { TRuleWithTheme } from 'styles/theme';

export const container: TRuleWithTheme = () => ({
    '> .Button.ant-btn': {
        height: '3rem',

        marginTop: '2.5rem',

        fontSize: '0.875rem',

        borderRadius: '1.5rem',
    },
    '> .Button.ant-btn > .anticon': {
        fontSize: '1.125rem',
    },

    min768: {
        '> .Button.ant-btn': {
            marginTop: '3rem',
        },
    },
});
