import { createAction } from '@reduxjs/toolkit';

import types from '../actionTypes';
import type { SettingFormValues } from '../../types';

export const updateSettings = createAction(types.UPDATE_SETTINGS, (values: SettingFormValues, actions: any) => ({
    meta: actions,
    payload: values,
}));

export const updateSettingsSuccess = createAction<SettingFormValues>(types.UPDATE_SETTINGS_SUCCESS);

export const updateSettingsFailure = createAction(types.UPDATE_SETTINGS_FAILURE, (error: any) => ({
    payload: undefined,
    error,
}));
