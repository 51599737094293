import { useFela } from 'react-fela';
import { FormattedMessage } from 'react-intl';
import { NavLink } from 'react-router-dom';

import config from 'config';

import { TagManager } from 'services/utilities';

import { Icon } from 'modules/ui';

import { useHasSecurityAuditReport } from '../../hooks/useHasSecurityAuditReport';

import * as felaRules from './SecurityAuditSecretsTabs.rules';

export const SecurityAuditSecretsTabs = () => {
    const { css } = useFela();

    const hasSecurityAuditReport = useHasSecurityAuditReport();

    return (
        <div className={css(felaRules.container)}>
            <NavLink to={config.routes.securityAudit} className={css(felaRules.link)} activeClassName="active">
                <FormattedMessage id="securityAudit.tabs.securityReport" />
                {hasSecurityAuditReport ? <Icon type="warning-inverse" color="red" /> : null}
            </NavLink>
            <NavLink
                to={config.routes.securityAuditLevel}
                className={css(felaRules.link)}
                activeClassName="active"
                onClick={() => {
                    TagManager.push('view_security_password_strength');
                }}
            >
                <FormattedMessage id="securityAudit.tabs.securityLevel" />
            </NavLink>
            <NavLink
                to={config.routes.securityAuditAge}
                className={css(felaRules.link)}
                activeClassName="active"
                onClick={() => {
                    TagManager.push('view_security_password_age');
                }}
            >
                <FormattedMessage id="securityAudit.tabs.securityAge" />
            </NavLink>
            <NavLink
                to={config.routes.securityAuditLeaks}
                className={css(felaRules.link)}
                activeClassName="active"
                onClick={() => {
                    TagManager.push('view_security_password_leaks');
                }}
            >
                <FormattedMessage id="securityAudit.tabs.securityLeaks" />
            </NavLink>
        </div>
    );
};
