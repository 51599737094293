import { AUTH_SESSION_START } from '@ackee/petrus';
import { actionChannel, put, select, takeEvery } from 'redux-saga/effects';
import { matchPath } from 'react-router';
import { replace } from 'redux-first-history';

import type { AppState } from 'types';

import { config } from 'config/config';
import { logger } from 'config/logger';

import { parseRedirectUriParams } from '../helpers/parseRedirectUriParams';
import { parseState } from '../utils';

export const locationSelector = (state: AppState) => state.router.location;

export function* oauthRedirectStateRestorationSaga() {
    const channel = yield actionChannel(AUTH_SESSION_START);

    yield takeEvery(channel, function* (action) {
        try {
            switch (action.type) {
                case AUTH_SESSION_START: {
                    const { pathname, hash, search } = yield select(locationSelector);

                    const match = matchPath(pathname, {
                        exact: true,
                        path: config.googleOAuth.redirectUrl,
                    });

                    if (match) {
                        const params = parseRedirectUriParams(hash || search);
                        const state = parseState(params.state);

                        if (!state.url || state.url === config.googleOAuth.redirectUrl) {
                            yield put(replace(config.routes.home));
                        } else {
                            yield put(replace(state.url));
                        }
                    }

                    break;
                }
                default: {
                }
            }
        } catch (e) {
            logger.error(e);
        }
    });
}
