import { FormattedMessage } from 'react-intl';

import { Button } from 'modules/ui';
import { useModal } from 'modules/modals';

import { REMOVE_SECRET_MODAL_KEY } from '../../../../../constants';

export const RemoveSecretButton = () => {
    const modal = useModal(REMOVE_SECRET_MODAL_KEY);

    return (
        <Button
            onClick={() => {
                modal.updateModal(true);
            }}
            type="text"
            size="large"
            color="red"
        >
            <FormattedMessage id="delete" />
        </Button>
    );
};
