import type { TRuleWithTheme } from 'styles/theme';

export const dragger: TRuleWithTheme<{
    disabled: boolean;
    hasError: boolean;
}> = ({ theme, disabled, hasError }) => ({
    '& .ant-upload.ant-upload-drag': {
        borderRadius: '0.25rem',
        borderWidth: '0.0625rem',
        borderColor: hasError ? theme.colors.red : theme.colors.lightGrey3,
        borderStyle: 'dashed',

        background: theme.colors.white,
    },

    '& .ant-upload.ant-upload-drag:hover': {
        borderColor: theme.colors.blue,
    },
    '& .ant-upload.ant-upload-drag:focus-within': {
        borderColor: theme.colors.blue,
    },

    '& .ant-upload.ant-upload-drag .ant-upload': {
        padding: '2rem',

        cursor: disabled ? 'default' : undefined,
    },
});

export const text: TRuleWithTheme = () => ({
    lineHeight: '1.714em',
});

export const button: TRuleWithTheme<{ disabled: boolean }> = ({ theme, disabled }) => ({
    color: disabled ? theme.colors.foregroundsTertiary : theme.colors.blue,

    textDecoration: 'underline',
});
