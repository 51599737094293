import config from 'config';
import { logger } from 'config/logger';
import { store } from 'modules/core/modules/redux';

import { SECRETS_TOTAL_COUNT_HEADER } from '../constants';
import { setSecretsTotalCount } from '../services/actions';

export async function totalCountInterceptor(response: Response) {
    try {
        const totalCount = response.headers.get(SECRETS_TOTAL_COUNT_HEADER);

        if (totalCount) {
            store.dispatch(setSecretsTotalCount(Number.parseInt(totalCount, 10)));
        } else if (response.url.includes(config.api.secrets)) {
            const clonedResponse = response.clone();

            const secrets = await clonedResponse.json();

            store.dispatch(setSecretsTotalCount(secrets.length));
        }
    } catch (e) {
        logger.error(e);
    }

    return response;
}
