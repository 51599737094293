import { createReducer } from '@reduxjs/toolkit';

import type { SecurityAuditData } from '../../types';
import { fetchAuditFailure, fetchAuditSuccess } from '../actions';

const initialState: SecurityAuditData = {
    total: 0,
    reports: [],
};

export const entitiesReducer = createReducer(initialState, builder => {
    builder.addCase(fetchAuditSuccess, (_, action) => action.payload).addCase(fetchAuditFailure, () => initialState);
});
