import type { User } from 'types';

export const users: readonly User[] = [
    {
        id: 'SB.01',
        email: 'john.doe@passwd.team',
        name: 'John Doe',
    },
    {
        id: 'SB.02',
        email: 'jenna.burns@passwd.team',
        name: 'Jenna Burns',
    },
    {
        id: 'SB.03',
        email: 'alice.kaestner@passwd.team',
        name: 'Alice Kaestner',
    },
    {
        id: 'SB.04',
        email: 'bob.waddy@passwd.team',
        name: 'Bob Waddy',
    },
    {
        id: 'SB.05',
        email: 'rick.sandmann@passwd.team',
        name: 'Rick Sandman',
    },
    {
        id: 'SB.06',
        email: 'george.saunders@passwd.team',
        name: 'George Saunders',
        isAdmin: true,
    },
    {
        id: 'SB.07',
        email: 'admin@passwd.team',
        name: 'Admin',
        isAdmin: true,
    },
] as const;

export const defaultDemoUserId = users[0].id;

export const usersWithCredentials = users.map<{
    user: User;
    credentials: {
        accessToken: string;
        expiresIn: number;
    };
}>(user => ({
    user,
    credentials: {
        accessToken: user.id,
        expiresIn: 24 * 365 * 100,
    },
}));
