import { useDispatch } from 'react-redux';

import { TagManager } from 'services/utilities';

import { useAppSelector } from 'hooks/useAppSelector';

import { selectExportApi } from '../services/selectors';
import { exportRequest } from '../services/actions';

export const useExport = () => {
    const dispatch = useDispatch();

    const { inProgress } = useAppSelector(selectExportApi);

    const handleExport = () => {
        TagManager.push('used_import_export');
        dispatch(exportRequest());
    };

    return {
        inProgress,
        handleExport,
    };
};
