import { combineRules } from 'fela';

import tinyColor from 'tinycolor2';

import type { TRuleWithTheme } from 'styles/theme';

export const button: TRuleWithTheme = () => ({
    display: 'inline',

    padding: 0,

    margin: 0,

    border: 'none',

    fontSize: '0.875rem',
    fontWeight: 500,
    lineHeight: '1.43em',
    letterSpacing: '0.0143em',

    ':hover': {
        cursor: 'pointer',
    },
});

export const dropdown: TRuleWithTheme = ({ theme }) => ({
    '&.ant-dropdown .ant-dropdown-menu': {
        paddingTop: '0.375rem',
        paddingRight: 0,
        paddingBottom: '0.375rem',
        paddingLeft: 0,

        borderRadius: 4,
    },
    '&.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item': {
        paddingTop: '0.5rem',
        paddingRight: '0.75rem',
        paddingBottom: 'calc(0.5rem - 1px)',
        paddingLeft: '0.75rem',

        borderRadius: 0,
    },

    '&.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item.active': {
        color: theme.colors.brightMagenta,
        backgroundColor: tinyColor(theme.colors.lightPink).setAlpha(0.5).toRgbString(),
    },

    '&.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item.ant-dropdown-menu-item-active': {
        color: theme.colors.brightMagenta,
        backgroundColor: tinyColor(theme.colors.lightPink).setAlpha(0.5).toRgbString(),
    },
});

export const caret: TRuleWithTheme = ({ theme }) => ({
    marginLeft: '0.5rem',

    color: theme.colors.foregroundsPrimary,

    transitionDuration: '0.2s',
    transitionTimingFunction: 'ease',
    transitionProperty: 'color, transform',
});
export const caretOpened = combineRules(caret, (({ theme }) => ({
    color: theme.colors.accentSecondary,

    transform: 'rotate(-180deg)',
})) as TRuleWithTheme);
