import type { TRuleWithTheme } from 'styles/theme';

export const container: TRuleWithTheme = ({ theme }) => ({
    display: 'inline-flex',
    alignSelf: 'center',

    marginLeft: '0.5rem',

    color: theme.colors.blue,
});

export const button: TRuleWithTheme = ({ theme }) => ({
    width: '1rem',
    height: '1rem',

    transition: 'color 0.2s',

    ':hover': {
        color: theme.colors.accentSecondary,
    },
});

export const loadingIcon: TRuleWithTheme = () => ({
    width: '1rem',
    height: '1rem',

    animationName: {
        from: {
            transform: 'rotate(0deg)',
        },
        to: {
            transform: 'rotate(-360deg)',
        },
    },
    animationDuration: '2500ms',
    animationIterationCount: 'infinite',
    animationTimingFunction: 'linear',
});
