import type { TRuleWithTheme } from 'styles/theme';

export const container: TRuleWithTheme = ({ theme }) => ({
    marginTop: '1.5rem',
    marginBottom: '1.5rem',

    marginLeft: '-1.5rem',
    width: theme.widths.menu,

    background: theme.colors.foregroundsSeparator,
});
