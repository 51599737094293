import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';

import { createReduxHistoryContext } from 'redux-first-history';
import { createBrowserHistory } from 'history';

import { persistStore } from 'redux-persist';

import { config } from 'config/config';
import { logger } from 'config/logger';

import { createRootReducer } from './createRootReducer';
import { createRootSaga } from './createRootSaga';

export const createStore = () => {
    const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({
        history: createBrowserHistory(),
    });

    const sagaMiddleware = createSagaMiddleware({
        onError: error => {
            logger.error(error);
        },
    });

    const middleware = [routerMiddleware, sagaMiddleware];

    const reducer = createRootReducer(routerReducer);

    const store = configureStore({
        devTools: config.devTools,
        reducer,
        middleware,
    });

    if (window.Cypress) {
        window.store = store;
    }

    const saga = createRootSaga();

    sagaMiddleware.run(saga);

    const history = createReduxHistory(store);
    const persistor = persistStore(store);

    return { store, history, persistor };
};
