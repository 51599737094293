import { FormattedMessage } from 'react-intl';

import { ConfirmationModal } from 'modules/modals';

import { useRemoveSecret } from '../../hooks/useRemoveSecret';

export const RemoveSecretModal = () => {
    const { open, name, deleting, handleRemove, handleClose } = useRemoveSecret();

    return (
        <ConfirmationModal
            open={open}
            okButton={{
                children: <FormattedMessage id="secret.modal.remove.ok" />,
                loading: deleting,
                onClick: handleRemove,
            }}
            cancelButton={{
                children: <FormattedMessage id="secret.modal.remove.cancel" />,
                onClick: handleClose,
            }}
        >
            <FormattedMessage
                id="secret.modal.remove.title"
                values={{
                    secret: name,
                }}
            />
        </ConfirmationModal>
    );
};
