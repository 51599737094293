import { Field } from 'react-final-form';
import { FormattedMessage } from 'react-intl';
import { useFela } from 'react-fela';
import { identity } from 'lodash';

import { Checkbox, FormItem } from 'modules/forms';

import { Fields } from '../../../constants';

import * as felaRules from './ReplaceSecretField.rules';

export const ReplaceSecretsField = () => {
    const { css } = useFela();

    return (
        <Field<boolean>
            name={Fields.REPLACE_SECRETS}
            initialValue={false}
            type="checkbox"
            format={identity}
            parse={identity}
        >
            {({ input, meta }) => {
                const { checked, onBlur, onChange, onFocus } = input;
                return (
                    <FormItem meta={meta} customStyle={felaRules.formItem}>
                        <Checkbox
                            checked={checked}
                            // @ts-expect-error
                            onBlur={onBlur}
                            onFocus={onFocus}
                            onChange={e => {
                                onChange(e.target.checked);
                            }}
                        >
                            <span className={css(felaRules.label)}>
                                <FormattedMessage id="import.form.rewrite" />
                            </span>
                        </Checkbox>
                    </FormItem>
                );
            }}
        </Field>
    );
};
