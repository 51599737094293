import type { ReactNode } from 'react';
import type { TooltipProps } from 'antd/es/tooltip';
import { FormattedMessage } from 'react-intl';

import { Tooltip, Button, Text, Heading } from 'modules/ui';

import { Guide } from '../../constants';

import { useUserGuide } from '../../hooks/useUserGuide';

import { UserGuide } from '../UserGuide';

import * as felaRules from './UserGuideSecretsView.rules';

export interface UserGuideSecretsViewProps {
    show?: boolean;
    placement: TooltipProps['placement'];
    align?: TooltipProps['align'];
    children: ReactNode;
}

export const UserGuideSecretsView = ({ show = true, children, placement, align }: UserGuideSecretsViewProps) => {
    const userGuide = useUserGuide(Guide.SECRETS_VIEW);

    return (
        <UserGuide
            open={show && userGuide.open}
            extend={{
                item: felaRules.guideItem,
            }}
        >
            <Tooltip
                open={show && userGuide.open}
                title={
                    <>
                        <Heading elementType="h2" color="black" customStyle={felaRules.title}>
                            <FormattedMessage id="user-guide.secrets.view.title" />
                        </Heading>
                        <Text elementType="p" size="small" color="brownGrey" customStyle={felaRules.text}>
                            <FormattedMessage id="user-guide.secrets.view.text" />
                        </Text>
                        <Button
                            type="primary"
                            onClick={() => {
                                userGuide.close();
                            }}
                            customStyle={felaRules.button}
                        >
                            <FormattedMessage id="user-guide.secrets.view.button" />
                        </Button>
                    </>
                }
                placement={placement}
                align={align}
                customOverlayStyle={felaRules.tooltip}
            >
                {children}
            </Tooltip>
        </UserGuide>
    );
};
