import { put } from 'redux-saga/effects';

import { logger } from 'config/logger';

import { takeLatestRequest } from 'services/utilities/takeRequest';

import { actions as messagesActions } from 'modules/messages';

import { setViewFilter, setViewFilterIds } from '../actions';

import fetchSecrets from './fetchSecrets';

function* handler({ payload: { view }, meta: { verbose } }: ReturnType<typeof setViewFilter>, signal: AbortSignal) {
    if (view === null) {
        yield put(setViewFilterIds(null));

        return;
    }

    try {
        if (verbose) {
            yield put(
                messagesActions.displayLoadingMessage({
                    message: { id: 'loading.viewFilter.fetch' },
                    options: {
                        key: 'view-filter',
                        duration: 0,
                    },
                }),
            );
        }

        const data = yield* fetchSecrets({ signal, viewFilter: view });

        yield put(setViewFilterIds(data.map(({ id }) => id)));

        if (verbose) {
            yield put(
                messagesActions.displaySuccessMessage({
                    message: { id: 'success.viewFilter.fetch' },
                    options: {
                        key: 'view-filter',
                        duration: 3.5,
                    },
                }),
            );
        }
    } catch (e) {
        logger.error(e);

        yield put(
            messagesActions.displaySuccessMessage({
                message: { id: 'error.viewFilter.fetch' },
                options: {
                    key: 'view-filter',
                    duration: 3.5,
                },
            }),
        );

        yield put(setViewFilter(null));
    }
}

export default function* fetchViewFilter() {
    yield takeLatestRequest(
        {
            actionCreator: setViewFilter,
            idSelector: action => action.type,
        },
        handler,
    );
}
