import { useFela } from 'react-fela';

import { SecretsWithFilter } from '../SecretsWithFilter';

import * as felaRules from './SecretsPage.rules';

export const SecretsPage = () => {
    const { css } = useFela();

    return (
        <div className={css(felaRules.container)}>
            <SecretsWithFilter />
        </div>
    );
};
