import type { TRuleWithTheme } from 'styles/theme';

export const container: TRuleWithTheme = () => ({
    display: 'block',
    padding: '0',
    border: 'none',
    ':hover': {
        cursor: 'pointer',
    },
});
