import { put } from 'redux-saga/effects';

import { api } from 'config/antonio';
import { config } from 'config/config';
import { logger } from 'config/logger';

import { takeLatestRequest } from 'services/utilities/takeRequest';

import { actions as modalActions, StaticModal } from 'modules/modals';

import { updateTag as updateSecretTag } from '../../../../services/actions';

import { updateTag, updateTagSuccess, updateTagFailure, updateTagReset } from '../actions';

function* handler({ meta, payload }: ReturnType<typeof updateTag>, signal: AbortSignal) {
    try {
        yield* api.put<any>(
            config.api.tag,
            {
                newName: payload.tag,
            },
            {
                uriParams: {
                    tag: meta.tag,
                },
                signal,
            },
        );
        yield put(updateSecretTag([meta.tag, payload.tag]));
        yield put(updateTagSuccess(meta.tag));
        yield put(modalActions.setModalVisibility(StaticModal.UPDATE_TAG, false));
    } catch (e) {
        logger.error(e);

        yield put(updateTagFailure(meta.tag, e));
    }
}

export default function* () {
    yield takeLatestRequest(
        {
            actionCreator: updateTag,
            cancelActionFunction: action => updateTagReset(action.meta.tag),
            idSelector: action => action.meta.tag,
        },
        handler,
    );
}
