import type { PropsWithChildren } from 'react';
import { useFela } from 'react-fela';
import { FormattedMessage } from 'react-intl';

import { Popover } from 'antd';

import { Button } from 'modules/ui';

import { useGeneratePassword } from '../../hooks/useGeneratePassword';

import { GeneratePasswordLength } from './GeneratePasswordLength';
import { GeneratePasswordOptions } from './GeneratePasswordOptions';
import { GeneratePasswordCloseButton } from './GeneratePasswordCloseButton';

import * as felaRules from './GeneratePasswordWidget.rules';

export interface GeneratePasswordWidgetProps extends PropsWithChildren {
    onClose: () => void;
    onSave: (password: string) => void;
    open: boolean;
}

export const GeneratePasswordWidget = ({ onClose, onSave, open, children }: GeneratePasswordWidgetProps) => {
    const { data, setData, generateAndReplace } = useGeneratePassword(onSave);

    const { css } = useFela();

    return (
        <Popover
            open={open}
            placement="bottomLeft"
            align={{ offset: [0, 24] }}
            arrow={false}
            content={
                <div className={css(felaRules.container)}>
                    <GeneratePasswordLength
                        onChange={value =>
                            setData(state => ({
                                ...state,
                                length: value,
                            }))
                        }
                        value={data.length}
                    />
                    <GeneratePasswordOptions
                        value={data.options}
                        onChange={value => {
                            setData(state => ({
                                ...state,
                                options: value,
                            }));
                        }}
                    />
                    <Button
                        size="small"
                        type="primary"
                        onClick={() => {
                            generateAndReplace();
                        }}
                        customStyle={felaRules.generateButton}
                    >
                        <FormattedMessage id="secret.generate.generatePassword" />
                    </Button>

                    <GeneratePasswordCloseButton onClick={onClose} />
                </div>
            }
        >
            {children}
        </Popover>
    );
};
