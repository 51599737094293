import type { TRuleWithTheme } from 'styles/theme';

export const message: TRuleWithTheme = () => ({
    minHeight: '150px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '16px',
    lineHeight: '19px',
    maxWidth: '800px',
    margin: '0 auto',
});
