import { createSelector } from '@reduxjs/toolkit';
import { apiSelector } from '@ackee/redux-utils';

import type { AppState } from 'types';

import { selectEntities, selectIdProp } from 'services/selectors';
import { selectViewFilteredSecrets } from 'modules/secrets/services/selectors';

import type { Group } from '../types';

const selectGroupsEntities = createSelector(selectEntities, entities => entities.groups.groups);

const selectGroupMembersEntities = createSelector(
    selectEntities,
    selectIdProp,
    (entities, id) => entities.groups.groupMembers[id] || entities.groups.groupMembers.placeholder,
);

export const selectGroups = createSelector(
    [selectGroupsEntities, selectViewFilteredSecrets],
    ({ byIds, ids }, secrets) => {
        const groups = ids.map(id => byIds[id]);

        const groupsWithUsedCount = groups.map(group => ({
            ...group,
            usedCount: secrets.filter(secret => secret.groups.some(secretGroup => secretGroup.id === group.id)).length,
        }));

        return groupsWithUsedCount;
    },
);

export const selectGroupMembers = createSelector(selectGroupMembersEntities, ({ byIds, ids }) =>
    ids.map(id => byIds[id]),
);

export const selectGroupMembersApi = (state: AppState, { id }) => apiSelector(state, 'groups', 'fetchMembers', id);

export const selectGroupsApi = (state: AppState) => state.api.groups.fetch;

export const selectChangeGroupVisibilityApi = (state: AppState, id: Group['id']) =>
    apiSelector(state, 'groups', 'changeVisibility', id);
