import type { TRuleWithTheme } from 'styles/theme';

export const container: TRuleWithTheme = () => ({
    flex: 1,

    display: 'flex',
    flexDirection: 'column',

    rowGap: '2rem',

    width: '100%',

    overflow: 'auto',

    paddingTop: '1rem',
    paddingRight: '1.5rem',
    paddingBottom: '2.5rem',
    paddingLeft: '1.5rem',

    min1440: {
        paddingTop: '2.5rem',
    },
});
