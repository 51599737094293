// Edit translations at https://docs.google.com/spreadsheets/d/1XTsal4RH5Vaa9Wed9CFCgjH-ukmIgsHd9Lp5JYbQ4ec/edit#gid=0

import csAntd from 'antd/locale/cs_CZ';
import enAntd from 'antd/locale/en_GB';
import deAntd from 'antd/locale/de_DE';
import plAntd from 'antd/locale/pl_PL';
import esAntd from 'antd/locale/es_ES';
import ruAntd from 'antd/locale/ru_RU';

import csIntl from './cs.json';
import enIntl from './en.json';
import deIntl from './de.json';
import plIntl from './pl.json';
import esIntl from './es.json';
import ruIntl from './ru.json';

import type { Language } from '../contants';

export const intlData: Record<Language, typeof csIntl> = {
    cs: csIntl,
    en: enIntl,
    de: deIntl,
    pl: plIntl,
    es: esIntl,
    ru: ruIntl,
} as const;

export const antdData: Record<Language, typeof csAntd> = {
    cs: csAntd,
    en: enAntd,
    de: deAntd,
    pl: plAntd,
    es: esAntd,
    ru: ruAntd,
} as const;
