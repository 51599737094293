import type { ReactNode } from 'react';
import { useRef } from 'react';
import { Redirect } from 'react-router';

import config from 'config';

import { useSaasCountReached } from '../../hooks/useSaasCountReached';

export interface MaxSaasSecretsFirewallProps {
    children: ReactNode;
}

export const MaxSaasSecretsFirewall = ({ children }: MaxSaasSecretsFirewallProps) => {
    const { isError } = useSaasCountReached();

    const isErrorRef = useRef(isError);

    // Check the error only on the first render, ignore any updates
    // This handles the situation when a user has locally not all the secrets and the limit is reached by other user
    // We don't want to apply the firewall in that case and rather display any error in the children component
    if (isErrorRef.current) {
        return <Redirect to={config.routes.secrets} />;
    }

    return <>{children}</>;
};
