import { createSelector } from '@reduxjs/toolkit';
import { translateSelector } from '@ackee/jerome';

import type {
    SecurityAuditAgeSecret,
    SecurityAuditDuplicitiesSecret,
    SecurityAuditLeaksSecret,
    SecurityAuditLevelSecret,
    SecurityAuditReportItem,
    SecurityAuditDuplicitiesGroup,
} from '../../types';
import { SecurityLeaks, ListItemType, SecurityAge, SecurityLevel, SecurityReport } from '../../constants';

import { selectMappedSecrets } from './list';
import { getSecurityAuditSecretsWithAgeGroups, selectSecurityAuditAgeSortedListSecrets } from './auditAgeList';
import { selectSecurityAuditLevelSortedListSecrets, getSecurityAuditSecretsWithLevelGroups } from './auditLevelList';
import { getSecurityAuditSecretsWithLeaksGroups, selectSecurityAuditLeaksSortedListSecrets } from './auditLeaksList';
import { getSecretsWithGroups, compareByName } from './utilities';

const selectSecurityAuditReportDuplicitiesSecrets = createSelector(
    [selectMappedSecrets, translateSelector],
    (secrets, { locale }) =>
        secrets
            .map<SecurityAuditDuplicitiesSecret>(secret => ({
                ...secret,
                type: ListItemType.SECRET,
                key: `${secret.id}-${SecurityReport.DUPLICITIES}`,
            }))
            .map<SecurityAuditDuplicitiesSecret>((secret, _, prevSecrets) => ({
                ...secret,
                duplicities: prevSecrets.reduce((count, otherSecret) => {
                    if (
                        // Do not count itself
                        secret.id !== otherSecret.id &&
                        // Do not count duplicities for empty passwords
                        secret.passwordHashed &&
                        // Count matching hashes
                        secret.passwordHashed === otherSecret.passwordHashed
                    ) {
                        return (count += 1);
                    }
                    return count;
                }, 0),
            }))
            .filter(secret => secret.duplicities && !secret.ignoreSecurityReport)
            .sort(function (secretA, secretB) {
                return secretB.duplicities - secretA.duplicities || compareByName(secretA, secretB, locale);
            }),
);

const selectSecurityAuditReportHighAgeSecrets = createSelector(
    selectSecurityAuditAgeSortedListSecrets,
    (secrets): SecurityAuditAgeSecret[] =>
        secrets.filter(
            secret => secret.securityAge === SecurityAge.MORE_THAN_TWO_YEARS && !secret.ignoreSecurityReport,
        ),
);

const selectSecurityAuditReportLowLevelSecrets = createSelector(
    selectSecurityAuditLevelSortedListSecrets,
    (secrets): SecurityAuditLevelSecret[] =>
        secrets.filter(secret => secret.securityLevel <= SecurityLevel.AVERAGE && !secret.ignoreSecurityReport),
);

const selectSecurityAuditReportManyLeaksSecrets = createSelector(
    selectSecurityAuditLeaksSortedListSecrets,
    (secrets): SecurityAuditLeaksSecret[] =>
        secrets.filter(secret => secret.securityLeaks === SecurityLeaks.MANY_LEAKS && !secret.ignoreSecurityReport),
);

export const selectSecurityAuditReport = createSelector(
    [
        selectSecurityAuditReportHighAgeSecrets,
        selectSecurityAuditReportLowLevelSecrets,
        selectSecurityAuditReportDuplicitiesSecrets,
        selectSecurityAuditReportManyLeaksSecrets,
    ],
    (highAgeSecrets, lowLevelSecrets, duplicitiesSecrets, leaksSecrets) => {
        const report: Partial<Record<SecurityReport, number>> = {};

        if (lowLevelSecrets.length > 0) {
            report.lowLevel = lowLevelSecrets.length;
        }

        if (highAgeSecrets.length > 0) {
            report.highAge = highAgeSecrets.length;
        }

        if (duplicitiesSecrets.length > 0) {
            report.duplicities = duplicitiesSecrets.length;
        }

        if (leaksSecrets.length > 0) {
            report.manyLeaks = leaksSecrets.length;
        }

        return report;
    },
);

export const selectHasAnySecurityAuditReport = createSelector(selectSecurityAuditReport, report =>
    Object.keys(report).some(key => Boolean(report[key])),
);

const getSecurityAuditSecretsWithDuplicitiesGroups = (secrets: SecurityAuditDuplicitiesSecret[]) =>
    getSecretsWithGroups(
        secrets,
        () => false,
        (): SecurityAuditDuplicitiesGroup => ({
            key: 'duplicities',
            type: ListItemType.GROUP,
            count: 0,
        }),
        group => {
            group.count += 1;
        },
    );

export const selectSecurityAuditSecretsWithReportGroups = createSelector(
    [
        selectSecurityAuditReportLowLevelSecrets,
        selectSecurityAuditReportHighAgeSecrets,
        selectSecurityAuditReportManyLeaksSecrets,
        selectSecurityAuditReportDuplicitiesSecrets,
    ],
    (lowLevelSecrets, highAgeSecrets, manyLeaksSecrets, duplicitiesSecrets) => {
        let list: SecurityAuditReportItem[] = [];

        if (lowLevelSecrets.length > 0) {
            list = getSecurityAuditSecretsWithLevelGroups(lowLevelSecrets);
        }

        if (highAgeSecrets.length > 0) {
            list = [...list, ...getSecurityAuditSecretsWithAgeGroups(highAgeSecrets)];
        }

        if (manyLeaksSecrets.length > 0) {
            list = [...list, ...getSecurityAuditSecretsWithLeaksGroups(manyLeaksSecrets)];
        }

        if (duplicitiesSecrets.length > 0) {
            list = [...list, ...getSecurityAuditSecretsWithDuplicitiesGroups(duplicitiesSecrets)];
        }

        return list;
    },
);
