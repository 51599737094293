import type { SVGProps } from 'react';

export const Delete = (props: SVGProps<SVGSVGElement>) => (
    <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.70701 6.00001L8.85301 3.85401C9.04901 3.65801 9.04901 3.34201 8.85301 3.14601C8.65801 2.95101 8.34201 2.95101 8.14601 3.14601L6.00001 5.29301L3.85301 3.14601C3.65801 2.95101 3.34201 2.95101 3.14601 3.14601C2.95101 3.34201 2.95101 3.65801 3.14601 3.85401L5.29301 6.00001L3.14601 8.14601C2.95101 8.34201 2.95101 8.65801 3.14601 8.85401C3.24401 8.95101 3.37201 9.00001 3.50001 9.00001C3.62801 9.00001 3.75601 8.95101 3.85301 8.85401L6.00001 6.70701L8.14601 8.85401C8.24401 8.95101 8.37201 9.00001 8.50001 9.00001C8.62801 9.00001 8.75601 8.95101 8.85301 8.85401C9.04901 8.65801 9.04901 8.34201 8.85301 8.14601L6.70701 6.00001Z"
        />
    </svg>
);
