import { useDispatch } from 'react-redux';
import { Field, Form } from 'react-final-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { Button } from 'modules/ui';
import { Checkbox, FormItem } from 'modules/forms';
import type { SubmitErrors } from 'modules/forms';

import { login } from '../../services/actions';

import { GdprLabel } from './GdprLabel';

import { button } from './DemoLoginForm.rules';

interface LoginFormValues {
    gdpr: boolean;
}

export const DemoLoginForm = () => {
    const intl = useIntl();

    const dispatch = useDispatch();

    return (
        <Form<LoginFormValues>
            validate={values => {
                const errors: SubmitErrors<LoginFormValues> = {};

                if (!values.gdpr) {
                    errors.gdpr = intl.formatMessage({ id: 'form.error.required' });
                }

                return errors;
            }}
            onSubmit={() => {
                dispatch(login());
            }}
        >
            {({ handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                    <Field<boolean> name="gdpr" type="checkbox">
                        {({ input, meta }) => (
                            <FormItem<boolean> meta={meta}>
                                <Checkbox
                                    id="btn_demo_terms"
                                    checked={input.checked}
                                    // @ts-expect-error
                                    onBlur={input.onBlur}
                                    onFocus={input.onFocus}
                                    onChange={e => {
                                        input.onChange(e.target.checked);
                                    }}
                                >
                                    <GdprLabel />
                                </Checkbox>
                            </FormItem>
                        )}
                    </Field>

                    <Button id="btn_demo_launch" type="primary" htmlType="submit" customStyle={button}>
                        <FormattedMessage id="auth.login.button.demo" />
                    </Button>
                </form>
            )}
        </Form>
    );
};
