import { createSelector } from '@reduxjs/toolkit';

import type { AppState } from 'types';

const selectSecrets = (state: AppState) => state.secrets;

export const selectSecretsNameFilter = createSelector(selectSecrets, ({ secretsFilter }) =>
    secretsFilter.toLowerCase(),
);

export const selectActiveSecretsAttributesFilters = createSelector(
    selectSecrets,
    ({ activeSecretsAttributesFilters }) => activeSecretsAttributesFilters,
);

export const selectSecretsListOffset = createSelector(selectSecrets, ({ secretsListOffset }) => secretsListOffset);

export const selectSecretsCount = createSelector(selectSecrets, ({ totalCount }) => totalCount);

export const selectViewFilter = createSelector(selectSecrets, ({ viewFilter }) => viewFilter);

export const selectViewFilterIds = createSelector(selectSecrets, ({ viewFilterIds }) => viewFilterIds);

export const selectSearchHistory = createSelector(selectSecrets, ({ searchHistory }) => searchHistory);
