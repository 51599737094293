import { combineRules } from 'fela';

import type { TRuleWithTheme } from 'styles/theme';

export const container: TRuleWithTheme<{
    isLastInGroup: boolean;
}> = ({ theme, isLastInGroup }) => ({
    height: '100%',

    paddingTop: '1.25rem',
    paddingBottom: '1.25rem',

    marginLeft: '1.5rem',
    marginRight: '1.5rem',

    ...(!isLastInGroup
        ? {
              borderBottomColor: theme.colors.lightGrey,
              borderBottomWidth: 1,
              borderBottomStyle: 'solid',
          }
        : undefined),

    min768: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
});

export const contentWrapper: TRuleWithTheme = () => ({
    flex: 1,

    overflow: 'auto',

    max768: {
        marginBottom: '0.75rem',
    },

    min768: {
        marginRight: '2rem',
    },
});

export const heading: TRuleWithTheme = () => ({
    position: 'relative',

    fontSize: '1.25rem',
    lineHeight: '1.3em',

    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
});

export const headingWithIcon = combineRules(heading, () => ({
    paddingLeft: '1.125rem',
}));

export const actionsWrapper: TRuleWithTheme = () => ({
    display: 'flex',
    gap: '1rem',
});

export const detailLink: TRuleWithTheme = () => ({
    flexShrink: 0,

    display: 'inline-block',

    textDecoration: 'underline',

    fontSize: '0.875rem',
    fontWeight: 400,
    lineHeight: '1.714em',
});

export const icon: TRuleWithTheme = () => ({
    position: 'absolute',
    top: 0,
    left: 0,

    width: '1rem',
    height: '1rem',
});

export const dismissButton: TRuleWithTheme = () => ({
    textOverflow: 'ellipsis',
    overflow: 'hidden',

    whiteSpace: 'nowrap',
});

export const additional: TRuleWithTheme = () => ({
    marginTop: '0.125rem',

    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
});
