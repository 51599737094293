import type { ReactNode } from 'react';
import { IntlProvider } from 'react-intl';
import { useSelector } from 'react-redux';

import { ConfigProvider } from 'antd';

import { translateSelector } from '@ackee/jerome';

import { intlData, antdData } from '../../translations';

export interface LocalizationsProviderProps {
    children: ReactNode;
}

export const LocalizationsProvider = ({ children }: LocalizationsProviderProps) => {
    const { locale } = useSelector(translateSelector);

    return (
        <IntlProvider locale={locale} messages={intlData[locale]}>
            <ConfigProvider locale={antdData[locale]}>{children}</ConfigProvider>
        </IntlProvider>
    );
};
