import type { TRuleWithTheme } from 'styles/theme';

export const container: TRuleWithTheme = () => ({
    display: 'flex',
    alignItems: 'flex-end',
    gap: '1rem',

    height: '1.75rem',

    overflow: 'hidden',
    whiteSpace: 'nowrap',

    min768: {
        alignItems: 'center',

        height: 'unset',
        overflow: 'unset',
    },
});
