import type { TRuleWithTheme } from 'styles/theme';

export const sectionsContainer: TRuleWithTheme = ({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',

    marginBottom: '2rem',

    min1024: {
        gap: '2rem',
        width: '50%',
        minWidth: '33.75rem',
    },
});

export const errorAlert: TRuleWithTheme = ({ theme }) => ({
    '&.ant-alert': {
        marginBottom: 0,
    },
});

export const buttonsContainer: TRuleWithTheme = ({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: '1.5rem',
});
