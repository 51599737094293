import { combineRules } from 'fela';
import { useFela } from 'react-fela';

import { Heading } from 'modules/ui';
import type { Secret } from 'modules/secrets';

import * as felaRules from './SecretNameWithIcon.rules';

export interface SecretNameProps {
    name: Secret['name'];
    favicon: Secret['favicon'];
    customStyle?: typeof felaRules.heading;
}

export const SecretNameWithIcon = ({ name, favicon, customStyle }: SecretNameProps) => {
    const { css } = useFela();

    return (
        <div className={css(felaRules.container)}>
            {favicon && (
                <img
                    className={css(felaRules.secretFavicon)}
                    src={`data:image/png;base64,${favicon}`}
                    alt={`${name} favicon`}
                />
            )}
            <Heading
                size="big"
                elementType="h1"
                color="foregroundsPrimary"
                customStyle={combineRules(felaRules.heading, customStyle)}
            >
                {name}
            </Heading>
        </div>
    );
};
