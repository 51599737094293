import { schema, normalize } from 'normalizr';

import { EntityKey } from '../types';
import type { Group, GroupById, GroupMember, GroupMemberById } from '../types';

export const groupSchema = new schema.Entity<Group>(EntityKey.GROUPS);
export const groupListSchema = new schema.Array(groupSchema);

export const groupMemberSchema = new schema.Entity<GroupMember>(EntityKey.GROUP_MEMBERS);
export const groupMemberListSchema = new schema.Array(groupMemberSchema);

export const normalizeGroup = (data: any) =>
    normalize<
        Group,
        {
            [EntityKey.GROUPS]: GroupById;
        },
        Group['id']
    >(data, groupSchema);
export const normalizeGroups = (data: any) =>
    normalize<
        Group,
        {
            [EntityKey.GROUPS]: GroupById;
        },
        Group['id'][]
    >(data, groupListSchema);

export const normalizeGroupMember = (data: any) =>
    normalize<
        GroupMember,
        {
            [EntityKey.GROUP_MEMBERS]: GroupMemberById;
        },
        GroupMember['id']
    >(data, groupMemberSchema);
export const normalizeGroupMembers = (data: any) =>
    normalize<
        GroupMember,
        {
            [EntityKey.GROUP_MEMBERS]: GroupMemberById;
        },
        GroupMember['id'][]
    >(data, groupMemberListSchema);
