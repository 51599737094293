import type { ReactNode } from 'react';
import { useFela } from 'react-fela';

import { Logo } from 'modules/ui';

import { ExtensionCTA, shouldDisplayExtensionPromo } from 'modules/extension';

import { menu, logoWrapper } from './MenuDesktop.rules';

export interface MenuDesktopProps {
    children: ReactNode;
}

export const MenuDesktop = ({ children }: MenuDesktopProps) => {
    const { css } = useFela();

    return (
        <div className={css(menu)}>
            <div>
                <div className={css(logoWrapper)}>
                    <Logo />
                </div>
                {children}
            </div>
            {shouldDisplayExtensionPromo ? <ExtensionCTA /> : null}
        </div>
    );
};
