import { FormattedMessage } from 'react-intl';
import { useFela } from 'react-fela';

import { Tag } from 'modules/ui';

import type { Secret } from '../../../types';

import { container, tag, intro } from './CreatorTag.rules';

export const CreatorTag = ({ creator }: { creator: Secret['createdBy'] }) => {
    const { css } = useFela();

    return (
        <div className={css(container)}>
            <Tag customStyle={tag}>
                <span className={css(intro)}>
                    <FormattedMessage id="secret.createdBy.label" />{' '}
                </span>
                {creator.name}
            </Tag>
        </div>
    );
};
