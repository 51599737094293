import type { CSSProperties, ReactNode } from 'react';

import { ListItemType } from '../../constants';

export interface SecurityAuditItemRendererProps<T> {
    className: string;
    style: CSSProperties;
    data: T[];
    children: (data: { item: T; isFirstGroup: boolean; isLastInGroup: boolean }) => ReactNode;
    index: number;
}

export function SecurityAuditItemRenderer<
    T extends {
        type: ListItemType;
    },
>({ className, style, data, index, children }: SecurityAuditItemRendererProps<T>) {
    const item = data[index];

    // We assume that the first group will be always on the first index
    const isFirstGroup = index === 0;
    const isLastInGroup = (data[index + 1]?.type ?? ListItemType.GROUP) === ListItemType.GROUP;

    return (
        <div className={className} style={style}>
            {children({
                item,
                isFirstGroup,
                isLastInGroup,
            })}
        </div>
    );
}
