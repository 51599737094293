import type { ListItemSize, Secret } from '../types';

export const GROUP_OTHER = '#';
export const SECRET_GROUPS = [GROUP_OTHER, ...'abcdefghijklmnopqrstuvwxyz'] as const;

export enum SecretListId {
    MAIN_LIST = 'main-list',
    AUDIT_REPORT_LIST = 'audit-report-list',
    AUDIT_LEVEL_LIST = 'audit-level-list',
    AUDIT_AGE_LIST = 'audit-age-list',
}

export enum ListItemType {
    SECRET = 'secret',
    GROUP = 'group',
}

export enum Fields {
    NAME = 'name',
    USERNAME = 'username',
    PASSWORD = 'password',
    WEB = 'web',
    NOTE = 'note',
    TAGS = 'tags',
    GROUPS = 'groups',
    WHITELIST_USERS = 'whitelistUsers',
    FILE = 'file',
    TOTP = 'TOTP',
    // A helper field for TOTP manipulation
    HAS_TOTP = 'hasTOTP',
}
export enum Readability {
    EASY_TO_SAY = 'easyToSay',
    EASY_TO_READ = 'easyToRead',
    ALL_CHARACTERS = 'allCharacters',
}
export enum Options {
    UPPERCASE = 'uppercase',
    LOWERCASE = 'lowercase',
    NUMBERS = 'numbers',
    SYMBOLS = 'symbols',
}
export enum GeneratePassword {
    LENGTH = 'length',
    READABILITY = 'readability',
    OPTIONS = 'options',
}

export const SECRET_PLACEHOLDER: Partial<Secret> = {
    name: '',
    username: '',
    password: '',
    web: '',
    note: '',
    userPermissions: [],
    tags: [],
    groups: [],
    whitelistUsers: [],
    file: null,
    share: null,
    TOTP: null,
    hasTOTP: false,
};

export const TOTP_VALUE_PLACEHOLDER = '••••••';

export const REMOVE_SECRET_MODAL_KEY = 'removeSecret';

export const SECRETS_CACHE = 'secrets';

export const LIST_OFFSET_DEBOUNCE = 250;

export const SENSITIVE_DATA_KEYS = ['password', 'note', 'file'] as const;

export const COPY_RESET_DELAY = 1500;

export const PASSWORD_CHECK_DEBOUNCE = 200;

export const MAX_PASSWORD_CHECK_LENGTH = 100;

export const WARNING_SAAS_SECRETS_COUNT = 40;
export const MAX_SAAS_SECRETS_COUNT = 50;

export const SECRETS_TOTAL_COUNT_HEADER = 'x-total-count';

export enum SecurityLevel {
    EXTRA_WEAK = 0,
    WEAK = 1,
    AVERAGE = 2,
    STRONG = 3,
    EXTRA_STRONG = 4,
}

export enum SecurityAge {
    LESS_THAN_ONE_YEAR = 0,
    ONE_YEAR = 1,
    MORE_THAN_TWO_YEARS = 2,
}

export enum SecurityLeaks {
    NO_LEAKS = 0,
    FEW_LEAKS = 1,
    MANY_LEAKS = 2,
}

export const UP_TO_LEAKS = 10;

export enum SecurityReport {
    LOW_LEVEL = 'lowLevel',
    HIGH_AGE = 'highAge',
    DUPLICITIES = 'duplicities',
    MANY_LEAKS = 'manyLeaks',
}

export const IGNORE_SECURITY_AUDIT_REPORT_MODAL = 'ignore-security-audit-report';

export const SecurityAuditGroupSize: ListItemSize = {
    desktop: 56,
    mobile: 56,
};

export const SecurityAuditSecretSize: ListItemSize = {
    desktop: 80,
    mobile: 110,
};

export const SecurityAuditSecretWithAdditionalSize: ListItemSize = {
    desktop: 95,
    mobile: 125,
};

export const SecurityAuditSecretWithAgeAndIndicatorSize: ListItemSize = {
    desktop: 95,
    mobile: 150,
};
