import type { TRuleWithTheme } from 'styles/theme';

export const buttons: TRuleWithTheme = () => ({
    marginTop: '2rem',
});

export const heading: TRuleWithTheme = () => ({
    marginBottom: '0.75rem',
});

export const icon: TRuleWithTheme = () => ({
    marginRight: '0.5rem',

    verticalAlign: 'sub',
});
