import type { TRuleWithTheme } from 'styles/theme';

export const container: TRuleWithTheme = ({ theme }) => ({
    display: 'flex',
    gap: '0.5rem',

    alignItems: 'center',
});
export const cannotEditMessage: TRuleWithTheme = ({ theme }) => ({
    maxWidth: '5.375rem',
    color: theme.colors.foregroundsTertiary,
});
