import { FormattedMessage } from 'react-intl';

import { Heading } from 'modules/ui';

import * as felaRules from './NewSecretHeading.rules';

export const NewSecretHeading = () => (
    <Heading size="big" elementType="h1" color="foregroundsPrimary" customStyle={felaRules.heading}>
        <FormattedMessage id="secret.new" />
    </Heading>
);
