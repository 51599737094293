import type { AnchorHTMLAttributes } from 'react';
import { useFela } from 'react-fela';
import { NavLink as RouterLink } from 'react-router-dom-v5-compat';
import type { NavLinkProps as RouteLinkProps } from 'react-router-dom-v5-compat';
import classNames from 'classnames';

import type { Theme } from 'styles/theme';

import { container, LinkType, LinkSize, externalLink } from './Link.rules';

interface CommonProps {
    size?: `${LinkSize}`;
    color?: keyof Theme['colors'];
    inheritColor?: boolean;
    customStyle?: typeof container;
}

export interface InternalLinkProps extends Omit<RouteLinkProps, 'color'>, CommonProps {
    type: 'router';
    overrideActiveClassName?: boolean;
}

export interface ExternalLinkProps extends Omit<AnchorHTMLAttributes<HTMLAnchorElement>, 'color'>, CommonProps {
    type: 'native';
}

export const Link = (props: InternalLinkProps | ExternalLinkProps) => {
    const { css } = useFela({
        size: props.size,
        color: props.color,
        inheritColor: props.inheritColor,
    });

    if (props.type === LinkType.ROUTER) {
        const { customStyle, className, type, size, color, overrideActiveClassName, ...rest } = props;
        return (
            <RouterLink
                className={classNames('Link', css(container, customStyle), { active: overrideActiveClassName })}
                {...rest}
            />
        );
    }

    const { customStyle, className, type, size, color, children, inheritColor, ...rest } = props;
    return (
        <a className={`Link ${css(externalLink, customStyle)}`} target="_blank" rel="noopener noreferrer" {...rest}>
            {children}
        </a>
    );
};
