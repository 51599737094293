import { all } from 'redux-saga/effects';

import { config } from 'config/config';

import { saga as petrusSaga } from '../petrus';

import { loginSaga } from './login';
import { logoutSaga } from './logout';
import { oauthRedirectStateRestorationSaga } from './oauthRedirectStateRestoration';
import { unauthorizedSaga } from './unauthorized';

import { switchDemoUserSaga } from './switchDemoUser';
import { trackLoggedDemoUserSaga } from './trackLoggedDemoUser';

export default function* () {
    yield all(
        [
            unauthorizedSaga(),
            petrusSaga(),
            loginSaga(),
            logoutSaga(),
            oauthRedirectStateRestorationSaga(),
            config.demo.enable && switchDemoUserSaga(),
            config.demo.enable && trackLoggedDemoUserSaga(),
        ].filter(Boolean),
    );
}
