import { useDispatch } from 'react-redux';

import { useAppSelector } from 'hooks/useAppSelector';

import { selectRemoveTagApi } from '../services/selectors';
import { removeTag } from '../services/actions';

export const useRemoveTag = (tag: string) => {
    const api = useAppSelector(state => selectRemoveTagApi(state, tag));

    const dispatch = useDispatch();
    return {
        api,
        remove: () => {
            dispatch(removeTag(tag));
        },
    };
};
