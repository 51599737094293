import { Field } from 'react-final-form';
import { useDispatch } from 'react-redux';
import { identity } from 'lodash';
import { FormattedMessage } from 'react-intl';
import type { ReactNode } from 'react';

import { FileInput, FormItem, InfoLabel } from 'modules/forms';
import { actions as messagesActions } from 'modules/messages';
import { Link } from 'modules/ui';
import { config } from 'config/config';

import { SUPPORTED_FILE_MIME, Fields } from '../../../constants';

export const SecretsField = () => {
    const dispatch = useDispatch();

    const label = (
        <InfoLabel>
            <FormattedMessage
                id="import.moreInfo"
                values={{
                    a: (parts: ReactNode[]) => (
                        <Link size="small" type="native" href={config.links.import} inheritColor>
                            {parts}
                        </Link>
                    ),
                }}
            />
        </InfoLabel>
    );

    return (
        <Field<File> name={Fields.SECRETS} allowNull initialValue={null} format={identity} parse={identity}>
            {({ input, meta }) => {
                const { value, onChange } = input;

                return (
                    <FormItem<File> meta={meta} label={label}>
                        <FileInput
                            file={value}
                            hasError={meta.submitFailed}
                            onFile={(file, action) => {
                                if (action === 'dropped') {
                                    onChange(file);
                                } else {
                                    if (action === 'unsupported') {
                                        dispatch(
                                            messagesActions.displayWarningMessage({
                                                message: {
                                                    id: 'import.unsupportedFile',
                                                },
                                            }),
                                        );
                                    }
                                    onChange(null);
                                }
                            }}
                            accept={SUPPORTED_FILE_MIME}
                        />
                    </FormItem>
                );
            }}
        </Field>
    );
};
