import { useFela } from 'react-fela';
import { FormattedMessage } from 'react-intl';
import { isEmpty } from 'lodash';

import { Label } from 'modules/forms';
import { Text } from 'modules/ui';

import { useSecurityAuditReport } from '../../hooks/useSecurityAuditReport';

import * as felaRules from './SecurityAuditReportOverview.rules';

export const SecurityAuditReportOverview = () => {
    const { css } = useFela();

    const report = useSecurityAuditReport();

    if (isEmpty(report)) {
        return null;
    }

    return (
        <div className={css(felaRules.container)}>
            <Text customStyle={felaRules.message} bold>
                <FormattedMessage id="securityAudit.overview.message" />
            </Text>
            <p>
                <Label>
                    <FormattedMessage id="securityAudit.overview.label" />
                </Label>
                <span className={css(felaRules.report)}>
                    {report.lowLevel ? (
                        <span className={css(felaRules.lowLevel)}>
                            <FormattedMessage
                                id="securityAudit.overview.lowLevel"
                                values={{
                                    count: report.lowLevel,
                                }}
                            />
                        </span>
                    ) : null}
                    {report.highAge ? (
                        <span className={css(felaRules.highAge)}>
                            <FormattedMessage
                                id="securityAudit.overview.highAge"
                                values={{
                                    count: report.highAge,
                                }}
                            />
                        </span>
                    ) : null}
                    {report.manyLeaks ? (
                        <span className={css(felaRules.manyLeaks)}>
                            <FormattedMessage
                                id="securityAudit.overview.manyLeaks"
                                values={{
                                    count: report.manyLeaks,
                                }}
                            />
                        </span>
                    ) : null}
                    {report.duplicities ? (
                        <span className={css(felaRules.duplicities)}>
                            <FormattedMessage
                                id="securityAudit.overview.duplicities"
                                values={{
                                    count: report.duplicities,
                                }}
                            />
                        </span>
                    ) : null}
                </span>
            </p>
        </div>
    );
};
