import { FormattedMessage } from 'react-intl';

import config from 'config';

import { IconType } from 'modules/ui';

import type { SecurityAuditLevelItem } from '../../types';

import { ListItemType, SecurityLevel } from '../../constants';

import { IgnoreIndicator } from '../IgnoreIndicator';

import { SecurityAuditGroupItem } from '../SecurityAuditGroupItem';
import { SecurityAuditSecretItem } from '../SecurityAuditSecretItem';

import { SecurityAuditItemRenderer } from '../SecurityAuditItemRenderer';
import type { SecurityAuditItemRendererProps } from '../SecurityAuditItemRenderer';

export interface SecurityAuditLevelItemRendererProps
    extends Omit<SecurityAuditItemRendererProps<SecurityAuditLevelItem>, 'children'> {}

export const iconTypeBySecurityLevel = {
    [SecurityLevel.EXTRA_WEAK]: IconType.STATUS_ALERT,
    [SecurityLevel.WEAK]: IconType.STATUS_ALERT,
    [SecurityLevel.AVERAGE]: IconType.STATUS_WARNING,
    [SecurityLevel.STRONG]: IconType.STATUS_BOLT,
    [SecurityLevel.EXTRA_STRONG]: IconType.STATUS_OK,
};

const NO_SECURITY_LEVEL = 'noSecurityLevel';

export const SecurityAuditLevelItemRenderer = (props: SecurityAuditLevelItemRendererProps) => (
    <SecurityAuditItemRenderer<SecurityAuditLevelItem> {...props}>
        {({ item, isFirstGroup, isLastInGroup }) => (
            <>
                {item.type === ListItemType.GROUP ? (
                    <SecurityAuditGroupItem
                        name={<FormattedMessage id={`password.strength.${item.securityLevel ?? NO_SECURITY_LEVEL}`} />}
                        count={item.count}
                        isFirstGroup={isFirstGroup}
                    />
                ) : (
                    <SecurityAuditSecretItem
                        id={item.id}
                        name={item.name}
                        icon={
                            !item.ignoreSecurityReport
                                ? iconTypeBySecurityLevel[item.securityLevel] ?? IconType.STATUS_NO_PASS
                                : null
                        }
                        isLastInGroup={isLastInGroup}
                        updateSecretRoute={config.routes.securityAuditLevelUpdateSecret}
                        additional={item.ignoreSecurityReport ? <IgnoreIndicator /> : null}
                    />
                )}
            </>
        )}
    </SecurityAuditItemRenderer>
);
