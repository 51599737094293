import { eventChannel } from 'redux-saga';
import { delay, race, select, take } from 'redux-saga/effects';

import { logger } from 'config/logger';

import { isOnlineSelector, updateNetworkStatus } from 'modules/network';

const TIMEOUT = 1000 * 60 * 30; // 30 minutes
const MINIMAL_TIMEOUT = TIMEOUT / 30; // 1 minute

const pageStateEmitter = () =>
    eventChannel(emitter => {
        document.onvisibilitychange = () => {
            emitter(document.visibilityState === 'visible');
        };
        return () => {};
    });

function* updateSw() {
    // The ready read-only property of the ServiceWorkerContainer interface provides a way
    // of delaying code execution until a service worker is active.
    // It returns a Promise that will never reject, and which waits indefinitely
    // until the ServiceWorkerRegistration associated with the current page has an active worker.
    // Once that condition is met, it resolves with the ServiceWorkerRegistration.
    // eslint-disable-next-line compat/compat
    const registration: ServiceWorkerRegistration = yield navigator.serviceWorker.ready;

    // From: https://developer.mozilla.org/en-US/docs/Web/API/ServiceWorkerRegistration/update
    // The update() method of the ServiceWorkerRegistration interface attempts to update the service worker.
    // It fetches the worker's script URL, and if the new worker is not byte-by-byte identical to the current worker,
    // it installs the new worker. The fetch of the worker bypasses any browser caches if the previous
    // fetch occurred over 24 hours ago.
    yield registration.update();
}

export function* updateChecker() {
    const pageState = pageStateEmitter();

    while (true) {
        const started = Date.now();

        yield race({
            networkState: take(updateNetworkStatus.toString()),
            pageState: take(pageState),
            timeout: delay(TIMEOUT),
        });

        const isOnline: boolean = yield select(isOnlineSelector);

        if (!isOnline) {
            continue;
        }

        const duration = Date.now() - started;

        if (duration < MINIMAL_TIMEOUT) {
            yield delay(MINIMAL_TIMEOUT - duration);
        }

        try {
            yield* updateSw();
        } catch (e) {
            logger.error(e);
        }
    }
}
