import { combineRules } from 'fela';

import type { TRuleWithTheme } from 'styles/theme';

import { SECRET_LIST_ITEM_SIZES } from '../../SecretsList';

export const container: TRuleWithTheme = ({ theme }) => ({
    width: '100%',

    paddingInline: '1rem',
    paddingBlock: '1.75rem',

    minHeight: SECRET_LIST_ITEM_SIZES.base,

    borderBottomColor: theme.colors.lightGrey,
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',

    '& .Heading': {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    },
});

export const topRowContainer: TRuleWithTheme = ({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '1rem',

    marginBottom: '0.75rem',

    min768: {
        marginBottom: '1rem',
    },
});

export const contentContainer: TRuleWithTheme = ({ theme }) => ({
    display: 'flex',
    flexDirection: 'column-reverse',
    justifyContent: 'space-between',
    gap: '1rem',

    min768: {
        flexDirection: 'row',
    },
});

export const secretFavicon: TRuleWithTheme = ({ theme }) => ({
    height: 24,
    width: 24,
});

export const titleWithFavicon: TRuleWithTheme = () => ({
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',

    overflow: 'hidden',
});

export const properties: TRuleWithTheme = () => ({
    display: 'flex',
    flexDirection: 'column',

    justifyContent: 'flex-start',

    columnGap: '2.5rem',
    rowGap: '0.5rem',

    min768: {
        flexDirection: 'row',
        columnGap: '5rem',
    },
});

export const propertyLabel: TRuleWithTheme = () => ({
    marginBottom: 0,

    whiteSpace: 'nowrap',
});

export const propertyText: TRuleWithTheme = () => ({
    maxWidth: '16rem',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
});

export const cryptedCharacters: TRuleWithTheme = combineRules(propertyText, () => ({
    letterSpacing: '0.125rem',
    fontSize: '1.125rem',
}));
