import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { useIntl } from 'react-intl';

import { api } from 'config/antonio';
import config from 'config';
import { actions as messagesActions } from 'modules/messages';

import { logger } from 'config/logger';

import type { CopyableSecretKeys, Secret, TOTP } from '../../../../types';

export function useCopySecretValue(id: Secret['id'], property: CopyableSecretKeys) {
    const { formatMessage } = useIntl();
    const dispatch = useDispatch();

    const [inProgress, setInProgress] = useState(false);
    const [success, setSuccess] = useState(false);

    const handleCopy = async () => {
        setInProgress(true);

        try {
            const clipboardItem = new ClipboardItem({
                'text/plain': fetchSecretValue(id, property).then(text => new Blob([text], { type: 'text/plain' })),
            });

            await navigator.clipboard.write([clipboardItem]);

            dispatch(
                messagesActions.displaySuccessMessage({
                    message: formatMessage({ id: 'copied' }),
                    options: { duration: 1 },
                }),
            );

            setSuccess(true);
        } catch (error) {
            dispatch(
                messagesActions.displayErrorMessage({
                    message: { id: 'error.secrets.copy' },
                }),
            );

            setSuccess(false);

            logger.error(error);
        }

        setInProgress(false);
    };

    return {
        inProgress,
        success,
        handleCopy,
    };
}

async function fetchSecretValue(id: Secret['id'], property: CopyableSecretKeys) {
    try {
        if (property === 'TOTP') {
            const response = await api.request<'GET', TOTP[]>({
                url: config.api.totp,
                method: 'GET',
                uriParams: {
                    id,
                },
            });

            const totp = response.data[0];

            const value = totp.code;
            return value;
        } else {
            const response = await api.request<'GET', Secret>({
                url: config.api.secret,
                method: 'GET',
                uriParams: {
                    id,
                },
            });

            const value = response.data[property];
            return value;
        }
    } catch (error) {
        logger.error(error);
        throw error;
    }
}
