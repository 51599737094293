import { createReducer } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { containerReducer, basicResetReducer } from '@ackee/redux-utils';

import type { Group, GroupById, GroupMember, GroupMemberById } from '../../types';

import { fetchGroupsSuccess, fetchGroupMembersSuccess, changeGroupVisibilityRequestSuccess } from '../actions';

export interface GroupsState {
    byIds: GroupById;
    ids: Group['id'][];
    filter?: string;
}

const groupsInitialState: GroupsState = {
    byIds: {},
    ids: [],
};

const groups = createReducer(groupsInitialState, builder => {
    builder
        .addCase(fetchGroupsSuccess, (state, action) => {
            const { byIds, ids } = action.payload;

            state.byIds = {
                ...state.byIds,
                ...byIds,
            };

            if (state.filter !== action.meta.filter) {
                state.filter = action.meta.filter;
            }

            if (action.meta.isNext) {
                state.ids = [...state.ids, ...ids];
            } else {
                state.ids = ids;
            }
        })
        .addCase(changeGroupVisibilityRequestSuccess, (state, action) => {
            const group = state.byIds[action.meta.groupId];

            if (group) {
                group.visible = action.payload.visible;
            }
        });
});

export interface GroupMembersState {
    byIds: GroupMemberById;
    ids: GroupMember['id'][];
}

const groupMembersInitialState: GroupMembersState = {
    byIds: {},
    ids: [],
};

const groupMembersChildReducer = createReducer(groupMembersInitialState, builder => {
    builder.addCase(fetchGroupMembersSuccess, (state, action) => {
        const { byIds, ids } = action.payload;
        return {
            byIds: {
                ...state.byIds,
                ...byIds,
            },
            ids,
        };
    });
});

const groupMembersContainer = containerReducer({
    actionTypes: [fetchGroupMembersSuccess.toString()],
    childReducer: groupMembersChildReducer,
    selectors: {
        itemId: action => action.meta.groupId,
    },
});

const groupMembers = basicResetReducer(groupMembersContainer, fetchGroupsSuccess.toString());

export const entitiesReducer = combineReducers({
    groups,
    groupMembers,
});
