import { useFela } from 'react-fela';

import { Slider as AntdSlider } from 'antd';
import type { SliderSingleProps as SliderProps } from 'antd/es/slider';

import * as felaRules from './Slider.rules';

export type { SliderProps };

export function Slider(props: SliderProps) {
    const { css } = useFela();

    return <AntdSlider {...props} className={css(felaRules.container)} />;
}
