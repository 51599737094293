import { FormattedMessage } from 'react-intl';

import { Button, Pane } from 'modules/ui';

import { useExport } from '../../hooks/useExport';

export const Export = () => {
    const { inProgress, handleExport } = useExport();

    return (
        <Pane titleId="export.title" messageId="export.message">
            <Button type="primary" loading={inProgress} onClick={handleExport}>
                <FormattedMessage id="export.button" />
            </Button>
        </Pane>
    );
};
