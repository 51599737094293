import type { TRuleWithTheme } from 'styles/theme';

export const container: TRuleWithTheme = () => ({
    position: 'relative',
    flex: 'auto',

    overflow: 'auto',

    display: 'flex',
    flexDirection: 'column',

    height: '100%',
});

export const listContainer: TRuleWithTheme = ({ theme }) => ({
    flex: 1,

    display: 'flex',

    borderTopColor: theme.colors.lightGrey,
    borderTopWidth: 1,
    borderTopStyle: 'solid',
});
