import { useEffect, useState } from 'react';
import { useFela } from 'react-fela';
import { FormattedMessage } from 'react-intl';

import { config } from 'config/config';

import { Button } from 'modules/ui';

import { card, title, text } from './Cta.rules';

const STICKY_BAR_ID = '0f9f958a-4fb2-46f4-8056-be07d14a3222';

export function Cta() {
    const { css } = useFela();
    const [visible, setVisibility] = useState(false);

    useEffect(() => {
        // @ts-expect-error
        window._ubeConfig = window._ubeConfig || {};
        const handleDismiss = id => {
            if (id === STICKY_BAR_ID) {
                setVisibility(true);
            }
        };

        // @ts-expect-error
        window._ubeConfig.onDismiss = handleDismiss;

        return () => {
            // @ts-expect-error
            delete window._ubeConfig.onDismiss;
        };
    }, []);

    if (!visible) {
        return null;
    }

    return (
        <div className={css(card)}>
            <h3 className={css(title)}>
                <FormattedMessage id="demo.cta.title" />
            </h3>

            <p className={css(text)}>
                <FormattedMessage id="demo.cta.text" />
            </p>

            <a href={config.demo.buyPageUrl} rel="noreferrer noopener" target="_blank">
                <Button type="primary">
                    <FormattedMessage id="demo.cta.button" />
                </Button>
            </a>
        </div>
    );
}
