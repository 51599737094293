import { useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useFela } from 'react-fela';

import { Button } from '../../Button';
import { Icon } from '../../Icon';
import { Text as UIText } from '../../typography/Text';
import { useCopyToClipboard } from '../../../hooks/useCopyToClipboard';

import type { TextProps } from '../Text';

import * as felaRules from './Secret.rules';
import type { CopyButtonProps } from '../CopyButton';

export interface SecretProps extends TextProps {
    type: Exclude<CopyButtonProps['type'], 'text'>;
}

export const Secret = ({ children, text, disabled = false, type }: SecretProps) => {
    const { css } = useFela({ disabled });

    const { handleCopy } = useCopyToClipboard();

    const [show, setShow] = useState(false);

    const toggleShowText = useCallback(() => {
        setShow(s => !s);
    }, []);

    return (
        <div className={css(felaRules.container)}>
            <div className={css(felaRules.secretContainer)}>
                <UIText customStyle={show ? felaRules.text : felaRules.cryptedText}>
                    {show ? children ?? text : <FormattedMessage id={`crypted.${type}`} />}
                </UIText>
                {disabled ? null : (
                    <Icon
                        onClick={toggleShowText}
                        type={!show ? 'eye' : 'eye-close'}
                        color={!show ? 'accentPrimary' : 'accentSecondary'}
                        width={24}
                        height={24}
                        customStyle={felaRules.icon}
                    />
                )}
            </div>
            <Button
                data-testid={`copy-button-${type}`}
                type="default"
                size="small"
                disabled={disabled}
                onClick={() => handleCopy(text)}
            >
                <FormattedMessage id="copy" />
            </Button>
            {disabled && (
                <UIText size="extraSmall" color="foregroundsTertiary" customStyle={felaRules.disabledWarningText}>
                    <FormattedMessage id="secret.password.autofillOnlyWarning" />
                </UIText>
            )}
        </div>
    );
};
