import { combineReducers, createReducer } from '@reduxjs/toolkit';

import type { Settings, Order } from '../../types';
import { loadSettingsSuccess, loadOrderSuccess, updateSettingsSuccess } from '../actions';

export const general = createReducer({} as Partial<Settings>, builder => {
    builder.addCase(loadSettingsSuccess, (state, action) => action.payload);
    builder.addCase(updateSettingsSuccess, (state, action) => ({
        ...state,
        ...action.payload,
    }));
});

export const order = createReducer(null as Order, builder => {
    builder.addCase(loadOrderSuccess, (state, action) => action.payload);
});

export const entitiesReducer = combineReducers({
    general,
    order,
});
