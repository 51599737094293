import { useDispatch } from 'react-redux';
import { useEffect } from 'react';

import { useAppSelector } from 'hooks/useAppSelector';

import { selectChangelog } from '../services/selectors';
import { fetchChangelog } from '../services/actions';

export const useChangelog = () => {
    const content = useAppSelector(selectChangelog);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchChangelog());
    }, [dispatch]);

    return content;
};
