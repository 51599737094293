import { useFela } from 'react-fela';

import { Icon } from 'modules/ui';

import { container } from './MenuButton.rules';

export enum MenuButtonType {
    OPEN = 'open',
    CLOSE = 'close',
}

const ICONS = {
    [MenuButtonType.OPEN]: <Icon type="menu" />,
    [MenuButtonType.CLOSE]: <Icon type="close" />,
};

export interface MenuButtonProps {
    type: `${MenuButtonType}`;
    customStyle?: typeof container;
    handleClick: () => void;
}

export const MenuButton = ({ type = MenuButtonType.CLOSE, customStyle, handleClick }: MenuButtonProps) => {
    const { css } = useFela();

    return (
        <button type="button" onClick={handleClick} className={css(container, customStyle)}>
            {ICONS[type]}
        </button>
    );
};
