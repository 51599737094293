const modulePrefix = 'messages';

export const actionTypes = {
    ADD_MESSAGE_REQUEST: `${modulePrefix}/ADD_MESSAGE_REQUEST`,
    ADD_MESSAGE_RESOLVE: `${modulePrefix}/ADD_MESSAGE_RESOLVE`,
    ADD_MESSAGE_REMOVE: `${modulePrefix}/ADD_MESSAGE_REMOVE`,

    REMOVE_MESSAGE: `${modulePrefix}/REMOVE_MESSAGE`,
    HIDE_MESSAGE: `${modulePrefix}/HIDE_MESSAGE`,
    CLEAR_MESSAGES: `${modulePrefix}/CLEAR_MESSAGES`,

    DISPLAY_SUCCESS_MESSAGE: `${modulePrefix}/DISPLAY_SUCCESS_MESSAGE`,
    DISPLAY_ERROR_MESSAGE: `${modulePrefix}/DISPLAY_ERROR_MESSAGE`,
    DISPLAY_INFO_MESSAGE: `${modulePrefix}/DISPLAY_INFO_MESSAGE`,
    DISPLAY_LOADING_MESSAGE: `${modulePrefix}/DISPLAY_LOADING_MESSAGE`,
    DISPLAY_WARNING_MESSAGE: `${modulePrefix}/DISPLAY_WARNING_MESSAGE`,
};
