import type { TRuleWithTheme } from 'styles/theme';

export const heading: TRuleWithTheme = () => ({
    marginBottom: '1rem',
});

export const text: TRuleWithTheme = () => ({
    marginBottom: '2rem',
});

export const buttons: TRuleWithTheme = () => ({
    display: 'flex',

    gap: '1.5rem',

    '& > .Button': {
        flex: 1,
    },
});
