import { FormattedMessage } from 'react-intl';
import { Field } from 'react-final-form';
import { identity } from 'lodash';

import { Label, FormItem, TextareaInput } from 'modules/forms';

import { Fields } from '../../../constants';

export interface NoteFieldProps {
    onChange?: () => void;
}

export const NoteField = ({ onChange }: NoteFieldProps) => {
    const name = Fields.NOTE;

    const label = (
        <Label>
            <FormattedMessage id={`secret.${name}.label`} />
        </Label>
    );

    return (
        <Field<string> name={name} format={identity} parse={identity}>
            {({ input, meta }) => {
                const { value, onFocus, onBlur } = input;

                return (
                    <FormItem<string> label={label} meta={meta}>
                        <TextareaInput
                            value={value}
                            onBlur={onBlur}
                            onFocus={onFocus}
                            onChange={e => {
                                input.onChange(e);
                                onChange?.();
                            }}
                            rows={5}
                        />
                    </FormItem>
                );
            }}
        </Field>
    );
};
