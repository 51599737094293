import { useFela } from 'react-fela';
import { FormattedMessage, useIntl } from 'react-intl';

import { permissionToRole, permissionByRole } from 'utilities/permission';

import { IconButton, Text } from 'modules/ui';
import { RoleDropdown } from 'modules/forms';

import type { Group } from '../../types';

import { GroupDetailButton } from '../GroupDetailButton';

import * as felaRules from './GroupItem.rules';

export interface GroupItemProps extends Group {
    onRemove?: (id: Group['id']) => void;
    onUpdate?: (item: Group) => void;
    editable?: boolean;
}

export const GroupItem = ({ id, name, email, accessPermissions, editable, onUpdate, onRemove }: GroupItemProps) => {
    const { css } = useFela();

    const { formatMessage } = useIntl();

    const accessRole = permissionToRole(accessPermissions);

    return (
        <li className={css(felaRules.container)}>
            <GroupDetailButton id={id} name={name} />
            {editable ? (
                <div className={css(felaRules.editOptionsContainer)}>
                    <RoleDropdown
                        value={accessRole}
                        onChange={newRole => {
                            onUpdate({
                                id,
                                name,
                                email,
                                accessPermissions: permissionByRole[newRole],
                            });
                        }}
                    />
                    <IconButton
                        aria-label={formatMessage({ id: 'remove' })}
                        type="ghost"
                        icon="trash"
                        customStyle={felaRules.trashButton}
                        onClick={() => {
                            onRemove(id);
                        }}
                    />
                </div>
            ) : (
                <Text size="small" color="foregroundsTertiary" customStyle={felaRules.roleText}>
                    <FormattedMessage id={`secret.permission.${accessRole}`} />
                </Text>
            )}
        </li>
    );
};
