import type { ReactNode } from 'react';
import { useFela } from 'react-fela';

import { Modal as AntModal } from 'antd';
import type { ModalProps as AntModalProps } from 'antd/es/modal';

import { Button, Icon } from 'modules/ui';
import type { ButtonProps } from 'modules/ui';

import { container, buttons } from './Modal.rules';

const MODAL_WIDTH = 338;

export interface ModalProps {
    open?: boolean;
    width?: number;
    closable?: boolean;
    children?: ReactNode;
    okButton?: ButtonProps;
    onClose?: AntModalProps['onCancel'];
    cancelButton?: ButtonProps;
    alignButtonsRight?: boolean;
    extend?: {
        container?: typeof container;
        buttons?: typeof buttons;
    };
}

export function Modal({
    open = false,
    width = MODAL_WIDTH,
    closable = false,
    alignButtonsRight = false,
    children,
    onClose,
    okButton,
    cancelButton,
    extend,
}: ModalProps) {
    const { css } = useFela({
        alignButtonsRight,
    });

    return (
        <AntModal
            width={width}
            wrapClassName={css(container, extend?.container)}
            open={open}
            footer={null}
            closable={closable}
            onCancel={onClose ?? cancelButton?.onClick}
            closeIcon={<Icon type="x" />}
            centered
            destroyOnClose
        >
            {children}
            <div className={css(buttons, extend?.buttons)}>
                {cancelButton ? <Button htmlType="button" {...cancelButton} /> : null}
                {okButton ? <Button htmlType="button" type="primary" {...okButton} /> : null}
            </div>
        </AntModal>
    );
}
